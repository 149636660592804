import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {ClientsService} from '../../../_services/clients.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ClientsNextInspectionNotification} from '../../../_interfaces/clients/clients-next-inspection-notification';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {APP_DATE_FORMATS, CustomDateAdapter} from '../../../_pipes/angular2-material-datepicker-custom-date';
import * as moment from 'moment';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';
import {Client} from '../../../_interfaces/clients/clients';
import {Reports} from '../../../_interfaces/reports/reports';

@Component({
	selector: 'app-clients-next-inspection-notification',
	templateUrl: './clients-next-inspection-notification.component.html',
	styleUrls: ['./clients-next-inspection-notification.component.scss'],
	animations: [routeAnimation],
	providers: [
		{
			provide: DateAdapter, useClass: CustomDateAdapter
		},
		{
			provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
		}
	]
})

export class ClientsNextInspectionNotificationComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public client: Client = null;
	public report: Reports = null;
	public client_id: number = null;
	public nextInspectionNotification: ClientsNextInspectionNotification = null;
	public dataLoaded: boolean = false;
	public isOffline: boolean = false;

	public nextInspectionNotificationForm = this.formBuilder.group({
		next_inspection_date: ['', Validators.required],
		next_inspection_date_input: [''],
		contact_company: [true],
		contact_persons_ids: this.formBuilder.array([]),
		custom_contacts: ['']
	});

	private _errorsSubscription: Subscription = null;
	private _activatedRouteSubscription: Subscription = null;

	constructor(public clientsService: ClientsService,
				public formsService: FormsService,
				private activatedRoute: ActivatedRoute,
				private formBuilder: FormBuilder,
				private previousUrlService: PreviousUrlService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.formsService.form = this.nextInspectionNotificationForm;

		this._errorsSubscription = this.clientsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.client_id = parseInt(params['client_id'], 10);

			this.clientsService.getClient(this.client_id).then((client: Client) => {
				if (typeof client !== 'undefined') {
					this.client = client;
					this.pageTitleService.setTitle(client.company_name + ' | Verstuur volgende inspectie notificatie | ' + environment.webappName);
					this.pageTitleService.setPageTitle(client.company_name);
				}
			});

			this.previousUrlService.setPreviousUrlIfNull('clients/' + this.client_id + '/view');

			this.clientsService.getNextInspectionNotificationDetails(this.client_id).then((details: ClientsNextInspectionNotification) => {
				if (typeof details !== 'undefined') {
					this.nextInspectionNotificationForm.get('next_inspection_date_input').setValue(new Date(details.next_inspection_date));

					if (details.id !== 0 && details.created !== 0 && details.updated !== 0 && details.report_nr !== null && details.inspector !== null && details.status !== null) {
						this.report = {
							client_id: details.client_id,
							company_name: details.company_name,
							id: details.id,
							inspector: details.inspector,
							type: <any>details.type,
							type_text: details.type,
							report_nr: details.report_nr,
							status: <any>details.status,
							created: details.created,
							updated: details.updated
						};
					}

					this.nextInspectionNotification = details;

					if (typeof details.contact_persons_ids !== 'undefined') {
						details.contact_persons_ids.forEach(contact_person => {
							let control = <FormArray>this.nextInspectionNotificationForm.controls.contact_persons_ids;
							control.push(new FormControl({value: contact_person.id, disabled: !contact_person.email}));
						});
					}

					this.formsService.populateForm(details);
					this.nextInspectionNotificationForm.get('next_inspection_date').setValue(moment(details.next_inspection_date).format('DD-MM-YYYY'));

					const el = this.nextInspectionNotificationForm.get('contact_company');
					if (details.email === '') {
						el.setValue(false);
						el.disable();
					} else {
						el.setValue(true);
						el.enable();
					}

					this.dataLoaded = true;
				} else {
					this.isOffline = true;
				}
			});
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}

	fixAngularScrewUpAndInsertActualValue(event, index) {
		if (event.checked) {
			this.nextInspectionNotificationForm.controls.contact_persons_ids['controls'][index].setValue(event.source.value);
		}
	}

	updateNextInspectionDate($event) {
		this.nextInspectionNotificationForm.get('next_inspection_date').setValue(moment($event.value).format('DD-MM-YYYY'));
	}
}
