import {Component, EventEmitter, Input, Output, AfterViewInit} from '@angular/core';
import {routeAnimation} from '../../route.animation';

@Component({
	selector: 'app-removable-photo',
	templateUrl: './removable-photo.component.html',
	styleUrls: ['./removable-photo.component.scss'],
	animations: [routeAnimation]
})

export class RemovablePhotoComponent implements AfterViewInit {

	@Input() id: number = null;
	@Input() shortcoming_id: number = null;
	@Input() photo: File = null;
	@Input() filename: string = null;
	@Input() size: number = null;

	@Output() destroy: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor() {
	}

	ngAfterViewInit() {
	}

	deletePhoto() {
		this.destroy.emit(true);
	}
}
