<form [formGroup]="changePasswordForm" (ngSubmit)="authenticationService.changeMyPassword(changePasswordForm.value)">
	<mat-form-field>
		<input matInput placeholder="Huidig wachtwoord" type="password" name="password" required formControlName="password">
		<mat-error *ngIf="formsService.getFieldError('password')">{{formsService.getFieldError('password')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="Nieuw wachtwoord" type="password" name="new_password" required formControlName="new_password">
		<mat-error *ngIf="formsService.getFieldError('new_password')">{{formsService.getFieldError('new_password')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="Herhaal nieuw wachtwoord" type="password" name="repeat_new_password" required formControlName="repeat_new_password">
		<mat-error *ngIf="formsService.getFieldError('repeat_new_password')">{{formsService.getFieldError('repeat_new_password')}}</mat-error>
	</mat-form-field>
	<button color="primary" mat-button [disabled]="!changePasswordForm.valid">WACHTWOORD AANPASSEN</button>
</form>
