import {Component} from '@angular/core';
import {PreviousUrlService} from '../../../_services/previous-url.service';

@Component({
	selector: 'app-my-account-router',
	templateUrl: './my-account-router.component.html'
})

export class MyAccountRouterComponent {
	constructor(public previousUrlService: PreviousUrlService) {}
}
