<mat-dialog-content>
	<p>Weet je zeker dat geselecteerd rapportage gekopieerd moet worden?</p>
	<mat-form-field>
		<mat-select id="report_type" [(ngModel)]="report_type" placeholder="Rapportage type" required>
			<mat-option *ngFor="let option of report_types" [value]="option.id">{{ option.text }}</mat-option>
		</mat-select>
	</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button color="grey" [mat-dialog-close]="false">ANNULEREN</button>
	<button mat-button color="primary" [mat-dialog-close]="true">BEVESTIGEN</button>
</mat-dialog-actions>
