<button *ngIf="reportEditable" mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/comments/create']">Nieuwe opmerkingen toevoegen</button>
<ng-container *ngIf="smallWidget === false">
	<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/' + (report_status === '/closed' ? report_status : '') + '/view']">Bekijk rapportage</button>
	<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + report_status]">Bekijk alle rapportages</button>
	<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
</ng-container>
<mat-nav-list *ngIf="reportComments.length" class="mat-nav-list-with-border">
	<div *ngIf="!smallWidget" infiniteScroll infiniteScrollDistance="1" (scrolled)="getComments()"></div>
	<mat-list-item *ngFor="let comment of reportComments">
		<div *ngIf="reportEditable" class="mat-list-content" [routerLink]="['/reports/' + report_type + '/' + report_id + '/comments/' + comment.id + '/edit']">
			<h4 matLine>{{ comment.created | datex:'DD-MM-YYYY' }} - {{ comment.inspector }}</h4>
			<a matLine>{{ comment.comment }}</a>
		</div>
		<div *ngIf="!reportEditable" class="mat-list-content">
			<h4 matLine>{{ comment.created | datex:'DD-MM-YYYY' }} - {{ comment.inspector }}</h4>
			<a matLine>{{ comment.comment }}</a>
		</div>
		<ng-container *ngIf="reportEditable && comment.account_user_id === user.id">
			<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<a mat-menu-item [routerLink]="['/reports/' + report_type + '/' + report_id + '/comments/' + comment.id + '/edit']">Aanpassen</a>
			</mat-menu>
		</ng-container>
	</mat-list-item>
	<a mat-list-item *ngIf="smallWidget === true && totalItems > 5" [routerLink]="['/reports/' + report_type + '/' + report_id + (report_status === '/closed' ? report_status : '') + '/comments']" class="mat-list-item-more">
		<span matLine>Bekijk meer ...</span>
		<button mat-icon-button>
			<mat-icon>chevron_right</mat-icon>
		</button>
	</a>
</mat-nav-list>
<div *ngIf="reportComments.length === 0">
	<br> Geen opmerkingen gevonden.
</div>
