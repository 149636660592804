/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clients-building-maps-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i6 from "@angular/material/icon";
import * as i7 from "./clients-building-maps-view.component";
import * as i8 from "../../../../_services/clients.service";
import * as i9 from "@angular/router";
import * as i10 from "../../../../_interceptors/api.service";
import * as i11 from "../../../../_services/previous-url.service";
import * as i12 from "../../../../_services/page-title.service";
var styles_ClientsBuildingMapsViewComponent = [i0.styles];
var RenderType_ClientsBuildingMapsViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientsBuildingMapsViewComponent, data: { "animation": [{ type: 7, name: "routeAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "400ms 150ms ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_ClientsBuildingMapsViewComponent as RenderType_ClientsBuildingMapsViewComponent };
function View_ClientsBuildingMapsViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "map"], ["class", "img-responsive"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.buildingMapImage, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ClientsBuildingMapsViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Actieve plattegrond"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientsBuildingMapsViewComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buildingMapImage; _ck(_v, 5, 0, currVal_0); }, null); }
function View_ClientsBuildingMapsViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "alert alert-danger"], ["fxFlex", ""], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i3.DefaultLayoutDirective, [i1.ElementRef, i4.StyleUtils, [2, i3.LayoutStyleBuilder], i4.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i3.DefaultFlexDirective, [i1.ElementRef, i4.StyleUtils, i4.LAYOUT_CONFIG, i3.FlexStyleBuilder, i4.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["warning"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Niet alle data is beschikbaar online, ga online om verder te gaan."]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = ""; _ck(_v, 2, 0, currVal_1); _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 4).inline; var currVal_3 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
export function View_ClientsBuildingMapsViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientsBuildingMapsViewComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientsBuildingMapsViewComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataLoaded; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.missingData.length; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ClientsBuildingMapsViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-clients-building-maps-view", [], [[40, "@routeAnimation", 0]], null, null, View_ClientsBuildingMapsViewComponent_0, RenderType_ClientsBuildingMapsViewComponent)), i1.ɵdid(1, 245760, null, 0, i7.ClientsBuildingMapsViewComponent, [i8.ClientsService, i9.ActivatedRoute, i10.ApiService, i11.PreviousUrlService, i12.PageTitleService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).routerTransition; _ck(_v, 0, 0, currVal_0); }); }
var ClientsBuildingMapsViewComponentNgFactory = i1.ɵccf("app-clients-building-maps-view", i7.ClientsBuildingMapsViewComponent, View_ClientsBuildingMapsViewComponent_Host_0, {}, {}, []);
export { ClientsBuildingMapsViewComponentNgFactory as ClientsBuildingMapsViewComponentNgFactory };
