import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { FormsService } from '../../../_services/forms.service';
import { ActivatedRoute } from '@angular/router';
import { APP_DATE_FORMATS } from '../../../_pipes/angular2-material-datepicker-custom-date';
import { PreviousUrlService } from '../../../_services/previous-url.service';
import { ReportsService } from '../../../_services/reports.service';
import { ClientsService } from '../../../_services/clients.service';
import { ApiService } from '../../../_interceptors/api.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { PageTitleService } from '../../../_services/page-title.service';
import { environment } from '../../../../environments/environment';
const ɵ0 = APP_DATE_FORMATS;
export class ReportsQuestionnaireComponent {
    constructor(reportsService, authenticationService, apiService, clientsService, formsService, activatedRoute, formBuilder, previousUrlService, pageTitleService) {
        this.reportsService = reportsService;
        this.authenticationService = authenticationService;
        this.apiService = apiService;
        this.clientsService = clientsService;
        this.formsService = formsService;
        this.activatedRoute = activatedRoute;
        this.formBuilder = formBuilder;
        this.previousUrlService = previousUrlService;
        this.pageTitleService = pageTitleService;
        this.routerTransition = true;
        this.report_id = null;
        this.report_type = null;
        this.dataLoaded = false;
        this.missingData = [];
        this.reportEditable = false;
        this.report = null;
        this.client = null;
        this.questionnaire = [];
        this.reportQuestionnaireForm = this.formBuilder.group({
            questions: this.formBuilder.group([])
        });
        this._errorsSubscription = null;
        this._activatedRouteSubscription = null;
        this._authenticationServiceSubscription = null;
    }
    ngOnInit() {
        this.apiService.connectionChanged.subscribe(online => {
            if (online && this.missingData.length) {
                this.missingData = [];
                this.loadData();
            }
        });
        this.formsService.form = this.reportQuestionnaireForm;
        this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
            this.formsService.processErrors(errors);
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params) => {
            this.report_type = params['report_type'];
            this.report_id = parseInt(params['report_id'], 10);
            this.reportsService.getReport(this.report_type, this.report_id).then((report) => {
                this._authenticationServiceSubscription = this.authenticationService.user.first().subscribe((user) => {
                    if (typeof report !== 'undefined' && typeof user !== 'undefined') {
                        this.pageTitleService.setTitle(report.report_nr + ' | Rapportage inspectieschema | ' + environment.webappName);
                        this.pageTitleService.setPageTitle(report.report_nr);
                        if (report.status !== 'closed') {
                            if (typeof report.edit_lock !== 'undefined' && typeof report.edit_lock_user_id !== 'undefined') {
                                if (report.edit_lock === true && (report.edit_lock_user_id === '' ||
                                    report.edit_lock_user_id === null ||
                                    report.edit_lock_user_id === 0 ||
                                    report.edit_lock_user_id === user.id ||
                                    user.type === 'kvvm-admin')) {
                                    this.reportEditable = true;
                                }
                            }
                        }
                    }
                });
            });
            this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_type + '/' + this.report_id + '/view');
            this.loadData();
        });
    }
    ngOnDestroy() {
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
        if (this._errorsSubscription !== null) {
            this._errorsSubscription.unsubscribe();
        }
        if (this._authenticationServiceSubscription !== null) {
            this._authenticationServiceSubscription.unsubscribe();
        }
    }
    loadData() {
        this.reportsService.getReport(this.report_type, this.report_id).then((report) => {
            if (typeof report !== 'undefined') {
                this.report = report;
                this.clientsService.getClient(report.client_id).then((client) => {
                    this.client = client;
                });
                this.reportsService.getWarehouseQuestionnaires(this.report_type).then((questionnaire) => {
                    if (typeof questionnaire !== 'undefined') {
                        if (typeof questionnaire.data !== 'undefined') {
                            questionnaire.data.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
                            this.reportsService.getWarehouseQuestionnaireAnswer(this.report_type, this.report_id).then((answers) => {
                                questionnaire.data.forEach(question_group => {
                                    let control = this.reportQuestionnaireForm.get('questions'), answer = (typeof answers !== 'undefined' ? (typeof answers.questions !== 'undefined' ? (typeof answers.questions['[' + question_group.id + '][status]'] !== 'undefined' ? answers.questions['[' + question_group.id + '][status]'] : null) : null) : null);
                                    control.addControl('[' + question_group.id + '][status]', new FormControl(answer));
                                    question_group['status'] = answer;
                                    if (typeof question_group.questions !== 'undefined') {
                                        question_group.questions.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
                                        if (typeof question_group.questions !== 'undefined') {
                                            question_group.questions.forEach(question => {
                                                let answer2 = (typeof answers !== 'undefined' ? (typeof answers.questions !== 'undefined' ? (typeof answers.questions['[' + question_group.id + '][' + question.id + ']'] !== 'undefined' ? answers.questions['[' + question_group.id + '][' + question.id + ']'] : null) : null) : 368);
                                                control.addControl('[' + question_group.id + '][' + question.id + ']', new FormControl(answer2));
                                                question['result'] = answer2;
                                            });
                                        }
                                    }
                                    this.questionnaire.push(question_group);
                                });
                            });
                        }
                        else {
                            this.missingData.push('Inspectieschema');
                        }
                    }
                    else {
                        this.missingData.push('Inspectieschema');
                    }
                });
                this.dataLoaded = true;
            }
            else {
                this.missingData.push('Rapportage');
            }
        });
    }
}
export { ɵ0 };
