import * as Sentry from '@sentry/browser';
import {environment} from '../../environments/environment';
import {ErrorHandler} from '@angular/core';
import {version} from '../../environments/version';

Sentry.init({
	dsn: environment.sentry,
	environment: environment.production ? 'live' : window.location.href.indexOf('localhost') === -1 ? 'staging' : 'local',
	release: version
});

export class SentryErrorHandler implements ErrorHandler {
	constructor() {
	}

	handleError(err: any): void {
		let error = err.originalError || err;

		if (environment.production) {
			if (this.isErrorOrErrorEvent(error)) {
				Sentry.captureException(error);
			} else {
				Sentry.captureMessage(error.error || error);
			}
		} else {
			throw error;
		}
	}

	isErrorOrErrorEvent (wat) {
		return Object.prototype.toString.call(wat) === '[object Error]' ||
			Object.prototype.toString.call(wat) === '[object ErrorEvent]' ||
			Object.prototype.toString.call(wat) === '[object Event]';
	}
}

export function provideErrorHandler() {
	if (environment.production) {
		return new SentryErrorHandler();
	} else {
		return new ErrorHandler();
	}
}
