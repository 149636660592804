import {Component, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../route.animation';
import {MatSnackBarRef} from '@angular/material';
import {LocalStorageService} from '../../_services/local-storage.service';
import * as moment from 'moment';

@Component({
	selector: 'app-ios-install-prompt',
	templateUrl: './ios-install-prompt.component.html',
	styleUrls: ['./ios-install-prompt.component.scss'],
	animations: [routeAnimation]
})
export class IosInstallPromptComponent implements OnInit, OnDestroy {

	constructor(private snackbarService: MatSnackBarRef<IosInstallPromptComponent>,
				private localStorageService: LocalStorageService) {
	}

	ngOnInit(): void {
	}

	ngOnDestroy(): void {
	}

	close(): void {
		this.snackbarService.dismiss();

		this.localStorageService.set('ios-install-prompt', moment().format('YYYY-MM-DD'));
	}
}
