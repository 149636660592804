import {Component} from '@angular/core';
import {PreviousUrlService} from '../../../_services/previous-url.service';

@Component({
	selector: 'app-planning-router',
	templateUrl: './planning-router.component.html'
})

export class PlanningRouterComponent {
	constructor(public previousUrlService: PreviousUrlService) {}
}
