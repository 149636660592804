<img src="/assets/images/logo.png" alt="KVVM" width="250" height="83">
<form [formGroup]="loginForm" (ngSubmit)="authenticationService.login(loginForm.value, returnUrl)">
	<mat-form-field>
		<input title="" matInput placeholder="E-Mail" type="email" name="email" required formControlName="email">
		<mat-error *ngIf="formsService.getFieldError('email')">{{formsService.getFieldError('email')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input title="" matInput placeholder="Wachtwoord" type="password" name="password" required formControlName="password">
		<mat-error *ngIf="formsService.getFieldError('password')">{{formsService.getFieldError('password')}}</mat-error>
	</mat-form-field>
	<button mat-raised-button color="primary" [disabled]="!loginForm.valid">LOG IN</button>
</form>
<a [routerLink]="['/forgot-password']">Wachtwoord vergeten?</a>
