import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {GeneralService} from '../../../_services/general.service';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {Reports} from '../../../_interfaces/reports/reports';
import {ReportsService} from '../../../_services/reports.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {ServerResponse} from '../../../_interfaces/server.response';
import {ApiService} from '../../../_interceptors/api.service';

@Component({
	selector: 'app-reports-reinspection-required-list',
	templateUrl: './reports-reinspection-required-list.component.html',
	styleUrls: ['./reports-reinspection-required-list.component.scss'],
	animations: [routeAnimation]
})

export class ReportsReinspectionRequiredListComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	private totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	private isLoading: boolean = false;
	private keepLoading: boolean = false;

	public reports: Reports[] = [];

	private _activatedRouteService: Subscription = null;
	private _authenticationService: Subscription = null;

	@Input() smallWidget: boolean = false;

	constructor(private reportsService: ReportsService,
				private apiService: ApiService,
				private previousUrlService: PreviousUrlService,
				private activatedRouteService: ActivatedRoute,
				private generalService: GeneralService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && !this.reports.length) {
				this.currentPage = 0;
				this.getReports(false);
			}
		});

		this.getReports(false);
	}

	ngOnDestroy(): void {
		if (this._activatedRouteService !== null) {
			this._activatedRouteService.unsubscribe();
		}

		if (this._authenticationService !== null) {
			this._authenticationService.unsubscribe();
		}
	}

	getReports(append_data: boolean = true) {
		if (!this.isLoading || this.keepLoading) {
			this.isLoading = true;
			this.keepLoading = false;
			this.currentPage++;

			this.reportsService.getReinspectionReports({
				start: (this.currentPage - 1) * this.generalService.itemsPerPage,
				length: this.generalService.itemsPerPage,
				'columns[0][search][value]': this.searchString
			}).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.recordsTotal !== 'undefined') {
						this.totalItems = data.recordsTotal;
					}
					if (append_data !== true) {
						this.reports = [];
					}
					this.reports.push(...<Reports[]>data.data);
				}
				this.isLoading = false;
			});
		}
	}

	copyReport(report_type: string, report_id: number) {
		this.reportsService.copyReport(report_type, report_id).then(() => {});
	}

	search(event) {
		this.searchString = event;
		this.currentPage = 0;
		this.getReports(false);
	}
}
