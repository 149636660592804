<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings']">
	Bekijk alle {{ report_type != 'tent' ? 'gebouwen' : 'tenten' }}
</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/view']">Bekijk rapportage</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/open']">Bekijk alle rapportages</button>
<ng-container *ngIf="dataLoaded && !missingData.length">
	<div *ngIf="!reportEditable" class="alert alert-danger" fxFlex fxLayoutAlign="center" fxLayout="row">
		<mat-icon>warning</mat-icon>
		<h2>Rapport is vergrendeld door een andere gebruiker. Om het te bewerken, moet u eerst de rapportage ontgrendelen.</h2>
	</div>
	<form class="with-action-buttons" [formGroup]="reportsBuildingEditForm" (ngSubmit)="reportsService.updateBuilding(report_type, report_id, building_id, reportsBuildingEditForm.value)">
		<mat-form-field>
			<input title="Naam {{ report_type != 'tent' ? 'gebouw' : 'tent' }}" matInput placeholder="Naam {{ report_type != 'tent' ? 'gebouw' : 'tent' }}" type="text" required formControlName="name">
			<mat-error *ngIf="formsService.getFieldError('name')">{{ formsService.getFieldError('name') }}</mat-error>
		</mat-form-field>
		<mat-form-field>
			<textarea title="Bijzonderheden" matInput placeholder="Bijzonderheden" formControlName="description" rows="8"></textarea>
			<mat-error *ngIf="formsService.getFieldError('description')">{{ formsService.getFieldError('description') }}</mat-error>
		</mat-form-field>
		<mat-checkbox *ngIf="report_type == 'tent'" color="primary" formControlName="company_inspection" value="1">Algemeen inspectie</mat-checkbox>
		<ng-container *ngIf="report_type != 'tent'">
			<div fxLayout="row">
				<mat-form-field class="search-input">
					<input matInput type="number" title="Aantal vierkante meter" placeholder="Aantal vierkante meter" formControlName="squares">
				</mat-form-field>
				<div style="line-height:65px;">m<sup>2</sup></div>
			</div>
			<mat-form-field>
				<mat-select placeholder="Selecteer een plattegrond" formControlName="building_map_id">
					<mat-option *ngFor="let buildingMap of clientBuildingMaps" [value]="buildingMap.id">{{ buildingMap.building_name }}</mat-option>
				</mat-select>
				<mat-error *ngIf="formsService.getFieldError('building_map_id')">{{ formsService.getFieldError('building_map_id') }}</mat-error>
			</mat-form-field>
			<ng-container *ngIf="report_type === 'warehouse'">
				<div class="divider"><span>Gegevens</span></div>
				<div fxLayout="row">
					<mat-form-field class="search-input">
						<input title="Zoeken" matInput placeholder="Zoeken" formControlName="query">
					</mat-form-field>
					<button type="reset" *ngIf="isFiltered" matSuffix mat-icon-button color="primary" class="search-button" (click)="clearSearchInput()">
						<mat-icon>close</mat-icon>
					</button>
					<button type="submit" matSuffix mat-icon-button color="primary" class="search-button">
						<mat-icon>search</mat-icon>
					</button>
				</div>
				<div class="checkboxes">
					<div *ngFor="let particular of particulars">
						<mat-checkbox color="primary" [class.hidden]="particular.visible === false" formControlName="lib_particular_id[{{ particular.id }}]" value="{{ particular.id }}" (change)="fixAngularScrewUpAndInsertActualValue($event, particular.id)">{{ particular.building_name }}</mat-checkbox>
						<mat-form-field *ngIf="showParticularTextarea.indexOf(particular.id) !== -1">
							<textarea title="" matInput rows="3" formControlName="particular_description[{{ particular.id }}]" placeholder="Toelichting"></textarea>
						</mat-form-field>
					</div>
				</div>
			</ng-container>
		</ng-container>
		<div class="action-buttons">
			<button *ngIf="reportEditable" color="primary" mat-button (click)="clearSearchInput()" [disabled]="!reportsBuildingEditForm.valid">OPSLAAN</button>
		</div>
	</form>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
