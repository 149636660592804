import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';

import {AuthenticationService} from '../../../_services/authentication.service';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
	selector: 'app-two-factor-authentication-regenerate-recovery-codes',
	templateUrl: './two-factor-authentication-regenerate-recovery-codes.component.html',
	styleUrls: ['./two-factor-authentication-regenerate-recovery-codes.component.scss'],
	animations: [routeAnimation]
})

export class TwoFactorAuthenticationRegenerateRecoveryCodesComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	public status: boolean|void = null;

	public otpForm = this.formBuilder.group({
		code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
	});

	private _errorSubscription: Subscription = null;

	constructor(public authenticationService: AuthenticationService,
				private formBuilder: FormBuilder,
				public formService: FormsService) {
	}

	ngOnInit(): void {
		this.authenticationService.getTwoFactorAuthStatus().then(response => {
			this.status = response.status;
		});

		this.formService.form = this.otpForm;

		this._errorSubscription = this.authenticationService.errors.subscribe(errors => {
			this.formService.processErrors(errors);
		});
	}

	ngOnDestroy() {
		if (this._errorSubscription !== null) {
			this._errorSubscription.unsubscribe();
		}
	}
}
