import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from '../../../../_services/general.service';
import { FormsService } from '../../../../_services/forms.service';
import { InspectorsService } from '../../../../_services/inspectors.service';
import * as moment from 'moment';
import { PreviousUrlService } from '../../../../_services/previous-url.service';
import { ActivatedRoute } from '@angular/router';
import { APP_DATE_FORMATS } from '../../../../_pipes/angular2-material-datepicker-custom-date';
import { ApiService } from '../../../../_interceptors/api.service';
const ɵ0 = APP_DATE_FORMATS;
export class InspectorsCalibrationsEditComponent {
    constructor(generalService, inspectorsService, formsService, formBuilder, activatedRoute, apiService, previousUrlService) {
        this.generalService = generalService;
        this.inspectorsService = inspectorsService;
        this.formsService = formsService;
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.apiService = apiService;
        this.previousUrlService = previousUrlService;
        this.routerTransition = true;
        this.calibration_id = null;
        this.dataLoaded = false;
        this.missingData = [];
        this.inspectorCalibrationForm = this.formBuilder.group({
            device_id: ['', [Validators.required, Validators.maxLength(100)]],
            device_brand: ['', [Validators.required, Validators.maxLength(100)]],
            device_description: ['', [Validators.required, Validators.maxLength(100)]],
            date_calibrated: ['', Validators.required],
            date_calibrated_input: [''],
            next_calibration_date: ['', Validators.required],
            next_calibration_date_input: [''],
            certificate_number: ['', Validators.required]
        });
        this._errorsSubscription = null;
        this._activatedRouteSubscription = null;
    }
    ngOnInit() {
        this.apiService.connectionChanged.subscribe(online => {
            if (online && this.missingData.length > 0) {
                this.missingData = [];
                this.loadData();
            }
        });
        this.formsService.form = this.inspectorCalibrationForm;
        this.previousUrlService.setPreviousUrlIfNull('/inspectors/calibrations');
        this._errorsSubscription = this.inspectorsService.errors.subscribe(errors => {
            this.formsService.processErrors(errors);
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params) => {
            this.calibration_id = parseInt(params['calibration_id'], 10);
            this.loadData();
        });
    }
    ngOnDestroy() {
        if (this._errorsSubscription !== null) {
            this._errorsSubscription.unsubscribe();
        }
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
    }
    updateInspectorDateCalibrated($event) {
        this.inspectorCalibrationForm.get('date_calibrated').setValue(moment($event.value).format('DD-MM-YYYY'));
    }
    updateInspectorNextCalibrationDate($event) {
        this.inspectorCalibrationForm.get('next_calibration_date').setValue(moment($event.value).format('DD-MM-YYYY'));
    }
    loadData() {
        this.inspectorsService.getInspectorCalibration(this.calibration_id).then((details) => {
            if (typeof details !== 'undefined') {
                this.inspectorCalibrationForm.get('date_calibrated').setValue(moment(details.date_calibrated).format('DD-MM-YYYY'));
                this.inspectorCalibrationForm.get('date_calibrated_input').setValue(new Date(details.date_calibrated));
                this.inspectorCalibrationForm.get('next_calibration_date').setValue(moment(details.next_calibration_date).format('DD-MM-YYYY'));
                this.inspectorCalibrationForm.get('next_calibration_date_input').setValue(new Date(details.next_calibration_date));
                this.formsService.populateForm(details);
                this.dataLoaded = true;
            }
            else {
                this.missingData.push('Kalibratie');
            }
        });
    }
}
export { ɵ0 };
