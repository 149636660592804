import {Component, HostBinding, Inject} from '@angular/core';
import {routeAnimation} from '../../route.animation';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
	selector: 'app-modal-copy-report',
	templateUrl: './modal-copy-report.component.html',
	styleUrls: ['./modal-copy-report.component.scss'],
	animations: [routeAnimation]
})

export class ModalCopyReportDialogComponent {
	@HostBinding('@routeAnimation') routerTransition = true;

	public report_types: Array<{id: string, text: string}> = [];
	public report_type: string = null;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
		if (this.data.original_report_type === 'warehouse') {
			this.report_types = [
				{
					id: 'new',
					text: 'Nieuwe inspectie'
				},
				{
					id: 'copy',
					text: 'Her-inspectie'
				},
				{
					id: 'buildings',
					text: 'Kopieer alleen gebouwen'
				}
			];
		} else if (this.data.original_report_type === 'ladders') {
			this.report_types = [
				{
					id: 'new',
					text: 'Nieuwe inspectie'
				}
			];
		} else {
			this.report_types = [
				{
					id: 'copy',
					text: 'Her-inspectie'
				}
			];
		}
	}
}
