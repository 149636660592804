import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {matchOtherValidator} from '../../../_validators/password-match';
import {FormsService} from '../../../_services/forms.service';
import {AuthenticationService} from '../../../_services/authentication.service';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss'],
	animations: [routeAnimation]
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	changePasswordForm = this.formBuilder.group({
		password: ['', Validators.required],
		new_password: ['', Validators.required],
		repeat_new_password: ['', [Validators.required, matchOtherValidator('new_password')]]
	});

	_errorsSubscription: any = null;

	constructor(public authenticationService: AuthenticationService,
				private formBuilder: FormBuilder,
				public formsService: FormsService) {
	}

	ngOnInit(): void {
		this.formsService.form = this.changePasswordForm;

		this._errorsSubscription = this.authenticationService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		this._errorsSubscription.unsubscribe();
	}
}
