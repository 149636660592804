import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute, Params} from '@angular/router';
import {GeneralService} from '../../../../_services/general.service';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {ReportsAttachments} from '../../../../_interfaces/reports/reports-attachments';
import {ReportsService} from '../../../../_services/reports.service';
import {ServerResponse} from '../../../../_interfaces/server.response';
import {ApiService} from '../../../../_interceptors/api.service';
import {PageTitleService} from '../../../../_services/page-title.service';
import {environment} from '../../../../../environments/environment';
import {Reports} from '../../../../_interfaces/reports/reports';
import {ClientsService} from '../../../../_services/clients.service';

@Component({
	selector: 'app-reports-attachments-list',
	templateUrl: './reports-attachments-list.component.html',
	styleUrls: ['./reports-attachments-list.component.scss'],
	animations: [routeAnimation]
})

export class ReportsAttachmentsListComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	private isLoading: boolean = false;
	private keepLoading: boolean = false;

	public report_type: string = null;
	public report_status: string = '/open';
	public report_id: number = null;
	public reportAttachments: ReportsAttachments[] = [];

	private _activatedRouteSubscription: Subscription = null;

	@Input() smallWidget: boolean = false;
	@Input() reportEditable: boolean = true;

	constructor(public reportsService: ReportsService,
				private apiService: ApiService,
				private generalService: GeneralService,
				private previousUrlService: PreviousUrlService,
				private activatedRoute: ActivatedRoute,
				private clientsService: ClientsService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && !this.reportAttachments.length) {
				this.currentPage = 0;
				this.getAttachments(false);
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];
			this.report_id = parseInt(params['report_id'], 10);

			if (this.smallWidget === false) {
				this.reportsService.getReport(this.report_type, this.report_id).then((report: Reports) => {
					if (typeof report !== 'undefined') {
						this.report_status = '/' + report.status;
						this.pageTitleService.setTitle(report.report_nr + ' | Rapportage bijlagen | ' + environment.webappName);
						this.pageTitleService.setPageTitle(report.report_nr);
					}
				});
			}

			this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_type + '/' + this.report_id + '/view');

			this.getAttachments(false);
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
	}

	getAttachments(append_data: boolean = true) {
		if (!this.isLoading || this.keepLoading) {
			this.isLoading = true;
			this.keepLoading = false;
			this.currentPage++;

			this.reportsService.getAttachments(this.report_type, this.report_id, {
				start: (this.currentPage - 1) * (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
				length: (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
				'columns[0][search][value]': this.searchString
			}).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.recordsTotal !== 'undefined') {
						this.totalItems = data.recordsTotal;
					}
					if (append_data === true) {
						this.reportAttachments.push(...<ReportsAttachments[]>data.data);
					} else {
						this.reportAttachments = <ReportsAttachments[]>data.data;
					}
				}
				this.isLoading = false;
			});
		}
	}

	deleteReportAttachment(report_type: string, report_id: number, attachment_id: number) {
		this.reportsService.deleteAttachment(report_type, report_id, attachment_id).then(status => {
			if (status === true) {
				let index = this.reportAttachments.findIndex(data => data.id === attachment_id);

				if (index !== -1) {
					this.reportAttachments.splice(index, 1);
				}
			}
		});
	}

	deleteClientAttachment(client_id: number, attachment_id: number) {
		this.clientsService.deleteAttachment(client_id, attachment_id).then(status => {
			if (status === true) {
				let index = this.reportAttachments.findIndex(data => data.id === attachment_id);

				if (index !== -1) {
					this.reportAttachments.splice(index, 1);
				}
			}
		});
	}

	search($event) {
		this.searchString = $event;
		this.currentPage = 0;
		this.getAttachments(false);
	}
}
