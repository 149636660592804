import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import {Router} from '@angular/router';
import {LocalStorageService} from '../_services/local-storage.service';
import {AuthenticationService} from '../_services/authentication.service';

@Injectable()

export class JwtInterceptor implements HttpInterceptor {
	constructor(private router: Router,
				private authenticationService: AuthenticationService,
				private localStorageService: LocalStorageService) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let user = this.localStorageService.get('currentUser');

		if (user !== null) {
			if (typeof user.jwt !== 'undefined') {
				if (user.jwt !== null && user.jwt !== '' && request.url.indexOf('https://maps.googleapis.com') === -1) {
					request = request.clone({
						setHeaders: {
							Authorization: `Bearer ${user.jwt}`
						}
					});
				}
			}
		}

		return next.handle(request).do((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
			}
		}, (err: any) => {
			if (err instanceof HttpErrorResponse) {
				if (err.status === 401) {
					this.authenticationService.logout(true);
				}
			}
		});
	}
}
