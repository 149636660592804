import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { FormsService } from '../../../../_services/forms.service';
import { ActivatedRoute } from '@angular/router';
import { APP_DATE_FORMATS } from '../../../../_pipes/angular2-material-datepicker-custom-date';
import { PreviousUrlService } from '../../../../_services/previous-url.service';
import { InspectorsService } from '../../../../_services/inspectors.service';
import { ClientsService } from '../../../../_services/clients.service';
import { debounceTime, tap } from 'rxjs/operators';
import { ApiService } from '../../../../_interceptors/api.service';
const ɵ0 = APP_DATE_FORMATS;
export class InspectorsInventoriesSendToClientComponent {
    constructor(inspectorsService, clientsService, formsService, activatedRoute, apiService, formBuilder, previousUrlService) {
        this.inspectorsService = inspectorsService;
        this.clientsService = clientsService;
        this.formsService = formsService;
        this.activatedRoute = activatedRoute;
        this.apiService = apiService;
        this.formBuilder = formBuilder;
        this.previousUrlService = previousUrlService;
        this.routerTransition = true;
        this.inventory_id = null;
        this.client_id = null;
        this.isFilteredClient = false;
        this.isLoadingClient = false;
        this.dataLoaded = false;
        this.missingData = [];
        // noinspection JSMismatchedCollectionQueryUpdate
        this.clientsFiltered = null;
        this.inspectorInventory = null;
        // noinspection JSMismatchedCollectionQueryUpdate
        this.clientsContactsPersons = [];
        this.inspectoryInventorySendToClientForm = this.formBuilder.group({
            client_id: [''],
            client_filter_input: [''],
            contact_company: [true],
            contact_persons_ids: this.formBuilder.array([]),
            custom_contacts: [''],
            message: ['']
        });
        this._errorsSubscription = null;
        this._activatedRouteSubscription = null;
    }
    ngOnInit() {
        this.apiService.connectionChanged.subscribe(online => {
            if (online && this.missingData.length > 0) {
                this.missingData = [];
                this.loadData();
            }
        });
        this.formsService.form = this.inspectoryInventorySendToClientForm;
        this._errorsSubscription = this.inspectorsService.errors.subscribe(errors => {
            this.formsService.processErrors(errors);
        });
        this.inspectoryInventorySendToClientForm
            .get('client_filter_input')
            .valueChanges
            .pipe(debounceTime(300), tap(() => this.isLoadingClient = true))
            .subscribe(value => {
            if (typeof value === 'string') {
                if (value !== '' && value !== null) {
                    if (value.length >= 3) {
                        this.clientsService.getClients({
                            start: 0,
                            length: -1,
                            'columns[0][search][value]': value
                        }).then((data) => {
                            this.clientsFiltered = data.data;
                            this.isLoadingClient = false;
                            this.isFilteredClient = true;
                        });
                    }
                    else {
                        this.isLoadingClient = false;
                    }
                }
                else {
                    this.isLoadingClient = false;
                }
            }
            else {
                this.isLoadingClient = false;
            }
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params) => {
            this.inventory_id = parseInt(params['inventory_id'], 10);
            this.previousUrlService.setPreviousUrlIfNull('/inspectors/inventories');
            this.loadData();
        });
    }
    ngOnDestroy() {
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
        if (this._errorsSubscription !== null) {
            this._errorsSubscription.unsubscribe();
        }
    }
    loadData() {
        this.inspectorsService.getInspectorInventory(this.inventory_id).then((details) => {
            if (typeof details !== 'undefined') {
                this.inspectorInventory = details;
                this.dataLoaded = true;
            }
            else {
                this.missingData.push('Inventarie gegevens');
            }
        });
    }
    getClientsContactsPersons(client) {
        this.client_id = client.id;
        const el = this.inspectoryInventorySendToClientForm.get('contact_company');
        if (client.email === '') {
            el.setValue(false);
            el.disable();
        }
        else {
            el.setValue(true);
            el.enable();
        }
        this.clientsService.getContactPersons(client.id).then((data) => {
            if (typeof data !== 'undefined') {
                this.clientsContactsPersons = data.data;
                if (data.data.length) {
                    data.data.forEach(contact_person => {
                        let control = this.inspectoryInventorySendToClientForm.get('contact_persons_ids');
                        control.push(new FormControl({ value: contact_person.id, disabled: !contact_person.email }));
                    });
                }
            }
        });
    }
    fixAngularScrewUpAndInsertActualValue(event, index) {
        if (event.checked) {
            this.inspectoryInventorySendToClientForm.controls.contact_persons_ids['controls'][index].setValue(event.source.value);
        }
    }
}
export { ɵ0 };
