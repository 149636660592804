<button mat-button color="primary-outline" [routerLink]="['/planning/']">Bekijk alle planning</button>
<ng-container *ngIf="dataLoaded && !missingData.length">
	<app-reports-view-small-widget [report]="report"></app-reports-view-small-widget>
	<app-clients-view-small-widget [client]="client" [showMap]="true"></app-clients-view-small-widget>
	<div class="divider"><span>Planning details</span></div>
	<dl *ngIf="planning">
		<dt>Start</dt>
		<dd>{{ planning.start | datex:'DD-MM-YYYY HH:mm' }}</dd>
		<dt>End</dt>
		<dd *ngIf="planning.end">{{ planning.end | datex:'DD-MM-YYYY HH:mm' }}</dd>
		<dd *ngIf="!planning.end">-</dd>
		<dt>Duration</dt>
		<dd *ngIf="planning.inspection_duration">{{ planning.inspection_duration }}</dd>
		<dd *ngIf="!planning.inspection_duration">-</dd>
	</dl>
	<button *ngIf="apiService.isOnline" mat-button color="primary-outline" [routerLink]="['/clients/' + client.id + '/view']">Bekijk klant</button>
	<button *ngIf="apiService.isOnline" mat-button color="primary-outline" [routerLink]="['/reports/' + report.type + '/' + report.id + '/view']">Bekijk rapportage</button>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
