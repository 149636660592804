import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AdminComponent} from './core/admin/admin.component';
import {LoginComponent} from './core/login/login/login.component';
import {ForgotPasswordComponent} from './core/login/forgot-password/forgot-password.component';
import {AuthGuard} from './_services/authguard.service';
import {ChangePasswordComponent} from './core/security/change-password/change-password.component';

import {environment} from '../environments/environment';
import {PageNotFoundComponent} from './core/page-not-found/page-not-found.component';
import {PageForbiddenComponent} from './core/page-forbidden/page-forbidden.component';
import {DashboardComponent} from './webapp/dashboard/dashboard.component';
import {ClientsListComponent} from './webapp/clients/clients-list/clients-list.component';
import {ClientsRouterComponent} from './webapp/clients/clients-router/clients-router.component';
import {ClientsCreateComponent} from './webapp/clients/clients-create/clients-create.component';
import {ClientsEditComponent} from './webapp/clients/clients-edit/clients-edit.component';
import {ClientsViewComponent} from './webapp/clients/clients-view/clients-view.component';
import {
	ClientsContactsPersonsViewComponent
} from './webapp/clients/clients-contacts-persons/clients-contacts-persons-view/clients-contacts-persons-view.component';
import {
	ClientsContactPersonsListComponent
} from './webapp/clients/clients-contacts-persons/clients-contacts-persons-list/clients-contacts-persons-list.component';
import {
	ClientsContactPersonsCreateComponent
} from './webapp/clients/clients-contacts-persons/clients-contacts-persons-create/clients-contacts-persons-create.component';
import {
	ClientsContactPersonsEditComponent
} from './webapp/clients/clients-contacts-persons/clients-contacts-persons-edit/clients-contacts-persons-edit.component';
import {
	ClientsAttachmentsListComponent
} from './webapp/clients/clients-attachments/clients-attachments-list/clients-attachments-list.component';
import {
	ClientsAttachmentsCreateComponent
} from './webapp/clients/clients-attachments/clients-attachments-create/clients-attachments-create.component';
import {
	ClientsAttachmentsEditComponent
} from './webapp/clients/clients-attachments/clients-attachments-edit/clients-attachments-edit.component';
import {
	ClientChangeNextInspectionDateComponent
} from './webapp/clients/clients-change-next-inspection-date/clients-change-next-inspection-date.component';
import {
	ClientsNextInspectionNotificationComponent
} from './webapp/clients/clients-next-inspection-notification/clients-next-inspection-notification.component';
import {InspectorsRouterComponent} from './webapp/inspectors/inspectors-router/inspectors-router.component';
import {InspectorsCalibrationsListComponent} from './webapp/inspectors/calibrations/calibrations-list/calibrations-list.component';
import {InspectorsCalibrationsCreateComponent} from './webapp/inspectors/calibrations/calibrations-create/calibrations-create.component';
import {InspectorsCalibrationsEditComponent} from './webapp/inspectors/calibrations/calibrations-edit/calibrations-edit.component';
import {InspectorsCalibrationsViewComponent} from './webapp/inspectors/calibrations/calibrations-view/calibrations-view.component';
import {InspectorsInventoriesListComponent} from './webapp/inspectors/inventories/inventories-list/inventories-list.component';
import {
	InspectorsInventoriesSendToClientComponent
} from './webapp/inspectors/inventories/inventories-send-to-client/inventories-send-to-client.component';
import {InspectorsInventoriesCreateComponent} from './webapp/inspectors/inventories/inventories-create/inventories-create.component';
import {InspectorsInspectionsListComponent} from './webapp/inspectors/inspections/inspections-list/inspections-list.component';
import {ReportsRouterComponent} from './webapp/reports/reports-router/reports-router.component';
import {ReportsListComponent} from './webapp/reports/reports-list/reports-list.component';
import {ReportsCreateComponent} from './webapp/reports/reports-create/reports-create.component';
import {ReportsEditComponent} from './webapp/reports/reports-edit/reports-edit.component';
import {
	ReportsAttachmentsCreateComponent
} from './webapp/reports/reports-attachments/reports-attachments-create/reports-attachments-create.component';
import {
	ReportsAttachmentsListComponent
} from './webapp/reports/reports-attachments/reports-attachments-list/reports-attachments-list.component';
import {ReportsBuildingsListComponent} from './webapp/reports/reports-buildings/reports-buildings-list/reports-buildings-list.component';
import {
	ReportsBuildingsCreateComponent
} from './webapp/reports/reports-buildings/reports-buildings-create/reports-buildings-create.component';
import {ReportsBuildingsEditComponent} from './webapp/reports/reports-buildings/reports-buildings-edit/reports-buildings-edit.component';
import {
	ReportsBuildingsReportsListComponent
} from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-list/reports-buildings-reports-list.component';
import {
	ReportsBuildingsReportsCreateComponent
} from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-create/reports-buildings-reports-create.component';
import {
	ReportsBuildingsReportsEditComponent
} from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-edit/reports-buildings-reports-edit.component';
import {ReportsCommentsCreateComponent} from './webapp/reports/reports-comments/reports-comments-create/reports-comments-create.component';
import {ReportsCommentsEditComponent} from './webapp/reports/reports-comments/reports-comments-edit/reports-comments-edit.component';
import {ReportsCommentsListComponent} from './webapp/reports/reports-comments/reports-comments-list/reports-comments-list.component';
import {ReportsViewComponent} from './webapp/reports/reports-view/reports-view.component';
import {ReportsQuestionnaireComponent} from './webapp/reports/reports-questionnaire/reports-questionnaire.component';
import {ReportsSendToClientComponent} from './webapp/reports/reports-send-to-client/reports-send-to-client.component';
import {ClientsReportsListComponent} from './webapp/clients/clients-reports-list/clients-reports-list.component';
import {ReportsClosedListComponent} from './webapp/reports/reports-list/reports-closed-list.component';
import {
	ReportsClosedAttachmentsListComponent
} from './webapp/reports/reports-attachments/reports-attachments-list/reports-closed-attachments-list.component';
import {ReportsClosedViewComponent} from './webapp/reports/reports-view/reports-closed-view.component';
import {
	ReportsClosedBuildingsListComponent
} from './webapp/reports/reports-buildings/reports-buildings-list/reports-closed-buildings-list.component';
import {
	ReportsClosedBuildingsReportsListComponent
} from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-list/reports-closed-buildings-reports-list.component';
import {
	ReportsClosedCommentsListComponent
} from './webapp/reports/reports-comments/reports-comments-list/reports-closed-comments-list.component';
import {
	ReportsClosedBuildingsReportsViewComponent
} from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-view/reports-closed-buildings-reports-view.component';
import {ReportsClosedQuestionnaireComponent} from './webapp/reports/reports-questionnaire/reports-closed-questionnaire.component';
import {
	ReportsReinspectionRequiredListComponent
} from './webapp/reports/reports-reinspection-required-list/reports-reinspection-required-list.component';
import {PlanningRouterComponent} from './webapp/planning/planning-router/planning-router.component';
import {PlanningListComponent} from './webapp/planning/planning-list/planning-list.component';
import {PlanningViewComponent} from './webapp/planning/planning-view/planning-view.component';
import {TwoFactorAuthenticationComponent} from './core/security/two-factor-authentication/two-factor-authentication.component';
import {
	TwoFactorAuthenticationEnableComponent
} from './core/security/two-factor-authentication-enable/two-factor-authentication-enable.component';
import {
	TwoFactorAuthenticationDisableComponent
} from './core/security/two-factor-authentication-disable/two-factor-authentication-disable.component';
import {
	TwoFactorAuthenticationRegenerateRecoveryCodesComponent
} from './core/security/two-factor-authentication-regenerate-recovery-codes/two-factor-authentication-regenerate-recovery-codes.component';
import {
	LoginTwoFactorAuthenticationComponent
} from './core/login/login-two-factor-authentication/login-two-factor-authentication.component';
import {MyAccountRouterComponent} from './core/security/my-account-router/my-account-router.component';
import {MyAccountComponent} from './core/security/my-account/my-account.component';
import {LoginWithRecoveryCodeComponent} from './core/login/login-with-recovery-code/login-with-recovery-code.component';
import {RecoverLostPasswordComponent} from './core/login/recover-lost-password/recover-lost-password.component';
import {
	ClientsBuildingMapsListComponent
} from './webapp/clients/clients-building-maps/clients-building-maps-list/clients-building-maps-list.component';
import {
	ClientsBuildingMapsCreateComponent
} from './webapp/clients/clients-building-maps/clients-building-maps-create/clients-building-maps-create.component';
import {
	ClientsBuildingMapsEditComponent
} from './webapp/clients/clients-building-maps/clients-building-maps-edit/clients-building-maps-edit.component';
import {
	ClientsBuildingMapsViewComponent
} from './webapp/clients/clients-building-maps/clients-building-maps-view/clients-building-maps-view.component';

const routes: Routes = [
		{
			path: 'login',
			component: LoginComponent,
			data: {
				title: 'Login | ' + environment.webappName
			}
		},
		{
			path: 'two-factor-authentication',
			component: LoginTwoFactorAuthenticationComponent,
			data: {
				title: 'Twee-factor-authenticatie login | ' + environment.webappName
			}
		},
		{
			path: 'login-with-recovery-code',
			component: LoginWithRecoveryCodeComponent,
			data: {
				title: 'Twee-factor herstelcode login | ' + environment.webappName
			}
		},
		{
			path: 'forgot-password',
			component: ForgotPasswordComponent,
			data: {
				title: 'Watchwoord vergeten | ' + environment.webappName
			}
		},
		{
			path: 'recover-lost-password/:recovery_token',
			component: RecoverLostPasswordComponent,
			data: {
				title: 'Watchwoord vergeten | ' + environment.webappName
			}
		},
		{
			path: '',
			component: AdminComponent,
			canActivate: [AuthGuard],
			canActivateChild: [AuthGuard],
			data: {
				title: environment.webappName
			},
			children: [
				{
					path: '',

					component: DashboardComponent,
					data: {
						title: 'Dashboard | ' + environment.webappName
					}
				},
				{
					path: 'my-account',
					component: MyAccountRouterComponent,
					data: {
						title: 'Mijn profiel aanpassen | ' + environment.webappName
					},
					children: [
						{
							path: '',
							component: MyAccountComponent,
							data: {
								title: 'Mijn profiel aanpassen | ' + environment.webappName
							}
						},
						{
							path: 'two-factor-authentication',
							component: TwoFactorAuthenticationComponent,
							data: {
								title: 'Twee-factor-authenticatie | ' + environment.webappName
							}
						},
						{
							path: 'two-factor-authentication/enable',
							component: TwoFactorAuthenticationEnableComponent,
							data: {
								title: 'Twee-factor-authenticatie inschakelen | ' + environment.webappName
							}
						},
						{
							path: 'two-factor-authentication/disable',
							component: TwoFactorAuthenticationDisableComponent,
							data: {
								title: 'Twee-factor-authenticatie uitschakelen | ' + environment.webappName
							}
						},
						{
							path: 'two-factor-authentication/regenerate-recovery-codes',
							component: TwoFactorAuthenticationRegenerateRecoveryCodesComponent,
							data: {
								title: 'Herstelcodes opnieuw genereren | ' + environment.webappName
							}
						}
					]
				},
				{
					path: 'change-password',
					component: ChangePasswordComponent,
					data: {
						title: 'Wachtwoord aanpassen | ' + environment.webappName
					}
				},
				{
					path: 'clients',
					component: ClientsRouterComponent,
					children: [
						{
							path: '',
							component: ClientsListComponent,
							data: {
								title: 'Klanten | ' + environment.webappName
							}
						},
						{
							path: 'create',
							component: ClientsCreateComponent,
							data: {
								title: 'Klant toevoegen | ' + environment.webappName
							}
						},
						{
							path: ':client_id/edit',
							component: ClientsEditComponent,
							data: {
								title: 'Klant aanpassen | ' + environment.webappName
							}
						},
						{
							path: ':client_id/view',
							component: ClientsViewComponent,
							data: {
								title: 'Bekijk klant | ' + environment.webappName
							}
						},
						{
							path: ':client_id/reports',
							component: ClientsReportsListComponent,
							data: {
								title: 'Bekijk klant rapportages | ' + environment.webappName
							}
						},
						{
							path: ':client_id/contacts-persons',
							component: ClientsContactPersonsListComponent,
							data: {
								title: 'Klant contactpersonen | ' + environment.webappName
							}
						},
						{
							path: ':client_id/contacts-persons/create',
							component: ClientsContactPersonsCreateComponent,
							data: {
								title: 'Klant contactperson toevoegen | ' + environment.webappName
							}
						},
						{
							path: ':client_id/contacts-persons/:contact_person_id/edit',
							component: ClientsContactPersonsEditComponent,
							data: {
								title: 'Klant contactperson aanpassen | ' + environment.webappName
							}
						},
						{
							path: ':client_id/contacts-persons/:contact_person_id/view',
							component: ClientsContactsPersonsViewComponent,
							data: {
								title: 'Klant contactperson bekijk | ' + environment.webappName
							}
						},
						{
							path: ':client_id/attachments',
							component: ClientsAttachmentsListComponent,
							data: {
								title: 'Klant bijlagen | ' + environment.webappName
							}
						},
						{
							path: ':client_id/attachments/create',
							component: ClientsAttachmentsCreateComponent,
							data: {
								title: 'Klant bijlage toevoegen | ' + environment.webappName
							}
						},
						{
							path: ':client_id/attachments/:attachment_id/edit',
							component: ClientsAttachmentsEditComponent,
							data: {
								title: 'Klant bijlage aanpassen | ' + environment.webappName
							}
						},
						{
							path: ':client_id/next-inspection-notification',
							component: ClientsNextInspectionNotificationComponent,
							data: {
								title: 'Verstuur volgende inspectie notificatie | ' + environment.webappName
							}
						},
						{
							path: ':client_id/change-next-inspection-date',
							component: ClientChangeNextInspectionDateComponent,
							data: {
								title: 'Aanpassen aankomende inspectie datum | ' + environment.webappName
							}
						},
						{
							path: ':client_id/buildings-maps',
							component: ClientsBuildingMapsListComponent,
							data: {
								title: 'Klant plattegrond | ' + environment.webappName
							}
						},
						{
							path: ':client_id/buildings-maps/create',
							component: ClientsBuildingMapsCreateComponent,
							data: {
								title: 'Klant plattegrond toevoegen | ' + environment.webappName
							}
						},
						{
							path: ':client_id/buildings-maps/:building_map_id/edit',
							component: ClientsBuildingMapsEditComponent,
							data: {
								title: 'Klant plattegrond aanpassen | ' + environment.webappName
							}
						},
						{
							path: ':client_id/buildings-maps/:building_map_id/view',
							component: ClientsBuildingMapsViewComponent,
							data: {
								title: 'Klant plattegrond bekijk | ' + environment.webappName
							}
						},
					]
				},
				{
					path: 'inspectors',
					component: InspectorsRouterComponent,
					children: [
						{
							path: '',
							pathMatch: 'full',
							redirectTo: 'calibrations'
						},
						{
							path: 'calibrations',
							component: InspectorsCalibrationsListComponent,
							data: {
								title: 'Kalibratie | ' + environment.webappName
							}
						},
						{
							path: 'calibrations/create',
							component: InspectorsCalibrationsCreateComponent,
							data: {
								title: 'Nieuwe kalibratie toevoegen | ' + environment.webappName
							}
						},
						{
							path: 'calibrations/:calibration_id/edit',
							component: InspectorsCalibrationsEditComponent,
							data: {
								title: 'Aanpassen kalibratie | ' + environment.webappName
							}
						},
						{
							path: 'calibrations/:calibration_id/view',
							component: InspectorsCalibrationsViewComponent,
							data: {
								title: 'Kalibratie gegevens | ' + environment.webappName
							}
						},
						{
							path: 'inventories',
							component: InspectorsInventoriesListComponent,
							data: {
								title: 'Inventaris | ' + environment.webappName
							}
						},
						{
							path: 'inventories/create',
							component: InspectorsInventoriesCreateComponent,
							data: {
								title: 'Inventaris toevoegen | ' + environment.webappName
							}
						},
						{
							path: 'inventories/:inventory_id/send-to-client',
							component: InspectorsInventoriesSendToClientComponent,
							data: {
								title: 'Verstuur inventaris naar klant | ' + environment.webappName
							}
						},
						{
							path: 'inspections',
							component: InspectorsInspectionsListComponent,
							data: {
								title: 'Inspecties | ' + environment.webappName
							}
						}
					]
				},
				{
					path: 'reports',
					component: ReportsRouterComponent,
					children: [
						{
							path: '',
							pathMatch: 'full',
							redirectTo: 'magazijn/open'
						},
						{
							path: 'reinspection-required',
							component: ReportsReinspectionRequiredListComponent,
							data: {
								title: 'Herinspectie nodig | ' + environment.webappName
							}
						},
						{
							path: ':report_type',
							component: ReportsListComponent,
							data: {
								title: 'Rapportages | ' + environment.webappName
							}
						},
						{
							path: ':report_type/create',
							component: ReportsCreateComponent,
							data: {
								title: 'Nieuwe rapportage toevoegen | ' + environment.webappName
							}
						},
						{
							path: ':report_type/create/:client_id',
							component: ReportsCreateComponent,
							data: {
								title: 'Nieuwe rapportage toevoegen | ' + environment.webappName
							}
						},
						{
							path: ':report_type/closed',
							component: ReportsClosedListComponent,
							data: {
								title: 'Rapportages | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/closed/view',
							component: ReportsClosedViewComponent,
							data: {
								title: 'Bekijk rapportage | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/closed/attachments',
							component: ReportsClosedAttachmentsListComponent,
							data: {
								title: 'Rapportage bijlagen | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/closed/comments',
							component: ReportsClosedCommentsListComponent,
							data: {
								title: 'Rapportage opmerking | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/closed/questionnaire',
							component: ReportsClosedQuestionnaireComponent,
							data: {
								title: 'Rapportage inspectieschema | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/closed/buildings',
							component: ReportsClosedBuildingsListComponent,
							data: {
								title: 'Rapportage gebouwen | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/closed/buildings/:building_id/reports',
							component: ReportsClosedBuildingsReportsListComponent,
							data: {
								title: 'Gebouw rapportages | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/closed/buildings/:building_id/reports',
							component: ReportsClosedBuildingsReportsListComponent,
							data: {
								title: 'Gebouw rapportages | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/closed/buildings/:building_id/reports/:building_report_id/view',
							component: ReportsClosedBuildingsReportsViewComponent,
							data: {
								title: 'Bekijk gebouw rapportage | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_status',
							component: ReportsListComponent,
							data: {
								title: 'Rapportages | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/edit',
							component: ReportsEditComponent,
							data: {
								title: 'Aanpassen Rapportage | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/view',
							component: ReportsViewComponent,
							data: {
								title: 'Bekijk rapportage | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/attachments',
							component: ReportsAttachmentsListComponent,
							data: {
								title: 'Rapportage bijlagen | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/attachments/create',
							component: ReportsAttachmentsCreateComponent,
							data: {
								title: 'Rapportage bijlage toevoegen | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/buildings',
							component: ReportsBuildingsListComponent,
							data: {
								title: 'Rapportage gebouwen | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/buildings/create',
							component: ReportsBuildingsCreateComponent,
							data: {
								title: 'Nieuw rapportage gebouw | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/buildings/:building_id/edit',
							component: ReportsBuildingsEditComponent,
							data: {
								title: 'Aanpassen rapportage gebouw | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/buildings/:building_id/reports',
							component: ReportsBuildingsReportsListComponent,
							data: {
								title: 'Gebouw rapportages | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/buildings/:building_id/reports/create',
							component: ReportsBuildingsReportsCreateComponent,
							data: {
								title: 'Nieuw gebouw rapportage | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/buildings/:building_id/reports/:building_report_id/edit',
							component: ReportsBuildingsReportsEditComponent,
							data: {
								title: 'Aanpassen gebouw rapportage | ' + environment.webappName
							}
						},
						/*
												{
													path: ':report_type/:report_id/buildings/:buiding_id/reports/:building_report_id/view',
													component: ReportsBuildingsReportsViewComponent,
													data: {
														title: 'Bekijk gebouw rapportage | ' + environment.webappName
													}
												},
						*/
						{
							path: ':report_type/:report_id/comments',
							component: ReportsCommentsListComponent,
							data: {
								title: 'Rapportage opmerking | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/comments/create',
							component: ReportsCommentsCreateComponent,
							data: {
								title: 'Rapportage opmerking toevoegen | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/comments/:comment_id/edit',
							component: ReportsCommentsEditComponent,
							data: {
								title: 'Aanpassen rapportage opmerking | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/questionnaire',
							component: ReportsQuestionnaireComponent,
							data: {
								title: 'Rapportage inspectieschema | ' + environment.webappName
							}
						},
						{
							path: ':report_type/:report_id/send-to-client',
							component: ReportsSendToClientComponent,
							data: {
								title: 'Verstuur rapportage naar klant | ' + environment.webappName
							}
						}
					]
				},
				{
					path: 'planning',
					component: PlanningRouterComponent,
					children: [
						{
							path: '',
							component: PlanningListComponent,
							data: {
								title: 'Mijn planning | ' + environment.webappName
							}
						},
						{
							path: ':planning_id/view',
							component: PlanningViewComponent,
							data: {
								title: 'Bekijk planning | ' + environment.webappName
							}
						}
					]
				},
				{
					path: '403',
					component: PageForbiddenComponent,
					data: {
						title: 'Forbidden | ' + environment.webappName
					}
				},
				{
					path: '**',
					component: PageNotFoundComponent,
					data: {
						title: 'Not found | ' + environment.webappName
					}
				}
			]
		}
	]
;

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: []
})
export class RoutingModule {
}
