<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/view']">Bekijk rapportage</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/open']">Bekijk alle rapportages</button>
<div *ngIf="!reportEditable" class="alert alert-danger" fxFlex fxLayoutAlign="center" fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Rapport is vergrendeld door een andere gebruiker. Om het te bewerken, moet u eerst de rapportage ontgrendelen.</h2>
</div>
<form class="with-action-buttons" [formGroup]="reportCommentCreateForm" (ngSubmit)="reportsService.createComment(report_type, report_id, reportCommentCreateForm.value)">
	<mat-form-field>
		<textarea matInput placeholder="Opmerkingen" required formControlName="comment" rows="8"></textarea>
		<mat-error *ngIf="formsService.getFieldError('comment')">{{formsService.getFieldError('comment')}}</mat-error>
	</mat-form-field>
	<div class="action-buttons">
		<button *ngIf="reportEditable" color="primary" mat-button [disabled]="!reportCommentCreateForm.valid">OPSLAAN</button>
	</div>
</form>
