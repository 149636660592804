import {Component, HostBinding} from '@angular/core';
import {routeAnimation} from '../../route.animation';

@Component({
	selector: 'app-simple-modal',
	templateUrl: './simple-modal.component.html',
	styleUrls: ['./simple-modal.component.scss'],
	animations: [routeAnimation]
})

export class SimpleModalDialogComponent {
	@HostBinding('@routeAnimation') routerTransition = true;

	public id: string = '';
	public text: string = '';
	public action_type: string = 'confirm';

	constructor() {
	}
}
