<ng-container *ngIf="dataLoaded">
	<div class="divider"><span>Bijlage gegevens</span></div>
	<form class="with-action-buttons" [formGroup]="clientsAttachmentsEditForm" (ngSubmit)="clientsService.updateAttachment(client_id, attachment_id, clientsAttachmentsEditForm.value)">
		<mat-form-field>
			<input title="" matInput placeholder="Titel" type="text" required formControlName="title">
			<mat-error *ngIf="formsService.getFieldError('title')">{{formsService.getFieldError('title')}}</mat-error>
		</mat-form-field>
		<mat-form-field>
			<textarea title="" matInput placeholder="Omschrijving" formControlName="description" rows="8"></textarea>
			<mat-error *ngIf="formsService.getFieldError('description')">{{formsService.getFieldError('description')}}</mat-error>
		</mat-form-field>
		<div class="divider"><span>Toevoegen aan</span></div>
		<div class="checkbox-container">
			<mat-checkbox color="primary" formControlName="append_warehouse" value="1">Magazijn</mat-checkbox>
		</div>
		<div class="checkbox-container">
			<mat-checkbox color="primary" formControlName="append_ladder" value="1">Trappen</mat-checkbox>
		</div>
		<div class="checkbox-container">
			<mat-checkbox color="primary" formControlName="append_tent" value="1">Tent</mat-checkbox>
		</div>
		<div class="action-buttons">
			<button color="primary" mat-button [disabled]="!clientsAttachmentsEditForm.valid">OPSLAAN</button>
		</div>
	</form>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
