<form class="with-action-buttons" [formGroup]="inspectorCalibrationForm" (ngSubmit)="inspectorsService.createInspectorCalibration(inspectorCalibrationForm.value)">
	<div class="divider"><span>Apparaat gegevens</span></div>
	<mat-form-field>
		<input matInput placeholder="ID" type="text" required formControlName="device_id">
		<mat-error *ngIf="formsService.getFieldError('device_id')">{{formsService.getFieldError('device_id')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="Merk" type="text" required formControlName="device_brand">
		<mat-error *ngIf="formsService.getFieldError('device_brand')">{{formsService.getFieldError('device_brand')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="Meet type materiaal" type="text" required formControlName="device_description">
		<mat-error *ngIf="formsService.getFieldError('device_description')">{{formsService.getFieldError('device_description')}}</mat-error>
	</mat-form-field>
	<div class="divider"><span>Kalibratie gegevens</span></div>
	<mat-form-field>
		<input type="hidden" formControlName="date_calibrated">
		<input matInput placeholder="Kalibratie datum" formControlName="date_calibrated_input" [matDatepicker]="inspectorDateCalibrated" required readonly (click)="inspectorDateCalibrated.open()" (dateChange)="updateInspectorDateCalibrated($event)">
		<mat-datepicker #inspectorDateCalibrated></mat-datepicker>
		<mat-error *ngIf="formsService.getFieldError('date_calibrated')">{{formsService.getFieldError('date_calibrated')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input type="hidden" formControlName="next_calibration_date">
		<input matInput placeholder="Volgende datum kalibratie" formControlName="next_calibration_date_input" [matDatepicker]="inspectorNextCalibrationDate" required readonly (click)="inspectorNextCalibrationDate.open()" (dateChange)="updateInspectorNextCalibrationDate($event)">
		<mat-datepicker #inspectorNextCalibrationDate></mat-datepicker>
		<mat-error *ngIf="formsService.getFieldError('next_calibration_date')">{{formsService.getFieldError('next_calibration_date')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="Certificaat nummer" type="text" required formControlName="certificate_number">
		<mat-error *ngIf="formsService.getFieldError('certificate_number')">{{formsService.getFieldError('certificate_number')}}</mat-error>
	</mat-form-field>
	<div class="action-buttons">
		<button color="primary" mat-button [disabled]="!inspectorCalibrationForm.valid">OPSLAAN</button>
	</div>
</form>
