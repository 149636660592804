<img src="/assets/images/logo.png" alt="KVVM" width="250" height="83">
<form [formGroup]="otpForm" (ngSubmit)="authenticationService.loginTwoFactorAuthentication(otpForm.value)">
	<input type="hidden" name="sessionID">
	<p>Voer de code van uw authenticator-app in.</p>
	<mat-form-field>
		<input matInput placeholder="Code" type="number" required formControlName="code">
		<mat-error *ngIf="formService.getFieldError('code')">{{ formService.getFieldError('code') }}</mat-error>
	</mat-form-field>
	<button color="primary" mat-raised-button [disabled]="!otpForm.valid">BEVESTIGEN</button>
</form>
<a [routerLink]="['/login-with-recovery-code']">Inloggen met herstelcode?</a>
