import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {ClientsService} from '../../../_services/clients.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ClientsChangeNextInspectionDate} from '../../../_interfaces/clients/clients-change-next-inspection-date';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {APP_DATE_FORMATS, CustomDateAdapter} from '../../../_pipes/angular2-material-datepicker-custom-date';
import * as moment from 'moment';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';
import {Client} from '../../../_interfaces/clients/clients';

@Component({
	selector: 'app-clients-change-next-inspection-date',
	templateUrl: './clients-change-next-inspection-date.component.html',
	styleUrls: ['./clients-change-next-inspection-date.component.scss'],
	animations: [routeAnimation],
	providers: [
		{
			provide: DateAdapter, useClass: CustomDateAdapter
		},
		{
			provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
		}
	]
})

export class ClientChangeNextInspectionDateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public client_id: number = null;
	public dataLoaded: boolean = false;
	public isOffline: boolean = false;

	public nextInspectionDetails: ClientsChangeNextInspectionDate = null;

	public changeNextInspectionDateForm = this.formBuilder.group({
		next_inspection_date: ['', Validators.required],
		next_inspection_date_input: ['', Validators.required]
	});

	private _errorsSubscription: Subscription = null;
	private _activatedRouteSubscription: Subscription = null;

	constructor(public clientsService: ClientsService,
				public formsService: FormsService,
				private activatedRoute: ActivatedRoute,
				private previousUrlService: PreviousUrlService,
				private formBuilder: FormBuilder,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.formsService.form = this.changeNextInspectionDateForm;

		this._errorsSubscription = this.clientsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.client_id = parseInt(params['client_id'], 10);

			this.clientsService.getClient(this.client_id).then((client: Client) => {
				if (typeof client !== 'undefined') {
					this.pageTitleService.setTitle(client.company_name + ' | Aanpassen aankomende inspectie datum | ' + environment.webappName);
					this.pageTitleService.setPageTitle(client.company_name);
				}
			});

			this.previousUrlService.setPreviousUrlIfNull('/clients/' + this.client_id + '/view');

			this.clientsService.getChangeNextInspectionDate(this.client_id).then((nextInspectionDetails: ClientsChangeNextInspectionDate) => {
				if (typeof nextInspectionDetails !== 'undefined') {
					this.changeNextInspectionDateForm.get('next_inspection_date').setValue(new Date(nextInspectionDetails.next_inspection_date));
					this.nextInspectionDetails = nextInspectionDetails;

					this.dataLoaded = true;
				} else {
					this.isOffline = true;
				}
			});
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}

	updateNextInspectionDate($event) {
		this.changeNextInspectionDateForm.get('next_inspection_date').setValue(moment($event.value).format('DD-MM-YYYY'));
	}
}
