import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {Languages} from '../../../_interfaces/languages';
import {matchOtherValidator} from '../../../_validators/password-match';
import {FormsService} from '../../../_services/forms.service';
import {GeneralService} from '../../../_services/general.service';

import {SignatureForUserTypeValidator} from '../../../_validators/signature-for-user-type';
import {LocalStorageService} from '../../../_services/local-storage.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {User} from '../../../_interfaces/user';

@Component({
	selector: 'app-my-account',
	templateUrl: './my-account.component.html',
	styleUrls: ['./my-account.component.scss'],
	animations: [routeAnimation]
})

export class MyAccountComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	// noinspection JSMismatchedCollectionQueryUpdate
	public languages: Languages[] = [];
	private user_type: string = null;

	myProfileForm = this.formBuilder.group({
		firstname: ['', [Validators.required, Validators.maxLength(50)]],
		lastname: ['', [Validators.required, Validators.maxLength(50)]],
		language_id: ['', Validators.required],
		email: ['', [Validators.required, Validators.email]],
		password: [''],
		confirm_password: ['', matchOtherValidator('password')],
		job_function: [''],
		signature_image: ['', SignatureForUserTypeValidator(this.user_type)]
	});

	_errorsSubscription: any = null;

	constructor(public authenticationService: AuthenticationService,
				private generalService: GeneralService,
				private localStorageService: LocalStorageService,
				public formsService: FormsService,
				private formBuilder: FormBuilder) {
	}

	ngOnInit(): void {
		this.formsService.form = this.myProfileForm;

		this.generalService.getLanguages().then((languages: Languages[]) => {
			this.languages = languages;

			let currentUser: User = this.localStorageService.get('currentUser');
			this.formsService.populateForm(currentUser);
		});

		this._errorsSubscription = this.authenticationService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}
}
