import {Component} from '@angular/core';
import {PreviousUrlService} from '../../../_services/previous-url.service';

@Component({
	selector: 'app-reports-router',
	templateUrl: './reports-router.component.html'
})

export class ReportsRouterComponent {
	constructor(public previousUrlService: PreviousUrlService) {}
}
