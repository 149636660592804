<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/create']">Nieuwe rapportage toevoegen</button>
<app-search-element *ngIf="smallWidget === false" (formSubmittedEvent)="search($event)"></app-search-element>
<mat-nav-list class="mat-nav-list-with-border" infiniteScroll infiniteScrollDistance="1" (scrolled)="getReports()">
	<mat-list-item *ngFor="let report of reports">
		<div class="mat-list-content" [routerLink]="['/reports/' + report_type + '/' + report.id + '/' + (report.status === 'closed' ? '/closed' : '') + '/view']">
			<h4 matLine>{{ report.company_name }}</h4>
			<a matLine>{{ report.created | datex:'DD-MM-YYYY' }} - {{ report.report_nr }}</a>
		</div>
		<ng-container *ngIf="report.color">
			<div class="tag" [style.background]="report.color"></div>
		</ng-container>
		<ng-container *ngIf="report.is_resolved">
			<mat-icon class="is_resolved">check_circle_outline</mat-icon>
		</ng-container>
		<ng-container *ngIf="report.reportEditable || report.status === 'closed'">
			<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<ng-container *ngIf="report.reportEditable">
					<a mat-menu-item [routerLink]="['/reports/' + report_type + '/' + report.id + '/edit']">Aanpassen</a>
				</ng-container>
				<a mat-menu-item *ngIf="report.status === 'closed'" (click)="reportsService.copyReport(report.type, report.id)">Copy</a>
				<ng-container *ngIf="report.reportEditable">
					<a mat-menu-item (click)="deleteReport(report_type, report.id)">Verwijderen</a>
					<mat-divider></mat-divider>
					<a mat-menu-item [routerLink]="['/reports/' + report_type + '/' + report.id + '/' + (report.status === 'closed' ? '/closed' : '') + '/buildings/create']">Nieuw gebouw toevoegen</a>
					<a mat-menu-item [routerLink]="['/reports/' + report_type + '/' + report.id + '/' + (report.status === 'closed' ? '/closed' : '') + '/attachments/create']">Nieuwe bijlage toevoegen</a>
					<a mat-menu-item [routerLink]="['/reports/' + report_type + '/' + report.id + '/' + (report.status === 'closed' ? '/closed' : '') + '/comments/create']">Nieuwe opmerkingen toevoegen</a>
					<a *ngIf="report_type === 'warehouse'" mat-menu-item [routerLink]="['/reports/' + report_type + '/' + report.id + '/' + (report.status === 'closed' ? '/closed' : '') + '/questionnaire']">Inspectieschema's</a>
					<a mat-menu-item [routerLink]="['/reports/' + report_type + '/' + report.id + '/' + (report.status === 'closed' ? '/closed' : '') + '/send-to-client']">Verstuur naar klant</a>
				</ng-container>
			</mat-menu>
		</ng-container>
	</mat-list-item>
</mat-nav-list>
