<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports']">
	Bekijk {{ report_type != 'tent' ? 'gebouwen schade' : 'tenten inspecties' }}
</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings']">
	Bekijk alle {{ report_type != 'tent' ? 'gebouwen' : 'tenten' }}
</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/view']">Bekijk rapportage</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/open']">Bekijk alle rapportages</button>
<ng-container>
	<div *ngIf="!reportEditable" class="alert alert-danger" fxFlex fxLayoutAlign="center" fxLayout="row">
		<mat-icon>warning</mat-icon>
		<h2>Rapport is vergrendeld door een andere gebruiker. Om het te bewerken, moet u eerst de rapportage ontgrendelen.</h2>
	</div>
	<form class="with-action-buttons" [formGroup]="reportsBuildingsReportCreateForm" (ngSubmit)="reportsService.createBuildingReport(report_type, report_id, building_id, reportsBuildingsReportCreateForm.value)">
		<ng-container *ngIf="report_type === 'warehouse'">
			<div class="divider"><span>Gebouw rapportage gegevens</span></div>
			<div fxLayout="row">
				<mat-form-field>
					<input title="Locatie nummer" matInput placeholder="Locatie nummer" type="text" required formControlName="location_nr">
					<mat-error *ngIf="formsService.getFieldError('location_nr')">{{formsService.getFieldError('location_nr')}}</mat-error>
				</mat-form-field>
				<button *ngIf="barcodeScannerActive" type="button" mat-icon-button color="primary" (click)="scanBarcode()">
					<mat-icon>photo_camera</mat-icon>
				</button>
				<button *ngIf="apiService.isOnline && speechToTextActive" type="button" mat-icon-button color="primary" (click)="recognizeSpeechToText()">
					<mat-icon>mic</mat-icon>
				</button>
				<mat-spinner *ngIf="isVerifyingLocation" diameter="30" style="margin:10px 0 0 10px;"></mat-spinner>
			</div>
			<div *ngIf="locationNotUnique" class="alert alert-danger" (click)="redirectLocationNr()" fxFlex fxLayout="row">
				<mat-icon>warning</mat-icon>
				<h2 [innerHTML]="locationErrorMessage"></h2>
			</div>
			<mat-form-field>
				<mat-select placeholder="Hoofd onderdeel" required formControlName="lib_warehouse_element_id" (selectionChange)="getSubcategories($event.value)">
					<mat-option *ngFor="let category of categories" [value]="category.id" (click)="setCategoryName(category.category_name)">{{ category.category_name }}</mat-option>
				</mat-select>
				<mat-error *ngIf="formsService.getFieldError('lib_warehouse_element_id')">{{formsService.getFieldError('lib_warehouse_element_id')}}</mat-error>
				<input type="hidden" formControlName="category_name">
			</mat-form-field>
			<mat-form-field>
				<mat-select placeholder="Subonderdeel" required formControlName="lib_warehouse_subelement_id" (selectionChange)="getDetails($event.value)">
					<mat-option *ngFor="let subcategory of subcategories" [value]="subcategory.id" (click)="setSubcategoryName(subcategory.subcategory_name)">{{ subcategory.subcategory_name }}</mat-option>
				</mat-select>
				<mat-error *ngIf="formsService.getFieldError('lib_warehouse_subelement_id')">{{formsService.getFieldError('lib_warehouse_subelement_id')}}</mat-error>
				<input type="hidden" formControlName="subcategory_name">
			</mat-form-field>
			<mat-radio-group formControlName="color_code" style="margin-bottom:20px;display:block;">
				<mat-radio-button color="primary" value="2">Groen</mat-radio-button>
				<mat-radio-button color="primary" value="0">Oranje</mat-radio-button>
				<mat-radio-button color="primary" value="1">Rood</mat-radio-button>
			</mat-radio-group>
			<div fxLayout="row">
				<mat-form-field>
					<input title="Diepte" matInput placeholder="Diepte" type="number" required formControlName="measure_depth" value="1">
					<mat-error *ngIf="formsService.getFieldError('measure_depth')">{{formsService.getFieldError('measure_depth')}}</mat-error>
				</mat-form-field>
				<button type="button" color="primary" mat-icon-button (click)="decreaseValue('measure_depth')">
					<mat-icon>remove</mat-icon>
				</button>
				<button type="button" color="primary" mat-icon-button (click)="increaseValue('measure_depth')">
					<mat-icon>add</mat-icon>
				</button>
			</div>
			<div fxLayout="row">
				<mat-form-field>
					<input title="Hoogte" matInput placeholder="Hoogte" type="number" required formControlName="measure_height" value="1">
					<mat-error *ngIf="formsService.getFieldError('measure_height')">{{formsService.getFieldError('measure_height')}}</mat-error>
				</mat-form-field>
				<button type="button" color="primary" mat-icon-button (click)="decreaseValue('measure_height')">
					<mat-icon>remove</mat-icon>
				</button>
				<button type="button" color="primary" mat-icon-button (click)="increaseValue('measure_height')">
					<mat-icon>add</mat-icon>
				</button>
			</div>
			<ng-container *ngIf="types.length">
				<div class="divider"><span>Type tekortkomingen</span></div>
				<div *ngFor="let type of types; let i = index">
					<div formArrayName="warehouse_type_ids">
						<mat-checkbox color="primary" formControlName="{{ i }}" value="{{ type.id }}" (change)="fixAngularScrewUpAndInsertActualValue('warehouse_type_ids', $event, i)">{{ type.type_name }}</mat-checkbox>
					</div>
					<div *ngIf="type.amounts === true" fxLayout="row">
						<mat-form-field formGroupName="warehouse_type_field_value">
							<input title="Stuks" matInput placeholder="Stuks" type="number" formControlName="{{ type.id }}">
						</mat-form-field>
						<button type="button" color="primary" mat-icon-button (click)="decreaseValue('warehouse_type_field_value.' + type.id, 'warehouse_type_ids.' + i)">
							<mat-icon>remove</mat-icon>
						</button>
						<button type="button" color="primary" mat-icon-button (click)="increaseValue('warehouse_type_field_value.' + type.id, 'warehouse_type_ids.' + i, type.id)">
							<mat-icon>add</mat-icon>
						</button>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="actions.length">
				<div class="divider"><span>Acties</span></div>
				<div *ngFor="let action of actions; let i = index" formArrayName="warehouse_action_ids">
					<mat-checkbox color="primary" formControlName="{{ i }}" value="{{ action.id }}" (change)="fixAngularScrewUpAndInsertActualValue('warehouse_action_ids', $event, i)">{{ action.action_name }}</mat-checkbox>
				</div>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="report_type === 'ladders'">
			<div class="divider"><span>Trappen inspectie gegevens</span></div>
			<div fxLayout="row">
				<mat-form-field>
					<input title="Nummer" matInput placeholder="Nummer" type="text" required formControlName="location_nr">
					<mat-error *ngIf="formsService.getFieldError('location_nr')">{{formsService.getFieldError('location_nr')}}</mat-error>
				</mat-form-field>
				<button *ngIf="barcodeScannerActive" type="button" mat-icon-button color="primary" (click)="scanBarcode()">
					<mat-icon>photo_camera</mat-icon>
				</button>
				<mat-spinner *ngIf="isVerifyingLocation" diameter="30" style="margin:10px 0 0 10px;"></mat-spinner>
			</div>
			<div *ngIf="locationNotUnique" class="alert alert-danger" (click)="redirectLocationNr()">
				<mat-icon>warning</mat-icon>
				<h2 [innerHTML]="locationErrorMessage"></h2>
			</div>
			<div fxLayout="row">
				<mat-form-field>
					<input title="ID" matInput placeholder="ID" type="text" required formControlName="ladder_id">
					<mat-error *ngIf="formsService.getFieldError('ladder_id')">{{formsService.getFieldError('ladder_id')}}</mat-error>
				</mat-form-field>
				<mat-spinner *ngIf="isVerifyingId" diameter="30" style="margin:10px 0 0 10px;"></mat-spinner>
			</div>
			<div *ngIf="idNotUnique" class="alert alert-danger" (click)="redirectId()">
				<mat-icon>warning</mat-icon>
				<h2 [innerHTML]="idErrorMessage"></h2>
			</div>
			<mat-form-field>
				<mat-select placeholder="Hoofd onderdeel" required formControlName="lib_ladder_element_id" (selectionChange)="getSubcategories($event.value)">
					<mat-option *ngFor="let category of categories" [value]="category.id" (click)="setCategoryName(category.category_name)">{{ category.category_name }}</mat-option>
				</mat-select>
				<mat-error *ngIf="formsService.getFieldError('lib_ladder_element_id')">{{formsService.getFieldError('lib_ladder_element_id')}}</mat-error>
				<input type="hidden" formControlName="category_name">
			</mat-form-field>
			<mat-form-field>
				<mat-select placeholder="Subonderdeel" required formControlName="lib_ladder_subelement_id" (selectionChange)="getDetails($event.value)">
					<mat-option *ngFor="let subcategory of subcategories" [value]="subcategory.id" (click)="setSubcategoryName(subcategory.subcategory_name)">{{ subcategory.subcategory_name }}</mat-option>
				</mat-select>
				<mat-error *ngIf="formsService.getFieldError('lib_warehouse_subelement_id')">{{formsService.getFieldError('lib_ladder_subelement_id')}}</mat-error>
				<input type="hidden" formControlName="subcategory_name">
			</mat-form-field>
			<mat-form-field>
				<mat-select placeholder="Materiaal" required formControlName="material_id">
					<mat-option *ngFor="let material of materials" [value]="material.id">{{ material.material_name }}</mat-option>
				</mat-select>
				<mat-error *ngIf="formsService.getFieldError('material_id')">{{formsService.getFieldError('material_id')}}</mat-error>
			</mat-form-field>
			<mat-form-field>
				<input title="Merk" matInput placeholder="Merk" type="text" required formControlName="brand">
				<mat-error *ngIf="formsService.getFieldError('brand')">{{formsService.getFieldError('brand')}}</mat-error>
			</mat-form-field>
			<mat-form-field>
				<mat-select placeholder="Keuringssticker geplaatst" required formControlName="sticker_posted">
					<mat-option value="0">Fout</mat-option>
					<mat-option value="1">Goed</mat-option>
				</mat-select>
				<mat-error *ngIf="formsService.getFieldError('sticker_posted')">{{formsService.getFieldError('sticker_posted')}}</mat-error>
			</mat-form-field>
			<mat-form-field>
				<textarea title="Uitleg" matInput placeholder="Uitleg" formControlName="description" rows="8"></textarea>
				<mat-error *ngIf="formsService.getFieldError('description')">{{formsService.getFieldError('description')}}</mat-error>
			</mat-form-field>
			<div *ngFor="let questionnaire of questionnaire; let i = index" formGroupName="ladder_questions" class="questionnaire-container">
				<div class="questionnaire-header">
					<h4>{{ questionnaire.name }}</h4>
					<p *ngIf="questionnaire.description">{{ questionnaire.description }}</p>
				</div>
				<div class="questionnaire-body">
					<div *ngFor="let question of questionnaire.questions; let j = index" class="questionnaire-question">
						<p>{{ question.question }}</p>
						<mat-radio-group color="primary" formControlName="[{{ questionnaire.id }}][{{ question.id }}]">
							<mat-radio-button value="yes">Goed</mat-radio-button>
							<mat-radio-button value="no">Fout</mat-radio-button>
							<mat-radio-button value="n/a">nvt</mat-radio-button>
						</mat-radio-group>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="report_type === 'tent'">
			<div class="divider"><span>Tent inspectie gegevens</span></div>
			<div fxLayout="row">
				<mat-form-field *ngIf="!companyInspection">
					<input title="Nummer" matInput placeholder="Nummer" type="text" required formControlName="location_nr">
					<mat-error *ngIf="formsService.getFieldError('location_nr')">{{formsService.getFieldError('location_nr')}}</mat-error>
				</mat-form-field>
				<button *ngIf="barcodeScannerActive" type="button" mat-icon-button color="primary" (click)="scanBarcode()">
					<mat-icon>photo_camera</mat-icon>
				</button>
				<mat-spinner *ngIf="isVerifyingLocation" diameter="30" style="margin:10px 0 0 10px;"></mat-spinner>
			</div>
			<div *ngIf="locationNotUnique" class="alert alert-danger" (click)="redirectLocationNr()" fxFlex fxLayout="row">
				<mat-icon>warning</mat-icon>
				<h2 [innerHTML]="locationErrorMessage"></h2>
			</div>
			<div fxLayout="row">
				<mat-form-field>
					<input title="ID" matInput placeholder="ID" type="text" required formControlName="tent_id">
					<mat-error *ngIf="formsService.getFieldError('tent_id')">{{formsService.getFieldError('tent_id')}}</mat-error>
				</mat-form-field>
				<mat-spinner *ngIf="isVerifyingId" diameter="30" style="margin:10px 0 0 10px;"></mat-spinner>
			</div>
			<div *ngIf="idNotUnique" class="alert alert-danger" (click)="redirectId()" fxFlex fxLayout="row">
				<mat-icon>warning</mat-icon>
				<h2 [innerHTML]="idErrorMessage"></h2>
			</div>
			<mat-form-field>
				<mat-select placeholder="Hoofd onderdeel" required formControlName="lib_tent_element_id" (selectionChange)="getSubcategories($event.value)">
					<mat-option *ngFor="let category of categories" [value]="category.id" (click)="setCategoryName(category.category_name)">{{ category.category_name }}</mat-option>
				</mat-select>
				<mat-error *ngIf="formsService.getFieldError('lib_tent_element_id')">{{formsService.getFieldError('lib_tent_element_id')}}</mat-error>
				<input type="hidden" formControlName="category_name">
			</mat-form-field>
			<mat-form-field>
				<mat-select placeholder="Subonderdeel" required formControlName="lib_tent_subelement_id" (selectionChange)="getDetails($event.value)">
					<mat-option *ngFor="let subcategory of subcategories" [value]="subcategory.id" (click)="setSubcategoryName(subcategory.subcategory_name)">{{ subcategory.subcategory_name }}</mat-option>
				</mat-select>
				<mat-error *ngIf="formsService.getFieldError('lib_warehouse_subelement_id')">{{formsService.getFieldError('lib_tent_subelement_id')}}</mat-error>
				<input type="hidden" formControlName="subcategory_name">
			</mat-form-field>
			<mat-form-field>
				<textarea title="Uitleg" matInput placeholder="Uitleg" formControlName="description" rows="8"></textarea>
				<mat-error *ngIf="formsService.getFieldError('description')">{{formsService.getFieldError('description')}}</mat-error>
			</mat-form-field>
			<div *ngFor="let questionnaire of questionnaire; let i = index" formGroupName="tent_questions" class="questionnaire-container">
				<div class="questionnaire-header">
					<h4>{{ questionnaire.name }}</h4>
					<p *ngIf="questionnaire.description">{{ questionnaire.description }}</p>
				</div>
				<div class="questionnaire-body">
					<div *ngFor="let question of questionnaire.questions; let j = index" class="questionnaire-question">
						<p>{{ question.question }}</p>
						<mat-radio-group color="primary" formControlName="[{{ questionnaire.id }}][{{ question.id }}]">
							<mat-radio-button value="yes">Goed</mat-radio-button>
							<mat-radio-button value="no">Fout</mat-radio-button>
							<mat-radio-button value="n/a">nvt</mat-radio-button>
						</mat-radio-group>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="divider"><span>Foto's</span></div>
		<mat-form-field class="photo-input">
			<ngx-mat-file-input formControlName="photosContainer[]" [multiple]="true" [accept]="'.jpg,.jpeg'" (change)="detectUploadFiles($event)" #photosContainer></ngx-mat-file-input>
			<button type="button" mat-button color="primary">
				<mat-icon>camera_alt</mat-icon>
				Foto's toevoegen
			</button>
			<mat-error *ngIf="formsService.getFieldError('photos')">{{formsService.getFieldError('photos')}}</mat-error>
		</mat-form-field>
		<mat-error *ngIf="photosCount >= 10">U kunt maximaal 10 foto's selecteren om te uploaden.</mat-error>
		<mat-list class="photos-container">
			<ng-template #photos></ng-template>
		</mat-list>
		<ng-container *ngIf="hasBuildingMap && apiService.isOnline">
			<div class="divider"><span>Plattegrond</span></div>
			<button type="button" mat-button color="primary-outline" (click)="showBuildingMap()">Voeg afwijking toe aan de plattegrond</button>
		</ng-container>
		<div class="action-buttons">
			<button *ngIf="reportEditable" color="primary" mat-button [disabled]="!reportsBuildingsReportCreateForm.valid">OPSLAAN</button>
		</div>
	</form>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Some of the required data is not available offline. Please go online in order to proceed.</h2>
</div>
