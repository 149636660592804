import {Injectable, EventEmitter} from '@angular/core';

@Injectable()

export class LoadingOverlayService {

	public overlay: EventEmitter<boolean> = new EventEmitter();
	public error: EventEmitter<boolean> = new EventEmitter();

	progress_type: EventEmitter<any> = new EventEmitter();
	percentage: EventEmitter<any> = new EventEmitter();

	type(type: boolean = false) {
		this.progress_type.emit(type);
	}

	progress(progress: number = 0) {
		this.percentage.emit(progress);
	}

	show() {
		this.showLoader();
	}

	hide() {
		this.hideLoader();
	}

	showLoader() {
		this.overlay.emit(true);
	}

	hideLoader() {
		this.overlay.emit(false);
	}

	showError() {
		this.error.emit(true);
	}

	hideError() {
		this.error.emit(false);
	}
}
