<ng-container *ngIf="dataLoaded && !missingData.length">
	<div class="divider"><span>Inventaris gegevens</span></div>
	<dl>
		<dt>Bestandsnaam</dt>
		<dd>{{ inspectorInventory.original_filename }}</dd>
		<dt>Datum aangemaakt</dt>
		<dd>{{ inspectorInventory.created | datex:'DD-MM-YYYY' }}</dd>
	</dl>
	<form class="with-action-buttons" [formGroup]="inspectoryInventorySendToClientForm" (ngSubmit)="inspectorsService.sendInspectorInventoryToClient(inventory_id, inspectoryInventorySendToClientForm.value)">
		<div class="divider"><span>Verstuur naar klant</span></div>
		<mat-form-field>
			<mat-select placeholder="Klant" formControlName="client_id">
				<mat-option>
					<ngx-mat-select-search formControlName="client_filter_input" [searching]="isLoadingClient" placeholderLabel="Zoeken"></ngx-mat-select-search>
				</mat-option>
				<mat-option *ngFor="let client of clientsFiltered" [value]="client.id" (onSelectionChange)="getClientsContactsPersons(client)">
					{{ client.company_name }}
					<span class="small-text">
						<ng-container *ngIf="client.address">{{ client.address }}, </ng-container>
						<ng-container *ngIf="client.city">{{ client.city }}, </ng-container>
						<ng-container *ngIf="client.country_name">{{ client.country_name }}</ng-container>
					</span>
				</mat-option>
			</mat-select>
		</mat-form-field>
		<ng-container *ngIf="client_id !== null">
			<div>
				<mat-checkbox color="primary" formControlName="contact_company" value="1">Verstuur naar bedrijfsemail</mat-checkbox>
			</div>
			<div *ngFor="let contactPerson of clientsContactsPersons; let i = index" formArrayName="contact_persons_ids">
				<mat-checkbox color="primary" formControlName="{{ i }}" value="{{ contactPerson.id }}" (change)="fixAngularScrewUpAndInsertActualValue($event, i)">{{ contactPerson.firstname }} {{ contactPerson.lastname }} <span class="small">({{ contactPerson.email }})</span></mat-checkbox>
			</div>
		</ng-container>
		<div class="divider"><span>Verstuur naar aangepaste ontvanger</span></div>
		<mat-form-field>
			<textarea matInput placeholder="Aangepaste ontvanger" formControlName="custom_contacts" rows="8"></textarea>
			<mat-error *ngIf="formsService.getFieldError('custom_contacts')">{{formsService.getFieldError('custom_contacts')}}</mat-error>
		</mat-form-field>
		<div class="divider"><span>Bericht gegevens</span></div>
		<mat-form-field>
			<textarea matInput placeholder="Notities" formControlName="message" rows="8"></textarea>
			<mat-error *ngIf="formsService.getFieldError('message')">{{formsService.getFieldError('message')}}</mat-error>
		</mat-form-field>
		<div class="action-buttons">
			<button color="primary" mat-button [disabled]="!inspectoryInventorySendToClientForm.valid">VERSTUUR</button>
		</div>
	</form>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
