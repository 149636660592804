<ng-container *ngIf="!status">
	<strong>Activeren twee-factor-authenticatie</strong>
	<ol>
		<li>Start uw authenticator-app (bijvoorbeeld <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Google authenticator</a> app) </li>
		<li>
			Maak in de authenticatie app een nieuwe login aan en kopieer de onderstaande sleutel:
			<mat-form-field>
				<input matInput placeholder="Secret" type="text" readonly value="{{ otpSecret }}">
				<button matSuffix mat-icon-button color="primary" (click)="copySecret()" matTooltip="Tap to copy">
					<mat-icon>file_copy</mat-icon>
				</button>
			</mat-form-field>
			<button color="primary" mat-button (click)="copySecret()">KOPIEER CODE</button>
		</li>
		<li>
			<p>Voer voordat u verder gaat de code in van uw verificatie-app in. Dit zorgt ervoor dat u uw account kan blijven gebruiken.</p>
			<form [formGroup]="otpForm" (ngSubmit)="authenticationService.enableTwoFactorAuth(otpForm.value)">
				<mat-form-field>
					<input matInput placeholder="Code" type="number" required formControlName="code">
					<mat-error *ngIf="formService.getFieldError('code')">{{ formService.getFieldError('code') }}</mat-error>
				</mat-form-field>
				<button color="primary" mat-button [disabled]="!otpForm.valid">BEVESTIGEN</button>
			</form>
		</li>
	</ol>
</ng-container>
<ng-container *ngIf="status">
	<div class="alert alert-info" fxFlex fxLayoutAlign="center" fxLayout="row">
		<mat-icon>warning</mat-icon>
		<h2>Twee-factor-authenticatie is al ingeschakeld op uw account.</h2>
	</div>
</ng-container>
