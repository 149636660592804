<button mat-button color="primary-outline" [routerLink]="['/inspectors/calibrations/create']">Nieuwe kalibratie toevoegen</button>
<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
<mat-nav-list class="mat-nav-list-with-border" infiniteScroll infiniteScrollDistance="1" (scrolled)="getInspectorsCalibrations()">
	<mat-list-item *ngFor="let inspectorCalibration of inspectorsCalibrations">
		<div class="mat-list-content" [routerLink]="['/inspectors/calibrations/' + inspectorCalibration.id + '/view']">
			<h4 matLine>{{ inspectorCalibration.device_id }} {{ inspectorCalibration.device_brand }}</h4>
			<a matLine>{{ inspectorCalibration.date_calibrated | datex:'DD-MM-YYYY' }}</a>
		</div>
		<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item [routerLink]="['/inspectors/calibrations/' + inspectorCalibration.id + '/edit']">Aanpassen</a>
			<a mat-menu-item (click)="deleteInspectorCalibration(inspectorCalibration.id)">Verwijderen</a>
		</mat-menu>
	</mat-list-item>
</mat-nav-list>
