<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/open']">Bekijk alle rapportages</button>
<ng-container *ngIf="!missingData.length">
	<form class="with-action-buttons" [formGroup]="reportsCreateForm" (ngSubmit)="reportsService.createReport(report_type, reportsCreateForm.value)">
		<div class="divider"><span>Klantgegevens</span></div>
		<mat-form-field>
			<mat-select placeholder="Bedrijfsnaam" formControlName="client_id">
				<mat-option>
					<ngx-mat-select-search formControlName="client_filterString" [searching]="isLoadingClient" placeholderLabel="Zoeken"></ngx-mat-select-search>
				</mat-option>
				<mat-option *ngFor="let client of clientsFiltered" [value]="client.id" (onSelectionChange)="setCompanyName(client.company_name)">
					{{ client.company_name }}
					<span class="small-text">
						<ng-container *ngIf="client.address">{{ client.address }}, </ng-container>
						<ng-container *ngIf="client.city">{{ client.city }}, </ng-container>
						<ng-container *ngIf="client.country_name">{{ client.country_name }}</ng-container>
					</span>
				</mat-option>
			</mat-select>
			<input type="hidden" formControlName="company_name" #company_name>
		</mat-form-field>
		<div class="divider"><span>Rapportage gegevens</span></div>
		<mat-form-field>
			<mat-select placeholder="Inspecteur" formControlName="account_user_id">
				<mat-option>
					<ngx-mat-select-search formControlName="account_user_id_filterString" [searching]="isLoadingInspector" placeholderLabel="Zoeken"></ngx-mat-select-search>
				</mat-option>
				<mat-option *ngFor="let inspector2 of inspectorsFiltered" [value]="inspector2.id" (onSelectionChange)="setInspectorName(inspector2.display_name)">{{ inspector2.display_name }}</mat-option>
			</mat-select>
			<input type="hidden" formControlName="inspector" #inspector>
		</mat-form-field>
		<mat-form-field *ngIf="report_type == 'tent'">
			<mat-select placeholder="Ster inspectie" formControlName="tent_star_rating">
				<mat-option [value]="1">1</mat-option>
				<mat-option [value]="2">2</mat-option>
				<mat-option [value]="3">3</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field>
			<mat-select placeholder="Type inspectie" formControlName="recurring">
				<mat-option value="0">Éénmalige inspectie</mat-option>
				<mat-option value="1">Terugkerende inspectie</mat-option>
			</mat-select>
		</mat-form-field>
		<div class="action-buttons">
			<button color="primary" mat-button [disabled]="!reportsCreateForm.valid">OPSLAAN</button>
		</div>
	</form>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
