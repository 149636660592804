export class SidenavItem {
	name: string;
	icon: string;
	route: any;
	parent: SidenavItem;
	subItems: SidenavItem[];
	position: number;
	badge: number | string;
	badgeColor: string;
	customClass: string;
	divider: boolean;

	constructor(model: any = null) {
		if (model) {
			this.name = model.name;
			this.icon = model.icon;
			this.route = model.route;
			this.parent = model.parent;
			this.subItems = this.mapSubItems(model.subItems);
			this.position = model.position;
			this.badge = model.badge;
			this.badgeColor = model.badgeColor;
			this.customClass = model.customClass;
			this.divider = model.divider;
		}
	}

	hasSubItems() {
		if (this.subItems) {
			return this.subItems.length > 0;
		}
		return false;
	}

	hasParent() {
		return !!this.parent;
	}

	mapSubItems(list: SidenavItem[]) {
		if (list) {
			list.forEach((item, index) => {
				list[index] = new SidenavItem(item);
			});

			return list;
		}
	}

	isRouteString() {
		return this.route instanceof String || typeof this.route === 'string';
	}

	isDivider() {
		return !!this.divider;
	}
}
