import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';

@Injectable()

export class FormsService {

	private ignoreFieldsWhenPopulating: Array<string> = [
		'signature_image',
		'filename',
		'contact_persons_ids',
		'date_calibrated',
		'next_calibration_date',
		'lib_particular_id',
		'photos',
		'warehouse_type_field_value',
		'warehouse_type_ids',
		'warehouse_action_ids',
		'ladder_questions',
		'tent_questions',
	];
	public form: FormGroup = null;
	private fileInputs: Array<string> = ['signature_image', 'photos'];

	constructor() {
	}

	populateForm(data) {
		if (typeof this.form !== 'undefined') {
			if (typeof this.form.controls !== 'undefined') {
				if (typeof data !== 'undefined') {
					Object.keys(this.form.controls).map(controlKey => {
						if (this.ignoreFieldsWhenPopulating.indexOf(controlKey) === -1) {
							if (typeof this.form.controls[controlKey] !== 'undefined' && typeof data[controlKey] !== 'undefined') {
								if (this.fileInputs.indexOf(controlKey) === -1) {
									this.form.controls[controlKey].setValue(data[controlKey]);
								}
							}
						}
					});
				}
			}
		}
	}

	processErrors(errors: any) {
		const fields = Object.keys(errors || {});
		fields.forEach(field => {
			const control = this.findFieldControl(field);
			const fieldErrors = this.fetchFieldErrors(errors, field);
			control.setErrors(fieldErrors);
		});
	}

	findFieldControl(field: string): AbstractControl {
		let control: AbstractControl;
		if (field === 'base') {
			control = this.form;
		} else if (this.form.contains(field)) {
			control = this.form.get(field);
		} else if (field.match(/_id$/) && this.form.contains(field.substring(0, field.length - 3))) {
			control = this.form.get(field.substring(0, field.length - 3));
		} else if (field.indexOf('.') > 0) {
			let group = this.form;
			field.split('.').forEach((f) => {
				if (group.contains(f)) {
					control = group.get(f);
					if (control instanceof FormGroup) {
						group = control;
					}
				} else {
					control = group;
				}
			});
		} else {
			// Field is not defined in form but there is a validation error for it, set it globally
			control = this.form;
		}
		return control;
	}

	fetchFieldErrors(data: any, field: string): any {
		const errors = {};
		Object.keys(data).forEach(key => {
			if (key === field) {
				errors[data[key].type] = data[key].message;
			}
		});
		return errors;
	}

	getFieldError(field: string): string | boolean {
		let control = this.findFieldControl(field),
			error = null;
		if (typeof control !== 'undefined') {
			if (typeof control.errors !== 'undefined') {
				if (control.errors !== null) {
					Object.keys(control.errors).map(key => {
						if (key !== 'required' && key !== 'email') {
							error = control.errors[key];
						}
					});
				}
			}
		}
		return error;
	}
}
