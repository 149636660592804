import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {ClientsService} from '../../../_services/clients.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Client} from '../../../_interfaces/clients/clients';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {User} from '../../../_interfaces/user';
import {ReportsService} from '../../../_services/reports.service';
import {debounceTime, tap} from 'rxjs/operators';
import {ServerResponse} from '../../../_interfaces/server.response';
import {Reports} from '../../../_interfaces/reports/reports';
import {ApiService} from '../../../_interceptors/api.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';

@Component({
	selector: 'app-reports-edit',
	templateUrl: './reports-edit.component.html',
	styleUrls: ['./reports-edit.component.scss'],
	animations: [routeAnimation]
})

export class ReportsEditComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;
	public report_type: string = null;
	public isLoadingClient: boolean = false;
	public isLoadingInspector: boolean = false;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];
	public reportEditable: boolean = false;

	public inspectors: User[] = null;
	public clientsFiltered: Client[] = null;
	public inspectorsFiltered: User[] = null;

	public reportsEditForm = this.formBuilder.group({
		client_id: ['', Validators.required],
		client_filterString: [''],
		company_name: [''],
		account_user_id: ['', Validators.required],
		account_user_id_filterString: [''],
		tent_star_rating: null,
		inspector: [''],
		recurring: ['1']
	});

	private _errorsSubscription: Subscription = null;
	private _activatedRouteSubscription: Subscription = null;
	private _authenticationServiceSubscription: Subscription = null;

	constructor(private activatedRoute: ActivatedRoute,
				private authenticationService: AuthenticationService,
				private apiService: ApiService,
				private clientsService: ClientsService,
				public reportsService: ReportsService,
				public formsService: FormsService,
				private previousUrlService: PreviousUrlService,
				private formBuilder: FormBuilder,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length) {
				this.missingData = [];
				this.loadData();
			}
		});

		this.formsService.form = this.reportsEditForm;

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this.reportsEditForm
			.get('client_filterString')
			.valueChanges
			.pipe(
				debounceTime(300)
				)
			.subscribe(value => {
				if (typeof value === 'string' && this.dataLoaded !== false) {
					if (value !== '' && value !== null) {
						if (value.length >= 3) {
							this.isLoadingClient = true;
							this.clientsService.getClients({
								start: 0,
								length: -1,
								'columns[0][search][value]': value
							}).then((data: ServerResponse) => {
								this.clientsFiltered = <Client[]>data.data;
								this.isLoadingClient = false;
							});
						}
					} else if (value !== null) {
						this.isLoadingClient = false;
					}
				} else {
					this.isLoadingClient = false;
				}
			});

		this.reportsEditForm
			.get('account_user_id_filterString')
			.valueChanges
			.pipe(
				debounceTime(300),
				tap(() => {
					this.isLoadingInspector = true;
				}))
			.subscribe(value => {
				if (typeof value === 'string' && this.dataLoaded !== false) {
					if (value !== '' && value !== null) {
						this.inspectorsFiltered = this.inspectors.filter(data => {
							return data.display_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
						});
						this.isLoadingInspector = false;
					} else if (value !== null) {
						this.inspectorsFiltered = this.inspectors;
						this.isLoadingInspector = false;
					}
				} else {
					this.isLoadingInspector = false;
				}
			});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];
			this.report_id = parseInt(params['report_id'], 10);

			this.loadData();

			this.reportsService.getReport(this.report_type, this.report_id).then((report: Reports) => {
				this._authenticationServiceSubscription = this.authenticationService.user.first().subscribe((user: User) => {
					if (typeof report !== 'undefined' && typeof user !== 'undefined') {

						this.pageTitleService.setTitle(report.report_nr + ' | Aanpassen rapportage | ' + environment.webappName);
						this.pageTitleService.setPageTitle(report.report_nr);

						if (typeof report.edit_lock !== 'undefined' && typeof report.edit_lock_user_id !== 'undefined') {
							if (report.edit_lock === true && (<any>report.edit_lock_user_id === '' ||
								report.edit_lock_user_id === null ||
								report.edit_lock_user_id === 0 ||
								report.edit_lock_user_id === user.id ||
								user.type === 'kvvm-admin')) {
								this.reportEditable = true;
							}
						}
					}
				});
			});

			this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_type + '/open');
		});

		this.formsService.form = this.reportsEditForm;

		this._errorsSubscription = this.clientsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}

		if (this._authenticationServiceSubscription !== null) {
			this._authenticationServiceSubscription.unsubscribe();
		}
	}

	loadData() {
		this.reportsService.getInspectors().then((data: ServerResponse) => {
			if (typeof data !== 'undefined') {
				if (typeof data.data !== 'undefined') {
					if (data.data.length) {
						this.inspectors = <User[]>data.data;
						this.inspectorsFiltered = <User[]>data.data;
					}
				} else {
					this.missingData.push('Inspecteur');
				}
			} else {
				this.missingData.push('Inspecteur');
			}
		});

		this.reportsService.getReport(this.report_type, this.report_id).then((data: Reports) => {
			if (typeof data !== 'undefined') {
				this.formsService.populateForm(data);
				this.reportsEditForm.get('client_filterString').setValue(data.company_name);
				this.dataLoaded = true;
			} else {
				this.missingData.push('Rapportage');
			}
		});
	}

	setCompanyName(company_name: string) {
		this.reportsEditForm.get('company_name').setValue(company_name);
	}

	setInspectorName(inspector: string) {
		this.reportsEditForm.get('inspector').setValue(inspector);
	}
}
