import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';

import {AuthenticationService} from '../../../_services/authentication.service';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {SnackbarService} from '../../../_services/snackbar.service';

@Component({
	selector: 'app-two-factor-authentication-enable',
	templateUrl: './two-factor-authentication-enable.component.html',
	styleUrls: ['./two-factor-authentication-enable.component.scss'],
	animations: [routeAnimation]
})

export class TwoFactorAuthenticationEnableComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	public status: boolean|void = null;
	public tfa_required: boolean|void = null;
	public otpSecret: string|void = null;

	public otpForm = this.formBuilder.group({
		code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
	});

	private _errorSubscription: Subscription = null;

	constructor(public authenticationService: AuthenticationService,
				private formBuilder: FormBuilder,
				private snackbarService: SnackbarService,
				public formService: FormsService) {
	}

	ngOnInit(): void {
		this.formService.form = this.otpForm;

		this.authenticationService.getTwoFactorAuthStatus().then(response1 => {
			this.status = response1.status;
			this.tfa_required = response1.tfa_required;

			if (!status) {
				this.authenticationService.getTwoFactorAuthSecret().then(response2 => {
					this.otpSecret = response2;
				}).catch(error => console.log(error));
			}
		});

		this._errorSubscription = this.authenticationService.errors.subscribe(errors => {
			this.formService.processErrors(errors);
		});
	}

	ngOnDestroy() {
		if (this._errorSubscription !== null) {
			this._errorSubscription.unsubscribe();
		}
	}

	copySecret() {
		if (!navigator.clipboard) {
			this.snackbarService.error('Er is een fout opgetreden bij het kopiëren van de code. Kopieer deze handmatig.');
		} else {
			navigator.clipboard.writeText(<string>this.otpSecret).then(() => {
				this.snackbarService.success('De code is naar uw klembord gekopieerd.');
			}, () => {
				this.snackbarService.error('Er is een fout opgetreden bij het kopiëren van de code. Kopieer deze handmatig.');
			});
		}
	}
}
