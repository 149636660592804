import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, GuardsCheckEnd, NavigationStart, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {AuthenticationService} from './authentication.service';
import {PageTitleService} from './page-title.service';
import {GeneralService} from './general.service';
import {LocalStorageService} from './local-storage.service';
import {User} from '../_interfaces/user';

@Injectable()

export class AuthGuard implements CanActivate, CanActivateChild {

	private user: User = null;

	constructor(private router: Router,
				private authenticationService: AuthenticationService,
				private generalService: GeneralService,
				private localStorageService: LocalStorageService,
				private titleService: PageTitleService) {
		// make sure that user variable is populated the second user logs in
		this.authenticationService.user.subscribe(user => {
			this.user = user;
		});

		// check if 2FA is enabled and if user has it enabled. If not, force user to enable it
		this.router.events.forEach((event: any) => {
			if (typeof event.url !== 'undefined' && this.user) {
				if ((event instanceof NavigationStart || event instanceof GuardsCheckEnd) && typeof this.user.tfa_enabled !== 'undefined' && typeof this.user.tfa_required !== 'undefined') {
					if (this.user.tfa_required === true && this.user.tfa_enabled !== true && event.url.indexOf('/my-account/two-factor-authentication') === -1) {
						this.router.navigate(['/my-account/two-factor-authentication']).then(() => {
						});
					}
				}
			}
		}).then(() => {});
	}

	canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
		return this.performCheck(next);
	}

	canActivateChild(next: ActivatedRouteSnapshot): Observable<boolean> {
		return this.performCheck(next);
	}

	private performCheck(next: ActivatedRouteSnapshot): Observable<boolean> {
		return this.authenticationService.isAuthenticated()
			.mergeMap(isAuthenticated => {
				if (isAuthenticated === true) {
					return this.authenticationService.user.map(user => user);
				} else {
					return Observable.of(null);
				}
			}).map(user => {
				if (user) {
					if (user.account_type === 'kvvm') {
						let roles = next.data['roles'] as Array<string>,
							title = next.data['title'];

						if (typeof roles !== 'undefined') {
							if (roles.length) {
								if (roles.indexOf(user.type) !== -1) {
									this.titleService.setTitle(title);
									return true;
								} else {
									return false;
								}
							} else {
								this.titleService.setTitle(title);
								return true;
							}
						} else {
							this.titleService.setTitle(title);
							return true;
						}
					} else {
						this.authenticationService.logout();
						return false;
					}
				} else {
					let allowed_urls = [
						'/login',
						'/forgot-password',
						'/recover-lost-password',
						'/two-factor-authentication',
						'/login-with-recovery-code'
					];
					if (allowed_urls.indexOf(this.router.url) !== -1) {
						return true;
					} else {
						this.localStorageService.delete('currentUser');
						this.router.navigate(['/login']).then(() => {
						});

						return false;
					}
				}
			});
	}
}
