import {Component} from '@angular/core';
import {PreviousUrlService} from '../../../_services/previous-url.service';

@Component({
	selector: 'app-clients-router',
	templateUrl: './inspectors-router.component.html'
})

export class InspectorsRouterComponent {
	constructor(public previousUrlService: PreviousUrlService) {}
}
