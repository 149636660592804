<form class="with-action-buttons" [formGroup]="inspectorInventoryCreateForm" (ngSubmit)="inspectorsService.createInspectorInventory(inspectorInventoryCreateForm.value)">
	<div class="divider"><span>Inventaris gegevens</span></div>
	<mat-form-field>
		<ngx-mat-file-input formControlName="filename" placeholder="Bestand" required #filenameInput>
			<button mat-icon-button *ngIf="!filenameInput.empty" (click)="filenameInput.clear($event)">
				<mat-icon>clear</mat-icon>
			</button>
		</ngx-mat-file-input>
		<mat-error *ngIf="formsService.getFieldError('filename')">{{formsService.getFieldError('filename')}}</mat-error>
	</mat-form-field>
	<div class="action-buttons">
		<button color="primary" mat-button [disabled]="!inspectorInventoryCreateForm.valid">OPSLAAN</button>
	</div>
</form>
