import {BehaviorSubject, Subject} from 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {ApiService} from '../_interceptors/api.service';
import {ServerResponse} from '../_interfaces/server.response';
import {environment} from '../../environments/environment';

@Injectable()

export class PlanningService {

	public errors: Subject<any> = new BehaviorSubject(null);

	private debug: boolean = !environment.production;

	constructor(private apiService: ApiService) {
	}

	getPlannings(formData = null) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('planning/schedule', formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								resolve(<ServerResponse>data);
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getPlanning(planning_id: number) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('planning/' + planning_id + '/view', {
					start: 0,
					length: -1
				}).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								resolve(<ServerResponse>data);
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}
}
