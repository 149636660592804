import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {ClientsService} from '../../../../_services/clients.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {ClientsContactsPersons} from '../../../../_interfaces/clients/clients-contacts-persons';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {ApiService} from '../../../../_interceptors/api.service';
import {PageTitleService} from '../../../../_services/page-title.service';
import {environment} from '../../../../../environments/environment';
import {Client} from '../../../../_interfaces/clients/clients';

@Component({
	selector: 'app-clients-contacts-persons-view',
	templateUrl: './clients-contacts-persons-view.component.html',
	styleUrls: ['./clients-contacts-persons-view.component.scss'],
	animations: [routeAnimation]
})

export class ClientsContactsPersonsViewComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public client_id: number = null;
	public contact_person_id: number = null;
	public contactPerson: ClientsContactsPersons = null;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];

	private _activatedRouteSubscription: Subscription = null;

	constructor(private clientsService: ClientsService,
				private previousUrlService: PreviousUrlService,
				private apiService: ApiService,
				private activatedRoute: ActivatedRoute,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length > 0) {
				this.missingData = [];
				this.loadData();
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.client_id = parseInt(params['client_id'], 10);
			this.contact_person_id = parseInt(params['contact_person_id'], 10);

			this.clientsService.getClient(this.client_id).then((client: Client) => {
				if (typeof client !== 'undefined') {
					this.pageTitleService.setTitle(client.company_name + ' | Bekijk contactpersoon | ' + environment.webappName);
					this.pageTitleService.setPageTitle(client.company_name);
				}
			});

			this.previousUrlService.setPreviousUrlIfNull('/clients/' + this.client_id + '/view');
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
	}

	loadData() {
		this.clientsService.getContactPerson(this.client_id, this.contact_person_id).then((contactPerson: ClientsContactsPersons) => {
			if (typeof contactPerson !== 'undefined') {
				this.contactPerson = contactPerson;

				this.dataLoaded = true;
			} else {
				this.missingData.push('Contactpersoon');
			}
		});
	}

	deleteContactPerson(client_id: number, contact_person_id: number) {
		this.clientsService.deleteContactPerson(client_id, contact_person_id).then(status => {
			if (status === true) {
				this.previousUrlService.goTo('/clients/' + client_id + '/view');
			}
		});
	}
}
