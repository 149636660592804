<button *ngIf="reportEditable" mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/create']">
	<span *ngIf="report_type === 'warehouse'">Nieuwe schade toevoegen</span>
	<span *ngIf="report_type !== 'warehouse'">Nieuwe inspectie</span>
</button>
<ng-container *ngIf="smallWidget === false">
	<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + (report_status === '/closed' ? '/closed' : '') + '/buildings']">Bekijk alle gebouwen</button>
	<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + (report_status === '/closed' ? '/closed' : '') + '/view']">Bekijk rapportage</button>
	<ng-container *ngIf="hasBuildingMap">
		<ng-container *ngIf="apiService.isOnline">
			<button mat-button color="primary-outline" (click)="showBuildingMap()">Plattegrond openen</button>
		</ng-container>
	</ng-container>
	<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
</ng-container>
<mat-nav-list *ngIf="buildingReports.length" class="mat-nav-list-small">
	<mat-list-item>
		<strong>Totaal rapportages: </strong> <span>{{ totalItems }}</span>
	</mat-list-item>
</mat-nav-list>
<mat-nav-list class="mat-nav-list-with-border" infiniteScroll infiniteScrollDistance="1" (scrolled)="getReportsBuildingReports()">
	<ng-container *ngIf="report_type === 'warehouse' && !isTablet || report_type === 'ladders' || report_type == 'tent'">
		<mat-list-item *ngFor="let buildingReport of buildingReports" [class.with-checkbox]="show_checkboxes">
			<ng-container *ngIf="!show_checkboxes">
				<div class="mat-list-content" [routerLink]="[(report_status === '/closed' ? '/reports/' + report_type + '/' + report_id + '/closed/buildings/' + building_id + '/reports/' + buildingReport.id + '/view' : '/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + buildingReport.id + '/edit')]">
					<h4 matLine>{{ buildingReport.location_nr }}</h4>
					<a matLine>{{ buildingReport.category_name }}; {{ buildingReport.subcategory_name }}</a>
				</div>
				<ng-container *ngIf="buildingReport.color">
					<div class="tag" [style.background]="buildingReport.color"></div>
				</ng-container>
				<ng-container *ngIf="buildingReport.client_fixed_status">
					<mat-icon class="is_resolved">check_circle_outline</mat-icon>
				</ng-container>
				<ng-container *ngIf="reportEditable">
					<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<a mat-menu-item [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + buildingReport.id + '/edit']">Aanpassen</a>
						<a mat-menu-item (click)="deleteBuildingReport(buildingReport.id)">Verwijderen</a>
					</mat-menu>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="show_checkboxes">
				<div class="mat-list-content" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + buildingReport.id + '/edit']">
					<h4 matLine>{{ buildingReport.location_nr }}</h4>
					<div matLine>
						<div *ngIf="report_type !== 'tent'">
							<span class="small-text">Diepte</span>
							{{ buildingReport.measure_depth }}
						</div>
						<div *ngIf="report_type !== 'tent'">
							<span class="small-text">Hoogte</span>
							{{ buildingReport.measure_height }}
						</div>
						<div>
							<span class="small-text">Hoofd onderdeel</span>
							{{ buildingReport.category_name }}
						</div>
						<div>
							<span class="small-text">Subonderdeel</span>
							{{ buildingReport.subcategory_name }}
						</div>
						<div *ngIf="report_type !== 'tent'">
							<span class="small-text">Schade omschrijving / Stuks</span>
							<ul *ngIf="buildingReport.shortcoming_types.length > 0">
								<li *ngFor="let type of buildingReport.shortcoming_types">
									{{ type.type_name }}<span *ngIf="type.result"> / {{ type.result }}</span>
								</li>
							</ul>
						</div>
						<div *ngIf="report_type !== 'tent'">
							<span class="small-text">Aanbeveling</span>
							<ul *ngIf="buildingReport.shortcoming_actions.length > 0">
								<li *ngFor="let action of buildingReport.shortcoming_actions">{{ action.action_name }}</li>
							</ul>
						</div>
					</div>
				</div>
				<ng-container *ngIf="buildingReport.color">
					<div class="tag" [style.background]="buildingReport.color"></div>
				</ng-container>
				<ng-container *ngIf="buildingReport.client_fixed_status">
					<mat-icon class="is_resolved">check_circle_outline</mat-icon>
				</ng-container>
				<ng-container>
					<mat-checkbox color="primary" name="verify_inspector_status" value="true" [checked]="buildingReport.verify_inspector_status === true" (change)="toggleBuildingReportInspectorStatus($event.checked, report_type, report_id, building_id, buildingReport.id)"></mat-checkbox>
				</ng-container>
				<ng-container *ngIf="reportEditable">
					<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<a mat-menu-item [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + buildingReport.id + '/edit']">Aanpassen</a>
						<a mat-menu-item (click)="deleteBuildingReport(buildingReport.id)">Verwijderen</a>
					</mat-menu>
				</ng-container>
			</ng-container>
		</mat-list-item>
	</ng-container>
	<ng-container *ngIf="report_type === 'warehouse' && isTablet">
		<table mat-table [dataSource]="buildingReports">
			<ng-container matColumnDef="number">
				<th mat-header-cell *matHeaderCellDef style="width:20px;min-width:20px;max-width:20px;">#</th>
				<td mat-cell *matCellDef="let report;let i = index" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + report.id + '/edit']">{{ i + 1 }}</td>
			</ng-container>
			<ng-container matColumnDef="location_nr">
				<th mat-header-cell *matHeaderCellDef>Locatie</th>
				<td mat-cell *matCellDef="let report" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + report.id + '/edit']">{{ report.location_nr }}</td>
			</ng-container>
			<ng-container matColumnDef="measure_depth">
				<th mat-header-cell *matHeaderCellDef>Diepte</th>
				<td mat-cell *matCellDef="let report" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + report.id + '/edit']">{{ report.measure_depth }}</td>
			</ng-container>
			<ng-container matColumnDef="measure_height">
				<th mat-header-cell *matHeaderCellDef>Hoogte</th>
				<td mat-cell *matCellDef="let report" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + report.id + '/edit']">{{ report.measure_height }}</td>
			</ng-container>
			<ng-container matColumnDef="color_code">
				<th mat-header-cell *matHeaderCellDef>Code</th>
				<td mat-cell *matCellDef="let report" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + report.id + '/edit']">
					<span class="tag" [style.background]="report.color"></span>
				</td>
			</ng-container>
			<ng-container matColumnDef="category_name">
				<th mat-header-cell *matHeaderCellDef>Hoofd onderdeel</th>
				<td mat-cell *matCellDef="let report" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + report.id + '/edit']">{{ report.category_name }}</td>
			</ng-container>
			<ng-container matColumnDef="subcategory_name">
				<th mat-header-cell *matHeaderCellDef>Subonderdeel</th>
				<td mat-cell *matCellDef="let report" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + report.id + '/edit']">{{ report.subcategory_name }}</td>
			</ng-container>
			<ng-container matColumnDef="shortcoming_types">
				<th mat-header-cell *matHeaderCellDef>Schade omschrijving / Stuks</th>
				<td mat-cell *matCellDef="let report" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + report.id + '/edit']">
					<div *ngFor="let type of report.shortcoming_types">{{ type.type_name }}<span *ngIf="type.result">/ {{ type.result }}</span></div>
				</td>
			</ng-container>
			<ng-container matColumnDef="shortcoming_actions">
				<th mat-header-cell *matHeaderCellDef>Aanbeveling</th>
				<td mat-cell *matCellDef="let report" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + report.id + '/edit']">
					<div *ngFor="let action of report.shortcoming_actions">{{ action.action_name }}</div>
				</td>
			</ng-container>
			<ng-container matColumnDef="commands">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let report">
					<mat-checkbox color="primary" name="verify_inspector_status" value="true" [checked]="report.verify_inspector_status === true" (change)="toggleBuildingReportInspectorStatus($event.checked, report_type, report_id, building_id, report.id)"></mat-checkbox>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</ng-container>
</mat-nav-list>
