import {Component, HostBinding, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {ClientsService} from '../../../_services/clients.service';
import {Client} from '../../../_interfaces/clients/clients';
import {GeneralService} from '../../../_services/general.service';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {ApiService} from '../../../_interceptors/api.service';
import {ServerResponse} from '../../../_interfaces/server.response';
import {AuthenticationService} from '../../../_services/authentication.service';

@Component({
	selector: 'app-clients-list',
	templateUrl: './clients-list.component.html',
	styleUrls: ['./clients-list.component.scss'],
	animations: [routeAnimation]
})

export class ClientsListComponent implements OnInit {

	@HostBinding('@routeAnimation') routerTransition = true;

	private totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	private isLoading: boolean = false;
	private keepLoading: boolean = false;

	public clients: Client[] = [];

	constructor(private clientsService: ClientsService,
				private previousUrlService: PreviousUrlService,
				public apiService: ApiService,
				public authenticationService: AuthenticationService,
				private generalService: GeneralService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && !this.clients.length) {
				this.currentPage = 0;
				this.getClients(false);
			}
		});

		this.getClients(false);
	}

	getClients(append_data: boolean = true) {
		if (!this.isLoading || this.keepLoading) {
			this.isLoading = true;
			this.keepLoading = false;
			this.currentPage++;

			this.clientsService.getClients({
				start: (this.currentPage - 1) * this.generalService.itemsPerPage,
				length: this.generalService.itemsPerPage,
				'columns[0][search][value]': this.searchString
			}).then((data: ServerResponse) => {
				if (typeof data.recordsTotal !== 'undefined') {
					this.totalItems = data.recordsTotal;
				}
				if (append_data === true) {
					this.clients.push(...<Client[]>data.data);
				} else {
					this.clients = <Client[]>data.data;
				}
				this.isLoading = false;
			});
		}
	}

	deleteClient(client_id: number) {
		this.clientsService.deleteClient(client_id).then(status => {
			if (status === true) {
				let index = this.clients.findIndex(data => data.id === client_id);

				if (index !== -1) {
					this.clients.splice(index, 1);
				}
			}
		});
	}

	search($event) {
		this.searchString = $event;
		this.currentPage = 0;
		this.getClients(false);
	}
}
