import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {FormArray, FormBuilder, FormControl} from '@angular/forms';
import {FormsService} from '../../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute, Params} from '@angular/router';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {APP_DATE_FORMATS, CustomDateAdapter} from '../../../../_pipes/angular2-material-datepicker-custom-date';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {InspectorsInventory} from '../../../../_interfaces/inspectors/inspectors-inventory';
import {InspectorsService} from '../../../../_services/inspectors.service';
import {Client} from '../../../../_interfaces/clients/clients';
import {ClientsService} from '../../../../_services/clients.service';
import {ClientsContactsPersons} from '../../../../_interfaces/clients/clients-contacts-persons';
import {ServerResponse} from '../../../../_interfaces/server.response';
import {debounceTime, tap} from 'rxjs/operators';
import {ApiService} from '../../../../_interceptors/api.service';

@Component({
	selector: 'app-inspectors-inventories-sent-to-client',
	templateUrl: './inventories-send-to-client.component.html',
	styleUrls: ['./inventories-send-to-client.component.scss'],
	animations: [routeAnimation],
	providers: [
		{
			provide: DateAdapter, useClass: CustomDateAdapter
		},
		{
			provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
		}
	]
})
export class InspectorsInventoriesSendToClientComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	public inventory_id: number = null;
	public client_id: number = null;
	private isFilteredClient: boolean = false;
	public isLoadingClient: boolean = false;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];

	// noinspection JSMismatchedCollectionQueryUpdate
	public clientsFiltered: Client[] = null;

	public inspectorInventory: InspectorsInventory = null;
	// noinspection JSMismatchedCollectionQueryUpdate
	public clientsContactsPersons: ClientsContactsPersons[] = [];

	public inspectoryInventorySendToClientForm = this.formBuilder.group({
		client_id: [''],
		client_filter_input: [''],
		contact_company: [true],
		contact_persons_ids: this.formBuilder.array([]),
		custom_contacts: [''],
		message: ['']
	});

	private _errorsSubscription: Subscription = null;
	private _activatedRouteSubscription: Subscription = null;

	constructor(public inspectorsService: InspectorsService,
				private clientsService: ClientsService,
				public formsService: FormsService,
				private activatedRoute: ActivatedRoute,
				private apiService: ApiService,
				private formBuilder: FormBuilder,
				private previousUrlService: PreviousUrlService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length > 0) {
				this.missingData = [];
				this.loadData();
			}
		});

		this.formsService.form = this.inspectoryInventorySendToClientForm;

		this._errorsSubscription = this.inspectorsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this.inspectoryInventorySendToClientForm
			.get('client_filter_input')
			.valueChanges
			.pipe(
				debounceTime(300),
				tap(() => this.isLoadingClient = true)
			)
			.subscribe(value => {
				if (typeof value === 'string') {
					if (value !== '' && value !== null) {
						if (value.length >= 3) {
							this.clientsService.getClients({
								start: 0,
								length: -1,
								'columns[0][search][value]': value
							}).then((data: ServerResponse) => {
								this.clientsFiltered = <Client[]>data.data;
								this.isLoadingClient = false;
								this.isFilteredClient = true;
							});
						} else {
							this.isLoadingClient = false;
						}
					} else {
						this.isLoadingClient = false;
					}
				} else {
					this.isLoadingClient = false;
				}
			});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.inventory_id = parseInt(params['inventory_id'], 10);

			this.previousUrlService.setPreviousUrlIfNull('/inspectors/inventories');

			this.loadData();
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}

	loadData() {
		this.inspectorsService.getInspectorInventory(this.inventory_id).then((details: InspectorsInventory) => {
			if (typeof details !== 'undefined') {
				this.inspectorInventory = details;

				this.dataLoaded = true;
			} else {
				this.missingData.push('Inventarie gegevens');
			}
		});
	}

	getClientsContactsPersons(client: Client) {
		this.client_id = client.id;

		const el = this.inspectoryInventorySendToClientForm.get('contact_company');
		if (client.email === '') {
			el.setValue(false);
			el.disable();
		} else {
			el.setValue(true);
			el.enable();
		}

		this.clientsService.getContactPersons(client.id).then((data: ServerResponse) => {
			if (typeof data !== 'undefined') {
				this.clientsContactsPersons = <ClientsContactsPersons[]>data.data;

				if (data.data.length) {
					data.data.forEach(contact_person => {
						let control = <FormArray>this.inspectoryInventorySendToClientForm.get('contact_persons_ids');
						control.push(new FormControl({value: contact_person.id, disabled: !contact_person.email}));
					});
				}
			}
		});
	}

	fixAngularScrewUpAndInsertActualValue(event, index) {
		if (event.checked) {
			this.inspectoryInventorySendToClientForm.controls.contact_persons_ids['controls'][index].setValue(event.source.value);
		}
	}
}
