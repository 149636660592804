<dl>
	<dt>Status</dt>
	<dd>{{ status ? 'Ingeschakeld' : 'Uitgeschakeld' }}</dd>
</dl>
<ng-container *ngIf="recoveryCodes">
	<p>De twee-factor-authenticatie is geactiveerd. U hoeft één keer per 30 dagen opnieuw in te loggen. Belangrijk dat u uw toegang tot de authenticator-app of herstelcodes behoud, herstelcodes worden éénmalig verstrekt.</p>
</ng-container>
<ng-container *ngIf="status && recoveryCodes">
	<p>Kopieer deze code en bewaar deze veilig! Je hebt ze nodig om weer toegang te krijgen tot je account als je twee-factor-authenticatie-apparaat kwijtraakt.</p>
	<dl>
		<dt>Herstel codes</dt>
		<dd [innerHTML]="'<pre>' + recoveryCodes + '</pre>'"></dd>
	</dl>
	<p><strong>Deze codes worden niet meer getoond!</strong></p>
</ng-container>
<ng-container *ngIf="status && !recoveryCodes">
	<dl>
		<dt>Herstel codes</dt>
		<dd>Niet zichtbaar</dd>
	</dl>
</ng-container>
<div *ngIf="status === null" class="alert alert-danger" fxFlex fxLayoutAlign="center" fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>De status van uw twee-factor-authenticatie kan niet worden geladen.</h2>
</div>
<button *ngIf="!status" mat-button color="primary" [routerLink]="['/my-account/two-factor-authentication/enable']">Inschakelen</button>
<button *ngIf="status" mat-button color="primary-outline" [routerLink]="['/my-account/two-factor-authentication/disable']">Uitschakelen</button>
<button *ngIf="status" mat-button color="primary" [routerLink]="['/my-account/two-factor-authentication/regenerate-recovery-codes']">Herstelcodes opnieuw genereren</button>
