import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {routeAnimation} from '../../route.animation';

@Component({
	selector: 'app-search-element',
	templateUrl: './search-element.component.html',
	styleUrls: ['./search-element.component.scss'],
	animations: [routeAnimation]
})

export class SearchElementComponent implements OnInit {

	@Output() formSubmittedEvent: EventEmitter<string> = new EventEmitter();

	public isFiltered: boolean = false;
	public query: string = '';

	constructor() {
	}

	ngOnInit(): void {
	}

	formSubmitted(query: string = '') {
		this.isFiltered = query !== '';
		this.query = query;

		this.formSubmittedEvent.next(query);
	}

	clearSearchInput() {
		this.formSubmitted();
	}
}
