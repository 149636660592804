<form class="with-action-buttons" [formGroup]="clientContactPersonsCreateForm" (ngSubmit)="clientsService.createContactPerson(client_id, clientContactPersonsCreateForm.value)">
	<div class="divider"><span>Gegevens contactpersoon</span></div>
	<mat-form-field>
		<input matInput placeholder="Voornaam" type="text" required formControlName="firstname">
		<mat-error *ngIf="formsService.getFieldError('firstname')">{{formsService.getFieldError('firstname')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="Achternaam" type="text" required formControlName="lastname">
		<mat-error *ngIf="formsService.getFieldError('lastname')">{{formsService.getFieldError('lastname')}}</mat-error>
	</mat-form-field>
	<div class="divider"><span>Contactgegevens</span></div>
	<mat-form-field>
		<input matInput placeholder="Telefoon" type="tel" formControlName="telephone">
		<mat-error *ngIf="formsService.getFieldError('telephone')">{{formsService.getFieldError('telephone')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="Mobiel" type="tel" formControlName="mobile">
		<mat-error *ngIf="formsService.getFieldError('mobile')">{{formsService.getFieldError('mobile')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="E-Mail" type="email" required formControlName="email">
		<mat-error *ngIf="formsService.getFieldError('email')">{{formsService.getFieldError('email')}}</mat-error>
	</mat-form-field>
	<div class="action-buttons">
		<button color="primary" mat-button [disabled]="!clientContactPersonsCreateForm.valid">OPSLAAN</button>
	</div>
</form>
