import {Component, OnDestroy, OnInit} from '@angular/core';
import {DashboardReportsStats} from '../../_interfaces/dashboard-reports-stats';
import {ApiService} from '../../_interceptors/api.service';
import {ServerResponse} from '../../_interfaces/server.response';
import {IndexedDBService} from '../../_services/indexeddb.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {

	public reports: DashboardReportsStats = {
		warehouse: {
			open: 0,
			verify: 0,
			closed: 0
		},
		ladder: {
			open: 0,
			verify: 0,
			closed: 0
		},
		tent: {
			open: 0,
			verify: 0,
			closed: 0
		},
		reinspection: 0
	};

	private _indexedDBSubscription: Subscription = null;
	private _syncSubscription: Subscription = null;

	constructor(public apiService: ApiService,
				private indexedDBService: IndexedDBService) {
	}

	ngOnInit(): void {
		if (this.apiService.isOnline) {
			this.apiService.get('reports/warehouse/count-closed-reports').then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.success !== 'undefined') {
						if (data.success === true) {
							if (typeof data.data !== 'undefined') {
								if (typeof data.data.warehouse !== 'undefined') {
									this.reports.warehouse.closed = data.data.warehouse;
								}
								if (typeof data.data.ladder !== 'undefined') {
									this.reports.ladder.closed = data.data.ladder;
								}
								if (typeof data.data.tent !== 'undefined') {
									this.reports.tent.closed = data.data.tent;
								}
							}
						}
					}
				}
			}).catch(error => {
				throw new Error(error);
			});
		}

		this._syncSubscription = this.indexedDBService.syncComplete.subscribe(() => {
			this._indexedDBSubscription = this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.recount();
				}
			});
		});
	}

	ngOnDestroy(): void {
		if (this._indexedDBSubscription !== null) {
			this._indexedDBSubscription.unsubscribe();
		}
		if (this._syncSubscription !== null) {
			this._syncSubscription.unsubscribe();
		}
	}

	clearCount(): void {
		this.reports = {
			warehouse: {
				open: 0,
				verify: 0,
				closed: 0
			},
			ladder: {
				open: 0,
				verify: 0,
				closed: 0
			},
			tent: {
				open: 0,
				verify: 0,
				closed: 0,
			},
			reinspection: 0
		};
	}

	recount(): void {
		// this.clearCount();

		let warehouse_open: number = 0,
			warehouse_verify: number = 0,
			ladders_open: number = 0,
			ladders_verify: number = 0,
			tent_open: number = 0,
			tent_verify: number = 0;

		this.indexedDBService.database.getAllFast(this.indexedDBService.tableReports).then(reports => {
			if (typeof reports !== 'undefined') {
				if (reports.length) {
					reports.forEach(report => {
						if (report.type === 'warehouse') {
							if (report.status === 'open') {
								warehouse_open++;
							} else if (report.status === 'verify') {
								warehouse_verify++;
							}
						} else if (report.type === 'ladders') {
							if (report.status === 'open') {
								ladders_open++;
							} else if (report.status === 'verify') {
								ladders_verify++;
							}
						} else if (report.type === 'tent') {
							if (report.status === 'open') {
								tent_open++;
							} else if (report.status === 'verify') {
								tent_verify++;
							}
						}
					});
				}
				this.reports.warehouse.open = warehouse_open;
				this.reports.warehouse.verify = warehouse_verify;
				this.reports.ladder.open = ladders_open;
				this.reports.ladder.verify = ladders_verify;
				this.reports.tent.open = tent_open;
				this.reports.tent.verify = tent_verify;
			}
		});

		this.indexedDBService.database.getAll(this.indexedDBService.tableReportsReinspections).then(reports => {
			if (typeof reports !== 'undefined') {
				if (reports.length) {
					this.reports.reinspection = reports.length;
				}
			}
		});
	}
}
