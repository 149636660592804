import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {GeneralService} from '../../../_services/general.service';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {Reports} from '../../../_interfaces/reports/reports';
import {ReportsService} from '../../../_services/reports.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {ServerResponse} from '../../../_interfaces/server.response';
import {ApiService} from '../../../_interceptors/api.service';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';
import {ClientsService} from '../../../_services/clients.service';
import {Client} from '../../../_interfaces/clients/clients';

@Component({
	selector: 'app-clients-reports-list',
	templateUrl: './clients-reports-list.component.html',
	styleUrls: ['./clients-reports-list.component.scss'],
	animations: [routeAnimation]
})

export class ClientsReportsListComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	private isLoading: boolean = false;

	public client_id: number = null;
	public reports: Reports[] = [];

	private _activatedRouteService: Subscription = null;

	@Input() smallWidget: boolean = false;

	constructor(private reportsService: ReportsService,
				private apiService: ApiService,
				private previousUrlService: PreviousUrlService,
				private activatedRouteService: ActivatedRoute,
				private generalService: GeneralService,
				private clientsService: ClientsService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this._activatedRouteService = this.activatedRouteService.params.subscribe((params: Params) => {
			this.client_id = parseInt(params['client_id'], 10);

			if (this.smallWidget === false) {
				this.clientsService.getClient(this.client_id).then((client: Client) => {
					if (typeof client !== 'undefined') {
						this.pageTitleService.setTitle(client.company_name + ' | Bekijk klant rapportage | ' + environment.webappName);
						this.pageTitleService.setPageTitle(client.company_name);
					}
				});
			}

			this.apiService.connectionChanged.subscribe(online => {
				if (online && this.reports.length === 0) {
					this.currentPage = 0;
					this.search('');
				}
			});
			this.search('');
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteService !== null) {
			this._activatedRouteService.unsubscribe();
		}
	}

	getReports(append_data: boolean = true) {
		if (!this.isLoading) {
			this.isLoading = true;
			this.currentPage++;

			this.reportsService.getClientReports(this.client_id, {
				start: (this.currentPage - 1) * (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
				length: (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
				'columns[0][search][value]': this.searchString
			}).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.recordsTotal !== 'undefined') {
						this.totalItems = data.recordsTotal;
					}
					if (append_data === true) {
						this.reports.push(...<Reports[]>data.data);
					} else {
						this.reports = <Reports[]>data.data;
					}
				}
				this.isLoading = false;
			});
		}
	}

	deleteReport(report_type: string, report_id: number) {
		this.reportsService.deleteReport(report_type, report_id).then(status => {
			if (status === true) {
				let index = this.reports.findIndex(data => data.id === report_id);

				if (index !== -1) {
					this.reports.splice(index, 1);
				}
			}
		});
	}

	search(event) {
		this.searchString = event;
		this.currentPage = 0;
		this.getReports(false);
	}
}
