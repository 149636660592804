<img src="assets/images/logo.png" alt="KVVM" width="250" height="83">
<div class="divider"><span>Magazijn rapportage</span></div>
<button mat-button color="primary" [routerLink]="['/reports/warehouse/create']">Nieuwe magazijn rapportage</button>
<div class="btn-group-rounded">
	<button mat-button color="grey-outline" [routerLink]="['/reports/warehouse/open']">Openstaande rapportage<span class="counter">{{ reports.warehouse.open }}</span></button>
	<button mat-button color="grey-outline" [routerLink]="['/reports/warehouse/verify']">Controle rapportage<span class="counter">{{ reports.warehouse.verify }}</span></button>
	<button *ngIf="apiService.isOnline" mat-button color="grey-outline" [routerLink]="['/reports/warehouse/closed']">Gesloten rapportage<span class="counter">{{ reports.warehouse.closed }}</span></button>
	<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">Gesloten rapportage</button>
</div>
<div class="divider"><span>Trappen rapportage</span></div>
<button mat-button color="accent" [routerLink]="['/reports/ladders/create']">Nieuwe trappen rapportage</button>
<div class="btn-group-rounded">
	<button mat-button color="grey-outline" [routerLink]="['/reports/ladders/open']">Openstaande rapportage<span class="counter">{{ reports.ladder.open }}</span></button>
	<button mat-button color="grey-outline" [routerLink]="['/reports/ladders/verify']">Controle rapportage<span class="counter">{{ reports.ladder.verify }}</span></button>
	<button *ngIf="apiService.isOnline" mat-button color="grey-outline" [routerLink]="['/reports/ladders/closed']">Gesloten rapportage<span class="counter">{{ reports.ladder.closed }}</span></button>
	<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">Gesloten rapportage</button>
</div>
<div class="divider"><span>Tenten rapportage</span></div>
<button mat-button color="accent" [routerLink]="['/reports/tent/create']">Nieuwe tent rapportage</button>
<div class="btn-group-rounded">
	<button mat-button color="grey-outline" [routerLink]="['/reports/tent/open']">Openstaande rapportage<span class="counter">{{ reports.tent.open }}</span></button>
	<button mat-button color="grey-outline" [routerLink]="['/reports/tent/verify']">Controle rapportage<span class="counter">{{ reports.tent.verify }}</span></button>
	<button *ngIf="apiService.isOnline" mat-button color="grey-outline" [routerLink]="['/reports/tent/closed']">Gesloten rapportage<span class="counter">{{ reports.tent.closed }}</span></button>
	<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">Gesloten rapportage</button>
</div>
<div class="divider"><span>Herinspectie nodig</span></div>
<button mat-button color="grey-outline" routerLink="/reports/reinspection-required" style="text-align:left;">Bekijk rapportage<span class="counter">{{ reports.reinspection }}</span></button>
<div class="divider"><span>Klanten</span></div>
<button mat-button color="primary-outline" [routerLink]="['/clients']">Bekijk alle klanten</button>
<button mat-button color="primary-outline" [routerLink]="['/clients/create']">Nieuwe klant aanmaken</button>
