import {AfterViewInit, ChangeDetectorRef, Component, HostBinding, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {routeAnimation} from '../../route.animation';
import {environment} from '../../../environments/environment';

export interface IWindow extends Window {
	webkitSpeechRecognition: any;
}

const {webkitSpeechRecognition}: IWindow = <IWindow>window;

@Component({
	selector: 'app-speech-to-text',
	templateUrl: './speech-to-text.component.html',
	styleUrls: ['./speech-to-text.component.scss'],
	animations: [routeAnimation]
})

export class SpeechToTextComponent implements OnInit, AfterViewInit {
	@HostBinding('@routeAnimation') routerTransition = true;

	public textItems: Array<{
		stop: any,
		text: string
	}> = [];

	private isStoppedSpeechRecog: boolean = false;
	private tempWords: string = '';
	private textToNumbers: Array<{}> = [
		{
			string: 'zero',
			number: '0'
		},
		{
			string: 'ou',
			number: '0'
		},
		{
			string: 'one',
			number: '1'
		},
		{
			string: 'two',
			number: '2'
		},
		{
			string: 'three',
			number: '3'
		},
		{
			string: 'four',
			number: '4'
		},
		{
			string: 'five',
			number: '5'
		},
		{
			string: 'six',
			number: '6'
		},
		{
			string: 'seven',
			number: '7'
		},
		{
			string: 'eight',
			number: '8'
		},
		{
			string: 'nine',
			number: '9'
		},
		{
			string: 'nul',
			number: '0'
		},
		{
			string: 'een',
			number: '1'
		},
		{
			string: 'twee',
			number: '2'
		},
		{
			string: 'drie',
			number: '3'
		},
		{
			string: 'vier',
			number: '4'
		},
		{
			string: 'vijf',
			number: '5'
		},
		{
			string: 'zes',
			number: '6'
		},
		{
			string: 'zeven',
			number: '7'
		},
		{
			string: 'acht',
			number: '8'
		},
		{
			string: 'negen',
			number: '9'
		}
	];

	private recognition = null;
	public supported: boolean = true;

	constructor(private ref: ChangeDetectorRef,
				public dialogRef: MatDialogRef<SpeechToTextComponent>) {
		if ('webkitSpeechRecognition' in window) {
			console.log('recognition available ...');
			this.recognition = new webkitSpeechRecognition();
		} else {
			this.supported = false;
		}
	}

	ngOnInit() {
		if (this.recognition !== null) {
			this.recognition.interimResults = false;
			if (environment.production) {
				this.recognition.lang = 'nl-NL';
			} else {
				this.recognition.lang = 'en-US';
			}
			this.recognition.speechContexts = [{
				'phrases': ['$OOV_CLASS_DIGIT_SEQUENCE']
				// $OOV_CLASS_ALPHANUMERIC_SEQUENCE
			}];

			this.recognition.addEventListener('result', (e) => {
				console.log('result', e);
				if (typeof e.results !== 'undefined') {
					let current = e.resultIndex,
						transcript = (typeof e.results[current] !== 'undefined' ? (typeof e.results[current][0] !== 'undefined' ? (typeof e.results[current][0].transcript !== 'undefined' ? e.results[current][0].transcript : null) : null) : null),
						mobileRepeatBug = (e.resultIndex === 1 && transcript === e.results[0][0].transcript);

					if (transcript !== null) {
						if (!mobileRepeatBug) {
							this.textToNumbers.forEach(val => {
								let index = transcript.indexOf(val['string']);

								if (index !== -1) {
									transcript = transcript.replace(val['string'], val['number']);
								}
							});

							transcript = transcript
								.replace(/\s/g, '')
								.replace(/:/g, '')
								.replace(/\//g, '')
								.replace(/\\/g, '');
							this.tempWords = transcript;
						}
					}
				}
			});
		}
	}

	ngAfterViewInit() {
		this.start();
	}

	start() {
		if (this.recognition !== null) {
			this.isStoppedSpeechRecog = false;
			this.recognition.start();
			this.recognition.addEventListener('end', this.processRecognitionResults.bind(this), true);
		}
	}

	stop(result = null) {
		if (this.recognition !== null) {
			this.isStoppedSpeechRecog = true;
			this.recognition.stop();
			this.recognition.removeEventListener('end', this.processRecognitionResults.bind(this), true);

			this.logCode(result);
		}
	}

	reset() {
		this.tempWords = '';
		this.textItems = [];
	}

	wordConcat() {
		if (this.tempWords !== '') {
			this.textItems.push({
				stop: this.stop.bind(this),
				text: this.formatNumbers(this.tempWords)
			});
		}
		this.tempWords = '';
	}

	logCode(result = null) {
		this.ref.detectChanges();
		this.dialogRef.close(result);
	}

	processRecognitionResults() {
		if (this.recognition !== null) {
			if (this.isStoppedSpeechRecog) {
				this.recognition.stop();
			} else {
				this.wordConcat();
				this.recognition.start();
			}
		}
	}

	formatNumbers(numbers) {
		return  numbers.slice(0, 3) + '-' + numbers.slice(3, 6) + '-' + numbers.slice(6, 9) + '-' + numbers.slice(9);
	}
}
