import {Component} from '@angular/core';
import {PreviousUrlService} from '../../../_services/previous-url.service';

@Component({
	selector: 'app-clients-router',
	templateUrl: './clients-router.component.html'
})

export class ClientsRouterComponent {

	constructor(public previousUrlService: PreviousUrlService) {
	}
}
