import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../../route.animation';
import {GeneralService} from '../../../../../_services/general.service';
import {FormsService} from '../../../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../../../_services/previous-url.service';
import {ReportsService} from '../../../../../_services/reports.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ReportsShortcomingsTypes} from '../../../../../_interfaces/reports/reports-shortcomings-types';
import {ReportsShortcomingsActions} from '../../../../../_interfaces/reports/reports-shortcomings-actions';
import {ReportsBuildingsShortcomingsQuestionnaire} from '../../../../../_interfaces/reports/reports-buildings-shortcomings-questionnaire';
import {PageTitleService} from '../../../../../_services/page-title.service';
import {MatDialog} from '@angular/material/dialog';
import {ReportsBuildingsReports} from '../../../../../_interfaces/reports/reports-buildings-reports';
import {ApiService} from '../../../../../_interceptors/api.service';
import {AuthenticationService} from '../../../../../_services/authentication.service';
import {Reports} from '../../../../../_interfaces/reports/reports';
import {User} from '../../../../../_interfaces/user';
import {environment} from '../../../../../../environments/environment';
import {ReportsBuildings} from '../../../../../_interfaces/reports/reports-buildings';
import {ReportsBuildingsMapsMarker} from '../../../../../_interfaces/reports/reports-buildings-maps-marker';
import {ClientsService} from '../../../../../_services/clients.service';
import {ClientsBuildingMaps} from '../../../../../_interfaces/clients/clients-building-maps';

@Component({
	selector: 'app-reports-buildings-reports-view',
	templateUrl: './reports-buildings-reports-view.component.html',
	styleUrls: ['./reports-buildings-reports-view.component.scss'],
	animations: [routeAnimation]
})

export class ReportsClosedBuildingsReportsViewComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_type: string = null;
	public report_id: number = null;
	public building_id: number = null;
	public building_report_id: number = null;
	public buildingReport: ReportsBuildingsReports = null;
	public offlinePhoto: Array<any> = [];
	public reportEditable: boolean = false;

	public actions: ReportsShortcomingsActions[] = [];
	public types: ReportsShortcomingsTypes[] = [];
	public questionnaire: ReportsBuildingsShortcomingsQuestionnaire[] = [];
	public material_name: string = null;

	public hasBuildingMap: boolean = false;
	public buildingMapUrl: string = null;
	public mapMarkers: ReportsBuildingsMapsMarker[] = [];

	private _activatedRouteSubscription: Subscription = null;
	private _authenticationServiceSubscription: Subscription = null;

	constructor(private generalService: GeneralService,
				private authenticationService: AuthenticationService,
				public apiService: ApiService,
				private reportsService: ReportsService,
				public formsService: FormsService,
				private activatedRoute: ActivatedRoute,
				private pageTitleService: PageTitleService,
				private clientsService: ClientsService,
				private dialogService: MatDialog,
				private previousUrlService: PreviousUrlService) {
	}

	ngOnInit(): void {
		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];
			this.report_id = parseInt(params['report_id'], 10);
			this.building_id = parseInt(params['building_id'], 10);
			this.building_report_id = parseInt(params['building_report_id'], 10);

			this.reportsService.getReport(this.report_type, this.report_id).then((report: Reports) => {
				this._authenticationServiceSubscription = this.authenticationService.user.first().subscribe((user: User) => {
					if (typeof report !== 'undefined' && typeof user !== 'undefined') {

						this.reportsService.getBuilding(this.report_type, this.report_id, this.building_id).then((building: ReportsBuildings) => {
							if (this.report_type === 'warehouse') {
								this.pageTitleService.setTitle(building.name + ' | Nieuwe schade toevoegen | ' + environment.webappName);
							} else {
								this.pageTitleService.setTitle(building.name + ' | Nieuwe inspectie | ' + environment.webappName);
							}
							this.pageTitleService.setPageTitle(building.name);
						});
					}
				});
			});

			this.reportsService.getClosedReportBuildingReport(this.report_type, this.report_id, this.building_id, this.building_report_id).then((data: ReportsBuildingsReports) => {
				if (typeof data !== 'undefined') {
					this.buildingReport = data;

					if (typeof data.markers !== 'undefined') {
						if (data.markers.length) {
							this.hasBuildingMap = true;
							this.mapMarkers = data.markers;

							this.reportsService.getClosedReports(this.report_type, this.report_id).then((report: Reports) => {
								if (typeof report !== 'undefined') {
									this.clientsService.getBuildingMap(report.client_id, data.building_map_id).then((buildingMap: ClientsBuildingMaps) => {
										if (typeof buildingMap !== 'undefined') {
											this.hasBuildingMap = true;
											this.buildingMapUrl = buildingMap.map_url;
										}
									});
								}
							});
						}
					}
				}
			});
		});

		this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_type + '/' + this.report_id + '/closed/buildings/' + this.building_id + '/reports');
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._authenticationServiceSubscription !== null) {
			this._authenticationServiceSubscription.unsubscribe();
		}
	}


	showBuildingMap() {
		this.reportsService.showReportBuildingMap(this.buildingMapUrl, true, this.mapMarkers).then(() => {
		});
	}}
