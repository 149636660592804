import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {routeAnimation} from '../../../route.animation';
import {AuthenticationService} from '../../../_services/authentication.service';
import {PageTitleService} from '../../../_services/page-title.service';
import {FormBuilder, Validators} from '@angular/forms';
import {matchOtherValidator} from '../../../_validators/password-match';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './recover-lost-password.component.html',
	styleUrls: ['./recover-lost-password.component.scss'],
	animations: [routeAnimation]
})
export class RecoverLostPasswordComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	public createPasswordForm = this.formBuilder.group({
		token: ['', Validators.required],
		new_password: ['', Validators.required],
		repeat_new_password: ['', [Validators.required, matchOtherValidator('new_password')]]
	});

	private _userAuthSubscription: Subscription = null;
	private _activatedRouteSubscription: Subscription = null;
	private _errorsSubscription: Subscription = null;

	constructor(private router: Router,
				public authenticationService: AuthenticationService,
				private activatedRoute: ActivatedRoute,
				private pageTitleService: PageTitleService,
				private formBuilder: FormBuilder,
				public formsService: FormsService) {
	}

	ngOnInit(): void {
		this.pageTitleService.setTitle('Vergeten wachtwoord');
		this.formsService.form = this.createPasswordForm;

		this._userAuthSubscription = this.authenticationService.user.first().subscribe(user => {
			if (user) {
				this.router.navigate(['/']).then(() => {});
			} else {
				this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
					this.createPasswordForm.get('token').setValue(params['recovery_token']);
				});
			}
		});

		this._errorsSubscription = this.authenticationService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._userAuthSubscription !== null) {
			this._userAuthSubscription.unsubscribe();
		}
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}
}
