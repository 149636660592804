import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {FormsService} from '../../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {ReportBuildingParticulars} from '../../../../_interfaces/reports/report-building-particulars';
import {ReportsService} from '../../../../_services/reports.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ServerResponse} from '../../../../_interfaces/server.response';
import {ReportsBuildings} from '../../../../_interfaces/reports/reports-buildings';
import {debounceTime} from 'rxjs/operators';
import {ApiService} from '../../../../_interceptors/api.service';
import {AuthenticationService} from '../../../../_services/authentication.service';
import {Reports} from '../../../../_interfaces/reports/reports';
import {User} from '../../../../_interfaces/user';
import {PageTitleService} from '../../../../_services/page-title.service';
import {environment} from '../../../../../environments/environment';
import {ClientsBuildingMaps} from '../../../../_interfaces/clients/clients-building-maps';
import {ClientsService} from '../../../../_services/clients.service';

@Component({
	selector: 'app-reports-buildings-edit',
	templateUrl: './reports-buildings-edit.component.html',
	styleUrls: ['./reports-buildings-edit.component.scss'],
	animations: [routeAnimation]
})

export class ReportsBuildingsEditComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_type: string = null;
	public report_id: number = null;
	public building_id: number = null;
	public particulars: ReportBuildingParticulars[] = [];
	public isFiltered: boolean = false;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];
	public reportEditable: boolean = false;
	public showParticularTextarea: Array<number> = [];
	public clientBuildingMaps: ClientsBuildingMaps[] = [];

	public reportsBuildingEditForm = this.formBuilder.group({
		name: ['', [Validators.required, Validators.maxLength(50)]],
		description: [''],
		squares: [''],
		company_inspection: null,
		building_map_id: [''],
		query: ['']
	});

	private _activatedRouteSubscription: Subscription = null;
	private _errorsSubscription: Subscription = null;
	private _authenticationServiceSubscription: Subscription = null;

	constructor(public reportsService: ReportsService,
				private authenticationService: AuthenticationService,
				private apiService: ApiService,
				public formsService: FormsService,
				private previousUrlService: PreviousUrlService,
				private activatedRoute: ActivatedRoute,
				private clientsService: ClientsService,
				private formBuilder: FormBuilder,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length) {
				this.missingData = [];
				this.loadData();
			}
		});

		this.formsService.form = this.reportsBuildingEditForm;

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];
			this.report_id = parseInt(params['report_id'], 10);
			this.building_id = parseInt(params['building_id'], 10);

			this.reportsService.getReport(this.report_type, this.report_id).then((report: Reports) => {
				this._authenticationServiceSubscription = this.authenticationService.user.first().subscribe((user: User) => {
					if (typeof report !== 'undefined' && typeof user !== 'undefined') {

						this.clientsService.getBuildingMaps(report.client_id).then(buildingMaps => {
							if (typeof buildingMaps !== 'undefined') {
								if (typeof buildingMaps.data !== 'undefined') {
									this.clientBuildingMaps = buildingMaps.data;
								}
							}

							this.loadData();
						});

						this.pageTitleService.setTitle(report.report_nr + ' | Rapportage gebouw aanpassen | ' + environment.webappName);
						this.pageTitleService.setPageTitle(report.report_nr);

						if (typeof report.edit_lock !== 'undefined' && typeof report.edit_lock_user_id !== 'undefined') {
							if (report.edit_lock === true && (<any>report.edit_lock_user_id === '' ||
								report.edit_lock_user_id === null ||
								report.edit_lock_user_id === 0 ||
								report.edit_lock_user_id === user.id ||
								user.type === 'kvvm-admin')) {
								this.reportEditable = true;
							}
						}
					}
				});
			});

			this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_type + '/' + this.report_id + '/buildings');
		});

		this.reportsBuildingEditForm
			.get('query')
			.valueChanges
			.pipe(
				debounceTime(300))
			.subscribe(value => {
				if (value !== null && value !== '') {
					this.particulars.forEach(data => {
						data.visible = data.building_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
					});
					this.isFiltered = true;
				} else {
					this.clearSearchInput();
				}
			});

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}

		if (this._authenticationServiceSubscription !== null) {
			this._authenticationServiceSubscription.unsubscribe();
		}
	}

	clearSearchInput() {
		this.reportsBuildingEditForm.get('query').setValue('');
		this.isFiltered = false;
		this.particulars.forEach(data => {
			data.visible = true;
		});
	}

	fixAngularScrewUpAndInsertActualValue(event, index) {
		if (event.checked) {
			this.reportsBuildingEditForm.controls['lib_particular_id[' + index + ']'].setValue(parseInt(event.source.value, 10));
		} else {
			this.reportsBuildingEditForm.controls['lib_particular_id[' + index + ']'].setValue('');
		}
	}

	loadData() {
		if (this.report_type === 'warehouse') {
			this.reportsService.getBuildingParticulars().then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					this.particulars = <ReportBuildingParticulars[]>data.data;

					if (data.data.length) {
						data.data.forEach(particular => {
							this.reportsBuildingEditForm.addControl('lib_particular_id[' + particular.id + ']', new FormControl());
							this.reportsBuildingEditForm.addControl('particular_description[' + particular.id + ']', new FormControl(''));
						});
					}

					this.reportsService.getBuilding(this.report_type, this.report_id, this.building_id).then((data2: ReportsBuildings) => {
						if (typeof data2 !== 'undefined') {
							this.formsService.populateForm(data2);

							let controls = this.reportsBuildingEditForm.controls;
							Object.keys(controls).map(key => {
								if (key.indexOf('lib_particular_id') !== -1) {
									let cleanKey = parseInt(key.replace('lib_particular_id[', '').replace(']', ''), 10);

									if (typeof data2.lib_particular_id !== 'undefined') {
										if (data2.lib_particular_id !== null) {
											if (data2.lib_particular_id.indexOf(cleanKey) !== -1) {
												this.showParticularTextarea.push(cleanKey);
												this.reportsBuildingEditForm.get('lib_particular_id[' + cleanKey + ']').setValue(cleanKey);
											} else {
												controls[key].setValue('');
											}
										} else {
											controls[key].setValue('');
										}
									} else {
										controls[key].setValue('');
									}

									if (typeof data2.particular_description !== 'undefined') {
										if (data2.particular_description !== null) {
											if (typeof data2.particular_description[cleanKey] !== 'undefined') {
												if (data2.particular_description[cleanKey] !== null) {
													if (typeof this.reportsBuildingEditForm.controls['particular_description[' + cleanKey + ']'] !== 'undefined') {
														this.reportsBuildingEditForm.controls['particular_description[' + cleanKey + ']'].setValue(data2.particular_description[cleanKey]);
													}
												}
											}
										}
									}

									controls[key].valueChanges.subscribe(value => {
										if (typeof value !== 'number') {
											if (value !== null && value !== '' && value !== false) {
												this.showParticularTextarea.push(cleanKey);
											} else {
												let index = this.showParticularTextarea.findIndex(id => id === cleanKey);
												if (index !== -1) {
													this.showParticularTextarea.splice(index, 1);
												}
											}
										}
									});
								}
							});

							this.dataLoaded = true;
						} else {
							this.missingData.push('Gebouw');
						}
					});
				} else {
					this.missingData.push('Gebouw');
				}
			});
		} else if (this.report_type === 'ladders' || this.report_type === 'tent') {
			this.reportsService.getBuilding(this.report_type, this.report_id, this.building_id).then((data2: ReportsBuildings) => {
				if (typeof data2 !== 'undefined') {
					this.formsService.populateForm(data2);

					this.dataLoaded = true;
				} else {
					this.missingData.push('Gebouw');
				}
			});
		}
	}
}
