<button *ngIf="reportEditable" mat-button color="primary" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/create']">
	Nieuwe {{ report_type != 'tent' ? 'gebouw toevoegen' : 'inspectie' }}
</button>
<ng-container *ngIf="smallWidget === false">
	<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + (report_status === '/closed' ? report_status : '') + '/' + '/view']">Bekijk rapportage</button>
	<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + report_status]">Bekijk alle rapportages</button>
	<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
</ng-container>
<mat-nav-list *ngIf="reportBuildings.length" class="mat-nav-list-with-border">
	<div *ngIf="!smallWidget" infiniteScroll infiniteScrollDistance="1" (scrolled)="getReportBuildings()"></div>
	<mat-list-item *ngFor="let reportBuilding of reportBuildings">
		<div class="mat-list-content" [routerLink]="['/reports/' + report_type + '/' + report_id + (report_status === '/closed' ? report_status : '') + '/buildings/' + reportBuilding.id + '/reports']">
			<h4 matLine>{{ reportBuilding.name }}</h4>
		</div>
		<ng-container *ngIf="reportBuilding.color">
			<div class="tag" [style.background]="reportBuilding.color"></div>
		</ng-container>
		<ng-container *ngIf="reportBuilding.is_resolved">
			<mat-icon class="is_resolved">check_circle_outline</mat-icon>
		</ng-container>
		<ng-container *ngIf="reportEditable">
			<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<a mat-menu-item [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + reportBuilding.id + '/edit']">Aanpassen</a>
				<a mat-menu-item [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + reportBuilding.id + '/reports/create']">
					Nieuwe {{ report_type != 'tent' ? 'schade toevoegen' : 'tent inspectie' }}
				</a>
				<a mat-menu-item (click)="deleteReportBuilding(report_type, report_id, reportBuilding.id)">Verwijderen</a>
			</mat-menu>
		</ng-container>
	</mat-list-item>
	<a mat-list-item *ngIf="smallWidget === true && totalItems > 5" [routerLink]="['/reports/' + report_type + '/' + report_id + (report_status === '/closed' ? report_status : '') + '/buildings']" class="mat-list-item-more">
		<span matLine>Bekijk meer ...</span>
		<button mat-icon-button>
			<mat-icon>chevron_right</mat-icon>
		</button>
	</a>
</mat-nav-list>
<div *ngIf="reportBuildings.length === 0">
	<br>Geen inspecties gevonden.
</div>
