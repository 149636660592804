import {Component, Input} from '@angular/core';
import {Reports} from '../../../_interfaces/reports/reports';
import {MatExpansionPanel} from '@angular/material';

@Component({
	selector: 'app-reports-view-small-widget',
	templateUrl: './reports-view-small-widget.component.html',
	styleUrls: ['./reports-view-small-widget.component.scss'],
	viewProviders: [
		MatExpansionPanel
	]
})

export class ReportsViewSmallWidgetComponent {

	@Input() report: Reports;

	constructor() {
	}
}
