import {Injectable} from '@angular/core';

@Injectable()

export class LocalStorageService {

	set(key: string, data: Object): void {
		localStorage.setItem(key, JSON.stringify(data));
	}

	get(key: string): any {
		return JSON.parse(localStorage.getItem(key));
	}

	delete(key: string): void {
		localStorage.removeItem(key);
	}
}
