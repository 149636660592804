import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {GeneralService} from '../../../../_services/general.service';
import {FormsService} from '../../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {InspectorsService} from '../../../../_services/inspectors.service';
import * as moment from 'moment';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {ActivatedRoute, Params} from '@angular/router';
import {InspectorsCalibration} from '../../../../_interfaces/inspectors/inspectors-calibration';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {APP_DATE_FORMATS, CustomDateAdapter} from '../../../../_pipes/angular2-material-datepicker-custom-date';
import {ApiService} from '../../../../_interceptors/api.service';

@Component({
	selector: 'app-inspectors-calibrations-edit',
	templateUrl: './calibrations-edit.component.html',
	styleUrls: ['./calibrations-edit.component.scss'],
	animations: [routeAnimation],
	providers: [
		{
			provide: DateAdapter, useClass: CustomDateAdapter
		},
		{
			provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
		}
	]
})

export class InspectorsCalibrationsEditComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public calibration_id: number = null;

	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];

	public inspectorCalibrationForm = this.formBuilder.group({
		device_id: ['', [Validators.required, Validators.maxLength(100)]],
		device_brand: ['', [Validators.required, Validators.maxLength(100)]],
		device_description: ['', [Validators.required, Validators.maxLength(100)]],
		date_calibrated: ['', Validators.required],
		date_calibrated_input: [''],
		next_calibration_date: ['', Validators.required],
		next_calibration_date_input: [''],
		certificate_number: ['', Validators.required]
	});

	private _errorsSubscription: Subscription = null;
	private _activatedRouteSubscription: Subscription = null;

	constructor(private generalService: GeneralService,
				public inspectorsService: InspectorsService,
				public formsService: FormsService,
				private formBuilder: FormBuilder,
				private activatedRoute: ActivatedRoute,
				private apiService: ApiService,
				private previousUrlService: PreviousUrlService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length > 0) {
				this.missingData = [];
				this.loadData();
			}
		});
		this.formsService.form = this.inspectorCalibrationForm;

		this.previousUrlService.setPreviousUrlIfNull('/inspectors/calibrations');

		this._errorsSubscription = this.inspectorsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.calibration_id = parseInt(params['calibration_id'], 10);

			this.loadData();
		});
	}

	ngOnDestroy(): void {
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}

		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
	}

	updateInspectorDateCalibrated($event) {
		this.inspectorCalibrationForm.get('date_calibrated').setValue(moment($event.value).format('DD-MM-YYYY'));
	}

	updateInspectorNextCalibrationDate($event) {
		this.inspectorCalibrationForm.get('next_calibration_date').setValue(moment($event.value).format('DD-MM-YYYY'));
	}

	loadData() {
		this.inspectorsService.getInspectorCalibration(this.calibration_id).then((details: InspectorsCalibration) => {
			if (typeof details !== 'undefined') {
				this.inspectorCalibrationForm.get('date_calibrated').setValue(moment(details.date_calibrated).format('DD-MM-YYYY'));
				this.inspectorCalibrationForm.get('date_calibrated_input').setValue(new Date(details.date_calibrated));
				this.inspectorCalibrationForm.get('next_calibration_date').setValue(moment(details.next_calibration_date).format('DD-MM-YYYY'));
				this.inspectorCalibrationForm.get('next_calibration_date_input').setValue(new Date(details.next_calibration_date));

				this.formsService.populateForm(details);

				this.dataLoaded = true;
			} else {
				this.missingData.push('Kalibratie');
			}
		});
	}
}
