import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {GeneralService} from '../../../_services/general.service';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {ReportsService} from '../../../_services/reports.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {ClientsService} from '../../../_services/clients.service';
import {Client} from '../../../_interfaces/clients/clients';
import {ApiService} from '../../../_interceptors/api.service';
import {Reports} from '../../../_interfaces/reports/reports';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';
import {SnackbarService} from '../../../_services/snackbar.service';
import {ServerResponse} from '../../../_interfaces/server.response';

@Component({
	selector: 'app-reports-closed-view',
	templateUrl: './reports-view.component.html',
	styleUrls: ['./reports-view.component.scss'],
	animations: [routeAnimation]
})

export class ReportsClosedViewComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_type: string = null;
	public report_id: number = null;
	public report: Reports = null;
	public client: Client = null;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];
	public reportEditable: boolean = false;
	public report_status: string = '/closed';

	private _activatedRouteSubscription: Subscription = null;
	private _authenticationServiceSubscription: Subscription = null;

	constructor(private reportsService: ReportsService,
				private apiService: ApiService,
				private clientsService: ClientsService,
				private activatedRoute: ActivatedRoute,
				private generalService: GeneralService,
				private snackbarService: SnackbarService,
				private previousUrlService: PreviousUrlService,
				private authenticationService: AuthenticationService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];
			this.report_id = parseInt(params['report_id'], 10);

			this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_type + '/open');

			this.loadData();
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._authenticationServiceSubscription !== null) {
			this._authenticationServiceSubscription.unsubscribe();
		}
	}

	loadData() {
		this.reportsService.getClosedReports(this.report_type, this.report_id).then((data: ServerResponse) => {
			if (typeof data !== 'undefined') {
				if (typeof data.data !== 'undefined') {
					if (typeof data.data[0] !== 'undefined') {
						this.report = data.data[0];

						if (typeof this.report.client_id !== 'undefined') {
							this.clientsService.getClient(this.report.client_id).then((client: Client) => {
								if (typeof client !== 'undefined') {
									this.client = client;
								} else {
									this.missingData.push('Klant');
								}
							});
						}

						this._authenticationServiceSubscription = this.authenticationService.user.first().subscribe(() => {

							this.pageTitleService.setTitle(this.report.report_nr + ' | Bekijk rapportage | ' + environment.webappName);
							this.pageTitleService.setPageTitle(this.report.report_nr);
/*
							if (this.report.edit_lock === true && (<any>this.report.edit_lock_user_id === '' ||
								this.report.edit_lock_user_id === null ||
								this.report.edit_lock_user_id === 0 ||
								this.report.edit_lock_user_id === parseInt('' + user.id, 10) ||
								user.type === 'kvvm-admin')) {
								this.reportEditable = true;
							}
*/
						});

						this.dataLoaded = true;
					} else {
						this.missingData.push('Rapportage');
					}
				}
			}
		});
	}

	downloadReport(report_type: string, report_id: number, file_type: string) {
		if (this.apiService.isOnline) {
			this.apiService.download(environment.api_endpoint + 'reports/' + report_type + '/' + report_id + '/download/' + file_type);
		} else {
			this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
		}
	}
}
