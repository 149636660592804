<form class="with-action-buttons" [formGroup]="clientsAttachmentsCreateForm" (ngSubmit)="clientsService.uploadNewAttachment(client_id, clientsAttachmentsCreateForm.value)">
	<div class="divider"><span>Bijlage gegevens</span></div>
	<mat-form-field>
		<ngx-mat-file-input formControlName="filename" placeholder="Bestand" #filenameInput required>
		</ngx-mat-file-input>
		<button mat-icon-button matSuffix *ngIf="!filenameInput.empty" (click)="filenameInput.clear($event)">
			<mat-icon>clear</mat-icon>
		</button>
		<mat-error *ngIf="formsService.getFieldError('filename')">{{formsService.getFieldError('filename')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input title="" matInput placeholder="Titel" type="text" formControlName="title" required>
		<mat-error *ngIf="formsService.getFieldError('title')">{{formsService.getFieldError('title')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<textarea title="" matInput placeholder="Omschrijving" formControlName="description" rows="8"></textarea>
		<mat-error *ngIf="formsService.getFieldError('description')">{{formsService.getFieldError('description')}}</mat-error>
	</mat-form-field>
	<div class="divider"><span>Toevoegen aan</span></div>
	<div class="checkbox-container">
		<mat-checkbox color="primary" formControlName="append_warehouse" value="1">Magazijn</mat-checkbox>
	</div>
	<div class="checkbox-container">
		<mat-checkbox color="primary" formControlName="append_ladder" value="1">Trappen</mat-checkbox>
	</div>
	<div class="checkbox-container">
		<mat-checkbox color="primary" formControlName="append_tent" value="1">Tent</mat-checkbox>
	</div>
	<div class="action-buttons">
		<button color="primary" mat-button [disabled]="!clientsAttachmentsCreateForm.valid">OPSLAAN</button>
	</div>
</form>
