import {Component, Input, OnChanges} from '@angular/core';
import {Client} from '../../../_interfaces/clients/clients';
import {MatExpansionPanel} from '@angular/material';
import {GoogleMapsCoordinates} from '../../../_interfaces/google-maps-api-response';
import {GeneralService} from '../../../_services/general.service';

@Component({
	selector: 'app-clients-view-small-widget',
	templateUrl: './clients-view-small-widget.component.html',
	styleUrls: ['./clients-view-small-widget.component.scss'],
	viewProviders: [
		MatExpansionPanel
	]
})

export class ClientsViewSmallWidgetComponent implements OnChanges {

	@Input() client: Client;
	@Input() showMap: boolean = false;

	public mapCanBeShown: boolean = false;
	public latitude: number = null;
	public longitude: number = null;

	constructor(private generalService: GeneralService) {
	}

	ngOnChanges() {
		let address = [];
		if (this.client !== null) {
			if (this.client.address) {
				address.push(this.client.address);
			}
			if (this.client.postalcode) {
				address.push(this.client.postalcode);
			}
			if (this.client.city) {
				address.push(this.client.city);
			}
			if (this.client.country_name) {
				address.push(this.client.country_name);
			}
			if (typeof this.client.lat !== 'undefined' && typeof this.client.lng !== 'undefined') {
				if (this.client.lat !== null && this.client.lng !== null) {
					this.latitude = this.client.lat;
					this.longitude = this.client.lng;
					this.mapCanBeShown = true;
				} else {
					this.generalService.getLatLngFromAddress(address).then((coords: GoogleMapsCoordinates) => {
						if (typeof coords !== 'undefined') {
							this.latitude = coords.lat;
							this.longitude = coords.lng;
							this.mapCanBeShown = true;
						}
					});
				}
			} else {
				this.generalService.getLatLngFromAddress(address).then((coords: GoogleMapsCoordinates) => {
					if (typeof coords !== 'undefined') {
						this.latitude = coords.lat;
						this.longitude = coords.lng;
						this.mapCanBeShown = true;
					}
				});
			}
		}
	}
}
