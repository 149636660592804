import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DatexPipe} from './pipe.datex';
import {ByteFormatPipe} from './byte-format';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		DatexPipe,
		ByteFormatPipe
	],
	exports: [
		DatexPipe,
		ByteFormatPipe
	]
})

export class CustomPipesModule {
	static forRoot() {
		return {
			ngModule: CustomPipesModule,
			providers: []
		};
	}
}
