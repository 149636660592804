import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {Subscription} from 'rxjs/Subscription';
import {ClientsService} from '../../../../_services/clients.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ClientsAttachments} from '../../../../_interfaces/clients/clients-attachment';
import {GeneralService} from '../../../../_services/general.service';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {ApiService} from '../../../../_interceptors/api.service';
import {ServerResponse} from '../../../../_interfaces/server.response';
import {PageTitleService} from '../../../../_services/page-title.service';
import {environment} from '../../../../../environments/environment';
import {Client} from '../../../../_interfaces/clients/clients';

@Component({
	selector: 'app-clients-attachments-list',
	templateUrl: './clients-attachments-list.component.html',
	styleUrls: ['./clients-attachments-list.component.scss'],
	animations: [routeAnimation]
})

export class ClientsAttachmentsListComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	public client_id: number = null;
	private client: Client = null;
	public isLoading: boolean = false;
	public keepLoading: boolean = false;

	public clientAttachments: ClientsAttachments[] = [];

	private _activatedRouteSubscription: Subscription = null;

	@Input() smallWidget: boolean = false;

	constructor(public clientsService: ClientsService,
				private generalService: GeneralService,
				private previousUrlService: PreviousUrlService,
				private apiService: ApiService,
				private activatedRoute: ActivatedRoute,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && !this.clientAttachments.length) {
				this.currentPage = 0;
				this.getClientAttachments(false);
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.client_id = parseInt(params['client_id'], 10);

			this.previousUrlService.setPreviousUrlIfNull('/clients/' + this.client_id + '/view');

			this.clientsService.getClient(this.client_id).then((client: Client) => {
				if (typeof client !== 'undefined') {
					this.client = client;

					if (this.smallWidget === false) {
						this.pageTitleService.setTitle(client.company_name + ' | Klant bijlagen | ' + environment.webappName);
						this.pageTitleService.setPageTitle(client.company_name);
					}

					this.getClientAttachments(false);
				}
			});
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
	}

	getClientAttachments(append_data: boolean = true) {
		if (!this.isLoading || this.keepLoading) {
			this.isLoading = true;
			this.keepLoading = false;
			this.currentPage++;

			this.clientsService.getAttachments(this.client, {
				start: (this.currentPage - 1) * (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
				length: (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
				'columns[0][search][value]': this.searchString
			}).then((data: ServerResponse) => {
				if (typeof data.recordsTotal !== 'undefined') {
					this.totalItems = data.recordsTotal;
				}
				if (append_data === true) {
					this.clientAttachments.push(...<ClientsAttachments[]>data.data);
				} else {
					this.clientAttachments = <ClientsAttachments[]>data.data;
				}
				this.isLoading = false;
			});
		}
	}

	deleteClientAttachment(client_id: number, attachment_id: number) {
		this.clientsService.deleteAttachment(client_id, attachment_id).then(status => {
			if (status === true) {
				let index = this.clientAttachments.findIndex(data => data.id === attachment_id);

				if (index !== -1) {
					this.clientAttachments.splice(index, 1);
				}
			}
		});
	}

	search($event) {
		this.searchString = $event;
		this.currentPage = 0;
		this.getClientAttachments(false);
	}
}
