<ng-container *ngIf="dataLoaded">
	<div class="divider"><span>Laatste gegevens rapportage</span></div>
	<app-reports-view-small-widget [report]="report"></app-reports-view-small-widget>
	<app-clients-view-small-widget [client]="client"></app-clients-view-small-widget>
	<form class="with-action-buttons" [formGroup]="nextInspectionNotificationForm" (ngSubmit)="clientsService.sendNextInspectionNotificationDetails(client_id, nextInspectionNotificationForm.value)">
		<div class="divider"><span>Aankomende inspectie</span></div>
		<mat-form-field>
			<input type="hidden" formControlName="next_inspection_date">
			<input title="" matInput placeholder="Selecteer volgende inspectie datum" formControlName="next_inspection_date_input" [matDatepicker]="clientNextInspectionDate" readonly (click)="clientNextInspectionDate.open()" (dateChange)="updateNextInspectionDate($event)">
			<mat-datepicker #clientNextInspectionDate></mat-datepicker>
			<mat-error *ngIf="formsService.getFieldError('next_inspection_date_input')">{{formsService.getFieldError('next_inspection_date_input')}}</mat-error>
		</mat-form-field>
		<div class="divider"><span>Verstuur naar</span></div>
		<div>
			<mat-checkbox color="primary" formControlName="contact_company" value="1">Verstuur naar bedrijfsemail</mat-checkbox>
		</div>
		<div *ngFor="let contactPerson of nextInspectionNotification.contact_persons_ids; let i = index" formArrayName="contact_persons_ids">
			<mat-checkbox color="primary" formControlName="{{ i }}" value="{{ contactPerson.id }}" (change)="fixAngularScrewUpAndInsertActualValue($event, i)">{{ contactPerson.firstname }} {{ contactPerson.lastname }} <span class="small">({{ contactPerson.email }})</span></mat-checkbox>
		</div>
		<mat-form-field>
			<textarea title="" matInput placeholder="Aangepaste ontvanger" formControlName="custom_contacts" rows="8"></textarea>
			<mat-error *ngIf="formsService.getFieldError('custom_contacts')">{{formsService.getFieldError('custom_contacts')}}</mat-error>
		</mat-form-field>
		<div class="action-buttons">
			<button color="primary" mat-button [disabled]="!nextInspectionNotificationForm.valid">VERSTUUR</button>
		</div>
	</form>
</ng-container>
<div *ngIf="!dataLoaded && isOffline" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Deze functie is alleen beschikbaar wanneer er een internet verbinding is.</h2>
</div>
