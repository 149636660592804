import {EventEmitter, Injectable} from '@angular/core';
import {ServerResponse} from '../_interfaces/server.response';
import {CustomErrorHandlerService} from './http.error-handler.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {LoadingOverlayService} from '../_services/loading-overlay.service';
import {environment} from '../../environments/environment';
import {SnackbarService} from '../_services/snackbar.service';
import {FileInput} from 'ngx-material-file-input';
import * as FileSaver from 'file-saver';
import {Observable, Subject} from 'rxjs/Rx';

@Injectable()

export class ApiService {

	public offlineError: EventEmitter<boolean> = new EventEmitter(false);
	public loginError: EventEmitter<boolean> = new EventEmitter(false);
	private internalConnectionChanged = new Subject<boolean>();

	constructor(private http: HttpClient,
				private errorHandler: CustomErrorHandlerService,
				private snackbarService: SnackbarService,
				private loadingOverlayService: LoadingOverlayService) {
		window.addEventListener('online', () => {
			this.updateOnlineStatus();
		});
		window.addEventListener('offline', () => {
			this.updateOnlineStatus();
		});
	}

	get connectionChanged(): Observable<boolean> {
		return this.internalConnectionChanged.asObservable();
	}

	get isOnline(): boolean {
		return !!window.navigator.onLine;
	}

	private updateOnlineStatus(): void {
		this.internalConnectionChanged.next(window.navigator.onLine);
	}

	get(url: string, urlData?: Object, showLoader: boolean = true): Promise<ServerResponse> {
		this.showLoader(showLoader);

		return this.http.get(environment.api_endpoint + url + this.buildGetUrl(urlData))
			.map((res: ServerResponse) => this.handleResponse(res, showLoader))
			.toPromise()
			.catch((error: HttpResponse<any>) => this.handleErrorResponse(error, showLoader));
	}

	post(url: string, formData: any, showLoader: boolean = true): Promise<ServerResponse> {
		this.showLoader(showLoader);

		return this.http.post(environment.api_endpoint + url, this.processFormData(formData))
			.map((res: ServerResponse) => this.handleResponse(res, showLoader))
			.toPromise()
			.catch((error: HttpResponse<any>) => this.handleErrorResponse(error, showLoader));
	}

	delete(url: string, showLoader: boolean = true): Promise<ServerResponse> {
		this.showLoader(showLoader);

		return this.http.delete(environment.api_endpoint + url)
			.map((res: ServerResponse) => this.handleResponse(res, showLoader))
			.toPromise()
			.catch((error: HttpResponse<any>) => this.handleErrorResponse(error, showLoader));
	}

	put(url: string, formData: any, showLoader: boolean = true): Promise<ServerResponse> {
		this.showLoader(showLoader);

		return this.http.put(environment.api_endpoint + url, this.processFormData(formData))
			.map((res: ServerResponse) => this.handleResponse(res, showLoader))
			.toPromise()
			.catch((error: HttpResponse<any>) => this.handleErrorResponse(error, showLoader));
	}

	download(url: string, filename: string = null, showLoader: boolean = true, mimeType: string = 'application/octet-stream'): void {
		this.showLoader(showLoader);

		this.http.get<Blob>(url, {
			observe: 'response',
			responseType: 'blob' as 'json'
		})
			.subscribe((res: HttpResponse<Blob>) => {
					let header = res.headers.get('content-disposition');
					FileSaver.saveAs(new Blob([res.body], {type: mimeType}), (filename !== null ? filename : (header !== null ? this.getFileNameFromHttpResponse(header) : null)));
					if (showLoader) {
						this.loadingOverlayService.hideLoader();
					}
				},
				error => {
				this.snackbarService.error('An error occurred while downloading file.');
					console.log(error);
					if (showLoader) {
						this.loadingOverlayService.hideLoader();
					}
					throw new Error(error);
				});
	}

	getFileNameFromHttpResponse(httpResponse) {
		let result = httpResponse.split(';')[1].trim().split('=')[1];
		return result.replace(/"/g, '');
	}

	buildGetUrl(urlData: any): string {
		let queryString: string = '?type=json';

		if (typeof urlData !== 'undefined') {
			if (urlData !== null) {
				for (const key in urlData) {
					if (urlData.hasOwnProperty(key)) {
						if (!queryString) {
							queryString = '?' + key + '=' + urlData[key];
						} else {
							queryString += '&' + key + '=' + urlData[key];
						}
					}
				}
			}
		}
		return queryString;
	}

	processFormData(formData: any) {
		let postData = new FormData(),
			ignoreInputs = [
				'_filterString',
				'photosContainer[]',
			];

		if (typeof formData !== 'undefined') {
			if (formData !== null) {
				Object.keys(formData).forEach(key => {
					if (ignoreInputs.indexOf(key) === -1) {
						if (formData[key] instanceof FileInput) {
							for (let i = 0; i < formData[key]['_files'].length; i++) {
								postData.append(key + (formData[key]['_files'].length > 1 ? '[]' : ''), formData[key]['_files'][i], formData[key]['_files'][i].name);
							}
						} else if (Array.isArray(formData[key])) {
							for (let i = 0; i < formData[key].length; i++) {
								if (formData[key][i] !== false && formData[key][i] !== 'undefined') {
									postData.append(key + '[]', formData[key][i]);
								}
							}
						} else if (formData[key] instanceof Object) {
							// do nothing
							Object.keys(formData[key]).forEach(key2 => {
								if (formData[key][key2] !== false && formData[key][key2] !== 'undefined') {
									if (key2.indexOf('[') !== -1) {
										postData.append(key + key2, formData[key][key2]);
									} else {
										postData.append(key + '[' + key2 + ']', formData[key][key2]);
									}
								}
							});
						} else {
							postData.append(key, formData[key]);
						}
					}
				});

				return postData;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	showLoader(showLoader: boolean) {
		if (showLoader) {
			this.loadingOverlayService.show();
		}
	}

	handleResponse(res: ServerResponse, showLoader: boolean): ServerResponse {
		if (showLoader) {
			this.loadingOverlayService.hide();
		}

		if (typeof res !== 'undefined') {
			if (typeof res.error !== 'undefined') {
				if (typeof res.message !== 'undefined') {
					this.snackbarService.error(res.message);
					return <ServerResponse>res;
				} else {
					return <ServerResponse>res;
				}
			} else if (typeof res.success === 'undefined') {
				this.loadingOverlayService.showError();
				this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				return <ServerResponse>{success: false};
			} else {
				return <ServerResponse>res;
			}
		} else {
			this.loadingOverlayService.showError();
			this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
			return <ServerResponse>{success: false};
		}
	}

	handleErrorResponse(error: HttpResponse<any>, showLoader: boolean) {
		this.loadingOverlayService.hide();
		if (error.status === 401) {
			this.loginError.next(true);
		}
		if (showLoader) {
			return Promise.resolve(this.errorHandler.tryParseError(error));
		} else {
			return Promise.resolve();
		}
	}

	notAvailableOffline(): void {
		this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
	}
}
