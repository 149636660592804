<button *ngIf="reportEditable" mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/attachments/create']">Nieuwe bijlage toevoegen</button>
<ng-container *ngIf="smallWidget === false">
	<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/' + (report_status === '/closed' ? report_status : '') + '/view']">Bekijk rapportage</button>
	<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + report_status]">Bekijk alle rapportages</button>
	<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
</ng-container>
<mat-nav-list *ngIf="reportAttachments.length" class="mat-nav-list-with-border">
	<div *ngIf="!smallWidget" infiniteScroll infiniteScrollDistance="1" (scrolled)="getAttachments()"></div>
	<mat-list-item *ngFor="let attachment of reportAttachments">
		<div class="mat-list-content" (click)="reportsService.downloadFile(attachment.download_url, attachment.original_filename)">
			<h4 matLine>{{ attachment.title }}</h4>
			<a matLine>
				<mat-icon class="small">get_app</mat-icon>
				<span>{{ attachment.created | datex:'DD-MM-YYYY HH:mm' }}</span>
			</a>
		</div>
		<button mat-icon-button [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item (click)="reportsService.downloadFile(attachment.download_url, attachment.original_filename)">
				<mat-icon>get_app</mat-icon>
				<span>Download</span>
			</a>
			<a *ngIf="attachment.report_id" mat-menu-item (click)="deleteReportAttachment(report_type, attachment.report_id, attachment.id)">
				<mat-icon class="icon-danger">delete</mat-icon>
				<span>Verwijderen</span>
			</a>
			<a *ngIf="attachment.client_id" mat-menu-item (click)="deleteClientAttachment(attachment.client_id, attachment.id)">
				<mat-icon class="icon-danger">delete</mat-icon>
				<span>Verwijderen</span>
			</a>
		</mat-menu>
	</mat-list-item>
	<a mat-list-item *ngIf="smallWidget === true && totalItems > 5" [routerLink]="['/reports/' + report_type + '/' +  report_id + (report_status === '/closed' ? report_status : '') + '/attachments']" class="mat-list-item-more">
		<span matLine>Bekijk meer ...</span>
		<button mat-icon-button>
			<mat-icon>chevron_right</mat-icon>
		</button>
	</a>
</mat-nav-list>
<div *ngIf="reportAttachments.length === 0">
	<br> Geen bijlages gevonden.
</div>
