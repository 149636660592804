import {Injectable} from '@angular/core';
import {Router, NavigationEnd, ActivationEnd} from '@angular/router';
import {BehaviorSubject} from 'rxjs/Rx';
import * as _ from 'lodash';

@Injectable()

export class PreviousUrlService {

	private previousUrls: Array<string> = [];
	private currentUrl: string = null;
	private wentBack: boolean = false;

	public navigated: BehaviorSubject<boolean> = new BehaviorSubject(false);

	public routeData: any = null;

	constructor(private router: Router) {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				if (this.wentBack === false) {
					if (this.currentUrl !== null) {
						this.previousUrls.push(this.currentUrl);
						if (event.url === '/') {
							this.routeData = null;
						}
					}
				}
				this.currentUrl = event.url;
				this.wentBack = false;
			}

			if (event instanceof ActivationEnd) {
				if (!_.isEmpty(event.snapshot.params)) {
					this.routeData = event.snapshot.params;
				}
			}
		});
	}

	getPreviousUrl() {
		return this.previousUrls[this.previousUrls.length - 1];
	}

	setPreviousUrlIfNull(url: string = null) {
		if (this.previousUrls.length === 0 || this.previousUrls[this.previousUrls.length - 1] === null || this.previousUrls[this.previousUrls.length - 1] === '') {
			this.previousUrls.push(url);
		}
	}

	goToPreviousUrl(): void {
		this.wentBack = true;
		if (this.previousUrls.length > 0) {
			this.router.navigate([this.previousUrls.pop()]).then(() => {
			});
		} else {
			this.router.navigate(['/']).then(() => {
			});
		}
	}

	goTo(url): void {
		this.router.navigate([url]).then(() => {
		});
	}
}
