import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {GeneralService} from '../../../_services/general.service';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {Reports} from '../../../_interfaces/reports/reports';
import {ReportsService} from '../../../_services/reports.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {ServerResponse} from '../../../_interfaces/server.response';
import {ApiService} from '../../../_interceptors/api.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {User} from '../../../_interfaces/user';

@Component({
	selector: 'app-reports-list',
	templateUrl: './reports-list.component.html',
	styleUrls: ['./reports-list.component.scss'],
	animations: [routeAnimation]
})

export class ReportsListComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	private totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	private isLoading: boolean = false;
	private keepLoading: boolean = false;

	public user: User = null;
	public report_type: string = null;
	public report_status: string = 'open';
	public reports: Reports[] = [];

	private _activatedRouteService: Subscription = null;
	private _authenticationService: Subscription = null;

	@Input() smallWidget: boolean = false;

	constructor(public reportsService: ReportsService,
				private apiService: ApiService,
				private previousUrlService: PreviousUrlService,
				private activatedRouteService: ActivatedRoute,
				private generalService: GeneralService,
				private authenticationService: AuthenticationService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && !this.reports.length) {
				this.currentPage = 0;
				this.getReports(false);
			}
		});

		this._activatedRouteService = this.activatedRouteService.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];
			this.report_status = params['report_status'];

			this._authenticationService = this.authenticationService.user.first().subscribe(user => {
				this.user = user;
				this.currentPage = 0;
				this.getReports(false);
			});
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteService !== null) {
			this._activatedRouteService.unsubscribe();
		}

		if (this._authenticationService !== null) {
			this._authenticationService.unsubscribe();
		}
	}

	getReports(append_data: boolean = true) {
		if (!this.isLoading || this.keepLoading) {
			this.isLoading = true;
			this.keepLoading = false;
			this.currentPage++;

			this.reportsService.getReports(this.report_type, this.report_status, {
				start: (this.currentPage - 1) * this.generalService.itemsPerPage,
				length: this.generalService.itemsPerPage,
				'columns[0][search][value]': this.searchString
			}).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.recordsTotal !== 'undefined') {
						this.totalItems = data.recordsTotal;
					}
					if (append_data !== true) {
						this.reports = [];
					}
					data.data.forEach(report => {
						if (report.edit_lock === true && (<any>report.edit_lock_user_id === '' ||
							report.edit_lock_user_id === null ||
							report.edit_lock_user_id === 0 ||
							report.edit_lock_user_id === this.user.id ||
							this.user.type === 'kvvm-admin' ||
							(this.user.type === 'kvvm-admin' || this.user.type === 'kvvm-manager' || this.user.type === 'kvvm-inspector') && report.status === 'closed')) {
							report.reportEditable = true;
						}
						this.reports.push(<Reports>report);
					});
				}
				this.isLoading = false;
			});
		}
	}

	deleteReport(report_type: string, report_id: number) {
		this.reportsService.deleteReport(report_type, report_id).then(status => {
			if (status === true) {
				let index = this.reports.findIndex(data => data.id === report_id);

				if (index !== -1) {
					this.reports.splice(index, 1);
				}
			}
		});
	}

	search(event) {
		this.searchString = event;
		this.currentPage = 0;
		this.getReports(false);
	}
}
