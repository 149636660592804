<form class="with-action-buttons" [formGroup]="clientsBuildingMapsCreateForm" (ngSubmit)="clientsService.uploadNewBuildingMap(client_id, clientsBuildingMapsCreateForm.value)">
	<div class="divider"><span>Plattegrond gegevens</span></div>
	<mat-form-field>
		<input title="" matInput placeholder="Naam gebouw" type="text" formControlName="building_name" required>
		<mat-error *ngIf="formsService.getFieldError('building_name')">{{formsService.getFieldError('building_name')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<ngx-mat-file-input formControlName="filename" placeholder="Plattengrond" #filenameInput required>
		</ngx-mat-file-input>
		<button mat-icon-button matSuffix *ngIf="!filenameInput.empty" (click)="filenameInput.clear($event)">
			<mat-icon>clear</mat-icon>
		</button>
		<mat-error *ngIf="formsService.getFieldError('filename')">{{formsService.getFieldError('filename')}}</mat-error>
	</mat-form-field>
	<div class="action-buttons">
		<button color="primary" mat-button [disabled]="!clientsBuildingMapsCreateForm.valid">OPSLAAN</button>
	</div>
</form>
