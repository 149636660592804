import {FormControl} from '@angular/forms';

export function SignatureForUserTypeValidator(userType: string) {

	let thisControl: FormControl;

	return function SignatureForUserTypeValidate(control: FormControl) {

		if (!control.parent) {
			return null;
		}

		if (!thisControl) {
			thisControl = control;
		}

		if (thisControl.value === '' && userType === 'kvvm-inspector') {
			return {
				missingSignature: 'Selecteer uw handtekening afbeelding'
			};
		}

		return null;
	};
}
