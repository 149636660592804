/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./removable-photo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-material-file-input";
import * as i3 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i4 from "@angular/material/list";
import * as i5 from "@angular/material/core";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "./removable-photo.component";
var styles_RemovablePhotoComponent = [i0.styles];
var RenderType_RemovablePhotoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RemovablePhotoComponent, data: { "animation": [{ type: 7, name: "routeAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "400ms 150ms ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_RemovablePhotoComponent as RenderType_RemovablePhotoComponent };
export function View_RemovablePhotoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ByteFormatPipe, [[2, i2.NGX_MAT_FILE_INPUT_CONFIG]]), (_l()(), i1.ɵeld(1, 0, null, null, 17, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i3.View_MatListItem_0, i3.RenderType_MatListItem)), i1.ɵdid(2, 1228800, null, 3, i4.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i4.MatNavList], [2, i4.MatList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(603979776, 2, { _avatar: 0 }), i1.ɵqud(603979776, 3, { _icon: 0 }), (_l()(), i1.ɵeld(6, 0, null, 2, 7, "div", [["class", "mat-list-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h4", [["class", "mat-line"], ["matLine", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, [[1, 4]], 0, i5.MatLine, [], null, null), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "a", [["class", "mat-line"], ["matLine", ""]], null, null, null, null, null)), i1.ɵdid(11, 16384, [[1, 4]], 0, i5.MatLine, [], null, null), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵppd(13, 1), (_l()(), i1.ɵeld(14, 0, null, 2, 4, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deletePhoto() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(16, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(17, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["delete"]))], function (_ck, _v) { _ck(_v, 17, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2)._avatar || i1.ɵnov(_v, 2)._icon); var currVal_1 = (i1.ɵnov(_v, 2)._avatar || i1.ɵnov(_v, 2)._icon); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.filename; _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v, 0), _co.size)); _ck(_v, 12, 0, currVal_3); var currVal_4 = (i1.ɵnov(_v, 15).disabled || null); var currVal_5 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵnov(_v, 17).inline; var currVal_7 = (((i1.ɵnov(_v, 17).color !== "primary") && (i1.ɵnov(_v, 17).color !== "accent")) && (i1.ɵnov(_v, 17).color !== "warn")); _ck(_v, 16, 0, currVal_6, currVal_7); }); }
export function View_RemovablePhotoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-removable-photo", [], null, null, null, View_RemovablePhotoComponent_0, RenderType_RemovablePhotoComponent)), i1.ɵdid(1, 4243456, null, 0, i12.RemovablePhotoComponent, [], null, null)], null, null); }
var RemovablePhotoComponentNgFactory = i1.ɵccf("app-removable-photo", i12.RemovablePhotoComponent, View_RemovablePhotoComponent_Host_0, { id: "id", shortcoming_id: "shortcoming_id", photo: "photo", filename: "filename", size: "size" }, { destroy: "destroy" }, []);
export { RemovablePhotoComponentNgFactory as RemovablePhotoComponentNgFactory };
