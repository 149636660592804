import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {GeneralService} from '../../../_services/general.service';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {ReportsService} from '../../../_services/reports.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {ClientsService} from '../../../_services/clients.service';
import {Client} from '../../../_interfaces/clients/clients';
import {ApiService} from '../../../_interceptors/api.service';
import {Reports} from '../../../_interfaces/reports/reports';
import {User} from '../../../_interfaces/user';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';
import {SnackbarService} from '../../../_services/snackbar.service';

@Component({
	selector: 'app-reports-view',
	templateUrl: './reports-view.component.html',
	styleUrls: ['./reports-view.component.scss'],
	animations: [routeAnimation]
})

export class ReportsViewComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_type: string = null;
	public report_id: number = null;
	public report: Reports = null;
	public client: Client = null;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];
	public reportEditable: boolean = false;
	public report_status: string = '/open';

	private _activatedRouteSubscription: Subscription = null;
	private _authenticationServiceSubscription: Subscription = null;

	constructor(private reportsService: ReportsService,
				public apiService: ApiService,
				private clientsService: ClientsService,
				private activatedRoute: ActivatedRoute,
				private generalService: GeneralService,
				private snackbarService: SnackbarService,
				private previousUrlService: PreviousUrlService,
				private authenticationService: AuthenticationService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length) {
				this.missingData = [];
				this.loadData();
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];
			this.report_id = parseInt(params['report_id'], 10);

			this.reportsService.getReport(this.report_type, this.report_id).then((report: Reports) => {
				this._authenticationServiceSubscription = this.authenticationService.user.first().subscribe((user: User) => {
					if (typeof report !== 'undefined' && typeof user !== 'undefined') {

						this.pageTitleService.setTitle(report.report_nr + ' | Bekijk rapportage | ' + environment.webappName);
						this.pageTitleService.setPageTitle(report.report_nr);

						if (typeof report.edit_lock !== 'undefined' && typeof report.edit_lock_user_id !== 'undefined') {
							if (report.edit_lock === true && (<any>report.edit_lock_user_id === '' ||
								report.edit_lock_user_id === null ||
								report.edit_lock_user_id === 0 ||
								report.edit_lock_user_id === user.id ||
								user.type === 'kvvm-admin')) {
								this.reportEditable = true;
							}
						}
					}
				});
			});

			this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_type + '/open');

			this.loadData();
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._authenticationServiceSubscription !== null) {
			this._authenticationServiceSubscription.unsubscribe();
		}
	}

	loadData() {
		this.reportsService.getReport(this.report_type, this.report_id).then((data: Reports) => {
			if (typeof data !== 'undefined') {
				this.report = data;

				if (typeof this.report.client_id !== 'undefined') {
					this.clientsService.getClient(this.report.client_id).then((client: Client) => {
						if (typeof client !== 'undefined') {
							this.client = client;
						} else {
							this.missingData.push('Klant');
						}
					});
				}

				this._authenticationServiceSubscription = this.authenticationService.user.first().subscribe(user => {
					if (this.report.edit_lock === true && (<any>this.report.edit_lock_user_id === '' ||
						this.report.edit_lock_user_id === null ||
						this.report.edit_lock_user_id === 0 ||
						this.report.edit_lock_user_id === parseInt('' + user.id, 10) ||
						user.type === 'kvvm-admin')) {
						this.reportEditable = true;
					}
				});

				this.dataLoaded = true;
			} else {
				this.missingData.push('Rapportage');
			}
		});
	}

	downloadReport(report_type: string, report_id: number, file_type: string) {
		if (this.apiService.isOnline) {
			this.apiService.download(environment.api_endpoint + 'reports/' + report_type + '/' + report_id + '/download/' + file_type);
		} else {
			this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
		}
	}
}
