<img src="/assets/images/logo.png" alt="KVVM" width="250" height="83">
<form [formGroup]="otpForm" (ngSubmit)="authenticationService.loginTwoFactorRecoveryCode(otpForm.value)">
	<input type="hidden" name="sessionID" value="{{ sessionId }}">
	<p>Voer de herstelcodes die je hebt ontvangen.</p>
	<mat-form-field>
		<input matInput placeholder="Recovery Code" type="text" required formControlName="code">
		<mat-error *ngIf="formService.getFieldError('code')">{{ formService.getFieldError('code') }}</mat-error>
	</mat-form-field>
	<button color="primary" mat-raised-button [disabled]="!otpForm.valid">BEVESTIGEN</button>
</form>
<a [routerLink]="['/login-two-factor-authentication']">Inloggen met twee-factor code</a>
