<div *ngIf="supported">
	<mat-dialog-content>
		<div class="start">
			<p>Spreek de nummers één voor één uit. Na het zeggen van de nummers wacht enkele seconden en de invoer wordt hieronder weergegeven.</p>
			<img src="/assets/images/sound.gif" alt="sound">
		</div>
		<div class="results">
			<ul class="results">
				<li *ngFor="let item of textItems">
					<p class="result" (click)="item.stop(item.text)">{{ item.text }}</p>
				</li>
			</ul>
			<p>Klik op de invoer hierboven om het toe te voegen of probeer het opnieuw.</p>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-button color="primary-outline" (click)="reset()">OPNIEUW</button>
		<button mat-button color="accent" (click)="stop()">ANNULEREN</button>
	</mat-dialog-actions>
</div>
<div *ngIf="!supported">
	<mat-dialog-content>
		<p><strong>Sorry, this functionality is not available on your device.</strong></p>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-button color="primary" mat-dialog-close="true">ANNULEREN</button>
	</mat-dialog-actions>
</div>
