import {Component, HostBinding, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';

import {AuthenticationService} from '../../../_services/authentication.service';
import {User} from '../../../_interfaces/user';
import {LocalStorageService} from '../../../_services/local-storage.service';

@Component({
	selector: 'app-two-factor-authentication',
	templateUrl: './two-factor-authentication.component.html',
	styleUrls: ['./two-factor-authentication.component.scss'],
	animations: [routeAnimation]
})

export class TwoFactorAuthenticationComponent implements OnInit {
	@HostBinding('@routeAnimation') routerTransition = true;

	public status: boolean = null;
	public tfa_required: boolean = null;
	public recoveryCodes: string|void = null;

	constructor(public authenticationService: AuthenticationService,
				private localStorageService: LocalStorageService) {
	}

	ngOnInit(): void {
		let currentUser: User = this.localStorageService.get('currentUser');

		if (typeof currentUser !== 'undefined' && currentUser !== null) {
			if (typeof currentUser.tfa_enabled !== 'undefined') {
				this.status = currentUser.tfa_enabled;
			}
		}

		this.authenticationService.getTwoFactorAuthStatus().then(response => {
			this.status = response.status;
			this.tfa_required = response.tfa_required && !response.status;

			if (response.status === true) {
				let recoveryCodes = this.localStorageService.get('tfa-recovery-codes');
				if (recoveryCodes !== null) {
					this.recoveryCodes = recoveryCodes.join('<br>');
					this.localStorageService.delete('tfa-recovery-codes');
				}
			}
		});
	}
}
