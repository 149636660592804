<ng-container *ngIf="dataLoaded && !missingData.length">
	<div class="divider"><span>Gegevens conatctpersoon</span></div>
	<dl>
		<dt>Voornaam</dt>
		<dd>{{ contactPerson.firstname }}</dd>
		<dt>Achternaam</dt>
		<dd>{{ contactPerson.lastname }}</dd>
	</dl>
	<div class="divider"><span>Contactgegevens</span></div>
	<dl>
		<dt>Telefoon</dt>
		<dd>
			<a *ngIf="contactPerson.telephone" href="tel:{{ contactPerson.telephone }}">{{ contactPerson.telephone }}</a>
			<span *ngIf="!contactPerson.telephone">-</span>
		</dd>
		<dt>Mobile</dt>
		<dd>
			<a *ngIf="contactPerson.mobile" href="tel:{{ contactPerson.mobile }}">{{ contactPerson.mobile }}</a>
			<span *ngIf="!contactPerson.mobile">-</span>
		</dd>
		<dt>E-Mail</dt>
		<dd>
			<a *ngIf="contactPerson.email" href="mailto:{{ contactPerson.email }}">{{ contactPerson.email }}</a>
			<span *ngIf="!contactPerson.email">-</span>
		</dd>
	</dl>
	<button mat-button color="primary-outline" [routerLink]="['/clients/' + contactPerson.client_id + '/contacts-persons/' + contactPerson.id + '/edit']">Aanpassen</button>
	<button mat-button color="accent-outline" (click)="deleteContactPerson(contactPerson.client_id, contactPerson.id)">Verwijderen</button>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
