<mat-expansion-panel>
	<mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
		<mat-panel-title>
			<div class="divider"><span>Klantgegevens</span></div>
		</mat-panel-title>
	</mat-expansion-panel-header>
	<dl *ngIf="client">
		<dt>Bedrijfsnaam</dt>
		<dd>{{ client.company_name }}</dd>
		<dt>Locatie</dt>
		<dd>{{ client.address }}<br>{{ client.postalcode }} {{ client.city }}<br>{{ client.country_name }}</dd>
		<dt>Contacten</dt>
		<dd>
			<mat-icon>phone</mat-icon>
			<a *ngIf="client.telephone" href="tel:{{ client.telephone }}">{{ client.telephone }}</a>
			<span *ngIf="!client.telephone">-</span> <br>
			<mat-icon>email</mat-icon>
			<a *ngIf="client.email" href="mailto:{{ client.email }}">{{ client.email }}</a>
			<span *ngIf="!client.email">-</span>
		</dd>
		<dt>Taal</dt>
		<dd>{{ client.language_name }}</dd>
	</dl>
	<ng-container *ngIf="client && showMap">
		<agm-map *ngIf="mapCanBeShown" [latitude]="client.lat" [longitude]="client.lng" [zoom]="13">
			<agm-marker [latitude]="client.lat" [longitude]="client.lng"></agm-marker>
		</agm-map>
		<div *ngIf="!mapCanBeShown" class="alert alert-danger" fxFlex fxLayout="row">
			<mat-icon>warning</mat-icon>
			<h2>Huidige adres kan niet worden weergegeven op de kaart</h2>
		</div>
	</ng-container>
	<p *ngIf="!client">Klant niet gevonden</p>
</mat-expansion-panel>
