<div class="planning-container">
	<div class="planning-left">
		<form [formGroup]="planningForm" (ngSubmit)="getPlanning()">
			<mat-form-field>
				<input type="hidden" formControlName="planning_date"> <input title="" matInput placeholder="Selecteer datum" formControlName="planning_date_input" [matDatepicker]="planningDate" [matDatepickerFilter]="weekendsDatesFilter" required readonly (click)="planningDate.open()" (dateChange)="planningDateChange($event)">
				<mat-datepicker #planningDate></mat-datepicker>
			</mat-form-field>
		</form>
	</div>
	<div class="planning-right">
		<div>
			<button type="button" mat-button color="primary" (click)="previousDay()"><mat-icon>chevron_left</mat-icon></button>
			<button type="button" mat-button color="primary" (click)="nextDay()"><mat-icon>chevron_right</mat-icon></button>
		</div>
	</div>
</div>
<mat-nav-list class="mat-nav-list-with-border">
	<mat-list-item *ngFor="let plan of planning">
		<div class="mat-list-content">
			<h4 matLine>{{ plan.company_name }}</h4>
			<div class="planning-box">
				<div class="left">
					<span>{{ plan.start | datex:'HH:mm' }} - {{ plan.end | datex:'HH:mm' }}</span> <span class="color" [style.background]="plan.color"></span>
				</div>
				<div class="right">
					<span>{{ plan.recurring }}</span> <span class="second">{{ plan.type }}</span>
				</div>
			</div>
		</div>
	</mat-list-item>
</mat-nav-list>
