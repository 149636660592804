import {Injectable} from '@angular/core';
import {SidenavItem} from './sidenav-item/sidenav-item.model';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import * as _ from 'lodash';
import {AuthenticationService} from '../../_services/authentication.service';

@Injectable()

export class SidenavService {
	private _itemsSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
	private _items: SidenavItem[] = [];
	items$: Observable<SidenavItem[]> = this._itemsSubject.asObservable();
	private _currentlyOpenSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
	private _currentlyOpen: SidenavItem[] = [];

	private _bottomItemsSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
	private _itemsBottom: SidenavItem[] = [];
	bottomItems$: Observable<SidenavItem[]> = this._bottomItemsSubject.asObservable();

	constructor(private authenticationService: AuthenticationService) {
		const menu = this;
		let menu_populated = false;

		let _authenticationService = this.authenticationService.user.first().subscribe(user => {
			this._items = [];
			if (user) {
				if (menu_populated === false) {
					menu_populated = true;

					menu.addItem('Overzicht', 'dashboard', '/', 10);
					// menu.addDivider(11);

					const warehouse = menu.addItem('Magazijn', 'assignment', null, 20);
					menu.addSubItem(warehouse, 'Openstaand', '/reports/warehouse/open', 1);
					menu.addSubItem(warehouse, 'Controle', '/reports/warehouse/verify', 2);
					menu.addSubItem(warehouse, 'Gesloten', '/reports/warehouse/closed', 3);

					const ladders = menu.addItem('Trappen', 'assignment', null, 30);
					menu.addSubItem(ladders, 'Openstaand', '/reports/ladders/open', 1);
					menu.addSubItem(ladders, 'Controle', '/reports/ladders/verify', 2);
					menu.addSubItem(ladders, 'Gesloten', '/reports/ladders/closed', 3);

					const tents = menu.addItem('Tenten', 'assignment', null, 35);
					menu.addSubItem(tents, 'Openstaand', '/reports/tent/open', 1);
					menu.addSubItem(tents, 'Controle', '/reports/tent/verify', 2);
					menu.addSubItem(tents, 'Gesloten', '/reports/tent/closed', 3);

					menu.addItem('Herinspectie nodig', 'assignment', '/reports/reinspection-required', 40);

					// menu.addDivider(31);

					menu.addItem('Klanten', 'people', '/clients', 50);
					menu.addItem('Inspecties', 'list', '/inspectors/inspections', 60);
					menu.addItem('Inventaris', 'description', '/inspectors/inventories', 70);
					menu.addItem('Kalibratie', 'center_focus_weak', '/inspectors/calibrations', 80);
					if (user.type === 'kvvm-inspector') {
						menu.addItem('Planning', 'calendar_today', '/planning', 90);
					}
				}
			} else {
				this._items = [];
				menu_populated = false;
			}
		});
		_authenticationService.unsubscribe();
	}

	addItem(name: string, icon: string, route: any, position: number, badge?: string | number, badgeColor?: string, customClass?: string) {
		const item = new SidenavItem({
			name: name,
			icon: icon,
			route: route,
			subItems: [],
			position: position || 99,
			badge: badge || null,
			badgeColor: badgeColor || null,
			customClass: customClass || null
		});

		this._items.push(item);
		this._itemsSubject.next(this._items);

		return item;
	}

	addBottomItem(name: string, icon: string, route: any, position: number, badge?: string | number, badgeColor?: string, customClass?: string) {
		const item = new SidenavItem({
			name: name,
			icon: icon,
			route: route,
			subItems: [],
			position: position || 99,
			badge: badge || null,
			badgeColor: badgeColor || null,
			customClass: customClass || null
		});

		this._itemsBottom.push(item);
		this._bottomItemsSubject.next(this._itemsBottom);

		return item;
	}

	addSubItem(parent: SidenavItem, name: string, route: any, position: number, badge?: string | number, badgeColor?: string) {
		const item = new SidenavItem({
			name: name,
			route: route,
			parent: parent,
			subItems: [],
			position: position || 99,
			badge: badge || null,
			badgeColor: badgeColor || null,
		});

		parent.subItems.push(item);
		this._itemsSubject.next(this._items);

		return item;
	}

	// noinspection JSUnusedGlobalSymbols
	addDivider(position: number, customClass?: string) {
		const item = new SidenavItem({
			name: 'divider',
			position: position,
			divider: true,
			customClass: customClass || null
		});

		this._items.push(item);
		this._itemsSubject.next(this._items);

		return item;
	}

	isOpen(item: SidenavItem) {
		return (this._currentlyOpen.indexOf(item) !== -1);
	}

	toggleCurrentlyOpen(item: SidenavItem) {
		let currentlyOpen = this._currentlyOpen;

		if (this.isOpen(item)) {
			if (currentlyOpen.length > 1) {
				currentlyOpen.length = this._currentlyOpen.indexOf(item);
			} else {
				currentlyOpen = [];
			}
		} else {
			currentlyOpen = this.getAllParents(item);
		}

		this._currentlyOpen = currentlyOpen;
		this._currentlyOpenSubject.next(currentlyOpen);
	}

	getAllParents(item: SidenavItem, currentlyOpen: SidenavItem[] = []) {
		currentlyOpen.unshift(item);

		if (item.hasParent()) {
			return this.getAllParents(item.parent, currentlyOpen);
		} else {
			return currentlyOpen;
		}
	}

	nextCurrentlyOpen(currentlyOpen: SidenavItem[]) {
		this._currentlyOpen = currentlyOpen;
		this._currentlyOpenSubject.next(currentlyOpen);
	}

	nextCurrentlyOpenByRoute(route: string) {
		let currentlyOpen = [];

		const item = this.findByRouteRecursive(route, this._items);

		if (item && item.hasParent()) {
			currentlyOpen = this.getAllParents(item);
		} else if (item) {
			currentlyOpen = [item];
		}

		this.nextCurrentlyOpen(currentlyOpen);
	}

	findByRouteRecursive(route: string, collection: SidenavItem[]) {
		let result = _.find(collection, {'route': route});

		if (!result) {
			_.each(collection, (item) => {
				if (item.hasSubItems()) {
					const found = this.findByRouteRecursive(route, item.subItems);

					if (found) {
						result = found;
						return false;
					}
				}
			});
		}

		return result;
	}
}
