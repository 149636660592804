import { Routes } from '@angular/router';
import { AdminComponent } from './core/admin/admin.component';
import { LoginComponent } from './core/login/login/login.component';
import { ForgotPasswordComponent } from './core/login/forgot-password/forgot-password.component';
import { AuthGuard } from './_services/authguard.service';
import { ChangePasswordComponent } from './core/security/change-password/change-password.component';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { PageForbiddenComponent } from './core/page-forbidden/page-forbidden.component';
import { DashboardComponent } from './webapp/dashboard/dashboard.component';
import { ClientsListComponent } from './webapp/clients/clients-list/clients-list.component';
import { ClientsRouterComponent } from './webapp/clients/clients-router/clients-router.component';
import { ClientsCreateComponent } from './webapp/clients/clients-create/clients-create.component';
import { ClientsEditComponent } from './webapp/clients/clients-edit/clients-edit.component';
import { ClientsViewComponent } from './webapp/clients/clients-view/clients-view.component';
import { ClientsContactsPersonsViewComponent } from './webapp/clients/clients-contacts-persons/clients-contacts-persons-view/clients-contacts-persons-view.component';
import { ClientsContactPersonsListComponent } from './webapp/clients/clients-contacts-persons/clients-contacts-persons-list/clients-contacts-persons-list.component';
import { ClientsContactPersonsCreateComponent } from './webapp/clients/clients-contacts-persons/clients-contacts-persons-create/clients-contacts-persons-create.component';
import { ClientsContactPersonsEditComponent } from './webapp/clients/clients-contacts-persons/clients-contacts-persons-edit/clients-contacts-persons-edit.component';
import { ClientsAttachmentsListComponent } from './webapp/clients/clients-attachments/clients-attachments-list/clients-attachments-list.component';
import { ClientsAttachmentsCreateComponent } from './webapp/clients/clients-attachments/clients-attachments-create/clients-attachments-create.component';
import { ClientsAttachmentsEditComponent } from './webapp/clients/clients-attachments/clients-attachments-edit/clients-attachments-edit.component';
import { ClientChangeNextInspectionDateComponent } from './webapp/clients/clients-change-next-inspection-date/clients-change-next-inspection-date.component';
import { ClientsNextInspectionNotificationComponent } from './webapp/clients/clients-next-inspection-notification/clients-next-inspection-notification.component';
import { InspectorsRouterComponent } from './webapp/inspectors/inspectors-router/inspectors-router.component';
import { InspectorsCalibrationsListComponent } from './webapp/inspectors/calibrations/calibrations-list/calibrations-list.component';
import { InspectorsCalibrationsCreateComponent } from './webapp/inspectors/calibrations/calibrations-create/calibrations-create.component';
import { InspectorsCalibrationsEditComponent } from './webapp/inspectors/calibrations/calibrations-edit/calibrations-edit.component';
import { InspectorsCalibrationsViewComponent } from './webapp/inspectors/calibrations/calibrations-view/calibrations-view.component';
import { InspectorsInventoriesListComponent } from './webapp/inspectors/inventories/inventories-list/inventories-list.component';
import { InspectorsInventoriesSendToClientComponent } from './webapp/inspectors/inventories/inventories-send-to-client/inventories-send-to-client.component';
import { InspectorsInventoriesCreateComponent } from './webapp/inspectors/inventories/inventories-create/inventories-create.component';
import { InspectorsInspectionsListComponent } from './webapp/inspectors/inspections/inspections-list/inspections-list.component';
import { ReportsRouterComponent } from './webapp/reports/reports-router/reports-router.component';
import { ReportsListComponent } from './webapp/reports/reports-list/reports-list.component';
import { ReportsCreateComponent } from './webapp/reports/reports-create/reports-create.component';
import { ReportsEditComponent } from './webapp/reports/reports-edit/reports-edit.component';
import { ReportsAttachmentsCreateComponent } from './webapp/reports/reports-attachments/reports-attachments-create/reports-attachments-create.component';
import { ReportsAttachmentsListComponent } from './webapp/reports/reports-attachments/reports-attachments-list/reports-attachments-list.component';
import { ReportsBuildingsListComponent } from './webapp/reports/reports-buildings/reports-buildings-list/reports-buildings-list.component';
import { ReportsBuildingsCreateComponent } from './webapp/reports/reports-buildings/reports-buildings-create/reports-buildings-create.component';
import { ReportsBuildingsEditComponent } from './webapp/reports/reports-buildings/reports-buildings-edit/reports-buildings-edit.component';
import { ReportsBuildingsReportsListComponent } from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-list/reports-buildings-reports-list.component';
import { ReportsBuildingsReportsCreateComponent } from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-create/reports-buildings-reports-create.component';
import { ReportsBuildingsReportsEditComponent } from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-edit/reports-buildings-reports-edit.component';
import { ReportsCommentsCreateComponent } from './webapp/reports/reports-comments/reports-comments-create/reports-comments-create.component';
import { ReportsCommentsEditComponent } from './webapp/reports/reports-comments/reports-comments-edit/reports-comments-edit.component';
import { ReportsCommentsListComponent } from './webapp/reports/reports-comments/reports-comments-list/reports-comments-list.component';
import { ReportsViewComponent } from './webapp/reports/reports-view/reports-view.component';
import { ReportsQuestionnaireComponent } from './webapp/reports/reports-questionnaire/reports-questionnaire.component';
import { ReportsSendToClientComponent } from './webapp/reports/reports-send-to-client/reports-send-to-client.component';
import { ClientsReportsListComponent } from './webapp/clients/clients-reports-list/clients-reports-list.component';
import { ReportsClosedListComponent } from './webapp/reports/reports-list/reports-closed-list.component';
import { ReportsClosedAttachmentsListComponent } from './webapp/reports/reports-attachments/reports-attachments-list/reports-closed-attachments-list.component';
import { ReportsClosedViewComponent } from './webapp/reports/reports-view/reports-closed-view.component';
import { ReportsClosedBuildingsListComponent } from './webapp/reports/reports-buildings/reports-buildings-list/reports-closed-buildings-list.component';
import { ReportsClosedBuildingsReportsListComponent } from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-list/reports-closed-buildings-reports-list.component';
import { ReportsClosedCommentsListComponent } from './webapp/reports/reports-comments/reports-comments-list/reports-closed-comments-list.component';
import { ReportsClosedBuildingsReportsViewComponent } from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-view/reports-closed-buildings-reports-view.component';
import { ReportsClosedQuestionnaireComponent } from './webapp/reports/reports-questionnaire/reports-closed-questionnaire.component';
import { ReportsReinspectionRequiredListComponent } from './webapp/reports/reports-reinspection-required-list/reports-reinspection-required-list.component';
import { PlanningRouterComponent } from './webapp/planning/planning-router/planning-router.component';
import { PlanningListComponent } from './webapp/planning/planning-list/planning-list.component';
import { PlanningViewComponent } from './webapp/planning/planning-view/planning-view.component';
import { TwoFactorAuthenticationComponent } from './core/security/two-factor-authentication/two-factor-authentication.component';
import { TwoFactorAuthenticationEnableComponent } from './core/security/two-factor-authentication-enable/two-factor-authentication-enable.component';
import { TwoFactorAuthenticationDisableComponent } from './core/security/two-factor-authentication-disable/two-factor-authentication-disable.component';
import { TwoFactorAuthenticationRegenerateRecoveryCodesComponent } from './core/security/two-factor-authentication-regenerate-recovery-codes/two-factor-authentication-regenerate-recovery-codes.component';
import { LoginTwoFactorAuthenticationComponent } from './core/login/login-two-factor-authentication/login-two-factor-authentication.component';
import { MyAccountRouterComponent } from './core/security/my-account-router/my-account-router.component';
import { MyAccountComponent } from './core/security/my-account/my-account.component';
import { LoginWithRecoveryCodeComponent } from './core/login/login-with-recovery-code/login-with-recovery-code.component';
import { RecoverLostPasswordComponent } from './core/login/recover-lost-password/recover-lost-password.component';
import { ClientsBuildingMapsListComponent } from './webapp/clients/clients-building-maps/clients-building-maps-list/clients-building-maps-list.component';
import { ClientsBuildingMapsCreateComponent } from './webapp/clients/clients-building-maps/clients-building-maps-create/clients-building-maps-create.component';
import { ClientsBuildingMapsEditComponent } from './webapp/clients/clients-building-maps/clients-building-maps-edit/clients-building-maps-edit.component';
import { ClientsBuildingMapsViewComponent } from './webapp/clients/clients-building-maps/clients-building-maps-view/clients-building-maps-view.component';
const ɵ0 = {
    title: 'Login | ' + environment.webappName
}, ɵ1 = {
    title: 'Twee-factor-authenticatie login | ' + environment.webappName
}, ɵ2 = {
    title: 'Twee-factor herstelcode login | ' + environment.webappName
}, ɵ3 = {
    title: 'Watchwoord vergeten | ' + environment.webappName
}, ɵ4 = {
    title: 'Watchwoord vergeten | ' + environment.webappName
}, ɵ5 = {
    title: environment.webappName
}, ɵ6 = {
    title: 'Dashboard | ' + environment.webappName
}, ɵ7 = {
    title: 'Mijn profiel aanpassen | ' + environment.webappName
}, ɵ8 = {
    title: 'Mijn profiel aanpassen | ' + environment.webappName
}, ɵ9 = {
    title: 'Twee-factor-authenticatie | ' + environment.webappName
}, ɵ10 = {
    title: 'Twee-factor-authenticatie inschakelen | ' + environment.webappName
}, ɵ11 = {
    title: 'Twee-factor-authenticatie uitschakelen | ' + environment.webappName
}, ɵ12 = {
    title: 'Herstelcodes opnieuw genereren | ' + environment.webappName
}, ɵ13 = {
    title: 'Wachtwoord aanpassen | ' + environment.webappName
}, ɵ14 = {
    title: 'Klanten | ' + environment.webappName
}, ɵ15 = {
    title: 'Klant toevoegen | ' + environment.webappName
}, ɵ16 = {
    title: 'Klant aanpassen | ' + environment.webappName
}, ɵ17 = {
    title: 'Bekijk klant | ' + environment.webappName
}, ɵ18 = {
    title: 'Bekijk klant rapportages | ' + environment.webappName
}, ɵ19 = {
    title: 'Klant contactpersonen | ' + environment.webappName
}, ɵ20 = {
    title: 'Klant contactperson toevoegen | ' + environment.webappName
}, ɵ21 = {
    title: 'Klant contactperson aanpassen | ' + environment.webappName
}, ɵ22 = {
    title: 'Klant contactperson bekijk | ' + environment.webappName
}, ɵ23 = {
    title: 'Klant bijlagen | ' + environment.webappName
}, ɵ24 = {
    title: 'Klant bijlage toevoegen | ' + environment.webappName
}, ɵ25 = {
    title: 'Klant bijlage aanpassen | ' + environment.webappName
}, ɵ26 = {
    title: 'Verstuur volgende inspectie notificatie | ' + environment.webappName
}, ɵ27 = {
    title: 'Aanpassen aankomende inspectie datum | ' + environment.webappName
}, ɵ28 = {
    title: 'Klant plattegrond | ' + environment.webappName
}, ɵ29 = {
    title: 'Klant plattegrond toevoegen | ' + environment.webappName
}, ɵ30 = {
    title: 'Klant plattegrond aanpassen | ' + environment.webappName
}, ɵ31 = {
    title: 'Klant plattegrond bekijk | ' + environment.webappName
}, ɵ32 = {
    title: 'Kalibratie | ' + environment.webappName
}, ɵ33 = {
    title: 'Nieuwe kalibratie toevoegen | ' + environment.webappName
}, ɵ34 = {
    title: 'Aanpassen kalibratie | ' + environment.webappName
}, ɵ35 = {
    title: 'Kalibratie gegevens | ' + environment.webappName
}, ɵ36 = {
    title: 'Inventaris | ' + environment.webappName
}, ɵ37 = {
    title: 'Inventaris toevoegen | ' + environment.webappName
}, ɵ38 = {
    title: 'Verstuur inventaris naar klant | ' + environment.webappName
}, ɵ39 = {
    title: 'Inspecties | ' + environment.webappName
}, ɵ40 = {
    title: 'Herinspectie nodig | ' + environment.webappName
}, ɵ41 = {
    title: 'Rapportages | ' + environment.webappName
}, ɵ42 = {
    title: 'Nieuwe rapportage toevoegen | ' + environment.webappName
}, ɵ43 = {
    title: 'Nieuwe rapportage toevoegen | ' + environment.webappName
}, ɵ44 = {
    title: 'Rapportages | ' + environment.webappName
}, ɵ45 = {
    title: 'Bekijk rapportage | ' + environment.webappName
}, ɵ46 = {
    title: 'Rapportage bijlagen | ' + environment.webappName
}, ɵ47 = {
    title: 'Rapportage opmerking | ' + environment.webappName
}, ɵ48 = {
    title: 'Rapportage inspectieschema | ' + environment.webappName
}, ɵ49 = {
    title: 'Rapportage gebouwen | ' + environment.webappName
}, ɵ50 = {
    title: 'Gebouw rapportages | ' + environment.webappName
}, ɵ51 = {
    title: 'Gebouw rapportages | ' + environment.webappName
}, ɵ52 = {
    title: 'Bekijk gebouw rapportage | ' + environment.webappName
}, ɵ53 = {
    title: 'Rapportages | ' + environment.webappName
}, ɵ54 = {
    title: 'Aanpassen Rapportage | ' + environment.webappName
}, ɵ55 = {
    title: 'Bekijk rapportage | ' + environment.webappName
}, ɵ56 = {
    title: 'Rapportage bijlagen | ' + environment.webappName
}, ɵ57 = {
    title: 'Rapportage bijlage toevoegen | ' + environment.webappName
}, ɵ58 = {
    title: 'Rapportage gebouwen | ' + environment.webappName
}, ɵ59 = {
    title: 'Nieuw rapportage gebouw | ' + environment.webappName
}, ɵ60 = {
    title: 'Aanpassen rapportage gebouw | ' + environment.webappName
}, ɵ61 = {
    title: 'Gebouw rapportages | ' + environment.webappName
}, ɵ62 = {
    title: 'Nieuw gebouw rapportage | ' + environment.webappName
}, ɵ63 = {
    title: 'Aanpassen gebouw rapportage | ' + environment.webappName
}, ɵ64 = {
    title: 'Rapportage opmerking | ' + environment.webappName
}, ɵ65 = {
    title: 'Rapportage opmerking toevoegen | ' + environment.webappName
}, ɵ66 = {
    title: 'Aanpassen rapportage opmerking | ' + environment.webappName
}, ɵ67 = {
    title: 'Rapportage inspectieschema | ' + environment.webappName
}, ɵ68 = {
    title: 'Verstuur rapportage naar klant | ' + environment.webappName
}, ɵ69 = {
    title: 'Mijn planning | ' + environment.webappName
}, ɵ70 = {
    title: 'Bekijk planning | ' + environment.webappName
}, ɵ71 = {
    title: 'Forbidden | ' + environment.webappName
}, ɵ72 = {
    title: 'Not found | ' + environment.webappName
};
const routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: ɵ0
    },
    {
        path: 'two-factor-authentication',
        component: LoginTwoFactorAuthenticationComponent,
        data: ɵ1
    },
    {
        path: 'login-with-recovery-code',
        component: LoginWithRecoveryCodeComponent,
        data: ɵ2
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: ɵ3
    },
    {
        path: 'recover-lost-password/:recovery_token',
        component: RecoverLostPasswordComponent,
        data: ɵ4
    },
    {
        path: '',
        component: AdminComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: ɵ5,
        children: [
            {
                path: '',
                component: DashboardComponent,
                data: ɵ6
            },
            {
                path: 'my-account',
                component: MyAccountRouterComponent,
                data: ɵ7,
                children: [
                    {
                        path: '',
                        component: MyAccountComponent,
                        data: ɵ8
                    },
                    {
                        path: 'two-factor-authentication',
                        component: TwoFactorAuthenticationComponent,
                        data: ɵ9
                    },
                    {
                        path: 'two-factor-authentication/enable',
                        component: TwoFactorAuthenticationEnableComponent,
                        data: ɵ10
                    },
                    {
                        path: 'two-factor-authentication/disable',
                        component: TwoFactorAuthenticationDisableComponent,
                        data: ɵ11
                    },
                    {
                        path: 'two-factor-authentication/regenerate-recovery-codes',
                        component: TwoFactorAuthenticationRegenerateRecoveryCodesComponent,
                        data: ɵ12
                    }
                ]
            },
            {
                path: 'change-password',
                component: ChangePasswordComponent,
                data: ɵ13
            },
            {
                path: 'clients',
                component: ClientsRouterComponent,
                children: [
                    {
                        path: '',
                        component: ClientsListComponent,
                        data: ɵ14
                    },
                    {
                        path: 'create',
                        component: ClientsCreateComponent,
                        data: ɵ15
                    },
                    {
                        path: ':client_id/edit',
                        component: ClientsEditComponent,
                        data: ɵ16
                    },
                    {
                        path: ':client_id/view',
                        component: ClientsViewComponent,
                        data: ɵ17
                    },
                    {
                        path: ':client_id/reports',
                        component: ClientsReportsListComponent,
                        data: ɵ18
                    },
                    {
                        path: ':client_id/contacts-persons',
                        component: ClientsContactPersonsListComponent,
                        data: ɵ19
                    },
                    {
                        path: ':client_id/contacts-persons/create',
                        component: ClientsContactPersonsCreateComponent,
                        data: ɵ20
                    },
                    {
                        path: ':client_id/contacts-persons/:contact_person_id/edit',
                        component: ClientsContactPersonsEditComponent,
                        data: ɵ21
                    },
                    {
                        path: ':client_id/contacts-persons/:contact_person_id/view',
                        component: ClientsContactsPersonsViewComponent,
                        data: ɵ22
                    },
                    {
                        path: ':client_id/attachments',
                        component: ClientsAttachmentsListComponent,
                        data: ɵ23
                    },
                    {
                        path: ':client_id/attachments/create',
                        component: ClientsAttachmentsCreateComponent,
                        data: ɵ24
                    },
                    {
                        path: ':client_id/attachments/:attachment_id/edit',
                        component: ClientsAttachmentsEditComponent,
                        data: ɵ25
                    },
                    {
                        path: ':client_id/next-inspection-notification',
                        component: ClientsNextInspectionNotificationComponent,
                        data: ɵ26
                    },
                    {
                        path: ':client_id/change-next-inspection-date',
                        component: ClientChangeNextInspectionDateComponent,
                        data: ɵ27
                    },
                    {
                        path: ':client_id/buildings-maps',
                        component: ClientsBuildingMapsListComponent,
                        data: ɵ28
                    },
                    {
                        path: ':client_id/buildings-maps/create',
                        component: ClientsBuildingMapsCreateComponent,
                        data: ɵ29
                    },
                    {
                        path: ':client_id/buildings-maps/:building_map_id/edit',
                        component: ClientsBuildingMapsEditComponent,
                        data: ɵ30
                    },
                    {
                        path: ':client_id/buildings-maps/:building_map_id/view',
                        component: ClientsBuildingMapsViewComponent,
                        data: ɵ31
                    },
                ]
            },
            {
                path: 'inspectors',
                component: InspectorsRouterComponent,
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'calibrations'
                    },
                    {
                        path: 'calibrations',
                        component: InspectorsCalibrationsListComponent,
                        data: ɵ32
                    },
                    {
                        path: 'calibrations/create',
                        component: InspectorsCalibrationsCreateComponent,
                        data: ɵ33
                    },
                    {
                        path: 'calibrations/:calibration_id/edit',
                        component: InspectorsCalibrationsEditComponent,
                        data: ɵ34
                    },
                    {
                        path: 'calibrations/:calibration_id/view',
                        component: InspectorsCalibrationsViewComponent,
                        data: ɵ35
                    },
                    {
                        path: 'inventories',
                        component: InspectorsInventoriesListComponent,
                        data: ɵ36
                    },
                    {
                        path: 'inventories/create',
                        component: InspectorsInventoriesCreateComponent,
                        data: ɵ37
                    },
                    {
                        path: 'inventories/:inventory_id/send-to-client',
                        component: InspectorsInventoriesSendToClientComponent,
                        data: ɵ38
                    },
                    {
                        path: 'inspections',
                        component: InspectorsInspectionsListComponent,
                        data: ɵ39
                    }
                ]
            },
            {
                path: 'reports',
                component: ReportsRouterComponent,
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'magazijn/open'
                    },
                    {
                        path: 'reinspection-required',
                        component: ReportsReinspectionRequiredListComponent,
                        data: ɵ40
                    },
                    {
                        path: ':report_type',
                        component: ReportsListComponent,
                        data: ɵ41
                    },
                    {
                        path: ':report_type/create',
                        component: ReportsCreateComponent,
                        data: ɵ42
                    },
                    {
                        path: ':report_type/create/:client_id',
                        component: ReportsCreateComponent,
                        data: ɵ43
                    },
                    {
                        path: ':report_type/closed',
                        component: ReportsClosedListComponent,
                        data: ɵ44
                    },
                    {
                        path: ':report_type/:report_id/closed/view',
                        component: ReportsClosedViewComponent,
                        data: ɵ45
                    },
                    {
                        path: ':report_type/:report_id/closed/attachments',
                        component: ReportsClosedAttachmentsListComponent,
                        data: ɵ46
                    },
                    {
                        path: ':report_type/:report_id/closed/comments',
                        component: ReportsClosedCommentsListComponent,
                        data: ɵ47
                    },
                    {
                        path: ':report_type/:report_id/closed/questionnaire',
                        component: ReportsClosedQuestionnaireComponent,
                        data: ɵ48
                    },
                    {
                        path: ':report_type/:report_id/closed/buildings',
                        component: ReportsClosedBuildingsListComponent,
                        data: ɵ49
                    },
                    {
                        path: ':report_type/:report_id/closed/buildings/:building_id/reports',
                        component: ReportsClosedBuildingsReportsListComponent,
                        data: ɵ50
                    },
                    {
                        path: ':report_type/:report_id/closed/buildings/:building_id/reports',
                        component: ReportsClosedBuildingsReportsListComponent,
                        data: ɵ51
                    },
                    {
                        path: ':report_type/:report_id/closed/buildings/:building_id/reports/:building_report_id/view',
                        component: ReportsClosedBuildingsReportsViewComponent,
                        data: ɵ52
                    },
                    {
                        path: ':report_type/:report_status',
                        component: ReportsListComponent,
                        data: ɵ53
                    },
                    {
                        path: ':report_type/:report_id/edit',
                        component: ReportsEditComponent,
                        data: ɵ54
                    },
                    {
                        path: ':report_type/:report_id/view',
                        component: ReportsViewComponent,
                        data: ɵ55
                    },
                    {
                        path: ':report_type/:report_id/attachments',
                        component: ReportsAttachmentsListComponent,
                        data: ɵ56
                    },
                    {
                        path: ':report_type/:report_id/attachments/create',
                        component: ReportsAttachmentsCreateComponent,
                        data: ɵ57
                    },
                    {
                        path: ':report_type/:report_id/buildings',
                        component: ReportsBuildingsListComponent,
                        data: ɵ58
                    },
                    {
                        path: ':report_type/:report_id/buildings/create',
                        component: ReportsBuildingsCreateComponent,
                        data: ɵ59
                    },
                    {
                        path: ':report_type/:report_id/buildings/:building_id/edit',
                        component: ReportsBuildingsEditComponent,
                        data: ɵ60
                    },
                    {
                        path: ':report_type/:report_id/buildings/:building_id/reports',
                        component: ReportsBuildingsReportsListComponent,
                        data: ɵ61
                    },
                    {
                        path: ':report_type/:report_id/buildings/:building_id/reports/create',
                        component: ReportsBuildingsReportsCreateComponent,
                        data: ɵ62
                    },
                    {
                        path: ':report_type/:report_id/buildings/:building_id/reports/:building_report_id/edit',
                        component: ReportsBuildingsReportsEditComponent,
                        data: ɵ63
                    },
                    /*
                                            {
                                                path: ':report_type/:report_id/buildings/:buiding_id/reports/:building_report_id/view',
                                                component: ReportsBuildingsReportsViewComponent,
                                                data: {
                                                    title: 'Bekijk gebouw rapportage | ' + environment.webappName
                                                }
                                            },
                    */
                    {
                        path: ':report_type/:report_id/comments',
                        component: ReportsCommentsListComponent,
                        data: ɵ64
                    },
                    {
                        path: ':report_type/:report_id/comments/create',
                        component: ReportsCommentsCreateComponent,
                        data: ɵ65
                    },
                    {
                        path: ':report_type/:report_id/comments/:comment_id/edit',
                        component: ReportsCommentsEditComponent,
                        data: ɵ66
                    },
                    {
                        path: ':report_type/:report_id/questionnaire',
                        component: ReportsQuestionnaireComponent,
                        data: ɵ67
                    },
                    {
                        path: ':report_type/:report_id/send-to-client',
                        component: ReportsSendToClientComponent,
                        data: ɵ68
                    }
                ]
            },
            {
                path: 'planning',
                component: PlanningRouterComponent,
                children: [
                    {
                        path: '',
                        component: PlanningListComponent,
                        data: ɵ69
                    },
                    {
                        path: ':planning_id/view',
                        component: PlanningViewComponent,
                        data: ɵ70
                    }
                ]
            },
            {
                path: '403',
                component: PageForbiddenComponent,
                data: ɵ71
            },
            {
                path: '**',
                component: PageNotFoundComponent,
                data: ɵ72
            }
        ]
    }
];
export class RoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39, ɵ40, ɵ41, ɵ42, ɵ43, ɵ44, ɵ45, ɵ46, ɵ47, ɵ48, ɵ49, ɵ50, ɵ51, ɵ52, ɵ53, ɵ54, ɵ55, ɵ56, ɵ57, ɵ58, ɵ59, ɵ60, ɵ61, ɵ62, ɵ63, ɵ64, ɵ65, ɵ66, ɵ67, ɵ68, ɵ69, ɵ70, ɵ71, ɵ72 };
