import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {GeneralService} from '../../../../_services/general.service';
import {FormsService} from '../../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {InspectorsService} from '../../../../_services/inspectors.service';
import * as moment from 'moment';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {APP_DATE_FORMATS, CustomDateAdapter} from '../../../../_pipes/angular2-material-datepicker-custom-date';

@Component({
	selector: 'app-inspectors-calibrations-create',
	templateUrl: './calibrations-create.component.html',
	styleUrls: ['./calibrations-create.component.scss'],
	animations: [routeAnimation],
	providers: [
		{
			provide: DateAdapter, useClass: CustomDateAdapter
		},
		{
			provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
		}
	]
})

export class InspectorsCalibrationsCreateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public inspectorCalibrationForm = this.formBuilder.group({
		device_id: ['', [Validators.required, Validators.maxLength(100)]],
		device_brand: ['', [Validators.required, Validators.maxLength(100)]],
		device_description: ['', [Validators.required, Validators.maxLength(100)]],
		date_calibrated: ['', Validators.required],
		date_calibrated_input: [''],
		next_calibration_date: ['', Validators.required],
		next_calibration_date_input: [''],
		certificate_number: ['', Validators.required]
	});

	private _errorsSubscription: Subscription = null;

	constructor(private generalService: GeneralService,
				public inspectorsService: InspectorsService,
				public formsService: FormsService,
				private formBuilder: FormBuilder,
				private previousUrlService: PreviousUrlService) {
	}

	ngOnInit(): void {
		this.formsService.form = this.inspectorCalibrationForm;

		this.previousUrlService.setPreviousUrlIfNull('/inspectors/calibrations');

		this._errorsSubscription = this.inspectorsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}

	updateInspectorDateCalibrated($event) {
		this.inspectorCalibrationForm.get('date_calibrated').setValue(moment($event.value).format('DD-MM-YYYY'));
		if (this.inspectorCalibrationForm.get('next_calibration_date').value === null || this.inspectorCalibrationForm.get('next_calibration_date').value === '') {
			const date = moment($event.value).add(1, 'years');
			this.inspectorCalibrationForm.get('next_calibration_date').setValue(date.format('DD-MM-YYYY'));
			this.inspectorCalibrationForm.get('next_calibration_date_input').setValue(date.format('YYYY-MM-DD'));
		}
	}

	updateInspectorNextCalibrationDate($event) {
		this.inspectorCalibrationForm.get('next_calibration_date').setValue(moment($event.value).format('DD-MM-YYYY'));
	}
}
