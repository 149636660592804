import {Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {LoadingOverlayService} from './loading-overlay.service';
import {ApiService} from '../_interceptors/api.service';
import {SnackbarService} from './snackbar.service';
import {ServerResponse} from '../_interfaces/server.response';
import {PreviousUrlService} from './previous-url.service';
import {InspectorsCalibration} from '../_interfaces/inspectors/inspectors-calibration';
import {InspectorsInventory} from '../_interfaces/inspectors/inspectors-inventory';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SimpleModalDialogComponent} from '../core/simple-modal/simple-modal.component';
import {IndexedDBService} from './indexeddb.service';
import {AuthenticationService} from './authentication.service';
import {InspectorsInspections} from '../_interfaces/inspectors/inspectors-inspections';
import * as moment from 'moment';
import {environment} from '../../environments/environment';

@Injectable()

export class InspectorsService {

	public errors: Subject<any> = new BehaviorSubject(null);

	private debug: boolean = !environment.production;

	constructor(private apiService: ApiService,
				private dialogService: MatDialog,
				private overlayService: LoadingOverlayService,
				private snackbarService: SnackbarService,
				private previousUrlService: PreviousUrlService,
				private indexedDBService: IndexedDBService,
				private authenticationService: AuthenticationService,
				private router: Router) {
	}

	getInspectorsCalibrations(formData) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: InspectorsCalibration[] = [],
						collectionData: InspectorsCalibration[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[3][search][value]'] !== 'undefined') {
							if (formData['columns[3][search][value]'] !== null && formData['columns[3][search][value]'] !== '') {
								searchString = formData['columns[3][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableInspectorsCalibrations).then(
						calibrationsDB => {
							if (typeof calibrationsDB !== 'undefined') {
								calibrationsDB.sort((a, b) => a.next_calibration_date > b.next_calibration_date ? -1 : 1);

								if (searchString !== null) {
									collectionData = calibrationsDB.filter(calibrations => calibrations.device_id.toLowerCase().indexOf(searchString) !== -1);
								} else {
									collectionData = calibrationsDB;
								}

								if (endIndex !== -1) {
									resultsData = collectionData.slice(startIndex, endIndex);
								} else {
									resultsData = collectionData.slice();
								}

								resolve({
									recordsTotal: collectionData.length,
									data: resultsData
								});
							} else {
								this.syncInspectorsCalibrations().then((calibrations: InspectorsCalibration[]) => {
									if (typeof calibrations !== 'undefined') {
										if (searchString !== null) {
											collectionData = calibrations.filter(calibrations2 => calibrations2.device_id.toLowerCase().indexOf(searchString) !== -1);
										} else {
											collectionData = calibrations;
										}

										if (endIndex !== -1) {
											resultsData = collectionData.slice(startIndex, endIndex);
										} else {
											resultsData = collectionData.slice();
										}

										resolve({
											recordsTotal: collectionData.length,
											data: resultsData
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncInspectorsCalibrations().then((calibrations: InspectorsCalibration[]) => {
								if (typeof calibrations !== 'undefined') {
									if (searchString !== null) {
										collectionData = calibrations.filter(calibrations2 => calibrations2.device_id.toLowerCase().indexOf(searchString) !== -1);
									} else {
										collectionData = calibrations;
									}

									if (endIndex !== -1) {
										resultsData = collectionData.slice(startIndex, endIndex);
									} else {
										resultsData = collectionData.slice();
									}

									resolve({
										recordsTotal: collectionData.length,
										data: resultsData
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getInspectorCalibration(calibration_id: number) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: InspectorsCalibration[] = [];

					this.indexedDBService.database.getByKey(this.indexedDBService.tableInspectorsCalibrations, calibration_id).then(calibration => {
							if (typeof calibration !== 'undefined') {
								resolve(calibration);
							} else {
								this.syncInspectorsCalibrations().then((calibrations: InspectorsCalibration[]) => {
									if (typeof calibrations !== 'undefined') {
										resultsData = calibrations.filter(calibrations2 => calibrations2.id === calibration_id);

										if (resultsData.length) {
											resolve(resultsData[0]);
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncInspectorsCalibrations().then((calibrations: InspectorsCalibration[]) => {
								if (typeof calibrations !== 'undefined') {
									resultsData = calibrations.filter(calibrations2 => calibrations2.id === calibration_id);

									if (resultsData.length) {
										resolve(resultsData[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	createInspectorCalibration(formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('calibration', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Kalibratie opgeslagen!');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableInspectorsCalibrations, <InspectorsCalibration>data.data).then(() => {
						}, error => {
							console.log(error);
							throw new Error(error);
						});
					}
					this.router.navigate(['/inspectors/calibrations']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			let _authenticationSubscription = this.authenticationService.user.first().subscribe(user => {
				formData['date_calibrated'] = moment(formData['date_calibrated'], 'DD-MM-YYYY').valueOf();
				formData['next_calibration_date'] = moment(formData['next_calibration_date'], 'DD-MM-YYYY').valueOf();
				formData['inspector'] = user.display_name;
				formData['id'] = moment().valueOf();
				delete formData['date_calibrated_input'];
				delete formData['next_calibration_date_input'];
				this.indexedDBService.database.add(this.indexedDBService.tableInspectorsCalibrations, formData).then(() => {
					this.snackbarService.success('Kalibratie opgeslagen!');

					formData['indexedDBMethod'] = '1-create';
					this.indexedDBService.database.add(this.indexedDBService.tableInspectorsCalibrationsPush, formData).then(() => {
					}, error => {
						console.log(error);
						throw new Error(error);
					});

					this.router.navigate(['/inspectors/calibrations']).then(() => {
					});
				}, error => {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
					throw new Error(error);
				});
			});
			_authenticationSubscription.unsubscribe();
		}
	}

	updateInspectorCalibration(calibration_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('calibration/' + calibration_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Kalibratie opgeslagen!');

					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableInspectorsCalibrations, <InspectorsCalibration>data.data).then(() => {
						}, error => {
							console.log(error);
							throw new Error(error);
						});
					}
					this.router.navigate(['/inspectors/calibrations']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableInspectorsCalibrations, 'id', calibration_id).then(calibration => {
				delete formData['date_calibrated_input'];
				delete formData['next_calibration_date_input'];
				let newCalibration = {...calibration, ...formData};
				newCalibration['date_calibrated'] = moment(formData['date_calibrated'], 'DD-MM-YYYY').valueOf();
				newCalibration['next_calibration_date'] = moment(formData['next_calibration_date'], 'DD-MM-YYYY').valueOf();

				this.indexedDBService.database.update(this.indexedDBService.tableInspectorsCalibrations, newCalibration).then(() => {
					this.snackbarService.success('Kalibratie opgeslagen!');

					newCalibration['indexedDBMethod'] = '2-update';
					this.indexedDBService.database.add(this.indexedDBService.tableInspectorsCalibrationsPush, newCalibration).then(() => {
					}, error => {
						console.log(error);
						throw new Error(error);
					});

					this.router.navigate(['/inspectors/calibrations']).then(() => {
					});
				}, error => {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
					throw new Error(error);
				});
			});
		}
	}

	deleteInspectorCalibration(calibration_id: number): Promise<boolean> {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Weet u zeker dat u deze kalibratie wilt verwijderen?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('calibration/' + calibration_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Kalibratie verwijderd.');

								this.indexedDBService.database.delete(this.indexedDBService.tableInspectorsCalibrations, calibration_id).then(() => {
								}, error => {
									console.log(error);
									throw new Error(error);
								});

								resolve(true);
							} else {
								this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
								resolve(false);
							}
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableInspectorsCalibrations, calibration_id).then(() => {
							this.snackbarService.success('Kalibratie verwijderd.');

							this.indexedDBService.database.add(this.indexedDBService.tableInspectorsCalibrationsPush, {
								id: calibration_id,
								indexedDBMethod: '3-delete'
							}).then(() => {
							}, error => {
								console.log(error);
								throw new Error(error);
							});
							resolve(true);
						}, error => {
							this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
							resolve(false);
							throw new Error(error);
						});
					}
				}
			});
		});
	}

	getInspectorsInventories(formData = null) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: InspectorsInventory[] = [],
						collectionData: InspectorsInventory[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[1][search][value]'] !== 'undefined') {
							if (formData['columns[1][search][value]'] !== null && formData['columns[1][search][value]'] !== '') {
								searchString = formData['columns[1][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableInspectorsInventory).then(
						inventoriesDB => {
							if (typeof inventoriesDB !== 'undefined') {
								inventoriesDB.sort((a, b) => a.created > b.created ? -1 : 1);
								if (searchString !== null) {
									collectionData = inventoriesDB.filter(inventories => inventories.original_filename.toLowerCase().indexOf(searchString) !== -1);
								} else {
									collectionData = inventoriesDB;
								}

								if (endIndex !== -1) {
									resultsData = collectionData.slice(startIndex, endIndex);
								} else {
									resultsData = collectionData.slice();
								}

								resolve({
									recordsTotal: collectionData.length,
									data: resultsData
								});
							} else {
								this.syncInspectorsInventories().then((inventories: InspectorsInventory[]) => {
									if (typeof inventories !== 'undefined') {
										if (searchString !== null) {
											collectionData = inventories.filter(inventories2 => inventories2.original_filename.toLowerCase().indexOf(searchString) !== -1);
										} else {
											collectionData = inventories;
										}

										if (endIndex !== -1) {
											resultsData = collectionData.slice(startIndex, endIndex);
										} else {
											resultsData = collectionData.slice();
										}

										resolve({
											recordsTotal: collectionData.length,
											data: resultsData
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncInspectorsInventories().then((inventories: InspectorsInventory[]) => {
								if (typeof inventories !== 'undefined') {
									if (searchString !== null) {
										collectionData = inventories.filter(inventories2 => inventories2.original_filename.toLowerCase().indexOf(searchString) !== -1);
									} else {
										collectionData = inventories;
									}

									if (endIndex !== -1) {
										resultsData = collectionData.slice(startIndex, endIndex);
									} else {
										resultsData = collectionData.slice();
									}

									resolve({
										recordsTotal: collectionData.length,
										data: resultsData
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getInspectorInventory(inventory_id: number) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: InspectorsInventory[] = [];

					this.indexedDBService.database.getByKey(this.indexedDBService.tableInspectorsInventory, inventory_id).then(
						inventory => {
							if (typeof inventory !== 'undefined') {
								resolve(inventory);
							} else {
								this.syncInspectorsInventories().then((inventories: InspectorsInventory[]) => {
									if (typeof inventories !== 'undefined') {
										resultsData = inventories.filter(inventories2 => inventories2.id === inventory_id);

										if (resultsData.length) {
											resolve(resultsData[0]);
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncInspectorsInventories().then((inventories: InspectorsInventory[]) => {
								if (typeof inventories !== 'undefined') {
									resultsData = inventories.filter(inventories2 => inventories2.id === inventory_id);

									if (resultsData.length) {
										resolve(resultsData[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	createInspectorInventory(formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('inventory', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Inventaris opgeslagen!');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableInspectorsInventory, <InspectorsInventory>data.data).then(() => {
						}, error => {
							console.log(error);
							throw new Error(error);
						});
					}

					this.router.navigate(['/inspectors/inventories']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			let _authenticationSubscription = this.authenticationService.user.first().subscribe(user => {
				formData['indexedDBMethod'] = '1-create';
				formData['created'] = moment().valueOf();
				formData['user_id'] = user.id;
				formData['id'] = moment().valueOf();

				let newFormData = {
					id: formData['id'],
					user_id: formData['user_id'],
					created: formData['created'],
					filename: formData.filename._files[0].name,
					original_filename: formData.filename._files[0].name
				};
				this.indexedDBService.database.add(this.indexedDBService.tableInspectorsInventory, newFormData).then(() => {
					this.snackbarService.success('Inventaris opgeslagen!');

					formData['original_filename'] = newFormData['filename'];
					this.indexedDBService.database.add(this.indexedDBService.tableInspectorsInventoryPush, <InspectorsInventory>formData).then(() => {
					}, error => {
						console.log(error);
						throw new Error(error);
					});

					this.router.navigate(['/inspectors/inventories']).then(() => {
					});
				}, error => {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
					throw new Error(error);
				});
			});
			_authenticationSubscription.unsubscribe();
		}
	}

	deleteInspectorInventory(inventory_id: number) {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Weet u zeker dat u deze inventaris wilt verwijderen?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('inventory/' + inventory_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Inventaris verwijderd.');

								this.indexedDBService.database.delete(this.indexedDBService.tableInspectorsInventory, inventory_id).then(() => {
								}, error => {
									console.log(error);
									throw new Error(error);
								});

								resolve(true);
							} else {
								this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
								resolve(false);
							}
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableInspectorsInventory, inventory_id).then(() => {
							this.snackbarService.success('Inventaris verwijderd.');

							this.indexedDBService.database.add(this.indexedDBService.tableInspectorsInventoryPush, {
								id: inventory_id,
								indexedDBMethod: '3-delete'
							}).then(() => {
							}, error => {
								console.log(error);
								throw new Error(error);
							});

							resolve(true);
						}, error => {
							this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
							resolve(false);
							throw new Error(error);
						});
					}
				}
			});
		});
	}

	sendInspectorInventoryToClient(inventory_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('inventory/' + inventory_id + '/send-to-client', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Inventaris document verstuurd naar klant.');
					if (this.previousUrlService.getPreviousUrl() !== '' && this.previousUrlService.getPreviousUrl() !== '/') {
						this.previousUrlService.goToPreviousUrl();
					} else {
						this.router.navigate(['/inspectors/inventories/']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			formData['inventory_id'] = inventory_id;
			delete formData['client_filter_input'];
			this.indexedDBService.database.add(this.indexedDBService.tableInspectorsInventorySendToClient, <InspectorsInventory>formData).then(() => {
				this.snackbarService.success('Inventaris document wordt verstuurd naar de klant indien er weer internet is.');
				if (this.previousUrlService.getPreviousUrl() !== '' && this.previousUrlService.getPreviousUrl() !== '/') {
					this.previousUrlService.goToPreviousUrl();
				} else {
					this.router.navigate(['/inspectors/inventories/']).then(() => {
					});
				}
			}, error => {
				console.log(error);
				throw new Error(error);
			});
		}
	}

	getInspectorsInspections(formData) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event === true) {
					let collectionData: InspectorsInspections[] = [],
						resultsData: InspectorsInspections[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableInspectorsInspections).then(
						inspectionsDB => {
							if (typeof inspectionsDB !== 'undefined') {
								inspectionsDB.sort((a, b) => a.created > b.created ? -1 : 1);
								if (searchString !== null) {
									collectionData = inspectionsDB.filter(inspections => inspections.company_name.toLowerCase().indexOf(searchString) !== -1);
								} else {
									collectionData = inspectionsDB;
								}

								if (endIndex !== -1) {
									resultsData = collectionData.slice(startIndex, endIndex);
								} else {
									resultsData = collectionData.slice();
								}

								resolve({
									recordsTotal: collectionData.length,
									data: resultsData
								});
							} else {
								this.syncInspectorsInspections().then((inspections: InspectorsInspections[]) => {
									if (typeof inspections !== 'undefined') {
										if (searchString !== null) {
											collectionData = inspections.filter(inspections2 => inspections2.company_name.toLowerCase().indexOf(searchString) !== -1);
										} else {
											collectionData = inspections;
										}

										if (endIndex !== -1) {
											resultsData = collectionData.slice(startIndex, endIndex);
										} else {
											resultsData = collectionData.slice();
										}

										resolve({
											recordsTotal: collectionData.length,
											data: resultsData
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncInspectorsInspections().then((inspections: InspectorsInspections[]) => {
								if (typeof inspections !== 'undefined') {
									if (searchString !== null) {
										collectionData = inspections.filter(inspections2 => inspections2.company_name.toLowerCase().indexOf(searchString) !== -1);
									} else {
										collectionData = inspections;
									}

									if (endIndex !== -1) {
										resultsData = collectionData.slice(startIndex, endIndex);
									} else {
										resultsData = collectionData.slice();
									}

									resolve({
										recordsTotal: collectionData.length,
										data: resultsData
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	syncInspectorsCalibrations() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('calibration/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('calibration', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableInspectorsCalibrations, <InspectorsCalibration[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableInspectorsCalibrations,
															updated: server_date.data.updated
														})
															.then(() => resolve(<InspectorsCalibration[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncInspectorsInventories() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('inventory/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('inventory', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableInspectorsInventory, <InspectorsInventory[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableInspectorsInventory,
															updated: server_date.data.updated
														})
															.then(() => resolve(<InspectorsInventory[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncInspectorsInspections() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('inspections/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('inspections', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableInspectorsInspections, <InspectorsInspections[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableInspectorsInspections,
															updated: server_date.data.updated
														})
															.then(() => resolve(<InspectorsInspections[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}
}
