import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {MaterialComponentsModule} from '../material-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RoutingModule} from '../app-routing.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgBoxModule} from '../core/ngbox/ngbox.module';

import {MediaReplayService} from '../core/sidenav/mediareplay/media-replay.service';
import {CustomErrorHandlerService} from '../_interceptors/http.error-handler.service';
import {NgBoxService} from '../core/ngbox/ngbox.service';
import {AgmCoreModule} from '@agm/core';

import {LoadingOverlayService} from '../_services/loading-overlay.service';
import {SidenavService} from '../core/sidenav/sidenav.service';
import {AuthGuard} from '../_services/authguard.service';
import {AuthenticationService} from '../_services/authentication.service';
import {ApiService} from '../_interceptors/api.service';
import {PreviousUrlService} from '../_services/previous-url.service';

import {CustomPipesModule} from '../_pipes/custom-pipes.module';
import {SearchElementComponent} from '../core/search-element/search-element.component';
import {SimpleModalDialogComponent} from '../core/simple-modal/simple-modal.component';

import {DashboardComponent} from './dashboard/dashboard.component';

import {ClientsService} from '../_services/clients.service';
import {ClientsRouterComponent} from './clients/clients-router/clients-router.component';
import {ClientsCreateComponent} from './clients/clients-create/clients-create.component';
import {ClientsEditComponent} from './clients/clients-edit/clients-edit.component';
import {ClientsListComponent} from './clients/clients-list/clients-list.component';
import {ClientsViewComponent} from './clients/clients-view/clients-view.component';
import {ClientsViewSmallWidgetComponent} from './clients/clients-view-small-widget/clients-view-small-widget.component';
import {ClientsAttachmentsCreateComponent} from './clients/clients-attachments/clients-attachments-create/clients-attachments-create.component';
import {ClientsAttachmentsEditComponent} from './clients/clients-attachments/clients-attachments-edit/clients-attachments-edit.component';
import {ClientsAttachmentsListComponent} from './clients/clients-attachments/clients-attachments-list/clients-attachments-list.component';
import {ClientsContactPersonsCreateComponent} from './clients/clients-contacts-persons/clients-contacts-persons-create/clients-contacts-persons-create.component';
import {ClientsContactPersonsEditComponent} from './clients/clients-contacts-persons/clients-contacts-persons-edit/clients-contacts-persons-edit.component';
import {ClientsContactPersonsListComponent} from './clients/clients-contacts-persons/clients-contacts-persons-list/clients-contacts-persons-list.component';
import {ClientsContactsPersonsViewComponent} from './clients/clients-contacts-persons/clients-contacts-persons-view/clients-contacts-persons-view.component';
import {ClientChangeNextInspectionDateComponent} from './clients/clients-change-next-inspection-date/clients-change-next-inspection-date.component';
import {ClientsNextInspectionNotificationComponent} from './clients/clients-next-inspection-notification/clients-next-inspection-notification.component';

import {InspectorsService} from '../_services/inspectors.service';
import {InspectorsRouterComponent} from './inspectors/inspectors-router/inspectors-router.component';
import {InspectorsCalibrationsListComponent} from './inspectors/calibrations/calibrations-list/calibrations-list.component';
import {InspectorsCalibrationsCreateComponent} from './inspectors/calibrations/calibrations-create/calibrations-create.component';
import {InspectorsCalibrationsEditComponent} from './inspectors/calibrations/calibrations-edit/calibrations-edit.component';
import {InspectorsCalibrationsViewComponent} from './inspectors/calibrations/calibrations-view/calibrations-view.component';
import {InspectorsInventoriesListComponent} from './inspectors/inventories/inventories-list/inventories-list.component';
import {InspectorsInventoriesCreateComponent} from './inspectors/inventories/inventories-create/inventories-create.component';
import {InspectorsInventoriesSendToClientComponent} from './inspectors/inventories/inventories-send-to-client/inventories-send-to-client.component';
import {InspectorsInspectionsListComponent} from './inspectors/inspections/inspections-list/inspections-list.component';

import {ReportsService} from '../_services/reports.service';
import {ReportsRouterComponent} from './reports/reports-router/reports-router.component';
import {ReportsListComponent} from './reports/reports-list/reports-list.component';
import {ReportsCreateComponent} from './reports/reports-create/reports-create.component';
import {ReportsEditComponent} from './reports/reports-edit/reports-edit.component';
import {ReportsAttachmentsCreateComponent} from './reports/reports-attachments/reports-attachments-create/reports-attachments-create.component';
import {ReportsAttachmentsListComponent} from './reports/reports-attachments/reports-attachments-list/reports-attachments-list.component';
import {ReportsBuildingsListComponent} from './reports/reports-buildings/reports-buildings-list/reports-buildings-list.component';
import {ReportsBuildingsCreateComponent} from './reports/reports-buildings/reports-buildings-create/reports-buildings-create.component';
import {ReportsBuildingsEditComponent} from './reports/reports-buildings/reports-buildings-edit/reports-buildings-edit.component';
import {ReportsBuildingsReportsListComponent} from './reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-list/reports-buildings-reports-list.component';
import {ReportsBuildingsReportsCreateComponent} from './reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-create/reports-buildings-reports-create.component';
import {ReportsBuildingsReportsEditComponent} from './reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-edit/reports-buildings-reports-edit.component';
// import {ReportsBuildingsReportsViewComponent} from './reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-view/reports-buildings-reports-view.component';
import {ReportsCommentsCreateComponent} from './reports/reports-comments/reports-comments-create/reports-comments-create.component';
import {ReportsCommentsEditComponent} from './reports/reports-comments/reports-comments-edit/reports-comments-edit.component';
import {ReportsCommentsListComponent} from './reports/reports-comments/reports-comments-list/reports-comments-list.component';
import {ReportsViewComponent} from './reports/reports-view/reports-view.component';
import {ReportsViewSmallWidgetComponent} from './reports/reports-view-small-widget/reports-view-small-widget.component';
import {ReportsQuestionnaireComponent} from './reports/reports-questionnaire/reports-questionnaire.component';
import {ReportsSendToClientComponent} from './reports/reports-send-to-client/reports-send-to-client.component';

import {environment} from '../../environments/environment';
import {BarcodeScannerComponent} from '../core/barcode-scanner/barcode-scanner.component';
import {ClientsReportsListComponent} from './clients/clients-reports-list/clients-reports-list.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {ReportsClosedListComponent} from './reports/reports-list/reports-closed-list.component';
import {ReportsClosedAttachmentsListComponent} from './reports/reports-attachments/reports-attachments-list/reports-closed-attachments-list.component';
import {ReportsClosedBuildingsListComponent} from './reports/reports-buildings/reports-buildings-list/reports-closed-buildings-list.component';
import {ReportsClosedBuildingsReportsListComponent} from './reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-list/reports-closed-buildings-reports-list.component';
import {ReportsClosedViewComponent} from './reports/reports-view/reports-closed-view.component';
import {ReportsClosedCommentsListComponent} from './reports/reports-comments/reports-comments-list/reports-closed-comments-list.component';
import {ReportsClosedBuildingsReportsViewComponent} from './reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-view/reports-closed-buildings-reports-view.component';
import {ReportsClosedQuestionnaireComponent} from './reports/reports-questionnaire/reports-closed-questionnaire.component';
import {ReportsReinspectionRequiredListComponent} from './reports/reports-reinspection-required-list/reports-reinspection-required-list.component';
import {ModalCopyReportDialogComponent} from '../core/modal-copy-report/modal-copy-report.component';
import {SpeechToTextComponent} from '../core/speech-to-text/speech-to-text.component';
import {PlanningService} from '../_services/planning.service';
import {PlanningRouterComponent} from './planning/planning-router/planning-router.component';
import {PlanningListComponent} from './planning/planning-list/planning-list.component';
import {PlanningViewComponent} from './planning/planning-view/planning-view.component';
import {TwoFactorAuthenticationComponent} from '../core/security/two-factor-authentication/two-factor-authentication.component';
import {
	TwoFactorAuthenticationEnableComponent
} from '../core/security/two-factor-authentication-enable/two-factor-authentication-enable.component';
import {
	TwoFactorAuthenticationDisableComponent
} from '../core/security/two-factor-authentication-disable/two-factor-authentication-disable.component';
import {
	TwoFactorAuthenticationRegenerateRecoveryCodesComponent
} from '../core/security/two-factor-authentication-regenerate-recovery-codes/two-factor-authentication-regenerate-recovery-codes.component';
import {
	ClientsBuildingMapsListComponent
} from './clients/clients-building-maps/clients-building-maps-list/clients-building-maps-list.component';
import {
	ClientsBuildingMapsCreateComponent
} from './clients/clients-building-maps/clients-building-maps-create/clients-building-maps-create.component';
import {
	ClientsBuildingMapsEditComponent
} from './clients/clients-building-maps/clients-building-maps-edit/clients-building-maps-edit.component';
import {
	ClientsBuildingMapsViewComponent
} from './clients/clients-building-maps/clients-building-maps-view/clients-building-maps-view.component';
import {BuildingMapWidgetComponent} from '../core/building-map-widget/building-map-widget.component';

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule,
		MaterialComponentsModule,
		FormsModule,
		ReactiveFormsModule,
		RoutingModule,
		FlexLayoutModule,
		NgxPaginationModule,
		CustomPipesModule.forRoot(),
		NgBoxModule,
		AgmCoreModule.forRoot({
			apiKey: environment.google_api_key
		}),
		MaterialFileInputModule,
		InfiniteScrollModule,
		NgxMatSelectSearchModule
	],
	entryComponents: [
		SimpleModalDialogComponent,
		BarcodeScannerComponent,
		SpeechToTextComponent,
		ModalCopyReportDialogComponent,
		BuildingMapWidgetComponent
	],
	declarations: [
		DashboardComponent,
		SearchElementComponent,
		ClientsRouterComponent,
		ClientsCreateComponent,
		ClientsEditComponent,
		ClientsListComponent,
		ClientsViewComponent,
		ClientsViewSmallWidgetComponent,
		ClientsReportsListComponent,
		ClientsAttachmentsCreateComponent,
		ClientsAttachmentsEditComponent,
		ClientsAttachmentsListComponent,
		ClientsContactPersonsCreateComponent,
		ClientsContactPersonsEditComponent,
		ClientsContactPersonsListComponent,
		ClientsContactsPersonsViewComponent,
		ClientsNextInspectionNotificationComponent,
		ClientChangeNextInspectionDateComponent,
		ClientsBuildingMapsListComponent,
		ClientsBuildingMapsCreateComponent,
		ClientsBuildingMapsEditComponent,
		ClientsBuildingMapsViewComponent,
		InspectorsRouterComponent,
		InspectorsCalibrationsListComponent,
		InspectorsCalibrationsCreateComponent,
		InspectorsCalibrationsEditComponent,
		InspectorsCalibrationsViewComponent,
		InspectorsInventoriesListComponent,
		InspectorsInventoriesCreateComponent,
		InspectorsInventoriesSendToClientComponent,
		InspectorsInspectionsListComponent,
		ReportsRouterComponent,
		ReportsListComponent,
		ReportsCreateComponent,
		ReportsEditComponent,
		ReportsAttachmentsCreateComponent,
		ReportsAttachmentsListComponent,
		ReportsBuildingsListComponent,
		ReportsBuildingsCreateComponent,
		ReportsBuildingsEditComponent,
		ReportsBuildingsReportsListComponent,
		ReportsBuildingsReportsCreateComponent,
		ReportsBuildingsReportsEditComponent,
		// ReportsBuildingsReportsViewComponent,
		ReportsCommentsCreateComponent,
		ReportsCommentsEditComponent,
		ReportsCommentsListComponent,
		ReportsViewComponent,
		ReportsViewSmallWidgetComponent,
		ReportsQuestionnaireComponent,
		ReportsSendToClientComponent,
		BarcodeScannerComponent,
		SpeechToTextComponent,
		ReportsClosedListComponent,
		ReportsClosedAttachmentsListComponent,
		ReportsClosedCommentsListComponent,
		ReportsClosedBuildingsListComponent,
		ReportsClosedBuildingsReportsListComponent,
		ReportsClosedBuildingsReportsViewComponent,
		ReportsClosedQuestionnaireComponent,
		ReportsClosedViewComponent,
		ReportsReinspectionRequiredListComponent,
		ModalCopyReportDialogComponent,
		PlanningRouterComponent,
		PlanningListComponent,
		PlanningViewComponent,
		TwoFactorAuthenticationComponent,
		TwoFactorAuthenticationEnableComponent,
		TwoFactorAuthenticationDisableComponent,
		TwoFactorAuthenticationRegenerateRecoveryCodesComponent,
	],
	providers: [
		SidenavService,
		MediaReplayService,
		AuthGuard,
		AuthenticationService,
		LoadingOverlayService,
		NgBoxService,
		ApiService,
		CustomErrorHandlerService,
		ClientsService,
		PreviousUrlService,
		InspectorsService,
		ReportsService,
		PlanningService
	],
	exports: [
		BarcodeScannerComponent,
		SpeechToTextComponent,
		ModalCopyReportDialogComponent
	]
})
export class WebappModule {
}
