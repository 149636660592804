<ng-container *ngIf="dataLoaded && !isOffline">
	<form class="with-action-buttons" *ngIf="nextInspectionDetails" [formGroup]="changeNextInspectionDateForm" (ngSubmit)="clientsService.changeNextInspectionDate(client_id, changeNextInspectionDateForm.value)">
		<dl>
			<dt>Laatst uitgevoerde inspectie</dt>
			<dd *ngIf="nextInspectionDetails.next_inspection_date">{{ nextInspectionDetails.next_inspection_date | datex:'DD-MM-YYYY' }}</dd>
			<dd *ngIf="!nextInspectionDetails.next_inspection_date">-</dd>
		</dl>
		<mat-form-field>
			<input type="hidden" formControlName="next_inspection_date">
			<input title="" matInput placeholder="Selecteer volgende inspectie datum" formControlName="next_inspection_date_input" [matDatepicker]="clientNextInspectionDate" required readonly (click)="clientNextInspectionDate.open()" (dateChange)="updateNextInspectionDate($event)">
			<mat-datepicker #clientNextInspectionDate></mat-datepicker>
			<mat-error *ngIf="formsService.getFieldError('next_inspection_date')">{{formsService.getFieldError('next_inspection_date')}}</mat-error>
		</mat-form-field>
		<div class="action-buttons">
			<button color="primary" mat-button [disabled]="!changeNextInspectionDateForm.valid">OPSLAAN</button>
		</div>
	</form>
</ng-container>
<div *ngIf="!dataLoaded && isOffline" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Deze functie is alleen beschikbaar wanneer er een internet verbinding is.</h2>
</div>
