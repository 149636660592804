import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {Client} from '../../../_interfaces/clients/clients';
import {ClientsService} from '../../../_services/clients.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {GeneralService} from '../../../_services/general.service';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {ApiService} from '../../../_interceptors/api.service';
import {GoogleMapsCoordinates} from '../../../_interfaces/google-maps-api-response';
import {SnackbarService} from '../../../_services/snackbar.service';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';
import {AuthenticationService} from '../../../_services/authentication.service';
import {User} from '../../../_interfaces/user';

@Component({
	selector: 'app-clients-view',
	templateUrl: './clients-view.component.html',
	styleUrls: ['./clients-view.component.scss'],
	animations: [routeAnimation]
})

export class ClientsViewComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public latitude: number = null;
	public longitude: number = null;
	public showMap: boolean = false;

	public client_id: number = null;
	public client: Client = null;

	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];

	public user: User = null;

	private _activatedRouteSubscription: Subscription = null;
	private _authenticationSubscription: Subscription = null;

	constructor(private clientsService: ClientsService,
				private activatedRoute: ActivatedRoute,
				private generalService: GeneralService,
				public apiService: ApiService,
				private snackbarService: SnackbarService,
				private previousUrlService: PreviousUrlService,
				private authenticationService: AuthenticationService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length > 0) {
				this.missingData = [];
				this.loadData();
			}
		});

		this._authenticationSubscription = this.authenticationService.user.first().subscribe(user => {
			if (user) {
				this.user = user;
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.client_id = parseInt(params['client_id'], 10);

			this.previousUrlService.setPreviousUrlIfNull('/clients');

			this.loadData();
		});
	}

	ngOnDestroy(): void {
		this.showMap = false;

		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
	}

	loadData() {
		this.clientsService.getClient(this.client_id).then((client: Client) => {
			if (typeof client !== 'undefined') {
				this.client = client;

				this.pageTitleService.setTitle(this.client.company_name + ' | Bekijk klant | ' + environment.webappName);
				this.pageTitleService.setPageTitle(this.client.company_name);

				let address = [];
				if (this.client.address) {
					address.push(this.client.address);
				}
				if (this.client.postalcode) {
					address.push(this.client.postalcode);
				}
				if (this.client.city) {
					address.push(this.client.city);
				}
				if (this.client.country_name) {
					address.push(this.client.country_name);
				}

				if (typeof this.client.lat !== 'undefined' && typeof this.client.lng !== 'undefined') {
					if (this.client.lat !== null && this.client.lng !== null) {
						this.latitude = this.client.lat;
						this.longitude = this.client.lng;
						this.showMap = true;
					} else {
						this.generalService.getLatLngFromAddress(address).then((coords: GoogleMapsCoordinates) => {
							if (typeof coords !== 'undefined') {
								this.latitude = coords.lat;
								this.longitude = coords.lng;
								this.showMap = true;
							}
						});
					}
				} else {
					this.generalService.getLatLngFromAddress(address).then((coords: GoogleMapsCoordinates) => {
						if (typeof coords !== 'undefined') {
							this.latitude = coords.lat;
							this.longitude = coords.lng;
							this.showMap = true;
						}
					});
				}

				this.dataLoaded = true;
			} else {
				this.missingData.push('Klant');
			}
		});
	}

	deleteClient(client_id: number) {
		this.clientsService.deleteClient(client_id).then(status => {
			if (status === true) {
				this.previousUrlService.goTo('/clients');
			}
		});
	}
}
