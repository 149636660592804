<ng-container *ngIf="dataLoaded && !missingData.length">
	<form class="with-action-buttons" [formGroup]="clientContactPersonsEditForm" (ngSubmit)="clientsService.updateContactPerson(client_id, contact_person_id, clientContactPersonsEditForm.value)">
		<div class="divider"><span>Gegevens contactpersoon</span></div>
		<mat-form-field>
			<input matInput placeholder="Voornaam" type="text" required formControlName="firstname">
			<mat-error *ngIf="formsService.getFieldError('firstname')">{{formsService.getFieldError('firstname')}}</mat-error>
		</mat-form-field>
		<mat-form-field>
			<input matInput placeholder="Achternaam" type="text" required formControlName="lastname">
			<mat-error *ngIf="formsService.getFieldError('lastname')">{{formsService.getFieldError('lastname')}}</mat-error>
		</mat-form-field>
		<div class="divider"><span>Contactgegevens</span></div>
		<mat-form-field>
			<input matInput placeholder="Telefoon" type="tel" formControlName="telephone">
			<mat-error *ngIf="formsService.getFieldError('lastname')">{{formsService.getFieldError('lastname')}}</mat-error>
		</mat-form-field>
		<mat-form-field>
			<input matInput placeholder="Mobiel" type="tel" formControlName="mobile">
			<mat-error *ngIf="formsService.getFieldError('lastname')">{{formsService.getFieldError('lastname')}}</mat-error>
		</mat-form-field>
		<mat-form-field>
			<input matInput placeholder="E-Mail" type="email" required formControlName="email">
			<mat-error *ngIf="formsService.getFieldError('email')">{{formsService.getFieldError('email')}}</mat-error>
		</mat-form-field>
		<div class="action-buttons">
			<button color="primary" mat-button [disabled]="!clientContactPersonsEditForm.valid">OPSLAAN</button>
		</div>
	</form>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
