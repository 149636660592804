<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/view']">Bekijk rapportage</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/open']">Bekijk alle rapportages</button>
<div *ngIf="!reportEditable" class="alert alert-danger" fxFlex fxLayoutAlign="center" fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Rapport is vergrendeld door een andere gebruiker. Om het te bewerken, moet u eerst de rapportage ontgrendelen.</h2>
</div>
<form class="with-action-buttons" [formGroup]="reportAttachmentsCreateForm" (ngSubmit)="reportsService.createAttachment(report_type, report_id, reportAttachmentsCreateForm.value)">
	<mat-form-field>
		<ngx-mat-file-input formControlName="filename" placeholder="Bestand" required #filenameInput>
			<button mat-icon-button *ngIf="!filenameInput.empty" (click)="filenameInput.clear($event)">
				<mat-icon>clear</mat-icon>
			</button>
		</ngx-mat-file-input>
		<mat-error *ngIf="formsService.getFieldError('filename')">{{formsService.getFieldError('filename')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="Titel" type="text" required formControlName="title">
		<mat-error *ngIf="formsService.getFieldError('title')">{{formsService.getFieldError('title')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<textarea matInput placeholder="Omschrijving" formControlName="description" rows="8"></textarea>
		<mat-error *ngIf="formsService.getFieldError('description')">{{formsService.getFieldError('description')}}</mat-error>
	</mat-form-field>
	<div class="action-buttons">
		<button *ngIf="reportEditable" color="primary" mat-button [disabled]="!reportAttachmentsCreateForm.valid">OPSLAAN</button>
	</div>
</form>
