<div class="sidenav-container" fxLayout="column">
	<div class="sidenav-scrollbar-container" fxLayout="column" fxLayoutAlign="space-between">
		<mat-nav-list fxLayout="column" class="sidenav-list sidenav-toplevel">
			<app-sidenav-item *ngFor="let item of items" [item]="item"></app-sidenav-item>
		</mat-nav-list>
		<div>
			<div class="version">
				v{{ version }}
			</div>
			<mat-nav-list fxLayout="column" class="sidenav-list sidenav-toplevel sidenav-bottom">
				<app-sidenav-item *ngFor="let item of bottomItems" [item]="item"></app-sidenav-item>
			</mat-nav-list>
		</div>
	</div>
</div>
