import {Component} from '@angular/core';
import {MediaReplayService} from './core/sidenav/mediareplay/media-replay.service';
import {PreviousUrlService} from './_services/previous-url.service';
import {IndexedDBService} from './_services/indexeddb.service';
import {version} from '../environments/version';
import {environment} from '../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent {
	// noinspection JSUnusedLocalSymbols
	constructor(private mediaReplayService: MediaReplayService,
				private previousUrlService: PreviousUrlService,
				private indexedDBService: IndexedDBService) {
		console.log(version + environment.appVersionAddon);
	}
}
