import {Injectable} from '@angular/core';
import {SnackbarService} from '../_services/snackbar.service';
import {LoadingOverlayService} from '../_services/loading-overlay.service';
import {HttpResponse} from '@angular/common/http';

@Injectable()

export class CustomErrorHandlerService {

	constructor(private snackbarService: SnackbarService,
				private loadingOverlayService: LoadingOverlayService) {
	}

	tryParseError(error: HttpResponse<any>) {
		try {
			this.loadingOverlayService.showError();
			return (<any>error).message;
		} catch (ex) {
			try {
				this.loadingOverlayService.showError();
				return error;
			} catch (ex) {
				this.loadingOverlayService.showError();
				return error.toString();
			}
		}
	}
}
