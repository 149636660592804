import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

// because of IE bug (naturally), shamelessly lifted from
// https://stackoverflow.com/questions/39728481/angular2-date-pipe-does-not-work-in-ie-11-and-edge-13-14
@Pipe({
	name: 'datex'
})

export class DatexPipe implements PipeTransform {
	transform(value: number, format: string = 'DD-MM-YY HH:mm'): string {
		if (typeof value !== 'undefined') {
			// Try and parse the passed value.
			let momentDate = moment(value);

			// If moment didn't understand the value, return it unformatted.
			if (!momentDate.isValid()) {
				return '' + value;
			}

			// Otherwise, return the date formatted as requested.
			return momentDate.format(format);
		} else {
			return '-';
		}
	}
}
