<mat-expansion-panel>
	<mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
		<mat-panel-title>
			<div class="divider"><span>Rapportage gegevens</span></div>
		</mat-panel-title>
	</mat-expansion-panel-header>
	<dl *ngIf="report">
		<dt>Rapportage nummer</dt>
		<dd>{{ report.report_nr }}</dd>
		<dt>Inspecteur</dt>
		<dd>{{ report.inspector }}</dd>
		<dt>Status</dt>
		<dd>{{ report.status_text }}</dd>
		<dt>Aantal vierkante meter</dt>
		<dd>{{ report.total_squares }}m<sup>2</sup></dd>
		<dt>Duur van inspectie</dt>
		<dd *ngIf="report.inspection_duration !== null">{{ report.inspection_duration }}</dd>
		<dd *ngIf="report.inspection_duration === null">-</dd>
		<dt>Datum aangemaakt</dt>
		<dd *ngIf="report.created !== null">{{ report.created | datex:'DD-MM-YYYY' }}</dd>
		<dd *ngIf="report.created === null">-</dd>
		<dt>Datum aangepast</dt>
		<dd *ngIf="report.updated !== null">{{ report.updated | datex:'DD-MM-YYYY' }}</dd>
		<dd *ngIf="report.updated === null">-</dd>
		<dt>Kleurcode</dt>
		<dd><div class="tag" [style.background]="report.color"></div></dd>
		<ng-container *ngIf="report.tent_star_rating">
			<dt>Ster inspectie</dt>
			<dd>{{ report.tent_star_rating }}</dd>
		</ng-container>
	</dl>
	<p *ngIf="!report">Rapportage niet gevonden</p>
</mat-expansion-panel>
