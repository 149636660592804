import {Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {LoadingOverlayService} from './loading-overlay.service';
import {ApiService} from '../_interceptors/api.service';
import {SnackbarService} from './snackbar.service';
import {ServerResponse} from '../_interfaces/server.response';
import {PreviousUrlService} from './previous-url.service';
import {Reports} from '../_interfaces/reports/reports';
import {ReportsComments} from '../_interfaces/reports/reports-comments';
import {ReportsBuildings} from '../_interfaces/reports/reports-buildings';
import {ReportsShortcomingsCategories} from '../_interfaces/reports/reports-shortcomings-categories';
import {ReportsShortcomingsSubcategories} from '../_interfaces/reports/reports-shortcomings-subcategories';
import {ReportsShortcomingsTypes} from '../_interfaces/reports/reports-shortcomings-types';
import {ReportsShortcomingsActions} from '../_interfaces/reports/reports-shortcomings-actions';
import {ReportsShortcomingsMaterials} from '../_interfaces/reports/reports-shortcomings-materials';
import {ReportsBuildingsShortcomingsQuestionnaire} from '../_interfaces/reports/reports-buildings-shortcomings-questionnaire';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SimpleModalDialogComponent} from '../core/simple-modal/simple-modal.component';
import {IndexedDBService} from './indexeddb.service';
import {User} from '../_interfaces/user';
import {ReportBuildingParticulars} from '../_interfaces/reports/report-building-particulars';
import {ReportsAttachments} from '../_interfaces/reports/reports-attachments';
import {
	ReportsBuildingsShortcomingsQuestionnaireAnswers
} from '../_interfaces/reports/reports-buildings-shortcomings-questionnaire-answers';
import {ReportsQuestions} from '../_interfaces/reports/reports-questions';
import {ReportsQuestionsAnswers} from '../_interfaces/reports/reports-questions-answers';
import * as moment from 'moment';
import {AuthenticationService} from './authentication.service';
import {ReportsBuildingsReports} from '../_interfaces/reports/reports-buildings-reports';
import {ClientsService} from './clients.service';
import {LocalStorageService} from './local-storage.service';
import {environment} from '../../environments/environment';
import {ModalCopyReportDialogComponent} from '../core/modal-copy-report/modal-copy-report.component';
import {BuildingMapWidgetComponent} from '../core/building-map-widget/building-map-widget.component';
import {ReportsBuildingsMapsMarker} from '../_interfaces/reports/reports-buildings-maps-marker';
import {ReportsBuildingsMaps} from '../_interfaces/reports/reports-buildings-maps';
import * as _ from 'lodash';

@Injectable()

export class ReportsService {

	public errors: Subject<any> = new BehaviorSubject(null);

	private debug: boolean = !environment.production;

	constructor(private apiService: ApiService,
				private clientsService: ClientsService,
				private indexedDBService: IndexedDBService,
				private dialogService: MatDialog,
				private overlayService: LoadingOverlayService,
				private snackbarService: SnackbarService,
				private previousUrlService: PreviousUrlService,
				private authenticationService: AuthenticationService,
				private localStorageService: LocalStorageService,
				private router: Router) {
	}

	getClientReports(client_id: number, formData?) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: Reports[] = [],
						collectionData: Reports[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReports).then(
						reportsDB => {
							if (typeof reportsDB !== 'undefined') {
								reportsDB.sort((a, b) => a.created > b.created ? -1 : 1);
								collectionData = reportsDB.filter(reports => reports.client_id === client_id && (typeof reports.indexedDBMethod === 'undefined' || typeof reports.indexedDBMethod !== 'undefined' && reports.indexedDBMethod !== '3-delete'));

								if (searchString !== null) {
									collectionData = collectionData.filter(collection2 => collection2.report_nr.toLowerCase().indexOf(searchString) !== -1);
								}

								if (endIndex !== -1) {
									resultsData = collectionData.slice(startIndex, endIndex);
								} else {
									resultsData = collectionData.slice();
								}

								resolve({
									recordsTotal: collectionData.length,
									data: resultsData
								});
							} else {
								this.syncReports().then((reports: Reports[]) => {
									collectionData = reports.filter(reports2 => reports2.client_id === client_id);
									if (searchString !== null) {
										collectionData = collectionData.filter(reports2 => reports2.report_nr === searchString);
									}

									if (endIndex !== -1) {
										resultsData = collectionData.slice(startIndex, endIndex);
									} else {
										resultsData = collectionData.slice();
									}

									resolve({
										recordsTotal: collectionData.length,
										data: resultsData
									});
								}).catch(error => {
									reject();
									throw new Error(error);
								});
								resolve(undefined);
							}
						},
						() => {
							this.syncReports().then((reports: Reports[]) => {
								collectionData = reports.filter(reports2 => reports2.client_id === client_id);
								if (searchString !== null) {
									collectionData = collectionData.filter(reports2 => reports2.report_nr === searchString);
								}

								if (endIndex !== -1) {
									resultsData = collectionData.slice(startIndex, endIndex);
								} else {
									resultsData = collectionData.slice();
								}

								resolve({
									recordsTotal: collectionData.length,
									data: resultsData
								});
							}).catch(error => {
								reject();
								throw new Error(error);
							});
							resolve(undefined);
						}
					);
				}
			});
		});
	}

	getInspectors() {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableInspectors).then(
						inspectorsDB => {
							if (typeof inspectorsDB !== 'undefined') {
								inspectorsDB.sort((a, b) => a.firstname.toLowerCase() > b.firstname.toLowerCase() ? 1 : -1);
								resolve({
									recordsTotal: inspectorsDB.length,
									data: inspectorsDB
								});
							} else {
								this.syncInspectors().then((inspectors: User[]) => {
									if (typeof inspectors !== 'undefined') {
										resolve({
											recordsTotal: inspectors.length,
											data: inspectors
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncInspectors().then((inspectors: User[]) => {
								if (typeof inspectors !== 'undefined') {
									resolve({
										recordsTotal: inspectors.length,
										data: inspectors
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getBuildingParticulars() {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingParticulars).then(
						buildingParticularsDB => {
							if (typeof buildingParticularsDB !== 'undefined') {
								let collection = buildingParticularsDB.filter(particular => particular.active === true);
								collection.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
								resolve({
									recordsTotal: collection.length,
									data: collection
								});
							} else {
								this.syncBuildingParticulars().then((buildingParticulars: ReportBuildingParticulars[]) => {
									if (typeof buildingParticulars !== 'undefined') {
										let collection = buildingParticulars.filter(particular => particular.active === true);
										resolve({
											recordsTotal: collection.length,
											data: collection
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncInspectors().then((buildingParticulars: ReportBuildingParticulars[]) => {
								if (typeof buildingParticulars !== 'undefined') {
									let collection = buildingParticulars.filter(particular => particular.active === true);
									resolve({
										recordsTotal: collection.length,
										data: collection
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getShortcomingCategories(report_type: string, tent_star_rating: number = null) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getAllFast((
						report_type === 'warehouse' ? this.indexedDBService.tableReportsWarehouseCategories : (
							report_type === 'ladders' ? this.indexedDBService.tableReportsLadderCategories :
								this.indexedDBService.tableReportsTentCategories
						)
					)).then(
						categoriesDB => {
							if (typeof categoriesDB !== 'undefined') {
								let collection = categoriesDB.filter(category => category.active === true)
									.filter(category => tent_star_rating === null ? true : category.star_rating === tent_star_rating);
								collection.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
								resolve({
									recordsTotal: collection.length,
									data: collection
								});
							} else {
								this.syncCategories(report_type).then((categories: ReportsShortcomingsCategories[]) => {
									if (typeof categories !== 'undefined') {
										let collection = categories.filter(category => category.active === true);
										resolve({
											recordsTotal: collection.length,
											data: collection
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncCategories(report_type).then((categories: ReportsShortcomingsCategories[]) => {
								if (typeof categories !== 'undefined') {
									let collection = categories.filter(category => category.active === true);
									resolve({
										recordsTotal: collection.length,
										data: collection
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getShortcomingSubcategories(report_type: string, category_id: number) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let collection: ReportsShortcomingsSubcategories[] = [];
					this.indexedDBService.database.getAllFast(
						report_type === 'warehouse' ? this.indexedDBService.tableReportsWarehouseSubcategories :
							report_type === 'ladders' ? this.indexedDBService.tableReportsLadderSubcategories :
								this.indexedDBService.tableReportsTentSubcategories
					).then(
						subcategoriesDB => {
							if (typeof subcategoriesDB !== 'undefined') {
								collection = subcategoriesDB.filter(subcategory => subcategory.category_id === category_id && subcategory.active === true);
								collection.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
								resolve({
									recordsTotal: collection.length,
									data: collection
								});
							} else {
								this.syncSubcategories(report_type).then((subcategories: ReportsShortcomingsSubcategories[]) => {
									if (typeof subcategories !== 'undefined') {
										collection = subcategories.filter(subcategory => subcategory.category_id === category_id && subcategory.active === true);
										resolve({
											recordsTotal: collection.length,
											data: collection
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncSubcategories(report_type).then((subcategories: ReportsShortcomingsSubcategories[]) => {
								if (typeof subcategories !== 'undefined') {
									collection = subcategories.filter(subcategory => subcategory.category_id === category_id && subcategory.active === true);
									resolve({
										recordsTotal: collection.length,
										data: collection
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getShortcomingTypes(report_type: string, category_id: number, subcategory_id: number) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let collection: ReportsShortcomingsTypes[] = [];
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsWarehouseTypes).then(
						typesDB => {
							if (typeof typesDB !== 'undefined') {
								collection = typesDB.filter(type => type.category_id === category_id && type.subcategory_id === subcategory_id && type.active === true);
								collection.sort((a, b) => a.sequence > b.sequence ? 1 : -1);

								resolve({
									recordsTotal: collection.length,
									data: collection
								});
							} else {
								this.syncTypes(report_type).then((types: ReportsShortcomingsTypes[]) => {
									if (typeof types !== 'undefined') {
										collection = types.filter(type => type.category_id === category_id && type.subcategory_id === subcategory_id && type.active === true);
										resolve({
											recordsTotal: collection.length,
											data: collection
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncTypes(report_type).then((types: ReportsShortcomingsTypes[]) => {
								if (typeof types !== 'undefined') {
									collection = types.filter(type => type.category_id === category_id && type.subcategory_id === subcategory_id && type.active === true);
									resolve({
										recordsTotal: collection.length,
										data: collection
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getShortcomingActions(report_type: string, category_id: number, subcategory_id: number) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let collection: ReportsShortcomingsActions[] = [];
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsWarehouseActions).then(
						actionsDB => {
							if (typeof actionsDB !== 'undefined') {
								collection = actionsDB.filter(action => action.category_id === category_id && action.subcategory_id === subcategory_id && action.active === true);
								collection.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
								resolve({
									recordsTotal: collection.length,
									data: collection
								});
							} else {
								this.syncActions(report_type).then((actions: ReportsShortcomingsActions[]) => {
									if (typeof actions !== 'undefined') {
										collection = actions.filter(action => action.category_id === category_id && action.subcategory_id === subcategory_id && action.active === true);
										resolve({
											recordsTotal: collection.length,
											data: collection
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncActions(report_type).then((actions: ReportsShortcomingsActions[]) => {
								if (typeof actions !== 'undefined') {
									collection = actions.filter(action => action.category_id === category_id && action.subcategory_id === subcategory_id && action.active === true);
									resolve({
										recordsTotal: collection.length,
										data: collection
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getShortcomingMaterials(report_type: string) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsLadderMaterials).then(
						materialsDB => {
							if (typeof materialsDB !== 'undefined') {
								let collection = materialsDB.filter(material => material.active === true);
								collection.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
								resolve({
									recordsTotal: collection.length,
									data: collection
								});
							} else {
								this.syncMaterials(report_type).then((materials: ReportsShortcomingsMaterials[]) => {
									if (typeof materials !== 'undefined') {
										let collection = materials.filter(material => material.active === true);
										resolve({
											recordsTotal: collection.length,
											data: collection
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncMaterials(report_type).then((materials: ReportsShortcomingsMaterials[]) => {
								if (typeof materials !== 'undefined') {
									let collection = materials.filter(material => material.active === true);
									resolve({
										recordsTotal: collection.length,
										data: collection
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getShortcomingQuestionnaires(report_type: string, category_id: number, subcategory_id: number) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let collection: ReportsBuildingsShortcomingsQuestionnaire[] = [];
					this.indexedDBService.database.getAllFast(report_type === 'ladders' ? this.indexedDBService.tableReportsLadderQuestionnaire : this.indexedDBService.tableReportsTentQuestionnaire).then(
						questionnairesDB => {
							if (typeof questionnairesDB !== 'undefined') {
								collection = questionnairesDB.filter(collection2 => collection2.category_id === category_id && collection2.subcategory_id === subcategory_id);
								collection.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
								resolve({
									recordsTotal: collection.length,
									data: collection
								});
							} else {
								this.syncShortcomingQuestionnaires(report_type).then((questionnaires: ReportsBuildingsShortcomingsQuestionnaire[]) => {
									if (typeof questionnaires !== 'undefined') {
										collection = questionnaires.filter(questionnaire => questionnaire.category_id === category_id && questionnaire.subcategory_id === subcategory_id);
										resolve({
											recordsTotal: collection.length,
											data: collection
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncShortcomingQuestionnaires(report_type).then((questionnaires: ReportsBuildingsShortcomingsQuestionnaire[]) => {
								if (typeof questionnaires !== 'undefined') {
									collection = questionnaires.filter(questionnaire => questionnaire.category_id === category_id && questionnaire.subcategory_id === subcategory_id);
									resolve({
										recordsTotal: collection.length,
										data: collection
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getShortcomingQuestionnaireAnswer(report_type: string, report_id: number, building_report_id: number) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let collection: ReportsBuildingsShortcomingsQuestionnaireAnswers[] = [];
					this.indexedDBService.database.getByIndex((report_type === 'ladders' ? this.indexedDBService.tableReportsLadderQuestionnaireAnswers : this.indexedDBService.tableReportsTentQuestionnaireAnswers), 'building_report_id', building_report_id).then(
						answersDB => {
							if (typeof answersDB !== 'undefined') {
								resolve(answersDB);
							} else {
								this.syncShortcomingQuestionnaireAnswers(report_type).then((answers: ReportsBuildingsShortcomingsQuestionnaireAnswers[]) => {
									if (typeof answers !== 'undefined') {
										collection = answers.filter(answer => answer.report_id === report_id && answer.building_report_id === building_report_id);
										if (collection.length) {
											resolve(collection[0]);
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncShortcomingQuestionnaireAnswers(report_type).then((answers: ReportsBuildingsShortcomingsQuestionnaireAnswers[]) => {
								if (typeof answers !== 'undefined') {
									collection = answers.filter(answer => answer.report_id === report_id && answer.building_report_id === building_report_id);
									if (collection.length) {
										resolve(collection[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getWarehouseQuestionnaires(report_type: string) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsWarehouseQuestionnaire).then(
						questionnairesDB => {
							if (typeof questionnairesDB !== 'undefined') {
								questionnairesDB.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
								resolve({
									recordsTotal: questionnairesDB.length,
									data: questionnairesDB
								});
							} else {
								this.syncWarehouseQuestionnaires(report_type).then((questionnaires: ReportsQuestions[]) => {
									if (typeof questionnaires !== 'undefined') {
										resolve({
											recordsTotal: questionnaires.length,
											data: questionnaires
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncWarehouseQuestionnaires(report_type).then((questionnaires: ReportsQuestions[]) => {
								if (typeof questionnaires !== 'undefined') {
									resolve({
										recordsTotal: questionnaires.length,
										data: questionnaires
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getWarehouseQuestionnaireAnswer(report_type: string, report_id: number) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let collection: ReportsQuestionsAnswers[] = [];
					this.indexedDBService.database.getByKey(this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers, report_id).then(
						answerDB => {
							if (typeof answerDB !== 'undefined') {
								resolve(answerDB);
							} else {
								this.syncWarehouseQuestionnaireAnswers(report_type).then((answers: ReportsQuestionsAnswers[]) => {
									if (typeof answers !== 'undefined') {
										collection = answers.filter(answer => answer.report_id === report_id);
										if (collection.length) {
											resolve(collection[0]);
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncWarehouseQuestionnaireAnswers(report_type).then((answers: ReportsQuestionsAnswers[]) => {
								if (typeof answers !== 'undefined') {
									collection = answers.filter(answer => answer.report_id === report_id);
									if (collection.length) {
										resolve(collection[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	saveReportQuestionnaire(report_type: string, report_id: number, formData = null) {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_type + '/' + report_id + '/questionnaire', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Inspectischema opgeslagen.');
					formData['report_id'] = report_id;
					this.indexedDBService.database.update(this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers, formData).then(() => {
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
					this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			formData['report_type'] = report_type;
			formData['report_id'] = report_id;
			formData['indexedDBMethod'] = '2-update';
			this.indexedDBService.database.update(this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers, formData).then(() => {
				this.snackbarService.success('Inspectieschema opgeslagen.');
				this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(() => {
				});
			}, error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		}
	}

	getClosedReports(report_type: string = 'warehouse', report_id: number = null, formData = null) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/closed' + (report_id !== null ? '/' + report_id : ''), formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								resolve(<ServerResponse>data);
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getClosedReportAttachments(report_type: string = 'warehouse', report_id: number, attachment_id: number = null, formData = null) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/' + report_id + '/attachments' + (attachment_id !== null ? '/' + attachment_id : ''), formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								resolve(<ServerResponse>data);
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getClosedReportComments(report_type: string = 'warehouse', report_id: number, comment_id: number = null, formData = null) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/' + report_id + '/comments' + (comment_id !== null ? '/' + comment_id : ''), formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								resolve(<ServerResponse>data);
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getClosedReportBuildings(report_type: string = 'warehouse', report_id: number, building_id: number = null, formData = null) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/' + report_id + '/buildings' + (building_id !== null ? '/' + building_id : ''), formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								resolve(<ServerResponse>data);
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getClosedReportBuildingReports(report_type: string = 'warehouse', report_id: number, building_id: number, building_report_id: number = null, formData = null) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports' + (building_report_id !== null ? '/' + building_report_id : ''), formData).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								resolve(<ServerResponse>data);
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getClosedReportBuildingReport(report_type: string = 'warehouse', report_id: number, building_id: number, building_report_id: number) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports' + '/' + building_report_id + '/view').then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									resolve(<ReportsBuildingsReports>data.data);
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getClosedWarehouseQuestionnaireAnswer(report_type: string, report_id: number) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/' + report_id + '/questionnaire', {
					start: 0,
					length: -1
				}).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								resolve(<ServerResponse>data);
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getReports(report_type: string = 'warehouse', report_status: string = 'open', formData) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let results: Reports[] = [],
						collection: Reports[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReports).then(
						reportsDB => {
							if (typeof reportsDB !== 'undefined') {
								collection = reportsDB.filter(reports => reports.type === report_type && reports.status === report_status && (typeof reports.indexedDBMethod === 'undefined' || typeof reports.indexedDBMethod !== 'undefined' && reports.indexedDBMethod !== '3-delete'));
								collection.sort((a, b) => a.created > b.created ? -1 : 1);

								if (searchString !== null) {
									collection = collection.filter(collection2 => collection2.report_nr.toLowerCase().indexOf(searchString) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString) !== -1);
								}

								if (endIndex !== -1) {
									results = collection.slice(startIndex, endIndex);
								} else {
									results = collection.slice();
								}
								resolve({
									recordsTotal: collection.length,
									data: results
								});
							} else {
								this.syncReports().then((reports: Reports[]) => {
									if (typeof reports !== 'undefined') {
										collection = reports.filter(reports2 => reports2.type === report_type && reports2.status === report_status);

										if (searchString !== null) {
											collection = collection.filter(collection2 => collection2.report_nr.toLowerCase().indexOf(searchString) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString) !== -1);
										}

										if (endIndex !== -1) {
											results = collection.slice(startIndex, endIndex);
										} else {
											results = collection.slice();
										}
										resolve({
											recordsTotal: collection.length,
											data: results
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncReports().then((reports: Reports[]) => {
								if (typeof reports !== 'undefined') {
									collection = reports.filter(reports2 => reports2.type === report_type && reports2.status === report_status);

									if (searchString !== null) {
										collection = collection.filter(collection2 => collection2.report_nr.toLowerCase().indexOf(searchString) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString) !== -1);
									}

									if (endIndex !== -1) {
										results = collection.slice(startIndex, endIndex);
									} else {
										results = collection.slice();
									}

									resolve({
										recordsTotal: collection.length,
										data: results
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getReinspectionReports(formData) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let results: Reports[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsReinspections).then(
						reportsDB => {
							if (typeof reportsDB !== 'undefined') {
								reportsDB.sort((a, b) => a.created > b.created ? -1 : 1);

								if (searchString !== null) {
									reportsDB = reportsDB.filter(collection2 => collection2.report_nr.toLowerCase().indexOf(searchString) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString) !== -1);
								}

								if (endIndex !== -1) {
									results = reportsDB.slice(startIndex, endIndex);
								} else {
									results = reportsDB.slice();
								}
								resolve({
									recordsTotal: reportsDB.length,
									data: results
								});
							} else {
								this.syncReinspectionReports().then((reports: Reports[]) => {
									if (typeof reports !== 'undefined') {
										if (searchString !== null) {
											reports = reports.filter(collection2 => collection2.report_nr.toLowerCase().indexOf(searchString) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString) !== -1);
										}

										if (endIndex !== -1) {
											results = reports.slice(startIndex, endIndex);
										} else {
											results = reports.slice();
										}
										resolve({
											recordsTotal: reports.length,
											data: results
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncReinspectionReports().then((reports: Reports[]) => {
								if (typeof reports !== 'undefined') {
									if (searchString !== null) {
										reports = reports.filter(collection2 => collection2.report_nr.toLowerCase().indexOf(searchString) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString) !== -1);
									}

									if (endIndex !== -1) {
										results = reports.slice(startIndex, endIndex);
									} else {
										results = reports.slice();
									}

									resolve({
										recordsTotal: reports.length,
										data: results
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getReport(report_type: string = 'warehouse', report_id: number) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let collection: Reports[] = [];
					this.indexedDBService.database.getByKey(this.indexedDBService.tableReports, report_id).then(
						reportDB => {
							if (typeof reportDB !== 'undefined') {
								resolve(reportDB);
							} else {
								this.syncReports().then((reports: Reports[]) => {
									if (typeof reports !== 'undefined') {
										collection = reports.filter(reports2 => reports2.type === report_type && reports2.id === report_id && (typeof reports2.indexedDBMethod === 'undefined' || typeof reports2.indexedDBMethod !== 'undefined' && reports2.indexedDBMethod !== '3-delete'));

										if (collection.length) {
											resolve(collection[0]);
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncReports().then((reports: Reports[]) => {
								if (typeof reports !== 'undefined') {
									collection = reports.filter(reports2 => reports2.type === report_type && reports2.id === report_id && (typeof reports2.indexedDBMethod === 'undefined' || typeof reports2.indexedDBMethod !== 'undefined' && reports2.indexedDBMethod !== '3-delete'));
									if (collection.length) {
										resolve(collection[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	createReport(report_type: string, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_type, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Rapportage opgeslagen!');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableReports, <Reports>data.data).then(() => {
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});

						this.router.navigate(['/reports/' + report_type + '/' + data.data.id + '/view']).then(() => {
						});
					} else {
						this.router.navigate(['/reports/' + report_type + '/open']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			let _authenticationService = this.authenticationService.user.first().subscribe(user => {
				formData['id'] = moment().valueOf();
				formData['created'] = moment().valueOf();
				formData['status'] = 'open';
				formData['status_text'] = 'Openstaand';
				formData['type'] = report_type;
				formData['type_text'] = report_type === 'warehouse' ? 'Magazijn' : 'Trappen';
				formData['edit_lock'] = true;
				formData['edit_lock_user_id'] = user.id;
				delete formData['account_user_id_filterString'];
				delete formData['client_filterString'];

				this.indexedDBService.database.add(this.indexedDBService.tableReports, formData).then(() => {
					this.snackbarService.success('Rapportage opgeslagen!');

					formData['indexedDBMethod'] = '1-create';
					this.indexedDBService.database.add(this.indexedDBService.tableReportsPush, formData).then(() => {
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});

					this.router.navigate(['/reports/' + report_type + '/' + formData['id'] + '/view']).then(() => {
					});
				}, error => {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
					throw new Error(error);
				});
			});
			_authenticationService.unsubscribe();
		}
	}

	updateReport(report_type: string, report_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_type + '/' + report_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Rapportage opgeslagen!');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableReports, <Reports>data.data).then(() => {
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});

						this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(() => {
						});
					} else {
						this.router.navigate(['/reports/' + report_type + '/open']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableReports, 'id', report_id).then(report => {
				delete formData['account_user_id_filterString'];
				delete formData['client_filterString'];
				let newReport: Reports = {...report, ...formData};
				newReport['updated'] = moment().valueOf();
				this.indexedDBService.database.update(this.indexedDBService.tableReports, newReport).then(() => {
					this.snackbarService.success('Rapportage opgeslagen!');

					newReport['indexedDBMethod'] = '2-update';
					this.indexedDBService.database.add(this.indexedDBService.tableReportsPush, newReport).then(() => {
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});

					this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(() => {
					});
				}, error => {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
					throw new Error(error);
				});
			}, error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		}
	}

	deleteReport(report_type: string, report_id: number): Promise<boolean> {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Weet u zeker dat u deze rapportage wilt verwijderen?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('reports/' + report_type + '/' + report_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.indexedDBService.database.delete(this.indexedDBService.tableReports, report_id).then(() => {
								}, error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});

								this.snackbarService.success('Rapportage verwijderd.');
								resolve(true);
							} else {
								this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
								resolve(false);
							}
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableReports, report_id).then(() => {
							this.snackbarService.success('Rapportage verwijderd.');

							this.indexedDBService.database.add(this.indexedDBService.tableReportsPush, {
								id: report_id,
								indexedDBMethod: '3-delete',
								type: report_type
							}).then(() => {
							}, error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});

							this.cleanupAfterReportDeletion([report_id]);
							resolve(true);
						}, error => {
							this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
							resolve(false);
							throw new Error(error);
						});
					}
				}
			});
		});
	}

	sendToClient(report_type: string, report_id: number, formData) {
		if ((typeof formData['contact_persons_ids'] === 'undefined' || typeof formData['contact_persons_ids'] !== 'undefined' && formData['contact_persons_ids'].some(elem => elem !== false)) || formData['custom_contacts'] !== '') {
			if (this.apiService.isOnline) {
				this.apiService.post('reports/' + report_type + '/' + report_id + '/send-to-client', formData).then((data: ServerResponse) => {
					if (data.success === true) {
						this.indexedDBService.database.getByIndex(this.indexedDBService.tableReports, 'id', report_id).then(report => {
							report['status'] = 'verify';
							report['status_text'] = 'Controle';
							this.indexedDBService.database.update(this.indexedDBService.tableReports, report).then(() => {
								this.snackbarService.success('Rapportage verstuurd!');
								this.indexedDBService.database.getByKey(this.indexedDBService.tableReports, report_id).then(report2 => {
									report2.inspection_duration = ((formData['inspection_duration_day'] > 0 ? (formData['inspection_duration_day'] * 8) : 0) + formData['inspection_duration_hour']) + ':' + formData['inspection_duration_minute'] + ':00';
									this.indexedDBService.database.update(this.indexedDBService.tableReports, report2);
								}, error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
								this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(() => {
								});
							}, error => {
								this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
						});
					} else if (typeof data.error !== 'undefined') {
						this.errors.next(data.error);
					} else {
						this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
					}
				});
			} else {
				this.indexedDBService.database.getByIndex(this.indexedDBService.tableReports, 'id', report_id).then(report => {
					report['status'] = 'verify';
					report['status_text'] = 'Controle';
					this.indexedDBService.database.update(this.indexedDBService.tableReports, report).then(() => {
						formData['report_type'] = report_type;
						formData['report_id'] = report_id;
						this.indexedDBService.database.add(this.indexedDBService.tableReportsSendToClient, formData).then(() => {
							this.snackbarService.success('Rapportage wordt verstuurd indien er weer een internet verbinding is.');
							this.indexedDBService.database.getByKey(this.indexedDBService.tableReports, report_id).then(report2 => {
								report2.inspection_duration = ((formData['inspection_duration_day'] > 0 ? (formData['inspection_duration_day'] * 8) : 0) + formData['inspection_duration_hour']) + ':' + formData['inspection_duration_minute'] + ':00';
								this.indexedDBService.database.update(this.indexedDBService.tableReports, report2);
							}, error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
							this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(() => {
							});
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
					}, error => {
						this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				});
			}
		} else {
			this.snackbarService.warning('Selecteer minimaal één contact persoon of voeg een email adres in handmatig');
		}
	}

	getAttachments(report_type: string, report_id: number, formData) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsAttachments[] = [],
						collectionData: ReportsAttachments[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.getReport(report_type, report_id).then((report: Reports) => {
						if (typeof report !== 'undefined') {
							let promises = [];

							promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableClientsAttachments).then(
								clientsAttachmentsDB => {
									collectionData = clientsAttachmentsDB.filter(attachments => attachments.client_id === report.client_id && (attachments.append_ladder === true && report_type === 'ladder' || attachments.append_warehouse === true && report_type === 'warehouse' || attachments.append_tent === true && report_type === 'tent'));

									return collectionData;
								}
							));

							promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsAttachments).then(
								attachmentsDB => {
									if (typeof attachmentsDB !== 'undefined') {
										collectionData = attachmentsDB.filter(attachments => attachments.report_id === report_id && (typeof attachments.indexedDBMethod === 'undefined' || typeof attachments.indexedDBMethod !== 'undefined' && attachments.indexedDBMethod !== '3-delete'));

										return collectionData;
									} else {
										this.syncReportsAttachments().then((attachments: ReportsAttachments[]) => {
											if (typeof attachments !== 'undefined') {
												collectionData = attachments.filter(attachments2 => attachments2.report_id === report_id);

												return collectionData;
											} else {
												resolve(undefined);
											}
										}).catch(error => {
											reject();
											throw new Error(error);
										});
									}
								},
								() => {
									this.syncReportsAttachments().then((attachments: ReportsAttachments[]) => {
										if (typeof attachments !== 'undefined') {
											collectionData = attachments.filter(attachments2 => attachments2.report_id === report_id);

											return collectionData;
										} else {
											resolve(undefined);
										}
									}).catch(error => {
										reject();
										throw new Error(error);
									});
								}
							));

							Promise.all(promises).then(
								data => {
									let collectionData2 = [];
									data.forEach(d => collectionData2.push(...d));

									collectionData2.sort((a, b) => a.created > b.created ? -1 : 1);

									if (searchString !== null) {
										collectionData2 = collectionData2.filter(attachments => attachments.title.toLowerCase().indexOf(searchString) !== -1);
									}

									if (endIndex !== -1) {
										resultsData = collectionData2.slice(startIndex, endIndex);
									} else {
										resultsData = collectionData2.slice();
									}

									resolve({
										recordsTotal: collectionData2.length,
										data: resultsData
									});
								}
							);
						}
					});
				}
			});
		});
	}

	createAttachment(report_type: string, report_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_type + '/' + report_id + '/attachments', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Bijlage opgeslagen.');

					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableReportsAttachments, <ReportsAttachments>data.data).then(() => {
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
					}

					this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			let _authenticationService = this.authenticationService.user.first().subscribe(user => {
				formData['id'] = moment().valueOf();
				formData['indexedDBMethod'] = '1-create';
				formData['report_id'] = report_id;
				formData['report_type'] = report_type;
				formData['created'] = moment().valueOf();

				let newFormData = {
					id: formData.id,
					report_id: report_id,
					filename: formData.filename._files[0].name,
					original_filename: formData.filename._files[0].name,
					title: formData['title'],
					description: formData['description'],
					inspector: user.display_name,
					created: formData['created']
				};

				this.indexedDBService.database.add(this.indexedDBService.tableReportsAttachments, newFormData).then(() => {
					this.snackbarService.success('Bijlage opgeslagen.');

					this.indexedDBService.database.add(this.indexedDBService.tableReportsAttachmentsPush, <ReportsAttachments>formData);

					this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(() => {
					});
				}, error => {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
					throw new Error(error);
				});
			});
			_authenticationService.unsubscribe();
		}
	}

	deleteAttachment(report_type: string, report_id: number, attachment_id: number) {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Weet u zeker dat u deze bijlage wilt verwijderen?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('reports/' + report_type + '/' + report_id + '/attachments/' + attachment_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Bijlage verwijderd.');

								this.indexedDBService.database.delete(this.indexedDBService.tableReportsAttachments, attachment_id).then(
									() => {
									}, error => {
										if (this.debug) {
											console.log(error);
										}
										throw new Error(error);
									}
								);

								resolve(true);
							} else {
								this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
								resolve(false);
							}
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableReportsAttachments, attachment_id).then(() => {
							this.snackbarService.success('Bijlage verwijderd.');

							let formData = {
								'report_type': report_type,
								'report_id': report_id,
								'id': attachment_id,
								'indexedDBMethod': '3-delete'
							};
							this.indexedDBService.database.add(this.indexedDBService.tableReportsAttachmentsPush, formData).then(
								() => {
								}, error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								}
							);

						}, error => {
							this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
							throw new Error(error);
						});
					}
				}
			});
		});
	}

	getComments(report_type: string, report_id: number, formData) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsComments[] = [],
						collectionData: ReportsComments[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsComments).then(
						commentsDB => {
							if (typeof commentsDB !== 'undefined') {
								collectionData = commentsDB.filter(comments => comments.report_id === report_id && (typeof comments.indexedDBMethod === 'undefined' || typeof comments.indexedDBMethod !== 'undefined' && comments.indexedDBMethod !== '3-delete'));
								collectionData.sort((a, b) => a.created > b.created ? 1 : -1);

								if (searchString !== null) {
									collectionData = collectionData.filter(comments => comments.inspector.toLowerCase().indexOf(searchString) !== -1);
								}

								if (endIndex !== -1) {
									resultsData = collectionData.slice(startIndex, endIndex);
								} else {
									resultsData = collectionData.slice();
								}

								resolve({
									recordsTotal: collectionData.length,
									data: resultsData
								});
							} else {
								this.syncReportsComments().then((comments: ReportsComments[]) => {
									if (typeof comments !== 'undefined') {
										collectionData = comments.filter(comments2 => comments2.report_id === report_id);

										if (searchString !== null) {
											collectionData = collectionData.filter(comments2 => comments2.inspector.toLowerCase().indexOf(searchString) !== -1);
										}

										if (endIndex !== -1) {
											resultsData = collectionData.slice(startIndex, endIndex);
										} else {
											resultsData = collectionData.slice();
										}

										resolve({
											recordsTotal: collectionData.length,
											data: resultsData
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncReportsComments().then((comments: ReportsComments[]) => {
								if (typeof comments !== 'undefined') {
									collectionData = comments.filter(comments2 => comments2.report_id === report_id);

									if (searchString !== null) {
										collectionData = collectionData.filter(comments2 => comments2.inspector.toLowerCase().indexOf(searchString) !== -1);
									}

									if (endIndex !== -1) {
										resultsData = collectionData.slice(startIndex, endIndex);
									} else {
										resultsData = collectionData.slice();
									}

									resolve({
										recordsTotal: collectionData.length,
										data: resultsData
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getComment(report_type: string, report_id: number, comment_id: number) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsComments[] = [];

					this.indexedDBService.database.getByKey(this.indexedDBService.tableReportsComments, comment_id).then(
						commentDB => {
							if (typeof commentDB !== 'undefined') {
								resolve(commentDB);
							} else {
								this.syncReportsComments().then((comments: ReportsComments[]) => {
									if (typeof comments !== 'undefined') {
										resultsData = comments.filter(comments2 => comments2.report_id === report_id && comments2.id === comment_id);
										if (resultsData.length) {
											resolve(resultsData[0]);
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncReportsComments().then((comments: ReportsComments[]) => {
								if (typeof comments !== 'undefined') {
									resultsData = comments.filter(comments2 => comments2.report_id === report_id && comments2.id === comment_id);
									if (resultsData.length) {
										resolve(resultsData[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	createComment(report_type: string, report_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_type + '/' + report_id + '/comments', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Opmerkingen opgeslagen.');

					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableReportsComments, <ReportsComments>data.data).then(() => {
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
					}

					this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			let _authenticationService = this.authenticationService.user.first().subscribe(user => {
				formData['id'] = moment().valueOf();
				formData['report_id'] = report_id;
				formData['report_type'] = report_type;
				formData['account_user_id'] = user.id;
				formData['inspector'] = user.display_name;
				formData['created'] = moment().valueOf();

				this.indexedDBService.database.add(this.indexedDBService.tableReportsComments, <ReportsComments>formData).then(() => {
					this.snackbarService.success('Opmerkingen opgeslagen.');

					formData['indexedDBMethod'] = '1-create';
					this.indexedDBService.database.add(this.indexedDBService.tableReportsCommentsPush, formData).then(() => {
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});

					this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(() => {
					});
				}, error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			});
			_authenticationService.unsubscribe();
		}
	}

	updateComment(report_type: string, report_id: number, comment_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_type + '/' + report_id + '/comments/' + comment_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Opmerkingen opgeslagen.');

					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableReportsComments, <ReportsComments>data.data).then(() => {
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
					}

					this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsComments, 'id', comment_id).then(comment => {
				comment['comment'] = formData['comment'];
				comment['report_type'] = report_type;
				this.indexedDBService.database.update(this.indexedDBService.tableReportsComments, <ReportsComments>comment).then(() => {
					this.snackbarService.success('Opmerkingen opgeslagen.');

					comment['indexedDBMethod'] = '2-update';
					this.indexedDBService.database.add(this.indexedDBService.tableReportsCommentsPush, comment).then(() => {
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});

					this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(() => {
					});
				}, error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			}, error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		}
	}

	getBuildings(report_type: string, report_id: number, formData) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsBuildings[] = [],
						collectionData: ReportsBuildings[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							if (endIndex !== -1) {
								endIndex += startIndex;
							}
						}
						if (typeof formData['columns[3][search][value]'] !== 'undefined') {
							if (formData['columns[3][search][value]'] !== null && formData['columns[3][search][value]'] !== '') {
								searchString = formData['columns[3][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildings).then(
						reportBuildingsDB => {
							if (typeof reportBuildingsDB !== 'undefined') {
								collectionData = reportBuildingsDB.filter(collection2 => collection2.report_id === report_id);
								collectionData.sort((a, b) => a.sequence > b.sequence ? -1 : 1);

								if (searchString !== null) {
									collectionData = collectionData.filter(collection2 => collection2.name.toLowerCase().indexOf(searchString) !== -1);
								}

								if (endIndex !== -1) {
									resultsData = collectionData.slice(startIndex, endIndex);
								} else {
									resultsData = collectionData.slice();
								}

								resolve({
									recordsTotal: collectionData.length,
									data: resultsData
								});
							} else {
								this.syncReportsBuildings().then((reportsBuildings: ReportsBuildings[]) => {
									if (typeof reportsBuildings !== 'undefined') {
										collectionData = reportsBuildings.filter(reportsBuilding => reportsBuilding.report_id === report_id);

										if (searchString !== null) {
											collectionData = collectionData.filter(reportsBuilding => reportsBuilding.name.toLowerCase().indexOf(searchString) !== -1);
										}

										if (endIndex !== -1) {
											resultsData = collectionData.slice(startIndex, endIndex);
										} else {
											resultsData = collectionData.slice();
										}

										resolve({
											recordsTotal: collectionData.length,
											data: resultsData
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncReportsBuildings().then((reportsBuildings: ReportsBuildings[]) => {
								if (typeof reportsBuildings !== 'undefined') {
									collectionData = reportsBuildings.filter(reportsBuilding => reportsBuilding.report_id === report_id);

									if (searchString !== null) {
										collectionData = collectionData.filter(reportsBuilding => reportsBuilding.name.toLowerCase().indexOf(searchString) !== -1);
									}
									resolve({
										recordsTotal: collectionData.length,
										data: resultsData
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getBuilding(report_type: string, report_id: number, building_id: number) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsBuildings[] = [];

					this.indexedDBService.database.getByKey(this.indexedDBService.tableReportsBuildings, building_id).then(
						reportBuildingDB => {
							if (typeof reportBuildingDB !== 'undefined') {
								resolve(reportBuildingDB);
							} else {
								this.syncReportsBuildings().then((reportBuilding: ReportsBuildings[]) => {
									if (typeof reportBuilding !== 'undefined') {
										resultsData = reportBuilding.filter(collection3 => collection3.report_id === report_id && collection3.id === building_id);

										if (resultsData.length) {
											resolve(resultsData[0]);
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncReportsBuildings().then((reportBuilding: ReportsBuildings[]) => {
								if (typeof reportBuilding !== 'undefined') {
									resultsData = reportBuilding.filter(collection3 => collection3.report_id === report_id && collection3.id === building_id);

									if (resultsData.length) {
										resolve(resultsData[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	createBuilding(report_type: string, report_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_type + '/' + report_id + '/buildings', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Gebouw opgeslagen.');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildings, <ReportsBuildings>data.data).then(() => {
							if (typeof data.data.total_squares !== 'undefined') {
								this.indexedDBService.database.getByKey(this.indexedDBService.tableReports, data.data.report_id).then(report => {
									report.total_squares = data.data.total_squares;
									this.indexedDBService.database.update(this.indexedDBService.tableReports, report);
								}, error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							}
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});

						this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + data.data.id + '/reports']).then(() => {
						});
					} else {
						this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			let _authenticationService = this.authenticationService.user.first().subscribe(user => {
				formData['id'] = moment().valueOf();
				formData['report_id'] = report_id;
				formData['report_type'] = report_type;
				formData['edit_lock'] = true;
				formData['edit_lock_user_id'] = user.id;
				formData['created'] = moment().valueOf();
				formData['sequence'] = 1;
				formData['lib_particular_id'] = [];
				formData['particular_description'] = {};
				Object.keys(formData).map(key => {
					if (key.indexOf('lib_particular_id[') !== -1) {
						if (formData[key] !== false && formData[key] !== '') {
							formData['lib_particular_id'].push(parseInt(key.replace('lib_particular_id[', '').replace(']', ''), 10));
						}
						delete formData[key];
					}
					if (key.indexOf('particular_description[') !== -1) {
						if (formData[key] !== false && formData[key] !== '') {
							formData['particular_description'][parseInt(key.replace('particular_description[', '').replace(']', ''), 10)] = formData[key];
						}
						delete formData[key];
					}
				});
				delete formData['query'];
				this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildings).then(buildings => {
					let total_squares = 0;
					if (typeof buildings !== 'undefined') {
						buildings.forEach(building => {
							if (building.report_id === report_id) {
								if (typeof building.sequence !== 'undefined') {
									if (formData['sequence'] <= parseInt(building.sequence, 10)) {
										formData['sequence'] = parseInt(building.sequence, 10) + 1;
									}
								}
								if (typeof building.squares !== 'undefined') {
									total_squares += building.squares;
								}
							}
						});
					}
					this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildings, formData).then(() => {
						this.snackbarService.success('Gebouw opgeslagen.');

						formData['indexedDBMethod'] = '1-create';
						this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsPush, formData).then(() => {
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});

						this.indexedDBService.database.getByKey(this.indexedDBService.tableReports, report_id).then(report => {
							report.total_squares = total_squares;
							this.indexedDBService.database.update(this.indexedDBService.tableReports, report);
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});

						this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + formData.id + '/reports']).then();
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}, error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			});
			_authenticationService.unsubscribe();
		}
	}

	updateBuilding(report_type: string, report_id: number, building_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_type + '/' + report_id + '/buildings/' + building_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Gebouw opgeslagen.');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildings, <ReportsBuildings>data.data).then(() => {
							if (typeof data.data.total_squares !== 'undefined') {
								this.indexedDBService.database.getByKey(this.indexedDBService.tableReports, data.data.report_id).then(report => {
									report.total_squares = data.data.total_squares;
									this.indexedDBService.database.update(this.indexedDBService.tableReports, report);
								}, error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							}
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
						this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports']).then(() => {
						});
					} else {
						this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsBuildings, 'id', building_id).then(building => {
				formData['lib_particular_id'] = [];
				formData['particular_description'] = {};
				Object.keys(formData).map(key => {
					if (key.indexOf('lib_particular_id[') !== -1) {
						if (formData[key] !== false && formData[key] !== '') {
							formData['lib_particular_id'].push(parseInt(key.replace('lib_particular_id[', '').replace(']', ''), 10));
						}
						delete formData[key];
					}
					if (key.indexOf('particular_description[') !== -1) {
						if (formData[key] !== false && formData[key] !== '') {
							formData['particular_description'][parseInt(key.replace('particular_description[', '').replace(']', ''), 10)] = formData[key];
						}
						delete formData[key];
					}
				});
				delete formData['query'];
				let newBuilding = {...building, ...formData};
				newBuilding['updated'] = moment().valueOf();
				this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildings, newBuilding).then(() => {
					this.snackbarService.success('Gebouw opgeslagen.');

					newBuilding['indexedDBMethod'] = '2-update';
					newBuilding['report_type'] = report_type;
					this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsPush, newBuilding).then(() => {
						this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildings).then(buildings => {
							let total_squares = 0;
							if (typeof buildings !== 'undefined') {
								buildings.forEach(building2 => {
									if (building2.report_id === report_id) {
										if (typeof building2.squares !== 'undefined') {
											total_squares += building2.squares;
										}
									}
								});
							}
							this.indexedDBService.database.getByKey(this.indexedDBService.tableReports, report_id).then(report => {
								report.total_squares = total_squares;
								this.indexedDBService.database.update(this.indexedDBService.tableReports, report);
							}, error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});

					this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports']).then();
				}, error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			}, error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		}
	}

	deleteBuilding(report_type: string, report_id: number, building_id: number): Promise<boolean> {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Weet u zeker dat u deze gebouw wilt verwijderen?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('reports/' + report_type + '/' + report_id + '/buildings/' + building_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Gebouw verwijderd.');
								this.indexedDBService.database.delete(this.indexedDBService.tableReportsBuildings, building_id).then(() => {
									this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildings).then(buildings => {
										let total_squares = 0;
										if (typeof buildings !== 'undefined') {
											buildings.forEach(building2 => {
												if (building2.report_id === report_id) {
													if (typeof building2.squares !== 'undefined') {
														total_squares += building2.squares;
													}
												}
											});
										}
										this.indexedDBService.database.getByKey(this.indexedDBService.tableReports, report_id).then(report => {
											report.total_squares = total_squares;
											this.indexedDBService.database.update(this.indexedDBService.tableReports, report);
										}, error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
									}, error => {
										if (this.debug) {
											console.log(error);
										}
										throw new Error(error);
									});
								}, error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
								resolve(true);
							} else {
								this.snackbarService.error('Fout bij verwijderen Gebouw.');
								resolve(false);
							}
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableReportsBuildings, building_id).then(() => {
							this.snackbarService.success('Gebouw verwijderd.');

							this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsPush, {
								id: building_id,
								report_id: report_id,
								report_type: report_type,
								indexedDBMethod: '3-delete'
							}).then(() => {
								this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildings).then(buildings => {
									let total_squares = 0;
									if (typeof buildings !== 'undefined') {
										buildings.forEach(building2 => {
											if (building2.report_id === report_id && building2.id !== building_id) {
												if (typeof building2.squares !== 'undefined') {
													total_squares += building2.squares;
												}
											}
										});
									}
									this.indexedDBService.database.getByKey(this.indexedDBService.tableReports, report_id).then(report => {
										report.total_squares = total_squares;
										this.indexedDBService.database.update(this.indexedDBService.tableReports, report);
									}, error => {
										if (this.debug) {
											console.log(error);
										}
										throw new Error(error);
									});
								}, error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							}, error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});

							this.deleteReportBuildingsReports(report_id, building_id).then(() => {
							});
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
					}
				}
			});
		});
	}

	getBuildingReports(report_type: string, report_id: number, building_id: number, formData) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsBuildingsReports[] = [],
						collectionData: ReportsBuildingsReports[] = [],
						startIndex: number = 0,
						endIndex: number = -1,
						searchString: string = null;

					if (formData !== null) {
						if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
							startIndex = formData['start'];
							endIndex = formData['length'];
							endIndex += startIndex;
						}
						if (typeof formData['columns[0][search][value]'] !== 'undefined') {
							if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
								searchString = formData['columns[0][search][value]'].toLowerCase();
							}
						}
					}

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReports).then(
						buildingReportsDB => {
							if (typeof buildingReportsDB !== 'undefined') {
								collectionData = buildingReportsDB.filter(collection2 => collection2.report_id === report_id && collection2.building_id === building_id && (typeof collection2.indexedDBMethod === 'undefined' || typeof collection2.indexedDBMethod !== 'undefined' && collection2.indexedDBMethod !== '3-delete'));
								collectionData.sort((a, b) => a.sequence > b.sequence ? -1 : 1);

								if (searchString !== null) {
									collectionData = collectionData.filter(collection2 => collection2.location_nr.toLowerCase().indexOf(searchString) !== -1);
								}

								if (endIndex !== -1) {
									resultsData = collectionData.slice(startIndex, endIndex);
								} else {
									resultsData = collectionData.slice();
								}

								resolve({
									recordsTotal: collectionData.length,
									data: resultsData
								});
							} else {
								this.syncReportsBuildingsReports().then((buildingReports: ReportsBuildingsReports[]) => {
									if (typeof buildingReports !== 'undefined') {
										collectionData = buildingReports.filter(collection3 => collection3.report_id === report_id && collection3.building_id === building_id);

										if (searchString !== null) {
											collectionData = collectionData.filter(collection3 => collection3.location_nr.toLowerCase().indexOf(searchString) !== -1);
										}

										if (endIndex !== -1) {
											resultsData = collectionData.slice(startIndex, endIndex);
										} else {
											resultsData = collectionData.slice();
										}

										resolve({
											recordsTotal: collectionData.length,
											data: resultsData
										});
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncReportsBuildingsReports().then((buildingReports: ReportsBuildingsReports[]) => {
								if (typeof buildingReports !== 'undefined') {
									collectionData = buildingReports.filter(collection3 => collection3.report_id === report_id && collection3.building_id === building_id);

									if (searchString !== null) {
										collectionData = collectionData.filter(collection3 => collection3.location_nr.toLowerCase().indexOf(searchString) !== -1);
									}

									if (endIndex !== -1) {
										resultsData = collectionData.slice(startIndex, endIndex);
									} else {
										resultsData = collectionData.slice();
									}

									resolve({
										recordsTotal: collectionData.length,
										data: resultsData
									});
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getBuildingReport(report_type: string, report_id: number, building_id: number, building_report_id: number) {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let resultsData: ReportsBuildingsReports[] = [];

					this.indexedDBService.database.getByKey(this.indexedDBService.tableReportsBuildingsReports, building_report_id).then(
						buildingReportDB => {
							if (typeof buildingReportDB !== 'undefined') {
								resolve(buildingReportDB);
							} else {
								this.syncReportsBuildingsReports().then((buildingReport: ReportsBuildingsReports[]) => {
									if (typeof buildingReport !== 'undefined') {
										resultsData = buildingReport.filter(collection3 => collection3.report_id === report_id && collection3.building_id === building_id && collection3.id === building_report_id);

										if (resultsData.length) {
											resolve(resultsData[0]);
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncReportsBuildingsReports().then((buildingReport: ReportsBuildingsReports[]) => {
								if (typeof buildingReport !== 'undefined') {
									resultsData = buildingReport.filter(collection3 => collection3.report_id === report_id && collection3.building_id === building_id && collection3.id === building_report_id);

									if (resultsData.length) {
										resolve(resultsData[0]);
									} else {
										resolve(undefined);
									}
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	getBuildingMapMarkers(report_id: number, building_id: number, shortcoming_id: number = null): Promise<ReportsBuildingsMapsMarker[]> {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					let collectionData: ReportsBuildingsMapsMarker[] = [];

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsMaps).then(
						buildingReportsDB => {
							if (typeof buildingReportsDB !== 'undefined') {
								collectionData = buildingReportsDB.filter(
									collection2 => collection2.report_id === report_id &&
										collection2.building_id === building_id &&
										(shortcoming_id !== null ? collection2.shortcoming_id === shortcoming_id : '' === '') &&
										(
											typeof collection2.indexedDBMethod === 'undefined' ||
											typeof collection2.indexedDBMethod !== 'undefined' &&
											collection2.indexedDBMethod !== '3-delete'
										)
								);

								resolve(collectionData);
							} else {
								this.syncReportsBuildingsMapMarkers().then((buildingReports: ReportsBuildingsMapsMarker[]) => {
									if (typeof buildingReports !== 'undefined') {
										collectionData = buildingReports.filter(
											collection3 => collection3.report_id === report_id &&
												collection3.building_id === building_id &&
												(shortcoming_id !== null ? collection3.shortcoming_id === shortcoming_id : '' === '')
										);

										resolve(collectionData);
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									reject();
									throw new Error(error);
								});
							}
						},
						() => {
							this.syncReportsBuildingsMapMarkers().then((buildingReports: ReportsBuildingsMapsMarker[]) => {
								if (typeof buildingReports !== 'undefined') {
									collectionData = buildingReports.filter(
										collection3 => collection3.report_id === report_id &&
											collection3.building_id === building_id &&
											(shortcoming_id !== null ? collection3.shortcoming_id === shortcoming_id : '' === '')
									);

									resolve(collectionData);
								} else {
									resolve(undefined);
								}
							}).catch(error => {
								reject();
								throw new Error(error);
							});
						}
					);
				}
			});
		});
	}

	createBuildingReport(report_type: string, report_id: number, building_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Schade opgeslagen.');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReports, <ReportsBuildingsReports>data.data).then(() => {
							if (typeof data.data.markers !== 'undefined') {
								if (data.data.markers.length) {
									data.data.markers.forEach(marker => {
										marker['shortcoming_id'] = data.data.id;
										marker['report_id'] = report_id;
										marker['building_id'] = building_id;
										marker['type'] = report_type;
										marker['location_nr'] = data.data.location_nr;
										this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsMaps, <ReportsBuildingsMapsMarker>marker).then(() => {
										});
									});
								}
							}
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
					}
					if (typeof formData['ladder_questions'] !== 'undefined') {
						let answers = {
							report_id: report_id,
							building_report_id: data.data.id,
							group_id: null,
							questions: {}
						};
						Object.keys(formData['ladder_questions']).map(questionnaire_id => {
							let key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
							answers.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
							if (formData['ladder_questions'][questionnaire_id] !== false) {
								answers.questions[key] = formData['ladder_questions'][questionnaire_id];
							}
						});
						if (_.isEmpty(answers.questions)) {
							this.indexedDBService.database.add(this.indexedDBService.tableReportsLadderQuestionnaireAnswers, answers).then(() => {
							}, error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
						}
					}
					if (typeof formData['tent_questions'] !== 'undefined') {
						let answers = {
							report_id: report_id,
							building_report_id: data.data.id,
							group_id: null,
							questions: {}
						};
						Object.keys(formData['tent_questions']).map(questionnaire_id => {
							let key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
							answers.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
							if (formData['tent_questions'][questionnaire_id] !== false) {
								answers.questions[key] = formData['tent_questions'][questionnaire_id];
							}
						});
						if (_.isEmpty(answers.questions)) {
							this.indexedDBService.database.add(this.indexedDBService.tableReportsTentQuestionnaireAnswers, answers).then(() => {
							}, error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
						}
					}
					this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			let _authenticationService = this.authenticationService.user.first().subscribe(user => {
				delete formData['photosContainer[]'];

				formData['id'] = moment().valueOf();
				formData['report_type'] = report_type;
				formData['report_id'] = report_id;
				formData['building_id'] = building_id;
				formData['verify_status'] = false;
				formData['verify_inspector_status'] = false;
				formData['created'] = moment().valueOf();
				formData['edit_lock'] = true;
				formData['edit_lock_user_id'] = user.id;
				formData['created'] = moment().valueOf();
				formData['sequence'] = 1;
				if (typeof formData['warehouse_action_ids'] !== 'undefined') {
					formData['warehouse_action_ids'] = formData['warehouse_action_ids'].filter(data => data !== false);
				}
				if (typeof formData['warehouse_type_ids'] !== 'undefined') {
					formData['warehouse_type_ids'] = formData['warehouse_type_ids'].filter(data => data !== false);
				}
				if (typeof formData['warehouse_type_field_value'] !== 'undefined') {
					let tmp = {};
					Object.keys(formData['warehouse_type_field_value']).map(key => {
						if (formData['warehouse_type_field_value'][key] !== '') {
							tmp[key.replace('[', '').replace(']', '')] = formData['warehouse_type_field_value'][key];
						}
					});
					formData['warehouse_type_field_value'] = tmp;
				}
				let pushPhotos = [],
					reportPhotos = [];
				if (typeof formData['photos'] !== 'undefined') {
					if (formData['photos'].length) {
						formData['photos'].forEach(photo => {
							let id = crypto['randomUUID']();
							pushPhotos.push({
								id: id,
								file: photo
							});
							reportPhotos.push({
								filename: photo.name,
								id: id,
								offline: true
							});
						});
						formData['photos'] = {
							inspector: reportPhotos
						};
					}
					if (typeof formData['photos']['inspector'] === 'undefined' && typeof formData['photos']['by_client'] === 'undefined') {
						delete formData['photos'];
					}
				}

				if (typeof formData['color_code'] !== 'undefined') {
					if (formData['color_code'] === '0') {
						formData['color'] = '#ed7c20';
						formData['color_text'] = 'Oranje';
					} else if (formData['color_code'] === '1') {
						formData['color'] = '#cc0000';
						formData['color_text'] = 'Rood';
					} else if (formData['color_code'] === '2') {
						formData['color'] = '#019342';
						formData['color_text'] = 'Groen';
					}
				}

				this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReports).then(buildingReports => {
					if (typeof buildingReports !== 'undefined') {
						buildingReports.forEach(buildingReport => {
							if (buildingReport.report_id === report_id && buildingReport.building_id === building_id) {
								if (typeof buildingReport.sequence !== 'undefined') {
									if (formData['sequence'] <= buildingReport.sequence) {
										formData['sequence'] = parseInt(buildingReport.sequence, 10) + 1;
									}
								}
							}
						});
					}

					this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReports, <ReportsBuildingsReports>formData).then(() => {
						this.snackbarService.success('Schade opgeslagen.');

						if (typeof formData['ladder_questions'] !== 'undefined') {
							let answers = {
								report_id: report_id,
								report_type: report_type,
								group_id: null,
								building_report_id: formData.id,
								questions: {}
							};
							Object.keys(formData['ladder_questions']).map(questionnaire_id => {
								let key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
								answers.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
								if (formData['ladder_questions'][questionnaire_id] !== false) {
									answers.questions[key] = formData['ladder_questions'][questionnaire_id];
								}
							});
							if (_.isEmpty(answers.questions)) {
								this.indexedDBService.database.add(this.indexedDBService.tableReportsLadderQuestionnaireAnswers, answers).then(() => {
								}, error => {
									console.log(error);
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							}
						}

						if (typeof formData['tent_questions'] !== 'undefined') {
							let answers = {
								report_id: report_id,
								report_type: report_type,
								group_id: null,
								building_report_id: formData.id,
								questions: {}
							};
							Object.keys(formData['tent_questions']).map(questionnaire_id => {
								let key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
								answers.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
								if (formData['tent_questions'][questionnaire_id] !== false) {
									answers.questions[key] = formData['tent_questions'][questionnaire_id];
								}
							});
							if (_.isEmpty(answers.questions)) {
								this.indexedDBService.database.add(this.indexedDBService.tableReportsTentQuestionnaireAnswers, answers).then(() => {
								}, error => {
									console.log(error);
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							}
						}

						formData['indexedDBMethod'] = '1-create';
						this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPush, formData).then(() => {
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});

						if (pushPhotos.length) {
							let reportPhotos2 = {
								report_id: report_id,
								report_type: report_type,
								building_id: building_id,
								building_report_id: formData.id,
								indexedDBMethod: '1-create',
								photos: pushPhotos
							};
							this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPhotos, reportPhotos2).then(() => {
							}, error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
						}

						this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports']).then(() => {
						});
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}, error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			});
			_authenticationService.unsubscribe();
		}
	}

	updateBuildingReport(report_type: string, report_id: number, building_id: number, building_report_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Schade opgeslagen.');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildingsReports, <ReportsBuildingsReports>data.data).then(() => {
							if (typeof data.data.markers !== 'undefined') {
								if (data.data.markers.length) {
									data.data.markers.forEach(marker => {
										marker['shortcoming_id'] = data.data.id;
										marker['report_id'] = report_id;
										marker['building_id'] = building_id;
										marker['type'] = report_type;
										marker['location_nr'] = data.data.location_nr;
										this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsMaps, <ReportsBuildingsMapsMarker>marker).then(() => {
										});
									});
								} else {
									// get all markers in database
									// delete them all
									this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsMaps).then(markersDB => {
										let ids = markersDB.filter(d => d.shortcoming_id === building_report_id);
										if (ids.length) {
											this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsBuildingsMaps, ids.map(d => d.id)).then(() => {
											});
										}
									});
								}
							}
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
					}

					if (typeof formData['ladder_questions'] !== 'undefined') {
						let answers = {
							report_id: report_id,
							group_id: null,
							building_report_id: data.data.id,
							questions: {}
						};
						Object.keys(formData['ladder_questions']).map(questionnaire_id => {
							let key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
							answers.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
							if (formData['ladder_questions'][questionnaire_id] !== false) {
								answers.questions[key] = formData['ladder_questions'][questionnaire_id];
							}
						});
						if (_.isEmpty(answers.questions)) {
							this.indexedDBService.database.update(this.indexedDBService.tableReportsLadderQuestionnaireAnswers, answers).then(() => {
							}, error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
						}
					}
					if (typeof formData['tent_questions'] !== 'undefined') {
						let answers = {
							report_id: report_id,
							group_id: null,
							building_report_id: data.data.id,
							questions: {}
						};
						Object.keys(formData['tent_questions']).map(questionnaire_id => {
							let key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
							answers.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
							if (formData['tent_questions'][questionnaire_id] !== false) {
								answers.questions[key] = formData['tent_questions'][questionnaire_id];
							}
						});
						if (_.isEmpty(answers.questions)) {
							this.indexedDBService.database.update(this.indexedDBService.tableReportsTentQuestionnaireAnswers, answers).then(() => {
							}, error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
						}
					}
					this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsBuildingsReports, 'id', building_report_id).then(report => {
				delete formData['photosContainer[]'];
				if (typeof formData['warehouse_action_ids'] !== 'undefined') {
					formData['warehouse_action_ids'] = formData['warehouse_action_ids'].filter(data => data !== false);
				}
				if (typeof formData['warehouse_type_ids'] !== 'undefined') {
					formData['warehouse_type_ids'] = formData['warehouse_type_ids'].filter(data => data !== false);
				}
				if (typeof formData['warehouse_type_field_value'] !== 'undefined') {
					let tmp = {};
					Object.keys(formData['warehouse_type_field_value']).map(key => {
						if (formData['warehouse_type_field_value'][key] !== '') {
							tmp[key.replace('[', '').replace(']', '')] = formData['warehouse_type_field_value'][key];
						}
					});
					formData['warehouse_type_field_value'] = tmp;
				}

				let pushPhotos = [],
					reportPhotos = [];
				if (typeof formData['photos'] !== 'undefined') {
					if (typeof report['photos'] !== 'undefined') {
						if (typeof report['photos']['inspector'] !== 'undefined') {
							reportPhotos = report['photos']['inspector'];
						}
					}
					if (formData['photos'].length) {
						formData['photos'].forEach(photo => {
							let id = crypto['randomUUID']();
							pushPhotos.push({
								id: id,
								file: photo
							});
							reportPhotos.push({
								filename: photo.name,
								id: id,
								offline: true
							});
						});
						formData['photos'] = {
							inspector: reportPhotos
						};
					}
					if (typeof formData['photos']['inspector'] === 'undefined' && typeof formData['photos']['by_client'] === 'undefined') {
						delete formData['photos'];
					}
				}

				if (typeof formData['color_code'] !== 'undefined') {
					if (formData['color_code'] === '0') {
						formData['color'] = '#ed7c20';
						formData['color_text'] = 'Oranje';
					} else if (formData['color_code'] === '1') {
						formData['color'] = '#cc0000';
						formData['color_text'] = 'Rood';
					} else if (formData['color_code'] === '2') {
						formData['color'] = '#019342';
						formData['color_text'] = 'Groen';
					}
				}

				formData['report_type'] = report_type;
				let newReport = {...report, ...formData};
				newReport['updated'] = moment().valueOf();

				this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildingsReports, <ReportsBuildingsReports>newReport).then(() => {
					this.snackbarService.success('Schade opgeslagen.');

					if (typeof formData['ladder_questions'] !== 'undefined') {
						let answers = {
							report_id: report_id,
							report_type: report_type,
							group_id: null,
							building_report_id: building_report_id,
							questions: {}
						};
						Object.keys(formData['ladder_questions']).map(questionnaire_id => {
							let key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
							answers.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
							if (formData['ladder_questions'][questionnaire_id] !== false) {
								answers.questions[key] = formData['ladder_questions'][questionnaire_id];
							}
						});
						if (_.isEmpty(answers.questions)) {
							this.indexedDBService.database.update(this.indexedDBService.tableReportsLadderQuestionnaireAnswers, answers).then(() => {
							}, error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
						}
					}

					if (typeof formData['tent_questions'] !== 'undefined') {
						let answers = {
							report_id: report_id,
							report_type: report_type,
							group_id: null,
							building_report_id: building_report_id,
							questions: {}
						};
						Object.keys(formData['tent_questions']).map(questionnaire_id => {
							let key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
							answers.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
							if (formData['tent_questions'][questionnaire_id] !== false) {
								answers.questions[key] = formData['tent_questions'][questionnaire_id];
							}
						});
						if (_.isEmpty(answers.questions)) {
							this.indexedDBService.database.update(this.indexedDBService.tableReportsTentQuestionnaireAnswers, answers).then(() => {
							}, error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
						}
					}

					newReport['report_type'] = report_type;
					newReport['indexedDBMethod'] = '2-update';
					this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPush, newReport).then(() => {
					}, error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});

					if (pushPhotos.length) {
						let reportPhotos2 = {
							report_id: report_id,
							report_type: report_type,
							building_id: building_id,
							building_report_id: building_report_id,
							indexedDBMethod: '1-create',
							photos: pushPhotos
						};
						this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsBuildingsReportsPhotos, 'building_report_id', building_report_id).then(photos => {
							if (typeof photos !== 'undefined') {
								if (typeof photos.photos !== 'undefined') {
									photos.photos = photos.photos.concat(pushPhotos);
									this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildingsReportsPhotos, photos).then(() => {
									}, error => {
										if (this.debug) {
											console.log(error);
										}
										throw new Error(error);
									});
								} else {
									this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPhotos, reportPhotos2).then(() => {
									}, error => {
										if (this.debug) {
											console.log(error);
										}
										throw new Error(error);
									});
								}
							} else {
								this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPhotos, reportPhotos2).then(() => {
								}, error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							}
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
					}

					this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports']).then(() => {
					});
				}, error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			}, error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		}
	}

	deleteBuildingReport(report_type: string, report_id: number, building_id: number, building_report_id: number): Promise<boolean> {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Weet u zeker dat u deze contactpersoon wilt verwijderen?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.indexedDBService.database.delete(this.indexedDBService.tableReportsBuildingsReports, building_report_id).then(() => {
								}, error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
								this.snackbarService.success('Schade verwijderd.');
								resolve(true);
							} else {
								this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
								resolve(false);
							}
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableReportsBuildingsReports, building_report_id).then(() => {
							this.snackbarService.success('Schade verwijderd.');

							this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPush, {
								id: building_report_id,
								report_type: report_type,
								report_id: report_id,
								building_id: building_id,
								indexedDBMethod: '3-delete'
							}).then(() => {
							}, error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});

							this.deleteReportShortcomingQuestionnaireAnswers(report_id, [building_report_id]).then(() => {
							});
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
					}
				}
			});
		});
	}

	deleteBuildingReportPhoto(report_type: string, report_id: number, building_id: number, building_report_id: number, photo_id: number): Promise<boolean> {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Weet u zeker dat u deze foto wilt verwijderen?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id + '/delete-image/' + photo_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Foto verwijderd.');
								resolve(true);
							} else {
								this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
								resolve(false);
							}
						});
					} else {
						this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsBuildingsReportsPhotos, 'building_report_id', building_report_id).then(report => {
							let was_photo_removed: boolean = false,
								promise = [];

							if (typeof report !== 'undefined') {
								if (typeof report.photos !== 'undefined') {
									if (report.photos.length) {
										let photo = report.photos.filter(data => data.id === photo_id);
										if (photo.length) {
											photo = report.photos.indexOf(photo[0]);
											report.photos.splice(photo, 1);
											if (report.photos.length) {
												promise.push(this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildingsReportsPhotos, report).then(() => {
													was_photo_removed = true;
												}, error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												}));
											} else {
												promise.push(this.indexedDBService.database.delete(this.indexedDBService.tableReportsBuildingsReportsPhotos, 'building_report_id', building_report_id).then(() => {
													was_photo_removed = true;
												}, error => {
													if (this.debug) {
														console.log(error);
													}
													throw new Error(error);
												}));
											}
											this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsBuildingsReports, 'id', building_report_id).then(report2 => {
												if (typeof report2 !== 'undefined') {
													if (typeof report2.photos !== 'undefined') {
														if (typeof report2.photos.inspector !== 'undefined') {
															if (report2.photos.inspector.length) {
																let photo2 = report2.photos.inspector.filter(data => data.id === photo_id);
																if (photo2.length) {
																	photo2 = report2.photos.inspector.indexOf(photo2[0]);
																	report2.photos.inspector.splice(photo2, 1);
																	this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildingsReports, report2).then(() => {
																		this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReportsPush).then(reports3 => {
																			if (typeof reports3 !== 'undefined') {
																				let report3 = reports3.filter(data => data.id === building_report_id)[0];
																				if (typeof report3.photos !== 'undefined') {
																					if (typeof report3.photos.inspector !== 'undefined') {
																						if (report3.photos.inspector.length) {
																							let photo3 = report3.photos.inspector.filter(data => data.id === photo_id);
																							if (photo3.length) {
																								photo3 = report3.photos.inspector.indexOf(photo3[0]);
																								report3.photos.inspector.splice(photo3, 1);
																								this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildingsReportsPush, report3).then(() => {
																								}, error => new Error(error));
																							}
																						}
																					}
																				}
																			}

																		});
																	}, error => new Error(error));
																}
															}
														}
													}
												}
											});
											this.snackbarService.success('Foto verwijderd.');
										}
									}
								}
							}

							Promise.all(promise).then(() => {
								if (was_photo_removed === false) {
									let formData = {
										id: photo_id,
										report_type: report_type,
										report_id: report_id,
										building_id: building_id,
										building_report_id: building_report_id,
										indexedDBMethod: '3-delete'
									};
									this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPhotos, formData).then(() => {
										this.snackbarService.success('Foto verwijderd.');
										resolve(true);
									}, error => new Error(error));
								}
							});

							resolve(true);
						}, error => new Error(error));
					}
				}
			});
		});
	}

	getOfflinePhotos(report_id: number, building_id: number, building_report_id: number, photo_id: number) {
		return new Promise(resolve => {
			this.indexedDBService.database.getAll(this.indexedDBService.tableReportsBuildingsReportsPhotos).then(reports => {
				reports.forEach(report => {
					if (report.report_id === report_id && report.building_id === building_id && report.building_report_id === building_report_id && typeof report.photos !== 'undefined') {
						if (typeof report.photos !== 'undefined') {
							let photo = report.photos.filter(data => data.id === photo_id)[0],
								file = photo.file,
								reader = new FileReader();

							reader.onloadend = () => {
								resolve(reader.result);
							};
							reader.readAsDataURL(file);
						}
					}
				});
			}, error => {
				if (this.debug) {
					if (this.debug) {
						console.log(error);
					}
				}
				resolve(undefined);
				throw new Error(error);
			});
		});
	}

	verifyInspectorStatus(report_type: string, report_id: number, building_id: number, building_report_id: number, status): Promise<any> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.post('reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id + '/verify-inspector-status', {
					status: status
				}).then((data: ServerResponse) => {
					if (data.success === true) {
						// this.snackbarService.success('Schade opgeslagen.');
						if (typeof data.data !== 'undefined') {
							this.indexedDBService.database.update(this.indexedDBService.tableReportsBuildingsReports, <ReportsBuildingsReports>data.data).then(() => {
								resolve(status);
							}, error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
						}
					} else if (typeof data.error !== 'undefined') {
						this.errors.next(data.error);
					} else {
						this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
					}
				});
			} else {
				let formData = {
					'report_type': report_type,
					'report_id': report_id,
					'building_id': building_id,
					'building_report_id': building_report_id,
					'status': status
				};

				this.indexedDBService.database.add(this.indexedDBService.tableReportsVerifyInspectorStatus, formData).then(() => {
					resolve(status);
					// this.snackbarService.success('Schade opgeslagen.');
				}, error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			}
		});
	}

	downloadFile(url: string, filename: string) {
		if (this.apiService.isOnline) {
			if (typeof url !== 'undefined') {
				this.apiService.download(url, filename);
			} else {
				this.snackbarService.error('Ongeldige link, kan niet worden gedownload.');
			}
		} else {
			this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
		}
	}

	checkBarcodeScanner() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('configuration/settings').then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.data !== 'undefined') {
							if (typeof data.data.barcode_scanner !== 'undefined') {
								this.localStorageService.set('barcode_scanner', data.data.barcode_scanner);
								if (data.data.barcode_scanner === '1') {
									resolve(true);
								} else {
									resolve(false);
								}
							} else {
								resolve(false);
							}
						} else {
							resolve(false);
						}
					} else {
						resolve(false);
					}
				});
			} else {
				let setting = this.localStorageService.get('barcode_scanner');
				if (setting !== null) {
					if (setting === '1') {
						resolve(true);
					} else {
						resolve(false);
					}
				}
			}
		});
	}

	checkSpeechToText() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('configuration/settings').then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.data !== 'undefined') {
							if (typeof data.data.speech_to_text !== 'undefined') {
								this.localStorageService.set('speech_to_text', data.data.speech_to_text);
								if (data.data.speech_to_text === '1') {
									resolve(true);
								} else {
									resolve(false);
								}
							} else {
								resolve(false);
							}
						} else {
							resolve(false);
						}
					} else {
						resolve(false);
					}
				});
			} else {
				let setting = this.localStorageService.get('speech_to_text');
				if (setting !== null) {
					if (setting === '1') {
						resolve(true);
					} else {
						resolve(false);
					}
				}
			}
		});
	}

	isLadderLocationNrUnique(report_id: number, building_id: number, location_number: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/ladders/' + report_id + '/buildings/' + building_id + '/is-location-unique/' + location_number).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						resolve(data);
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	isExistingLadderLocationNrUnique(report_id: number, building_id: number, building_report_id: number, location_number: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/ladders/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id + '/is-location-unique/' + location_number).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						resolve(data);
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	isLadderIDUnique(report_id: number, building_id: number, id: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/ladders/' + report_id + '/buildings/' + building_id + '/is-id-unique/' + id).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						resolve(data);
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	isExistingLadderIDUnique(report_id: number, building_id: number, building_report_id: number, id: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/ladders/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id + '/is-id-unique/' + id).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						resolve(data);
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getNextLadderLocationNr(report_id: number, building_id: number) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/ladders/' + report_id + '/buildings/' + building_id + '/next-ladder-location-nr').then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						resolve(data);
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	isTentLocationNrUnique(report_id: number, building_id: number, location_number: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/tent/' + report_id + '/buildings/' + building_id + '/is-tent-location-unique/' + location_number).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						resolve(data);
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	isExistingTentLocationNrUnique(report_id: number, building_id: number, building_report_id: number, location_number: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/tent/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id + '/is-tent-location-unique/' + location_number).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						resolve(data);
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	isTentIDUnique(report_id: number, building_id: number, id: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/tent/' + report_id + '/buildings/' + building_id + '/is-tent-id-unique/' + id).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						resolve(data);
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	isExistingTentIDUnique(report_id: number, building_id: number, building_report_id: number, id: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/tent/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id + '/is-tent-id-unique/' + id).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						resolve(data);
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	getNextTentLocationNr(report_id: number, building_id: number) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/tent/' + report_id + '/buildings/' + building_id + '/next-tent-location-nr').then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						resolve(data);
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	copyReport(report_type: string, report_id: number) {
		return new Promise(() => {
			let dialogRef: MatDialogRef<ModalCopyReportDialogComponent>;
			dialogRef = this.dialogService.open(ModalCopyReportDialogComponent, {
				data: {
					original_report_type: report_type
				}
			});
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						this.apiService.get('reports/' + report_type + '/' + report_id + '/copy-report/' + dialogRef.componentInstance.report_type, {
							report_type: dialogRef.componentInstance.report_type
						}).then((data: ServerResponse) => {
							if (typeof data !== 'undefined') {
								if (typeof data.data !== 'undefined') {
									if (typeof data.data.link !== 'undefined') {
										let promises = [
											this.syncBuildingParticulars(),
											this.syncActions('warehouse'),
											this.syncTypes('warehouse'),
											this.syncMaterials('ladders'),
											this.syncMaterials('tent'),
											this.syncWarehouseQuestionnaires('warehouse'),
											this.syncShortcomingQuestionnaires('ladders'),
											this.syncShortcomingQuestionnaires('tent'),
											this.syncReportsAttachments(),
											this.syncReportsBuildings(),
											this.syncReportsComments(),
											this.syncReportsBuildingsReports()
										];
										Promise.all(promises).then(() => {
											this.previousUrlService.goTo(data.data.link);
											this.snackbarService.success('Rapport kopiëren geslaagd!');
											this.indexedDBService.database.delete(this.indexedDBService.tableReportsReinspections, report_id);
										});
									}
								}
							}
						});
					} else {
						this.indexedDBService.database.getByKey(this.indexedDBService.tableReports, report_id).then(report => {
							if (typeof report !== 'undefined') {
								let new_report_id = moment().valueOf(),
									promises = [];

								report['parent_report_id'] = report['id'];
								report['id'] = new_report_id;
								report['created'] = moment().valueOf();
								report['status'] = 'open';
								report['status_text'] = 'Openstaand';
								report['report_nr'] = report['report_nr'] + '-Offline';
								report['is_offline'] = 'yes';
								delete report['updated'];

								// save report
								promises.push(new Promise(resolve0 => {
									this.indexedDBService.database.add(this.indexedDBService.tableReports, report).then(() => {
										let promises1 = [];

										report['type'] = report_type;
										report['indexedDBMethod'] = '1-create';

										promises1.push(new Promise(resolve1 => {
											this.indexedDBService.database.add(this.indexedDBService.tableReportsPush, report).then(() => {
												let promises2 = [];

												// copy report's comments
												promises2.push(new Promise(resolve2 => {
													this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsComments).then(commentsDB => {
														let promises3 = [];
														if (typeof commentsDB !== 'undefined') {
															let comments = commentsDB.filter(data => data.report_id === report_id);
															comments.forEach(comment => {
																let id = JSON.parse(JSON.stringify(comment.id));
																comment['id'] = moment().valueOf() + Math.floor((Math.random() * (500000 - 1) + 1));
																comment['created'] = report['created'];
																comment['report_id'] = report['id'];
																comment['report_type'] = report_type;

																promises3.push(new Promise(resolve3 => {
																	this.indexedDBService.database.add(this.indexedDBService.tableReportsComments, comment).then(() => {
																		comment['copy_from_attachment_id'] = id;
																		comment['copy_from_report_id'] = report_id;
																		comment['indexedDBMethod'] = '0-copy';

																		this.indexedDBService.database.add(this.indexedDBService.tableReportsCommentsPush, comment).then(() => {
																			resolve3();
																		});
																	});
																}));
															});
														}

														Promise.all(promises3).then(() => {
															resolve2();
														});
													});
												}));

												// copy report's attachments
												promises2.push(new Promise(resolve2 => {
													this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsAttachments).then(attachmentsDB => {
														let promises3 = [];
														if (typeof attachmentsDB !== 'undefined') {
															let attachments = attachmentsDB.filter(data => data.report_id === report_id);
															attachments.forEach(attachment => {
																let id = JSON.parse(JSON.stringify(attachment.id));
																attachment['id'] = moment().valueOf() + Math.floor((Math.random() * (500000 - 1) + 1));
																attachment['created'] = report['created'];
																attachment['report_id'] = report['id'];
																attachment['report_type'] = report_type;
																delete attachment['download_url'];

																promises3.push(new Promise(resolve3 => {
																	this.indexedDBService.database.add(this.indexedDBService.tableReportsAttachments, attachment).then(() => {
																		attachment['copy_from_attachment_id'] = id;
																		attachment['copy_from_report_id'] = report_id;
																		attachment['indexedDBMethod'] = '0-copy';

																		this.indexedDBService.database.add(this.indexedDBService.tableReportsAttachmentsPush, attachment).then(() => {
																			resolve3();
																		});
																	});
																}));
															});
														}

														Promise.all(promises3).then(() => {
															resolve2();
														});
													});
												}));

												// if type == warehouse, copy questionnaire
												if (report_type === 'warehouse') {
													promises2.push(new Promise(resolve2 => {
														this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers, 'report_id', report_id).then(questionnaire => {
															if (typeof questionnaire !== 'undefined') {
																questionnaire['report_id'] = report['id'];
																questionnaire['report_type'] = 'warehouse';
																questionnaire['indexedDBMethod'] = '2-update';
																questionnaire['report_type'] = report_type;

																this.indexedDBService.database.add(this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers, questionnaire).then(() => {
																	resolve2();
																});
															} else {
																resolve2();
															}
														});
													}));
												}

												// copy report's buildings
												promises2.push(new Promise(resolve2 => {
													this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildings).then(buildingsDB => {
														if (typeof buildingsDB !== 'undefined') {
															let buildings = buildingsDB.filter(data => data.report_id === report_id),
																promises3 = [];

															buildings.forEach(building => {
																let building_id = parseInt(JSON.parse(JSON.stringify(building['id'])), 10);
																building['report_id'] = report['id'];
																building['id'] = moment().valueOf() + Math.floor((Math.random() * (500000 - 1) + 1));
																building['report_type'] = report_type;

																if (typeof building.lib_particular_id !== 'undefined') {
																	if (building.lib_particular_id.length === 0) {
																		building['lib_particular_id'] = [];
																	}
																}

																promises3.push(new Promise(resolve3 => {
																	this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildings, building).then(() => {
																		let promises4 = [];

																		building['indexedDBMethod'] = '1-create';
																		promises4.push(new Promise(resolve4 => {
																			this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsPush, building).then(() => {
																				resolve4();
																			});
																		}));

																		// copy building reports
																		promises4.push(new Promise(resolve4 => {
																			this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReports).then(buildingReportsDB => {
																				let promises5 = [];

																				if (typeof buildingReportsDB !== 'undefined') {
																					let buildingReports = buildingReportsDB.filter(data => data.report_id === report_id && data.building_id === building_id);
																					buildingReports.forEach(buildingReport => {
																						// let id = parseInt(JSON.parse(JSON.stringify(buildingReport['id'])), 10);
																						buildingReport['report_id'] = report['id'];
																						buildingReport['building_id'] = building['id'];
																						buildingReport['id'] = moment().valueOf() + Math.floor((Math.random() * (500000 - 1) + 1));
																						buildingReport['report_type'] = report_type;
																						buildingReport['indexedDBMethod'] = '1-create';
																						delete buildingReport['photos'];

																						promises5.push(new Promise(resolve5 => {
																							this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReports, buildingReport).then(() => {
																								let promises6 = [];

																								// copy ladder questionnaire
																								/*
																																																if (report_type === 'ladders') {
																																																	promises6.push(new Promise(resolve6 => {
																																																		this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsLadderQuestionnaireAnswers, 'building_report_id', id).then(ladderQuestionnaireDB => {
																																																			let promises7 = [];

																																																			if (typeof ladderQuestionnaireDB !== 'undefined') {
																																																				delete ladderQuestionnaireDB['id'];
																																																				ladderQuestionnaireDB['report_id'] = report['id'];
																																																				ladderQuestionnaireDB['building_report_id'] = buildingReport['id'];
																																																				ladderQuestionnaireDB['indexedDBMethod'] = '1-create';
																																																				ladderQuestionnaireDB['report_type'] = report_type;

																																																				// save ladder questionnaire
																																																				promises7.push(new Promise(resolve7 => {
																																																					this.indexedDBService.database.add(this.indexedDBService.tableReportsLadderQuestionnaireAnswers, ladderQuestionnaireDB).then(() => {
																																																						resolve7();
																																																					});
																																																				}));

																																																				if (typeof ladderQuestionnaireDB.questions !== 'undefined') {
																																																					buildingReport['ladder_questions'] = {};
																																																					Object.keys(ladderQuestionnaireDB.questions).forEach(key => {
																																																						buildingReport['ladder_questions']['[' + ladderQuestionnaireDB['group_id'] + '][' + key + ']'] = ladderQuestionnaireDB.questions[key];
																																																					});
																																																				}
																																																				promises7.push(new Promise(resolve7 => {
																																																					this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPush, buildingReport).then(() => {
																																																						resolve7();
																																																					});
																																																				}));
																																																			}

																																																			Promise.all(promises7).then(() => {
																																																				resolve6();
																																																			});
																																																		});
																																																	}));
																																																} else {
																								*/
																								promises6.push(new Promise(resolve6 => {
																									this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPush, buildingReport).then(() => {
																										resolve6();
																									});
																								}));
																								// }

																								Promise.all(promises6).then(() => {
																									resolve5();
																								});
																							}).catch(error => {
																								if (this.debug) {
																									console.log(error);
																								}
																							});
																						}));
																					});
																				}

																				Promise.all(promises5).then(() => {
																					resolve4();
																				});
																			});
																		}));

																		Promise.all(promises4).then(() => {
																			resolve3();
																		});
																	});
																}));
															});

															Promise.all(promises3).then(() => {
																resolve2();
															});
														} else {
															resolve2();
														}
													});
												}));

												Promise.all(promises2).then(() => {
													resolve1();
												});
											});
										}));

										Promise.all(promises1).then(() => {
											resolve0();
										});
									});
								}));

								Promise.all(promises).then(() => {
									this.router.navigate(['/reports/' + report_type + '/' + new_report_id + '/view']).then(() => {
										this.snackbarService.success('Rapport kopiëren geslaagd!');
										this.indexedDBService.database.delete(this.indexedDBService.tableReportsReinspections, report_id);
									});
								}).catch(() => {
									if (this.debug) {
										console.log('error, cleaning up ...');
									}
									this.indexedDBService.database.delete(this.indexedDBService.tableReports, report['id']);
									this.indexedDBService.database.delete(this.indexedDBService.tableReportsPush, report['id']);
									this.deleteReportComments(report['id']).then(() => {
									});
									this.deleteReportAttachments(report['id']).then(() => {
									});
									this.deleteReportBuildings(report['id']).then(() => {
									});
									this.deleteReportBuildingsReports(report['id']).then(() => {
									});
									this.deleteReportShortcomingQuestionnaireAnswers(report['id']).then(() => {
									});
									this.deleteReportWarehouseQuestionnaireAnswers(report['id']).then(() => {
									});
								});
							}
						});
					}
				}
			});
		});
	}

	syncInspectors() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/inspectors/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/inspectors', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableInspectors, <User[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableInspectors,
															updated: server_date.data.updated
														})
															.then(() => {
																resolve(<User[]>data.data);
															})
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncBuildingParticulars() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/building-particulars/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/building-particulars', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsBuildingParticulars, <ReportBuildingParticulars[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: 'building_particulars',
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportBuildingParticulars[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncCategories(report_type: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/elements/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/' + report_type + '/elements', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk((
													report_type === 'warehouse' ? this.indexedDBService.tableReportsWarehouseCategories :
														report_type === 'ladders' ? this.indexedDBService.tableReportsLadderCategories :
															this.indexedDBService.tableReportsTentCategories
												), <ReportsShortcomingsCategories[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: report_type === 'warehouse' ? this.indexedDBService.tableReportsWarehouseCategories : report_type === 'ladders' ? this.indexedDBService.tableReportsLadderCategories : this.indexedDBService.tableReportsTentCategories,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportBuildingParticulars[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncSubcategories(report_type: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/elements/subelements/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/' + report_type + '/elements/subelements', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk((
													report_type === 'warehouse' ? this.indexedDBService.tableReportsWarehouseSubcategories :
														report_type === 'ladders' ? this.indexedDBService.tableReportsLadderSubcategories :
															this.indexedDBService.tableReportsTentSubcategories
												), <ReportsShortcomingsSubcategories[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: report_type === 'warehouse' ? this.indexedDBService.tableReportsWarehouseSubcategories : report_type === 'ladders' ? this.indexedDBService.tableReportsLadderSubcategories : this.indexedDBService.tableReportsTentSubcategories,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportBuildingParticulars[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncTypes(report_type: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/elements/subelements/types/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/' + report_type + '/elements/subelements/types', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												let filtered = [];
												data.data.forEach(type => {
													type.type_name = type.type_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
													filtered.push(type);
												});
												if (filtered.length) {
													return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsWarehouseTypes, <ReportsShortcomingsTypes[]>filtered)
														.then(() => {
															return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
																name: this.indexedDBService.tableReportsWarehouseTypes,
																updated: server_date.data.updated
															})
																.then(() => resolve(<ReportsShortcomingsTypes[]>data.data))
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																});
														})
														.catch(error => {
															if (this.debug) {
																console.log(error);
															}
															throw new Error(error);
														});
												} else {
													resolve(undefined);
												}
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncActions(report_type: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/elements/subelements/actions/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/' + report_type + '/elements/subelements/actions', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												let filtered = [];
												data.data.forEach(action => {
													action.action_name = action.action_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
													filtered.push(action);
												});
												if (filtered.length) {
													return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsWarehouseActions, <ReportsShortcomingsActions[]>filtered)
														.then(() => {
															return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
																name: this.indexedDBService.tableReportsWarehouseActions,
																updated: server_date.data.updated
															})
																.then(() => resolve(<ReportsShortcomingsActions[]>filtered))
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																});
														})
														.catch(error => {
															if (this.debug) {
																console.log(error);
															}
															throw new Error(error);
														});
												} else {
													resolve(undefined);
												}
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncMaterials(report_type: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/materials/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/' + report_type + '/materials', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												let filtered = [];
												data.data.forEach(material => {
													material.material_name = material.material_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
													filtered.push(material);
												});
												if (filtered.length) {
													return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsLadderMaterials, <ReportsShortcomingsMaterials[]>filtered)
														.then(() => {
															this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
																name: this.indexedDBService.tableReportsLadderMaterials,
																updated: server_date.data.updated
															})
																.then(() => resolve(<ReportsShortcomingsMaterials[]>filtered))
																.catch(error => {
																	if (this.debug) {
																		console.log(error);
																	}
																	throw new Error(error);
																});
														})
														.catch(error => {
															if (this.debug) {
																console.log(error);
															}
															throw new Error(error);
														});
												} else {
													resolve(undefined);
												}
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncWarehouseQuestionnaires(report_type: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/questionnaire/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/' + report_type + '/questionnaire', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsWarehouseQuestionnaire, <ReportsQuestions[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableReportsWarehouseQuestionnaire,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportsQuestions[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncWarehouseQuestionnaireAnswers(report_type: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/questionnaire/answers/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/' + report_type + '/questionnaire/answers', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers, <ReportsQuestionsAnswers[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportsQuestionsAnswers[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncShortcomingQuestionnaires(report_type: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/elements/subelements/questionnaires/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/' + report_type + '/elements/subelements/questionnaires', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk((report_type === 'ladders' ? this.indexedDBService.tableReportsLadderQuestionnaire : this.indexedDBService.tableReportsTentQuestionnaire), <ReportsBuildingsShortcomingsQuestionnaire[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: (report_type === 'ladders' ? this.indexedDBService.tableReportsLadderQuestionnaire : this.indexedDBService.tableReportsTentQuestionnaire),
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportsBuildingsShortcomingsQuestionnaire[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncShortcomingQuestionnaireAnswers(report_type: string) {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/' + report_type + '/elements/subelements/questionnaires/answers/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/' + report_type + '/elements/subelements/questionnaires/answers', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk((report_type === 'ladders' ? this.indexedDBService.tableReportsLadderQuestionnaireAnswers : this.indexedDBService.tableReportsTentQuestionnaireAnswers), <ReportsBuildingsShortcomingsQuestionnaireAnswers[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: (report_type === 'ladders' ? this.indexedDBService.tableReportsLadderQuestionnaireAnswers : this.indexedDBService.tableReportsTentQuestionnaireAnswers),
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportsBuildingsShortcomingsQuestionnaireAnswers[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncReports() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/sync', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReports, <Reports[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableReports,
															updated: server_date.data.updated
														})
															.then(() => resolve(<Reports[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncReinspectionReports() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				return this.indexedDBService.database.clear(this.indexedDBService.tableReportsReinspections).then(() => {
					this.apiService.get('reports/reinspections', {
						start: 0,
						length: -1
					}).then((data: ServerResponse) => {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsReinspections, <Reports[]>data.data)
										.then(() => {
											resolve(<Reports[]>data.data);
										})
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								} else {
									resolve(undefined);
								}
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					}).catch(error => {
						if (this.debug) {
							console.log(error);
						}
						throw new Error(error);
					});
				}, error => {
					console.log(error);
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncReportsAttachments() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/warehouse/attachments/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/warehouse/attachments', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsAttachments, <ReportsAttachments[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableReportsAttachments,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportsAttachments[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncReportsComments() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/warehouse/comments/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/warehouse/comments', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsComments, <ReportsComments[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableReportsAttachments,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportsComments[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncReportsBuildings() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/warehouse/buildings/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/warehouse/buildings', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsBuildings, <ReportsBuildings[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableReportsAttachments,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportsBuildings[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncReportsBuildingsReports() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/warehouse/buildings/reports/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/warehouse/buildings/reports', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsBuildingsReports, <ReportsBuildingsReports[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableReportsAttachments,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportsBuildingsReports[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncReportsBuildingsMapMarkers() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('reports/warehouse/buildings-maps/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('reports/warehouse/buildings-maps', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableReportsBuildingsMaps, <ReportsBuildingsMaps[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableReportsBuildingsMaps,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ReportsBuildingsMapsMarker>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	cleanupAfterReportDeletion(reportsIndexes: Array<number>) {
		reportsIndexes.forEach(report_id => {
			this.deleteReportAttachments(report_id).then(() => {
			});
			this.deleteReportBuildings(report_id).then(() => {
			});
			this.deleteReportBuildingsReports(report_id).then(() => {
			});
			this.deleteReportComments(report_id).then(() => {
			});
			this.deleteReportShortcomingQuestionnaireAnswers(report_id).then(() => {
			});
			this.deleteReportWarehouseQuestionnaireAnswers(report_id).then(() => {
			});

			this.indexedDBService.database.openCursorWithIndex(this.indexedDBService.tableReportsSendToClient, 'report_id', event => {
				let cursor = event.target.result;
				if (cursor) {
					if (cursor.value.report_id === report_id) {
						this.indexedDBService.database.delete(this.indexedDBService.tableReportsSendToClient, cursor.value.id).then(() => {
						}, error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
					}
				}
			}).then(() => {
			}, error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			});
		});
	}

	deleteReportAttachments(report_id: number): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsAttachments)
				.then(reports_attachments => {
					let filtered = [];
					reports_attachments.forEach(report_attachment => {
						if (report_attachment.report_id === report_id) {
							filtered.push(report_attachment.id);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsAttachments, filtered)
							.then(() => true)
							.catch(error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);

			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsAttachmentsPush)
				.then(reports_attachments => {
					let filtered = [];
					reports_attachments.forEach(report_attachment => {
						if (report_attachment.report_id === report_id) {
							filtered.push(report_attachment.entryId);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsAttachmentsPush, filtered)
							.then(() => true)
							.catch(error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);

			return Promise.all(promises).then(() => resolve());
		});
	}

	deleteReportBuildings(report_id: number): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildings)
				.then(report_buildings => {
					let filtered = [];
					report_buildings.forEach(report_building => {
						if (report_building.report_id === report_id) {
							filtered.push(report_building.id);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsBuildings, filtered)
							.then(() => true)
							.catch(error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);
			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsPush)
				.then(report_buildings => {
					let filtered = [];
					report_buildings.forEach(report_building => {
						if (report_building.report_id === report_id) {
							filtered.push(report_building.entryId);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsBuildingsPush, filtered)
							.then(() => true)
							.catch(error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);

			return Promise.all(promises).then(() => resolve());
		});
	}

	deleteReportBuildingsReports(report_id: number, building_id: number = null): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReports)
				.then(reports_buildings_reports => {
					let filtered = [];
					reports_buildings_reports.forEach(report_building_report => {
						if (building_id === null && report_building_report.report_id === report_id || building_id !== null && report_building_report.id === building_id && report_building_report.report_id === report_id) {
							filtered.push(report_building_report.id);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsBuildingsReports, filtered)
							.then(() => this.deleteReportShortcomingQuestionnaireAnswers(report_id, filtered))
							.catch(error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);

			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReportsPush)
				.then(reports_buildings_reports => {
					let filtered = [];
					reports_buildings_reports.forEach(report_building_report => {
						if (building_id === null && report_building_report.report_id === report_id || building_id !== null && report_building_report.id === building_id && report_building_report.report_id === report_id) {
							filtered.push(report_building_report.id);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsBuildingsReportsPush, filtered)
							.then(() => this.deleteReportShortcomingQuestionnaireAnswers(report_id, filtered))
							.catch(error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);

			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReportsPhotos)
				.then(reports_buildings_reports_photos => {
					let filtered = [];
					reports_buildings_reports_photos.forEach(report_building_report_photo => {
						if (building_id === null && report_building_report_photo.report_id === report_id || building_id !== null && report_building_report_photo.id === building_id && report_building_report_photo.report_id === report_id) {
							filtered.push(report_building_report_photo.id);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsBuildingsReportsPhotos, filtered)
							.then(() => true)
							.catch(error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);

			return Promise.all(promises).then(() => resolve());
		});
	}

	deleteReportComments(report_id: number): Promise<void> {
		return new Promise(resolve => {
			let promises = [];
			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsComments)
				.then(reports_comments => {
					let filtered = [];
					reports_comments.forEach(report_comment => {
						if (report_comment.report_id === report_id) {
							filtered.push(report_comment.id);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsComments, filtered)
							.then(() => true)
							.catch(error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);
			promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsCommentsPush)
				.then(reports_comments => {
					let filtered = [];
					reports_comments.forEach(report_comment => {
						if (report_comment.report_id === report_id) {
							filtered.push(report_comment.entryId);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsCommentsPush, filtered)
							.then(() => true)
							.catch(error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
					}
					return true;
				})
				.catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				})
			);

			return Promise.all(promises).then(() => resolve());
		});
	}

	deleteReportShortcomingQuestionnaireAnswers(report_id: number, building_report_ids: Array<number> = null): Promise<void> {
		let promises = [];
		promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsLadderQuestionnaireAnswers)
			.then(reports_ladder_questionnaire_answers => {
				let filtered = [];
				reports_ladder_questionnaire_answers.forEach(report_ladder_questionnaire_answer => {
					building_report_ids.forEach(building_report_id => {
						if (building_report_id === null && report_ladder_questionnaire_answer.report_id === report_id || building_report_id !== null && report_ladder_questionnaire_answer.building_report_id === building_report_id && report_ladder_questionnaire_answer.report_id === report_id) {
							filtered.push(report_ladder_questionnaire_answer.id);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsLadderQuestionnaireAnswers, filtered)
							.then(() => true)
							.catch(error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
					}
					return true;
				});
			})
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			})
		);
		promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsTentQuestionnaireAnswers)
			.then(reports_tent_questionnaire_answers => {
				let filtered = [];
				reports_tent_questionnaire_answers.forEach(report_tent_questionnaire_answer => {
					building_report_ids.forEach(building_report_id => {
						if (building_report_id === null && report_tent_questionnaire_answer.report_id === report_id || building_report_id !== null && report_tent_questionnaire_answer.building_report_id === building_report_id && report_tent_questionnaire_answer.report_id === report_id) {
							filtered.push(report_tent_questionnaire_answer.id);
						}
					});
					if (filtered.length) {
						return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsTentQuestionnaireAnswers, filtered)
							.then(() => true)
							.catch(error => {
								if (this.debug) {
									console.log(error);
								}
								throw new Error(error);
							});
					}
					return true;
				});
			})
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			})
		);

		return Promise.all(promises).then(() => {
		});
	}

	deleteReportWarehouseQuestionnaireAnswers(report_id: number): Promise<void> {
		return new Promise(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers)
			.then(reports_questionnaire_answers => {
				let filtered = [];
				reports_questionnaire_answers.forEach(report_questionnaire_answer => {
					if (report_questionnaire_answer.report_id === report_id) {
						filtered.push(report_questionnaire_answer.id);
					}
				});
				if (filtered.length) {
					return this.indexedDBService.database.deleteBulk(this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers, filtered)
						.then(() => true)
						.catch(error => {
							if (this.debug) {
								console.log(error);
							}
							throw new Error(error);
						});
				}
				return true;
			})
			.catch(error => {
				if (this.debug) {
					console.log(error);
				}
				throw new Error(error);
			})
		);
	}

	showReportBuildingMap(building_map_url: string, readonly: boolean = true, markers: ReportsBuildingsMapsMarker[] = []) {
		return new Promise(resolve => {
			let dialogRef: MatDialogRef<BuildingMapWidgetComponent>;
			dialogRef = this.dialogService.open(BuildingMapWidgetComponent, {
				data: {
					readonly: readonly,
					buildingMapUrl: building_map_url,
					markers: markers,
				}
			});
			dialogRef.afterClosed().subscribe(() => {
				resolve(dialogRef.componentInstance.markers);
			});
		});

	}
}
