<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports']">Bekijk gebouwen schade</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/buildings']">Bekijk alle gebouwen</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/view']">Bekijk rapportage</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/open']">Bekijk alle rapportages</button>
<ng-container *ngIf="buildingReport">
	<ng-container *ngIf="hasBuildingMap">
		<ng-container *ngIf="apiService.isOnline">
			<p>Er is een plattegrond beschikbaar. Vouw het hieronder uit om het en toegevoegde posities te bekijken.</p>
			<button mat-button color="primary-outline" (click)="showBuildingMap()">Plattegrond openen</button>
		</ng-container>
		<ng-container *ngIf="!apiService.isOnline">
			<p>Map is available, but you can access it only when the device is connected to the internet.</p>
		</ng-container>
	</ng-container>
	<div *ngIf="!reportEditable" class="alert alert-danger" fxFlex fxLayoutAlign="center" fxLayout="row">
		<mat-icon>warning</mat-icon>
		<h2>Rapport is vergrendeld door een andere gebruiker. Om het te bewerken, moet u eerst de rapportage ontgrendelen.</h2>
	</div>
	<div class="divider"><span>KVVM status</span></div>
	<div class="form-line">
		<div class="form-label">KVVM status</div>
		<div class="form-content">
			<span *ngIf="buildingReport.verify_inspector_status">Ja, {{ buildingReport.verify_inspector_date | datex:'DD-MM-YYYY' }}</span>
			<span *ngIf="!buildingReport.verify_inspector_status">Nee</span>
		</div>
	</div>
	<div class="divider"><span>Gebouw rapportage gegevens</span></div>
	<div class="form-line" *ngIf="buildingReport.report_type === 'warehouse'">
		<div class="form-label">Locatie nummer</div>
		<div class="form-content">{{ buildingReport.location_nr }}</div>
	</div>
	<div class="form-line" *ngIf="buildingReport.report_type === 'ladders'">
		<div class="form-label">Nummer</div>
		<div class="form-content">{{ buildingReport.location_nr }}</div>
	</div>
	<div class="form-line" *ngIf="buildingReport.report_type === 'ladders'">
		<div class="form-label">ID</div>
		<div class="form-content">{{ buildingReport.ladder_id }}</div>
	</div>
	<div class="form-line">
		<div class="form-label">Hoofd onderdeel</div>
		<div class="form-content">{{ buildingReport.category_name }}</div>
	</div>
	<div class="form-line">
		<div class="form-label">Subonderdeel</div>
		<div class="form-content">{{ buildingReport.subcategory_name }}</div>
	</div>
	<div class="form-line">
		<div class="form-label">Kleurcode</div>
		<div class="form-content">
			<div class="color" [style.background]="buildingReport.color"></div>
			<div class="text">{{ buildingReport.color_text }}</div>
		</div>
	</div>
	<div class="form-line" *ngIf="buildingReport.report_type === 'warehouse'">
		<div class="form-label">Diepte</div>
		<div class="form-content">{{ buildingReport.measure_depth }}</div>
	</div>
	<div class="form-line" *ngIf="buildingReport.report_type === 'warehouse'">
		<div class="form-label">Hoogte</div>
		<div class="form-content">{{ buildingReport.measure_height }}</div>
	</div>
	<div class="form-line" *ngIf="buildingReport.report_type === 'ladders'">
		<div class="form-label">Materiaal</div>
		<div class="form-content">{{ material_name }}</div>
	</div>
	<div class="form-line" *ngIf="buildingReport.report_type === 'ladders'">
		<div class="form-label">Brand</div>
		<div class="form-content">{{ buildingReport.brand }}</div>
	</div>
	<div class="form-line" *ngIf="buildingReport.report_type === 'ladders'">
		<div class="form-label">Keuringssticker geplaatst</div>
		<div class="form-content">{{ buildingReport.sticker_posted === 1 ? 'Ja' : 'Nee' }}</div>
	</div>
	<div class="form-line">
		<div class="form-label">Uitleg</div>
		<div class="form-content" *ngIf="buildingReport.description">{{ buildingReport.description }}</div>
		<div class="form-content" *ngIf="!buildingReport.description">-</div>
	</div>
	<ng-container *ngIf="buildingReport.report_type === 'warehouse'">
		<ng-container *ngIf="buildingReport.types_data.length">
			<div class="divider"><span>Type tekortkomingen</span></div>
			<ul>
				<li *ngFor="let type of buildingReport.types_data">
					{{ type.name }} <span *ngIf="type.value"> / {{ type.value }}</span>
				</li>
			</ul>
		</ng-container>
		<ng-container *ngIf="buildingReport.actions_data.length">
			<div class="divider"><span>Acties</span></div>
			<ul>
				<li *ngFor="let action of buildingReport.actions_data">
					{{ action }}
				</li>
			</ul>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="buildingReport.report_type === 'ladders'">
		<ng-container *ngIf="buildingReport.questionnaires.length">
			<div class="questionnaire-container view-only" *ngFor="let questionnaire of buildingReport.questionnaires">
				<div class="divider"><span>{{questionnaire.name}}</span></div>
				<p *ngIf="questionnaire.description">{{ questionnaire.description }}</p>
				<div class="questionnaire-body">
					<ng-container *ngIf="questionnaire.questions.length">
						<div class="questionnaire-question" *ngFor="let question of questionnaire.questions">
							<span class="questionnaire-color-answer" [style.background]="question.color"></span>
							<p>{{ question.question }}</p>
						</div>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="buildingReport?.photos?.inspector">
		<div class="divider"><span>Ge-uploadde foto's</span></div>
		<div class="image-container">
			<div *ngFor="let photo of buildingReport.photos.inspector; let i = index">
				<img alt="" src="{{ photo.filename_url }}">
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="buildingReport?.photos?.by_client">
		<div class="divider"><span>Ge-uploadde foto's van klant</span></div>
		<div class="image-container">
			<div *ngFor="let photo of buildingReport.photos.by_client; let i = index">
				<ng-container *ngIf="photo.offline">
					<img alt="" src="{{ offlinePhoto[i] }}">
				</ng-container>
				<ng-container *ngIf="!photo.offline">
					<img alt="" src="{{ photo.filename_url }}">
				</ng-container>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="buildingReport.color_code !== 2 && buildingReport.color_code !== '2'">
		<div class="divider"><span>Afvinken</span></div>
		<ng-container>
			<div class="form-line">
				<div class="form-label">Is dit punt opgelost?</div>
				<div class="form-content">
					<span *ngIf="buildingReport.client_fixed_status">Ja</span>
					<span *ngIf="!buildingReport.client_fixed_status">Nee</span>
				</div>
			</div>
			<div class="form-line">
				<div class="form-label">Datum</div>
				<div class="form-content" *ngIf="buildingReport.client_fixed_date">{{ buildingReport.client_fixed_date | datex }}</div>
				<div class="form-content" *ngIf="!buildingReport.client_fixed_date">-</div>
			</div>
			<div class="form-line">
				<div class="form-label">Oplossen details</div>
				<div class="form-content" *ngIf="buildingReport.client_fixed_comment">{{ buildingReport.client_fixed_comment }}</div>
				<div class="form-content" *ngIf="!buildingReport.client_fixed_comment">-</div>
			</div>
		</ng-container>
	</ng-container>
</ng-container>
