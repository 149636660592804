import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {Subscription} from 'rxjs/Subscription';
import {ServerResponse} from '../../../_interfaces/server.response';
import {ApiService} from '../../../_interceptors/api.service';
import {User} from '../../../_interfaces/user';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {APP_DATE_FORMATS, CustomDateAdapter} from '../../../_pipes/angular2-material-datepicker-custom-date';
import * as moment from 'moment';
import {PlanningBasic} from '../../../_interfaces/planning/basic';
import {PlanningService} from '../../../_services/planning.service';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';

@Component({
	selector: 'app-planning-list',
	templateUrl: './planning-list.component.html',
	styleUrls: ['./planning-list.component.scss'],
	animations: [routeAnimation],
	providers: [
		{
			provide: DateAdapter, useClass: CustomDateAdapter
		},
		{
			provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
		}
	]
})

export class PlanningListComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	private totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	private isLoading: boolean = false;
	private keepLoading: boolean = false;

	public user: User = null;
	public planning: PlanningBasic[] = [];
	public start: number = 0;
	public end: number = 0;
	public activeDayButton: number = 0;

	public planningForm = this.formBuilder.group({
		planning_date: ['', Validators.required],
		planning_date_input: ['', Validators.required]
	});

	private _authenticationService: Subscription = null;
	private _errorsSubscription: Subscription = null;

	public weekendsDatesFilter = (d: Date): boolean => {
		const day = d.getDay();

		/* Prevent Sunday for select. */
		return day !== 0;
	}

	constructor(public planningService: PlanningService,
				public formsService: FormsService,
				public formBuilder: FormBuilder,
				private apiService: ApiService) {
	}

	ngOnInit(): void {
		this.formsService.form = this.planningForm;

		this._errorsSubscription = this.planningService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this.apiService.connectionChanged.subscribe(online => {
			console.log(online, this.planning);
			if (online && !this.planning.length) {
				this.currentPage = 0;
				this.getPlanning(false);
			}
		});

		this.selectDate();
	}

	ngOnDestroy(): void {
		if (this._authenticationService !== null) {
			this._authenticationService.unsubscribe();
		}
	}

	getPlanning(append_data: boolean = true) {
		if (!this.isLoading || this.keepLoading) {
			this.isLoading = true;
			this.keepLoading = false;
			this.currentPage++;

			this.planningService.getPlannings({
				startDate: this.start,
				endDate: this.end
			}).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.recordsTotal !== 'undefined') {
						this.totalItems = data.recordsTotal;
					}
					if (append_data !== true) {
						this.planning = [];
					}
					if (typeof data.data !== 'undefined') {
						data.data.forEach(planning => {
							this.planning.push(<PlanningBasic>planning);
						});
					}
				}
				this.isLoading = false;
			});
		}
	}

	search(event) {
		this.searchString = event;
		this.currentPage = 0;
		this.getPlanning(false);
	}

	planningDateChange($event) {
		this.selectDate(moment($event.value).valueOf());
	}

	selectDate(date: number = null, day: number = null) {
		if (!date && !day) {
			day = moment().isoWeekday();
		}

		if (day) {
			if (day === 7) {
				this.start = moment().isoWeekday(6).valueOf();
				this.activeDayButton = 6;
			} else {
				this.start = moment().isoWeekday(day).valueOf();
				this.activeDayButton = day;
			}
		} else if (date) {
			let weekStart = moment().isoWeekday(1).valueOf(),
				weekEnd = moment().isoWeekday(7).valueOf(),
				current = moment(date).valueOf();
			if (current >= weekStart && current <= weekEnd) {
				this.activeDayButton = moment(date).isoWeekday();
			} else {
				this.activeDayButton = null;
			}
			this.start = date;
		}

		this.planningForm.get('planning_date_input').setValue(new Date(this.start));
		this.getPlanning(false);
	}

	selectToday() {
		this.selectDate(moment().valueOf());
	}

	previousDay() {
		let day = 1,
			date = moment(this.start);

		if (date.isoWeekday() === 1) {
			day = 2;
		}
		this.selectDate(moment(this.start).subtract(day, 'day').valueOf());
	}

	nextDay() {
		let day = 1,
			date = moment(this.start);

		if (date.isoWeekday() === 6) {
			day = 2;
		}
		this.selectDate(moment(this.start).add(day, 'day').valueOf());
	}

	/*
	<div class="planning-buttons">
	<button type="button" mat-flat-button (click)="selectDate(null, 1)" [class.active]="activeDayButton === 1">Mo</button>
	<button type="button" mat-flat-button (click)="selectDate(null, 2)" [class.active]="activeDayButton === 2">Tu</button>
	<button type="button" mat-flat-button (click)="selectDate(null, 3)" [class.active]="activeDayButton === 3">We</button>
	<button type="button" mat-flat-button (click)="selectDate(null, 4)" [class.active]="activeDayButton === 4">Th</button>
	<button type="button" mat-flat-button (click)="selectDate(null, 5)" [class.active]="activeDayButton === 5">Fr</button>
</div>
*/
}
