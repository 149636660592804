/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./barcode-scanner.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./barcode-scanner.component";
var styles_BarcodeScannerComponent = [i0.styles];
var RenderType_BarcodeScannerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_BarcodeScannerComponent, data: {} });
export { RenderType_BarcodeScannerComponent as RenderType_BarcodeScannerComponent };
export function View_BarcodeScannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "viewport"], ["id", "interactive"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "video", [["autoplay", ""], ["muted", ""], ["playsinline", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["color", "primary"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(_co.barcode) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["CLOSE"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 7).disabled || null); var currVal_1 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_0, currVal_1); }); }
export function View_BarcodeScannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-barcode-scanner", [], null, null, null, View_BarcodeScannerComponent_0, RenderType_BarcodeScannerComponent)), i1.ɵdid(1, 114688, null, 0, i7.BarcodeScannerComponent, [i1.ChangeDetectorRef, i2.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BarcodeScannerComponentNgFactory = i1.ɵccf("app-barcode-scanner", i7.BarcodeScannerComponent, View_BarcodeScannerComponent_Host_0, {}, {}, []);
export { BarcodeScannerComponentNgFactory as BarcodeScannerComponentNgFactory };
