<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
<mat-list class="mat-list-with-border" infiniteScroll infiniteScrollDistance="1" (scrolled)="getInspectorsInspections()">
	<mat-list-item *ngFor="let inspectorInspection of inspectorsInspections">
		<div class="mat-list-content">
			<h4 matLine>{{ inspectorInspection.next_inspection_date | datex:'DD-MM-YYYY' }}</h4>
			<span matLine>{{ inspectorInspection.company_name }}</span>
		</div>
		<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a *ngIf="apiService.isOnline" mat-menu-item [routerLink]="['/clients/' + inspectorInspection.client_id + '/change-next-inspection-date']">Aanpassen aankomende inspectie datum</a>
			<a *ngIf="apiService.isOnline" mat-menu-item [routerLink]="['/clients/' + inspectorInspection.client_id + '/next-inspection-notification']">Verstuur volgende inspectie notificatie</a>
			<a *ngIf="!apiService.isOnline" mat-menu-item (click)="apiService.notAvailableOffline()">Aanpassen aankomende inspectie datum</a>
			<a *ngIf="!apiService.isOnline" mat-menu-item (click)="apiService.notAvailableOffline()">Verstuur volgende inspectie notificatie</a>
			<a *ngIf="inspectorInspection.type" mat-menu-item [routerLink]="['/reports/' + inspectorInspection.type + '/create/' + inspectorInspection.client_id]">Nieuwe rapportage</a>
		</mat-menu>
	</mat-list-item>
</mat-list>
