<ng-container *ngIf="dataLoaded && !missingData.length">
	<div class="divider"><span>Apparaat gegevens</span></div>
	<dl>
		<dt>ID</dt>
		<dd>{{ inspectorCalibration.device_id }}</dd>
		<dt>Merk</dt>
		<dd>{{ inspectorCalibration.device_brand }}</dd>
		<dt>Meet type materiaal</dt>
		<dd>{{ inspectorCalibration.device_description }}</dd>
	</dl>
	<div class="divider"><span>Kalibratie gegevens</span></div>
	<dl>
		<dt>Kalibratie datum</dt>
		<dd *ngIf="inspectorCalibration.date_calibrated">{{ inspectorCalibration.date_calibrated | datex:'DD-MM-YYYY' }}</dd>
		<dd *ngIf="!inspectorCalibration.date_calibrated">-</dd>
		<dt>Volgende datum kalibratie</dt>
		<dd *ngIf="inspectorCalibration.next_calibration_date">{{ inspectorCalibration.next_calibration_date | datex:'DD-MM-YYYY' }}</dd>
		<dd *ngIf="!inspectorCalibration.next_calibration_date">-</dd>
		<dt>Certificaat nummer</dt>
		<dd *ngIf="inspectorCalibration.certificate_number">{{ inspectorCalibration.certificate_number }}</dd>
		<dd *ngIf="!inspectorCalibration.certificate_number">-</dd>
	</dl>
	<button mat-button color="primary-outline" [routerLink]="['/inspectors/calibrations/' + inspectorCalibration.id + '/edit']">Aanpassen</button>
	<button mat-button color="accent-outline" (click)="deleteInspectorCalibration(inspectorCalibration.id)">Verwijderen</button>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
