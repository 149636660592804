<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/view']">Bekijk rapportage</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/open']">Bekijk alle rapportages</button>
<ng-container *ngIf="dataLoaded && !missingData.length">
	<div *ngIf="!reportEditable" class="alert alert-danger" fxFlex fxLayoutAlign="center" fxLayout="row">
		<mat-icon>warning</mat-icon>
		<h2>Rapport is vergrendeld door een andere gebruiker. Om het te bewerken, moet u eerst de rapportage ontgrendelen.</h2>
	</div>
	<app-reports-view-small-widget [report]="report"></app-reports-view-small-widget>
	<app-clients-view-small-widget [client]="client"></app-clients-view-small-widget>
	<form class="with-action-buttons" [formGroup]="reportSendToClientForm" (ngSubmit)="reportsService.sendToClient(report_type, report_id, reportSendToClientForm.value)">
		<div class="divider"><span>Duur van inspectie</span></div>
		<div fxLayout="row">
			<mat-form-field style="margin-right:7px;">
				<mat-select placeholder="Dagen" formControlName="inspection_duration_day" required>
					<mat-option *ngFor="let day of days" value="{{ day }}">{{ day }}</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field style="margin-right:7px;">
				<mat-select placeholder="Uren" formControlName="inspection_duration_hour" required>
					<mat-option *ngFor="let hour of hours" value="{{ hour }}">{{ hour }}</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field style="margin-left:7px;">
				<mat-select placeholder="Minuten" formControlName="inspection_duration_minute" required>
					<mat-option *ngFor="let minute of minutes" value="{{ minute }}">{{ minute }}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="divider"><span>Verstuur naar</span></div>
		<div *ngFor="let contactPerson of contactPersons; let i = index" formArrayName="contact_persons_ids">
			<mat-checkbox color="primary" formControlName="{{ i }}" value="{{ contactPerson.id }}" (change)="fixAngularScrewUpAndInsertActualValue($event, i)">{{ contactPerson.firstname }} {{ contactPerson.lastname }} <span class="small">({{ contactPerson.email }})</span></mat-checkbox>
		</div>
		<div class="divider"><span>Verstuur naar aangepaste ontvanger</span></div>
		<mat-form-field>
			<textarea title="" matInput placeholder="Aangepaste ontvanger" formControlName="custom_contacts" rows="8"></textarea>
			<mat-error *ngIf="formsService.getFieldError('custom_contacts')">{{formsService.getFieldError('custom_contacts')}}</mat-error>
		</mat-form-field>
		<div class="divider"><span>Bijlagen</span></div>
		<div>
			<mat-checkbox color="primary" formControlName="include_certificate" value="1">Bewijs van inspectie</mat-checkbox>
		</div>
		<div class="divider"><span>Bericht gegevens</span></div>
		<mat-form-field>
			<textarea title="" matInput placeholder="Notities" formControlName="message"></textarea>
			<mat-error *ngIf="formsService.getFieldError('message')">{{formsService.getFieldError('message')}}</mat-error>
		</mat-form-field>
		<div class="action-buttons">
			<button *ngIf="reportEditable" color="primary" mat-button [disabled]="!reportSendToClientForm.valid">VERSTUUR</button>
		</div>
	</form>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
