import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {GeneralService} from '../../../../_services/general.service';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {InspectorsCalibration} from '../../../../_interfaces/inspectors/inspectors-calibration';
import {InspectorsService} from '../../../../_services/inspectors.service';
import {ApiService} from '../../../../_interceptors/api.service';

@Component({
	selector: 'app-inspectors-calibrations-view',
	templateUrl: './calibrations-view.component.html',
	styleUrls: ['./calibrations-view.component.scss'],
	animations: [routeAnimation]
})

export class InspectorsCalibrationsViewComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public calibration_id: number = null;
	public inspectorCalibration: InspectorsCalibration = null;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];

	private _activatedRouteSubscription: Subscription = null;

	constructor(private inspectorsService: InspectorsService,
				private activatedRoute: ActivatedRoute,
				private apiService: ApiService,
				private generalService: GeneralService,
				private previousUrlService: PreviousUrlService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length > 0) {
				this.missingData = [];
				this.loadData();
			}
		});

		this.previousUrlService.setPreviousUrlIfNull('/inspectors/calibrations');

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.calibration_id = parseInt(params['calibration_id'], 10);

			this.loadData();
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
	}

	loadData() {
		this.inspectorsService.getInspectorCalibration(this.calibration_id).then((calibration: InspectorsCalibration) => {
			if (typeof calibration !== 'undefined') {
				this.inspectorCalibration = calibration;

				this.dataLoaded = true;
			} else {
				this.missingData.push('Kalibratie');
			}
		});
	}

	deleteInspectorCalibration(calibration_id: number) {
		this.inspectorsService.deleteInspectorCalibration(calibration_id).then(status => {
			if (status === true) {
				this.previousUrlService.goTo('/inspectors/calibrations');
			}
		});
	}
}
