import {FormControl} from '@angular/forms';

export function MultipleEmailsValidator() {

	let thisControl: FormControl;

	return function MultipleEmailsValidate(control: FormControl) {
		if (!control.parent) {
			return null;
		}

		if (!thisControl) {
			thisControl = control;
		}

		if (thisControl.value !== '') {
			let lines = thisControl.value.split('\n'),
				result: boolean = null,
				test: boolean = false;
			if (lines.length) {
				lines.forEach(line => {
					let regexp = new RegExp('^(\\s?[^\\s,]+@[^\\s,]+\\.[^\\s,]+\\s?,)*(\\s?[^\\s,]+@[^\\s,]+\\.[^\\s,]+)$', 'gm');
					test = regexp.test(line);
					if (test === false && result !== true) {
						result = test;
					}
				});
			}
			if (result === false) {
				return {
					invalid: 'Een van de ingevoerde e-mail adressen is ongeldig'
				};
			}
		}

		return null;
	};
}
