import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {Subscription} from 'rxjs/Subscription';
import {ClientsService} from '../../../../_services/clients.service';
import {ActivatedRoute, Params} from '@angular/router';
import {GeneralService} from '../../../../_services/general.service';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {ApiService} from '../../../../_interceptors/api.service';
import {ServerResponse} from '../../../../_interfaces/server.response';
import {PageTitleService} from '../../../../_services/page-title.service';
import {environment} from '../../../../../environments/environment';
import {Client} from '../../../../_interfaces/clients/clients';
import {ClientsBuildingMaps} from '../../../../_interfaces/clients/clients-building-maps';

@Component({
	selector: 'app-clients-building-maps-list',
	templateUrl: './clients-building-maps-list.component.html',
	styleUrls: ['./clients-building-maps-list.component.scss'],
	animations: [routeAnimation]
})

export class ClientsBuildingMapsListComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	public client_id: number = null;
	public isLoading: boolean = false;
	public keepLoading: boolean = false;

	public clientBuildingMaps: ClientsBuildingMaps[] = [];

	private _activatedRouteSubscription: Subscription = null;

	@Input() smallWidget: boolean = false;

	constructor(public clientsService: ClientsService,
				private generalService: GeneralService,
				private previousUrlService: PreviousUrlService,
				private apiService: ApiService,
				private activatedRoute: ActivatedRoute,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && !this.clientBuildingMaps.length) {
				this.currentPage = 0;
				this.getClientBuildingMaps(false);
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.client_id = parseInt(params['client_id'], 10);

			this.previousUrlService.setPreviousUrlIfNull('/clients/' + this.client_id + '/view');

			if (this.smallWidget === false) {
				this.clientsService.getClient(this.client_id).then((client: Client) => {
					if (typeof client !== 'undefined') {
						this.pageTitleService.setTitle(client.company_name + ' | Plattegronds | ' + environment.webappName);
						this.pageTitleService.setPageTitle(client.company_name);
					}
				});
			}

			this.getClientBuildingMaps(false);
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
	}

	getClientBuildingMaps(append_data: boolean = true) {
		if (!this.isLoading || this.keepLoading) {
			this.isLoading = true;
			this.keepLoading = false;
			this.currentPage++;

			this.clientsService.getBuildingMaps(this.client_id, {
				start: (this.currentPage - 1) * (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
				length: (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
				'columns[0][search][value]': this.searchString
			}).then((data: ServerResponse) => {
				if (typeof data.recordsTotal !== 'undefined') {
					this.totalItems = data.recordsTotal;
				}
				if (append_data === true) {
					this.clientBuildingMaps.push(...<ClientsBuildingMaps[]>data.data);
				} else {
					this.clientBuildingMaps = <ClientsBuildingMaps[]>data.data;
				}
				this.isLoading = false;
			});
		}
	}

	deleteClientBuildingMap(client_id: number, building_map_id: number) {
		this.clientsService.deleteBuildingMap(client_id, building_map_id).then(status => {
			if (status === true) {
				let index = this.clientBuildingMaps.findIndex(data => data.id === building_map_id);

				if (index !== -1) {
					this.clientBuildingMaps.splice(index, 1);
				}
			}
		});
	}

	search($event) {
		this.searchString = $event;
		this.currentPage = 0;
		this.getClientBuildingMaps(false);
	}
}
