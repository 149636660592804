import {Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {LoadingOverlayService} from './loading-overlay.service';
import {ApiService} from '../_interceptors/api.service';
import {SnackbarService} from './snackbar.service';
import {ServerResponse} from '../_interfaces/server.response';
import {ClientsAttachments, ClientsAttachmentsResponse} from '../_interfaces/clients/clients-attachment';
import {Client, ClientResponse} from '../_interfaces/clients/clients';
import {ClientsContactsPersons, ClientsContactsPersonsResponse} from '../_interfaces/clients/clients-contacts-persons';
import {ClientsNextInspectionNotification} from '../_interfaces/clients/clients-next-inspection-notification';
import {ClientsChangeNextInspectionDate} from '../_interfaces/clients/clients-change-next-inspection-date';
import {PreviousUrlService} from './previous-url.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SimpleModalDialogComponent} from '../core/simple-modal/simple-modal.component';
import {IndexedDBService} from './indexeddb.service';
import * as moment from 'moment';
import {environment} from '../../environments/environment';
import {ClientsBuildingMaps, ClientsBuildingMapsResponse} from '../_interfaces/clients/clients-building-maps';

@Injectable()

export class ClientsService {

	public errors: Subject<any> = new BehaviorSubject(null);
	public clientDeleted: Subject<number> = new BehaviorSubject(null);

	private startIndex: number = 0;
	private endIndex: number = -1;
	private searchString: string = null;
	private debug: boolean = !environment.production;

	constructor(private apiService: ApiService,
				private dialogService: MatDialog,
				private overlayService: LoadingOverlayService,
				private snackbarService: SnackbarService,
				private previousUrlService: PreviousUrlService,
				private indexedDBService: IndexedDBService,
				private router: Router) {
	}

	private formData(formData: any): void {
		this.startIndex = 0;
		this.endIndex = -1;
		this.searchString = null;

		if (formData !== null) {
			if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
				this.startIndex = parseInt(formData['start'], 10);
				this.endIndex = parseInt(formData['length'], 10);
				if (this.endIndex !== -1) {
					this.endIndex += this.startIndex;
				}
			}
			if (typeof formData['columns[0][search][value]'] !== 'undefined') {
				if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
					this.searchString = formData['columns[0][search][value]'].toLowerCase();
				}
			}
		}
	}

	getAttachments(client: Client, formData = null): Promise<ClientsAttachmentsResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.formData(formData);

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableClientsAttachments)
						.then((attachments: ClientsAttachments[]) => {
								if (typeof attachments !== 'undefined') {
									resolve(this.processAttachments(client, attachments));
								} else {
									resolve(this.getAttachmentsFromServer(client));
								}
							},
							() => resolve(this.getAttachmentsFromServer(client)))
						.catch(() => resolve(this.getAttachmentsFromServer(client)));
				}
			});
		});
	}

	private getAttachmentsFromServer(client: Client): Promise<ClientsAttachmentsResponse> {
		return new Promise(resolve => {
			this.syncClientsAttachments().then((attachments: ClientsAttachments[]) => {
				if (typeof attachments !== 'undefined') {
					resolve(this.processAttachments(client, attachments));
				} else {
					resolve(undefined);
				}
			}).catch(() => resolve(undefined));
		});
	}

	private processAttachments(client: Client, attachments: ClientsAttachments[]): Promise<ClientsAttachmentsResponse> {
		return new Promise(resolve => {
			console.log(attachments);
			let collectionData: ClientsAttachments[] = attachments.filter(attachment =>
				(attachment.client_id === client.id ||
					attachment.client_id === client.head_office_id) &&
				(typeof attachment.indexedDBMethod === 'undefined' || typeof attachment.indexedDBMethod !== 'undefined' && attachment.indexedDBMethod !== '3-delete')
			);

			console.log(collectionData);

			if (this.searchString !== null) {
				collectionData = collectionData.filter(attachment => attachment.title.toLowerCase().indexOf(this.searchString) !== -1);
			}

			collectionData.sort((a, b) => a.created > b.created ? 1 : -1);

			resolve({
				recordsTotal: collectionData.length,
				data: this.endIndex !== -1 ? collectionData.slice(this.startIndex, this.endIndex) : collectionData.slice()
			});
		});
	}

	getAttachment(client_id: number, attachment_id: number): Promise<ClientsAttachments> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getByKey(this.indexedDBService.tableClientsAttachments, attachment_id)
						.then((attachment: ClientsAttachments) => {
								if (typeof attachment !== 'undefined') {
									resolve(attachment);
								} else {
									resolve(this.getAttachmentFromServer(client_id, attachment_id));
								}
							},
							() => resolve(this.getAttachmentFromServer(client_id, attachment_id)))
						.catch(() => resolve(this.getAttachmentFromServer(client_id, attachment_id)));
				}
			});
		});
	}

	private getAttachmentFromServer(client_id: number, attachment_id: number): Promise<ClientsAttachments> {
		return new Promise(resolve => {
			this.syncClientsAttachments().then((attachments: ClientsAttachments[]) => {
				if (typeof attachments !== 'undefined') {
					resolve(this.processAttachment(client_id, attachment_id, attachments));
				} else {
					resolve(undefined);
				}
			}).catch(() => resolve(undefined));
		});
	}

	private processAttachment(client_id: number, attachment_id: number, attachments: ClientsAttachments[]): Promise<ClientsAttachments> {
		return new Promise(resolve => {
			let resultsData = attachments.filter(attachment =>
				(attachment.client_id === client_id || attachment.head_office_id === client_id) &&
				attachment.id === attachment_id
			);

			if (resultsData.length) {
				resolve(resultsData[0]);
			} else {
				resolve(undefined);
			}
		});
	}

	uploadNewAttachment(client_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('clients/clients/' + client_id + '/attachments', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Bijlage opgeslagen!');

					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableClientsAttachments, <ClientsAttachments>data.data).then(
							() => {
							},
							error => {
								console.log(error);
								throw new Error(error);
							}
						);
					}

					if (this.previousUrlService.getPreviousUrl() !== '' && this.previousUrlService.getPreviousUrl() !== '/') {
						this.previousUrlService.goToPreviousUrl();
					} else {
						this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			formData['indexedDBMethod'] = '1-create';
			formData['client_id'] = client_id;
			formData['created'] = moment().valueOf();
			formData['id'] = moment().valueOf();

			let newFormData = {
				id: formData['id'],
				client_id: client_id,
				created: formData['created'],
				description: formData['description'],
				filename: formData.filename._files[0].name,
				original_filename: formData.filename._files[0].name,
				title: formData['title'],
				append_ladder: formData['append_ladder'],
				append_warehouse: formData['append_warehouse'],
				append_tent: formData['append_tent'],
			};

			this.indexedDBService.database.add(this.indexedDBService.tableClientsAttachments, newFormData).then(() => {
				this.snackbarService.success('Bijlage opgeslagen!');

				this.indexedDBService.database.add(this.indexedDBService.tableClientsAttachmentsPush, <ClientsAttachments>formData).then(
					() => {
					},
					error => {
						console.log(error);
						throw new Error(error);
					}
				);

				if (this.previousUrlService.getPreviousUrl() !== '' && this.previousUrlService.getPreviousUrl() !== '/') {
					this.previousUrlService.goToPreviousUrl();
				} else {
					this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
					});
				}
			}, error => {
				this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				throw new Error(error);
			});
		}
	}

	updateAttachment(client_id: number, attachment_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('clients/clients/' + client_id + '/attachments/' + attachment_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Bijlage opgeslagen!');

					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableClientsAttachments, <ClientsAttachments>data.data).then(
							() => {
							}, error => {
								console.log(error);
								throw new Error(error);
							}
						);
					}

					if (this.previousUrlService.getPreviousUrl() !== '' && this.previousUrlService.getPreviousUrl() !== '/') {
						this.previousUrlService.goToPreviousUrl();
					} else {
						this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableClientsAttachments, 'id', attachment_id).then(attachment => {
				let newAttachment: ClientsAttachments = {...attachment, ...formData};
				this.indexedDBService.database.update(this.indexedDBService.tableClientsAttachments, <ClientsAttachments>newAttachment).then(() => {
					this.snackbarService.success('Bijlage opgeslagen!');

					newAttachment['indexedDBMethod'] = '2-update';
					this.indexedDBService.database.add(this.indexedDBService.tableClientsAttachmentsPush, newAttachment).then(
						() => {
						}, error => {
							console.log(error);
							throw new Error(error);
						}
					);

					if (this.previousUrlService.getPreviousUrl() !== '' && this.previousUrlService.getPreviousUrl() !== '/') {
						this.previousUrlService.goToPreviousUrl();
					} else {
						this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
						});
					}
				}, error => {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
					throw new Error(error);
				});
			}, error => {
				console.log(error);
				throw new Error(error);
			});
		}
	}

	deleteAttachment(client_id: number, attachment_id: number): Promise<boolean> {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Weet u zeker dat u deze bijlage wilt verwijderen?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('clients/clients/' + client_id + '/attachments/' + attachment_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Bijlage verwijderd.');

								this.indexedDBService.database.delete(this.indexedDBService.tableClientsAttachments, attachment_id).then(
									() => {
									}, error => {
										console.log(error);
										throw new Error(error);
									}
								);

								resolve(true);
							} else {
								this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
								resolve(false);
							}
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableClientsAttachments, attachment_id).then(() => {
							this.snackbarService.success('Bijlage verwijderd.');

							let formData = {
								'client_id': client_id,
								'id': attachment_id,
								'indexedDBMethod': '3-delete'
							};
							this.indexedDBService.database.add(this.indexedDBService.tableClientsAttachmentsPush, formData).then(
								() => {
								}, error => {
									console.log(error);
									throw new Error(error);
								}
							);

						}, error => {
							this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
							throw new Error(error);
						});
					}
				}
			});
		});
	}

	downloadFile(url: string, filename: string) {
		if (this.apiService.isOnline) {
			if (typeof url !== 'undefined') {
				this.apiService.download(url, filename);
			} else {
				this.snackbarService.error('Ongeldige link, kan niet worden gedownload.');
			}
		} else {
			this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
		}
	}

	getClients(formData = null): Promise<ClientResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event === true) {
					this.formData(formData);

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableClients)
						.then(clients => {
								if (typeof clients !== 'undefined') {
									resolve(this.processClients(clients));
								} else {
									resolve(this.getClientsFromServer());
								}
							},
							() => resolve(this.getClientsFromServer()))
						.catch(() => resolve(this.getClientsFromServer()));
				}
			});
		});
	}

	private getClientsFromServer(): Promise<ClientResponse> {
		return new Promise(resolve => {
			this.syncClients().then((clients: Client[]) => {
				if (typeof clients !== 'undefined') {
					resolve(this.processClients(clients));
				} else {
					resolve(undefined);
				}
			}).catch(() => resolve(undefined));
		});
	}

	private processClients(clients: Client[]): Promise<ClientResponse> {
		return new Promise(resolve => {
			let collectionData = clients.filter(client =>
				typeof client.indexedDBMethod === 'undefined' ||
				typeof client.indexedDBMethod !== 'undefined' && client.indexedDBMethod !== '3-delete'
			);

			if (this.searchString !== null) {
				collectionData = collectionData.filter(client => client.company_name.toLowerCase().indexOf(this.searchString) !== -1);
			}

			collectionData.sort((a, b) => a.company_name.toLowerCase() > b.company_name.toLowerCase() ? 1 : -1);

			resolve({
				recordsTotal: collectionData.length,
				data: this.endIndex !== -1 ? collectionData.slice(this.startIndex, this.endIndex) : collectionData.slice()
			});
		});
	}

	getClient(client_id: number): Promise<Client> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event === true) {
					this.indexedDBService.database.getByKey(this.indexedDBService.tableClients, client_id)
						.then(client => {
								if (typeof client !== 'undefined') {
									resolve(client);
								} else {
									resolve(this.getClientFromServer(client_id));
								}
							},
							() => resolve(this.getClientFromServer(client_id)))
						.catch(() => resolve(this.getClientFromServer(client_id)));
				}
			});
		});
	}

	private getClientFromServer(client_id: number): Promise<Client> {
		return new Promise(resolve => {
			this.syncClients().then((clients: Client[]) => {
				if (typeof clients !== 'undefined') {
					resolve(this.processClient(client_id, clients));
				} else {
					resolve(undefined);
				}
			}).catch(() => resolve(undefined));
		});
	}

	private processClient(client_id: number, clients: Client[]): Promise<Client> {
		return new Promise(resolve => {
			let resultsData = clients.filter(client => client.id === client_id);

			if (resultsData.length) {
				resolve(resultsData[0]);
			} else {
				resolve(undefined);
			}
		});
	}

	createClient(formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('clients/clients', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Klant opgeslagen!');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableClients, <Client>data.data).then(
							() => {
							}, error => {
								console.log(error);
								throw new Error(error);
							}
						);

						this.router.navigate(['/clients/' + data.data.id + '/view']).then(() => {
						});
					} else {
						this.router.navigate(['/clients']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			formData['id'] = moment().valueOf();
			formData['last_inspection_date'] = null;
			formData['next_inspection_date'] = null;
			formData['next_inspection_email_sent'] = null;
			delete formData['country_id_filterString'];

			let pushClientData = formData;
			pushClientData['indexedDBMethod'] = '1-create';
			pushClientData['date_created'] = moment().valueOf();
			this.indexedDBService.database.add(this.indexedDBService.tableClients, <Client>formData).then(id => {
				this.snackbarService.success('Klant opgeslagen!');

				this.indexedDBService.database.add(this.indexedDBService.tableClientsPush, <Client>pushClientData).then(
					() => {
					}, error => {
						console.log(error);
						throw new Error(error);
					}
				);

				this.router.navigate(['/clients/' + id + '/view']).then(() => {
				});
			}, error => {
				this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				throw new Error(error);
			});
		}
	}

	updateClient(client_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('clients/clients/' + client_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Klant opgeslagen!');

					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableClients, data.data).then(
							() => {
							}, error => {
								console.log(error);
								throw new Error(error);
							}
						);
					}
					this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableClients, 'id', client_id).then(client => {
				let newClient: Client = {...client, ...formData};

				this.indexedDBService.database.update(this.indexedDBService.tableClients, <Client>newClient).then(() => {
					this.snackbarService.success('Klant opgeslagen!');

					newClient['indexedDBMethod'] = '2-update';
					newClient['date_updated'] = moment().valueOf();
					this.indexedDBService.database.add(this.indexedDBService.tableClientsPush, newClient).then(
						() => {
						}, error => {
							console.log(error);
							throw new Error(error);
						}
					);

					this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
					});
				}, error => {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
					throw new Error(error);
				});
			}, error => {
				console.log(error);
				throw new Error(error);
			});
		}
	}

	deleteClient(client_id: number): Promise<boolean> {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Weet u zeker dat u deze klant wilt verwijderen?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						this.apiService.delete('clients/clients/' + client_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Klant verwijderd.');

								this.indexedDBService.database.delete(this.indexedDBService.tableClients, client_id).then(() => {
								}, error => {
									console.log(error);
									throw new Error(error);
								});

								this.cleanupAfterClientDeletion(client_id);

								resolve(true);
							} else {
								this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
								resolve(false);
							}
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableClients, client_id).then(() => {
							this.snackbarService.success('Klant verwijderd.');

							this.indexedDBService.database.add(this.indexedDBService.tableClientsPush, {
								id: client_id,
								indexedDBMethod: '3-delete'
							}).then(() => {
							}, error => {
								console.log(error);
								throw new Error(error);
							});

							this.cleanupAfterClientDeletion(client_id);

							resolve(true);
						}, error => {
							this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
							resolve(false);
							throw new Error(error);
						});
					}
				}
			});
		});
	}

	cleanupAfterClientDeletion(client_id: number) {
		this.indexedDBService.database.openCursorWithIndex(this.indexedDBService.tableClientsAttachments, 'client_id', event => {
			let cursor = event.target.result;
			if (cursor) {
				if (cursor.value.client_id === client_id) {
					this.indexedDBService.database.delete(this.indexedDBService.tableClientsAttachments, cursor.value.id).then(() => {
					}, error => {
						console.log(error);
						throw new Error(error);
					});
				}
				cursor.continue();
			}
		}).then(() => {
		}, error => {
			console.log(error);
			throw new Error(error);
		});

		this.indexedDBService.database.openCursorWithIndex(this.indexedDBService.tableClientsContactPersons, 'client_id', event => {
			let cursor = event.target.result;
			if (cursor) {
				if (cursor.value.client_id === client_id) {
					this.indexedDBService.database.delete(this.indexedDBService.tableClientsContactPersons, cursor.value.id).then(() => {
					}, error => {
						console.log(error);
						throw new Error(error);
					});
				}
				cursor.continue();
			}
		}).then(() => {
		}, error => {
			console.log(error);
			throw new Error(error);
		});

		this.clientDeleted.next(client_id);
	}

	getContactPersons(client_id: number, formData = null): Promise<ClientsContactsPersonsResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.formData(formData);

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableClientsContactPersons)
						.then(contactPersons => {
								if (typeof contactPersons !== 'undefined') {
									resolve(this.processContactPersons(client_id, contactPersons));
								} else {
									resolve(this.getContactPersonsFromServer(client_id));
								}
							},
							() => resolve(this.getContactPersonsFromServer(client_id)))
						.catch(() => resolve(this.getContactPersonsFromServer(client_id)));
				}
			});
		});
	}

	private getContactPersonsFromServer(client_id: number): Promise<ClientsContactsPersonsResponse> {
		return new Promise(resolve => {
			this.syncClientsContactPersons().then((contactPersons: ClientsContactsPersons[]) => {
				if (typeof contactPersons !== 'undefined') {
					resolve(this.processContactPersons(client_id, contactPersons));
				} else {
					resolve(undefined);
				}
			}).catch(() => resolve(this.getContactPersonsFromServer(client_id)));
		});
	}

	private processContactPersons(client_id: number, contactPersons: ClientsContactsPersons[]): Promise<ClientsContactsPersonsResponse> {
		return new Promise(resolve => {
			let collectionData = contactPersons.filter(contactPerson =>
				contactPerson.client_id === client_id &&
				(typeof contactPerson.indexedDBMethod === 'undefined' || typeof contactPerson.indexedDBMethod !== 'undefined' && contactPerson.indexedDBMethod !== '3-delete')
			);

			if (this.searchString !== null) {
				collectionData = collectionData.filter(contactPerson => contactPerson.firstname.toLowerCase().indexOf(this.searchString) !== -1);
			}

			collectionData.sort((a, b) => a.firstname.toLowerCase() > b.firstname.toLowerCase() ? 1 : -1);

			resolve({
				recordsTotal: collectionData.length,
				data: this.endIndex !== -1 ? collectionData.slice(this.startIndex, this.endIndex) : collectionData.slice()
			});
		});
	}

	getContactPerson(client_id: number, contact_person_id: number) {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getByKey(this.indexedDBService.tableClientsContactPersons, contact_person_id)
						.then(contactPerson => {
								if (typeof contactPerson !== 'undefined') {
									resolve(contactPerson);
								} else {
									resolve(this.getContactPersonFromServer(client_id, contact_person_id));
								}
							},
							() => resolve(this.getContactPersonFromServer(client_id, contact_person_id)))
						.catch(() => resolve(this.getContactPersonFromServer(client_id, contact_person_id)));
				}
			});
		});
	}

	private getContactPersonFromServer(client_id: number, contact_person_id: number): Promise<ClientsContactsPersons> {
		return new Promise(resolve => {
			this.syncClientsContactPersons().then((contactPersons: ClientsContactsPersons[]) => {
				if (typeof contactPersons !== 'undefined') {
					resolve(this.processContactPerson(client_id, contact_person_id, contactPersons));
				} else {
					resolve(undefined);
				}
			}).catch(() => resolve(undefined));
		});
	}

	private processContactPerson(client_id: number, contact_person_id: number, contact_persons: ClientsContactsPersons[]): Promise<ClientsContactsPersons> {
		return new Promise(resolve => {
			let resultsData = contact_persons.filter(contactPerson => contactPerson.client_id === client_id && contactPerson.id === contact_person_id);

			if (resultsData.length) {
				resolve(resultsData[0]);
			} else {
				resolve(undefined);
			}
		});
	}

	createContactPerson(client_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('clients/clients/' + client_id + '/contacts-persons', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Contactpersoon opgeslagen!');
					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableClientsContactPersons, <ClientsContactsPersons>data.data).then(() => {
						}, error => {
							console.log(error);
							throw new Error(error);
						});
					}
					if (this.previousUrlService.getPreviousUrl() !== '' && this.previousUrlService.getPreviousUrl() !== '/') {
						this.previousUrlService.goToPreviousUrl();
					} else {
						this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			formData['id'] = moment().valueOf();
			formData['client_id'] = client_id;
			this.indexedDBService.database.add(this.indexedDBService.tableClientsContactPersons, <ClientsContactsPersons>formData).then(() => {
				this.snackbarService.success('Contactpersoon opgeslagen!');

				formData['indexedDBMethod'] = '1-create';
				formData['date_created'] = moment().valueOf();
				this.indexedDBService.database.add(this.indexedDBService.tableClientsContactPersonsPush, formData).then(() => {
				}, error => {
					console.log(error);
					throw new Error(error);
				});

				this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
				});
			}, error => {
				this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				throw new Error(error);
			});
		}
	}

	updateContactPerson(client_id: number, contact_person_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('clients/clients/' + client_id + '/contacts-persons/' + contact_person_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Contactpersoon opgeslagen!');

					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableClientsContactPersons, <ClientsContactsPersons>data.data).then(() => {
						}, error => {
							console.log(error);
							throw new Error(error);
						});
					}

					if (this.previousUrlService.getPreviousUrl() !== '' && this.previousUrlService.getPreviousUrl() !== '/') {
						this.previousUrlService.goToPreviousUrl();
					} else {
						this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableClientsContactPersons, 'id', contact_person_id).then(contactPerson => {
				let newContactPerson: ClientsContactsPersons = {...contactPerson, ...formData};

				this.indexedDBService.database.update(this.indexedDBService.tableClientsContactPersons, <ClientsContactsPersons>newContactPerson).then(() => {
					this.snackbarService.success('Contactpersoon opgeslagen!');

					newContactPerson['indexedDBMethod'] = '2-update';
					newContactPerson['date_updated'] = moment().valueOf();
					this.indexedDBService.database.add(this.indexedDBService.tableClientsContactPersonsPush, newContactPerson).then(() => {
					}, error => {
						console.log(error);
						throw new Error(error);
					});

					this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
					});
				}, error => {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
					throw new Error(error);
				});
			}, error => {
				console.log(error);
				throw new Error(error);
			});
		}
	}

	deleteContactPerson(client_id: number, contact_person_id: number): Promise<boolean> {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Weet u zeker dat u deze contactpersoon wilt verwijderen?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						this.apiService.delete('clients/clients/' + client_id + '/contacts-persons/' + contact_person_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Contactpersoon verwijderd.');

								this.indexedDBService.database.delete(this.indexedDBService.tableClientsContactPersons, contact_person_id).then(() => {
								}, error => {
									console.log(error);
									throw new Error(error);
								});

								resolve(true);
							} else {
								this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
								resolve(false);
							}
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableClientsContactPersons, contact_person_id).then(() => {
							this.snackbarService.success('Contactpersoon verwijderd.');

							this.indexedDBService.database.add(this.indexedDBService.tableClientsContactPersonsPush, {
								id: contact_person_id,
								client_id: client_id,
								indexedDBMethod: '3-delete'
							}).then(() => {
							}, error => {
								console.log(error);
								throw new Error(error);
							});

							resolve(true);
						}, error => {
							this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
							resolve(false);
							throw new Error(error);
						});
					}
				}
			});
		});
	}

	uploadNewBuildingMap(client_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('clients/clients/' + client_id + '/building-maps', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Plattegrond opgeslagen!');

					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.add(this.indexedDBService.tableClientsBuildingsMaps, <ClientsBuildingMaps>data.data).then(
							() => {
							},
							error => {
								console.log(error);
								throw new Error(error);
							}
						);
					}

					if (this.previousUrlService.getPreviousUrl() !== '' && this.previousUrlService.getPreviousUrl() !== '/') {
						this.previousUrlService.goToPreviousUrl();
					} else {
						this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			formData['indexedDBMethod'] = '1-create';
			formData['client_id'] = client_id;
			formData['created'] = moment().valueOf();
			formData['id'] = moment().valueOf();

			let newFormData = {
				id: formData['id'],
				client_id: client_id,
				created: formData['created'],
				building_name: formData['building_name'],
				filename: formData.filename._files[0].name,
			};

			this.indexedDBService.database.add(this.indexedDBService.tableClientsBuildingsMaps, newFormData).then(() => {
				this.snackbarService.success('Plattegrond opgeslagen!');

				this.indexedDBService.database.add(this.indexedDBService.tableClientsBuildingsMapsPush, <ClientsBuildingMaps>formData).then(
					() => {
					},
					error => {
						console.log(error);
						throw new Error(error);
					}
				);

				if (this.previousUrlService.getPreviousUrl() !== '' && this.previousUrlService.getPreviousUrl() !== '/') {
					this.previousUrlService.goToPreviousUrl();
				} else {
					this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
					});
				}
			}, error => {
				this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				throw new Error(error);
			});
		}
	}

	updateBuildingMap(client_id: number, building_map_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('clients/clients/' + client_id + '/building-maps/' + building_map_id, formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Plattegrond opgeslagen!');

					if (typeof data.data !== 'undefined') {
						this.indexedDBService.database.update(this.indexedDBService.tableClientsBuildingsMaps, <ClientsBuildingMaps>data.data).then(
							() => {
							}, error => {
								console.log(error);
								throw new Error(error);
							}
						);
					}

					if (this.previousUrlService.getPreviousUrl() !== '' && this.previousUrlService.getPreviousUrl() !== '/') {
						this.previousUrlService.goToPreviousUrl();
					} else {
						this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
						});
					}
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			this.indexedDBService.database.getByIndex(this.indexedDBService.tableClientsBuildingsMaps, 'id', building_map_id).then(buildingMap => {
				let newBuildingMap: ClientsBuildingMaps = {...buildingMap, ...formData};
				this.indexedDBService.database.update(this.indexedDBService.tableClientsBuildingsMaps, <ClientsBuildingMaps>newBuildingMap).then(() => {
					this.snackbarService.success('Plattegrond opgeslagen!');

					newBuildingMap['indexedDBMethod'] = '2-update';
					this.indexedDBService.database.add(this.indexedDBService.tableClientsBuildingsMapsPush, newBuildingMap).then(
						() => {
						}, error => {
							console.log(error);
							throw new Error(error);
						}
					);

					if (this.previousUrlService.getPreviousUrl() !== '' && this.previousUrlService.getPreviousUrl() !== '/') {
						this.previousUrlService.goToPreviousUrl();
					} else {
						this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
						});
					}
				}, error => {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
					throw new Error(error);
				});
			}, error => {
				console.log(error);
				throw new Error(error);
			});
		}
	}

	deleteBuildingMap(client_id: number, building_map_id: number): Promise<boolean> {
		return new Promise((resolve) => {
			let dialogRef: MatDialogRef<SimpleModalDialogComponent>;
			dialogRef = this.dialogService.open(SimpleModalDialogComponent);
			dialogRef.componentInstance.action_type = 'delete';
			dialogRef.componentInstance.text = 'Weet u zeker dat u deze plattegrond wilt verwijderen?';
			dialogRef.beforeClosed().subscribe(response => {
				if (response === true) {
					if (this.apiService.isOnline) {
						return this.apiService.delete('clients/clients/' + client_id + '/building-maps/' + building_map_id).then((data: ServerResponse) => {
							if (data.success === true) {
								this.snackbarService.success('Plattegrond verwijderd.');

								this.indexedDBService.database.delete(this.indexedDBService.tableClientsBuildingsMaps, building_map_id).then(
									() => {
									}, error => {
										console.log(error);
										throw new Error(error);
									}
								);

								resolve(true);
							} else {
								this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
								resolve(false);
							}
						});
					} else {
						this.indexedDBService.database.delete(this.indexedDBService.tableClientsBuildingsMaps, building_map_id).then(() => {
							this.snackbarService.success('Plattegrond verwijderd.');

							let formData = {
								'client_id': client_id,
								'id': building_map_id,
								'indexedDBMethod': '3-delete'
							};
							this.indexedDBService.database.add(this.indexedDBService.tableClientsBuildingsMapsPush, formData).then(
								() => {
								}, error => {
									console.log(error);
									throw new Error(error);
								}
							);

						}, error => {
							this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
							throw new Error(error);
						});
					}
				}
			});
		});
	}

	getBuildingMaps(client_id: number, formData = null): Promise<ClientsBuildingMapsResponse> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.formData(formData);

					this.indexedDBService.database.getAllFast(this.indexedDBService.tableClientsBuildingsMaps)
						.then((buildingMaps: ClientsBuildingMaps[]) => {
								if (typeof buildingMaps !== 'undefined') {
									resolve(this.processBuildingMaps(client_id, buildingMaps));
								} else {
									resolve(this.getBuildingMapsFromServer(client_id));
								}
							},
							() => resolve(this.getBuildingMapsFromServer(client_id)))
						.catch(() => resolve(this.getBuildingMapsFromServer(client_id)));
				}
			});
		});
	}

	private getBuildingMapsFromServer(client_id: number): Promise<ClientsBuildingMapsResponse> {
		return new Promise(resolve => {
			this.syncClientsBuildingMaps().then((buildingMaps: ClientsBuildingMaps[]) => {
				if (typeof buildingMaps !== 'undefined') {
					resolve(this.processBuildingMaps(client_id, buildingMaps));
				} else {
					resolve(undefined);
				}
			}).catch(() => resolve(undefined));
		});
	}

	private processBuildingMaps(client_id: number, buildingMaps: ClientsBuildingMaps[]): Promise<ClientsBuildingMapsResponse> {
		return new Promise(resolve => {
			let collectionData: ClientsBuildingMaps[] = buildingMaps.filter(buildingMap =>
				buildingMap.client_id === client_id &&
				(typeof buildingMap.indexedDBMethod === 'undefined' || typeof buildingMap.indexedDBMethod !== 'undefined' && buildingMap.indexedDBMethod !== '3-delete')
			);

			if (this.searchString !== null) {
				collectionData = collectionData.filter(buildingMap => buildingMap.building_name.toLowerCase().indexOf(this.searchString) !== -1);
			}

			collectionData.sort((a, b) => a.created > b.created ? 1 : -1);

			resolve({
				recordsTotal: collectionData.length,
				data: this.endIndex !== -1 ? collectionData.slice(this.startIndex, this.endIndex) : collectionData.slice()
			});
		});
	}

	getBuildingMap(client_id: number, building_map_id: number): Promise<ClientsBuildingMaps> {
		return new Promise(resolve => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getByKey(this.indexedDBService.tableClientsBuildingsMaps, building_map_id)
						.then((buildingMap: ClientsBuildingMaps) => {
								if (typeof buildingMap !== 'undefined') {
									resolve(buildingMap);
								} else {
									resolve(this.getBuildingMapFromServer(client_id, building_map_id));
								}
							},
							() => resolve(this.getBuildingMapFromServer(client_id, building_map_id)))
						.catch(() => resolve(this.getBuildingMapFromServer(client_id, building_map_id)));
				}
			});
		});
	}

	private getBuildingMapFromServer(client_id: number, building_map_id: number): Promise<ClientsBuildingMaps> {
		return new Promise(resolve => {
			this.syncClientsBuildingMaps().then((buildingMaps: ClientsBuildingMaps[]) => {
				if (typeof buildingMaps !== 'undefined') {
					resolve(this.processBuildingMap(client_id, building_map_id, buildingMaps));
				} else {
					resolve(undefined);
				}
			}).catch(() => resolve(undefined));
		});
	}

	private processBuildingMap(client_id: number, building_map_id: number, buildingMaps: ClientsBuildingMaps[]): Promise<ClientsBuildingMaps> {
		return new Promise(resolve => {
			let resultsData = buildingMaps.filter(buildingMap => buildingMap.client_id === client_id && buildingMap.id === building_map_id);

			if (resultsData.length) {
				resolve(resultsData[0]);
			} else {
				resolve(undefined);
			}
		});
	}



	getNextInspectionNotificationDetails(client_id: number) {
		if (this.apiService.isOnline) {
			return this.apiService.get('clients/clients/' + client_id + '/next-inspection-notification').then((data: ServerResponse) => {
				if (data.success === true) {
					if (typeof data.data !== 'undefined') {
						return <ClientsNextInspectionNotification>data.data;
					} else {
						this.overlayService.showError();
					}
				} else {
					this.overlayService.showError();
				}
			});
		} else {
			this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
			return new Promise(resolve => resolve(undefined));
		}
	}

	sendNextInspectionNotificationDetails(client_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('clients/clients/' + client_id + '/next-inspection-notification', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Volgende inspectie notificatie verstuurd. ');
					this.indexedDBService.database.getByIndex(this.indexedDBService.tableClients, 'id', client_id).then(client => {
						if (typeof client !== 'undefined') {
							client['next_inspection_date'] = moment(formData['next_inspection_date'], 'DD-MM-YYYY').valueOf();
							this.indexedDBService.database.update(this.indexedDBService.tableClients, client);
						}
						this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
						});
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
			return new Promise(resolve => resolve(undefined));
		}
	}

	getChangeNextInspectionDate(client_id: number) {
		if (this.apiService.isOnline) {
			return this.apiService.get('clients/clients/' + client_id + '/change-next-inspection-date').then((data: ServerResponse) => {
				if (data.success === true) {
					if (typeof data.data !== 'undefined') {
						return <ClientsChangeNextInspectionDate>data.data;
					} else {
						this.overlayService.showError();
					}
				} else {
					this.overlayService.showError();
				}
			});
		} else {
			this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
			return new Promise(resolve => resolve(undefined));
		}
	}

	changeNextInspectionDate(client_id: number, formData) {
		if (this.apiService.isOnline) {
			this.apiService.post('clients/clients/' + client_id + '/change-next-inspection-date', formData).then((data: ServerResponse) => {
				if (data.success === true) {
					this.snackbarService.success('Inspectie datum verstuurd.');
					this.indexedDBService.database.getByIndex(this.indexedDBService.tableClients, 'id', client_id).then(client => {
						if (typeof client !== 'undefined') {
							client['next_inspection_date'] = moment(formData['next_inspection_date'], 'DD-MM-YYYY').valueOf();
							this.indexedDBService.database.update(this.indexedDBService.tableClients, client);
						}
						this.router.navigate(['/clients/' + client_id + '/view']).then(() => {
						});
					});
				} else if (typeof data.error !== 'undefined') {
					this.errors.next(data.error);
				} else {
					this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
				}
			});
		} else {
			this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
			return new Promise(resolve => resolve(undefined));
		}
	}

	syncClients() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('clients/clients/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('clients/clients', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableClients, <Client[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableClients,
															updated: server_date.data.updated
														})
															.then(() => resolve(<Client[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncClientsAttachments() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('clients/clients/attachments/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('clients/clients/attachments', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableClientsAttachments, <ClientsAttachments[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableClientsAttachments,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ClientsAttachments[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncClientsContactPersons() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('clients/clients/contacts-persons/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('clients/clients/contacts-persons', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableClientsContactPersons, <ClientsContactsPersons[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableClientsContactPersons,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ClientsContactsPersons[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncClientsBuildingMaps() {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('clients/clients/building-maps/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('clients/clients/building-maps', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.updateBulk(this.indexedDBService.tableClientsBuildingsMaps, <ClientsBuildingMaps[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableClientsBuildingsMaps,
															updated: server_date.data.updated
														})
															.then(() => resolve(<ClientsBuildingMaps[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}
}
