<button mat-button color="primary-outline" [routerLink]="['/inspectors/inventories/create']">Nieuwe inventaris toevoegen</button>
<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
<mat-nav-list class="mat-nav-list-with-border" infiniteScroll infiniteScrollDistance="1" (scrolled)="getInspectorsInventories()">
	<mat-list-item *ngFor="let inspectorInventory of inspectorInventories">
		<div class="mat-list-content" [routerLink]="['/inspectors/inventories/' + inspectorInventory.id + '/send-to-client']">
			<h4 matLine>{{ inspectorInventory.created | datex:'DD-MM-YYYY' }}</h4>
			<a matLine>{{ inspectorInventory.original_filename }}</a>
		</div>
		<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item [routerLink]="['/inspectors/inventories/' + inspectorInventory.id + '/send-to-client']">Verstuur naar klant</a>
			<a mat-menu-item (click)="deleteInspectorInventory(inspectorInventory.id)">Verwijderen</a>
		</mat-menu>
	</mat-list-item>
</mat-nav-list>
