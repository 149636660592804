import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {GeneralService} from '../../../../_services/general.service';
import {InspectorsInventory} from '../../../../_interfaces/inspectors/inspectors-inventory';
import {InspectorsService} from '../../../../_services/inspectors.service';
import {ApiService} from '../../../../_interceptors/api.service';
import {ServerResponse} from '../../../../_interfaces/server.response';

@Component({
	selector: 'app-inspectors-inventories',
	templateUrl: './inventories-list.component.html',
	styleUrls: ['./inventories-list.component.scss'],
	animations: [routeAnimation]
})
export class InspectorsInventoriesListComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	private totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	private isLoading: boolean = false;
	private keepLoading: boolean = false;

	public inspectorInventories: InspectorsInventory[] = [];

	constructor(private inspectorsService: InspectorsService,
				private apiService: ApiService,
				private generalService: GeneralService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.inspectorInventories.length === 0) {
				this.currentPage = 0;
				this.getInspectorsInventories();
			}
		});

		this.getInspectorsInventories(false);
	}

	ngOnDestroy(): void {
		this.currentPage = 0;
		this.searchString = '';
	}

	getInspectorsInventories(append_data: boolean = true) {
		if (!this.isLoading || this.keepLoading) {
			this.isLoading = true;
			this.keepLoading = false;
			this.currentPage++;

			this.inspectorsService.getInspectorsInventories({
				start: (this.currentPage - 1) * this.generalService.itemsPerPage,
				length: this.generalService.itemsPerPage,
				'columns[1][search][value]': this.searchString
			}).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.recordsTotal !== 'undefined') {
						this.totalItems = data.recordsTotal;
					}
					if (append_data === true) {
						this.inspectorInventories.push(...<InspectorsInventory[]>data.data);
					} else {
						this.inspectorInventories = <InspectorsInventory[]>data.data;
					}
				}
				this.isLoading = false;
			});
		}
	}

	deleteInspectorInventory(inventory_id: number) {
		this.inspectorsService.deleteInspectorInventory(inventory_id).then(status => {
			if (status === true) {
				let index = this.inspectorInventories.findIndex(data => data.id === inventory_id);

				if (index !== -1) {
					this.inspectorInventories.splice(index, 1);
				}
			}
		});
	}

	search($event) {
		this.searchString = $event;
		this.currentPage = 0;
		this.getInspectorsInventories(false);
	}
}
