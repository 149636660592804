<button mat-button color="primary-outline" [routerLink]="['/clients/' + client_id + '/buildings-maps/create']">Plattegrond toevoegen</button>
<ng-container *ngIf="smallWidget === false">
	<button mat-button color="primary-outline" [routerLink]="['/clients/' + client_id + '/view']">Bekijk klant</button>
	<button mat-button color="primary-outline" [routerLink]="['/clients']">Bekijk alle klanten</button>
	<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
</ng-container>
<mat-nav-list *ngIf="clientBuildingMaps.length" class="mat-nav-list-with-border">
	<div *ngIf="!smallWidget" infiniteScroll infiniteScrollDistance="1" (scrolled)="getClientBuildingMaps()"></div>
	<mat-list-item *ngFor="let buildingMap of clientBuildingMaps">
		<div class="mat-list-content" [routerLink]="['/clients/' + buildingMap.client_id + '/buildings-maps/' + buildingMap.id + '/view']">
			<h4 matLine>{{ buildingMap.building_name }}</h4>
			<a matLine>
				<mat-icon class="small">get_app</mat-icon>
				<span>{{ buildingMap.created | datex:'DD-MM-YYYY HH:mm' }}</span>
			</a>
		</div>
		<button mat-icon-button [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item [routerLink]="['/clients/' + buildingMap.client_id + '/buildings-maps/' + buildingMap.id + '/edit']">
				<mat-icon>edit</mat-icon>
				<span>Aanpassen</span>
			</a>
			<a mat-menu-item (click)="deleteClientBuildingMap(buildingMap.client_id, buildingMap.id)">
				<mat-icon class="icon-danger">delete</mat-icon>
				<span>Verwijderen</span>
			</a>
		</mat-menu>
	</mat-list-item>
	<a mat-list-item *ngIf="smallWidget === true && totalItems > 5" [routerLink]="['/clients/' + client_id + '/buildings-maps']" class="mat-list-item-more">
		<span matLine>Bekijk meer ...</span>
		<button mat-icon-button>
			<mat-icon>chevron_right</mat-icon>
		</button>
	</a>
</mat-nav-list>
<div *ngIf="!clientBuildingMaps.length">
	<br> Geen plattegronde gevonden.
</div>
