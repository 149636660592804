import {Component, Input} from '@angular/core';
import {PageTitleService} from '../../_services/page-title.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {ApiService} from '../../_interceptors/api.service';
import {SnackbarService} from '../../_services/snackbar.service';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent {

	@Input() sidenav: any;

	constructor(public authenticationService: AuthenticationService,
				public pageTitleService: PageTitleService,
				public apiService: ApiService,
				private snackbarService: SnackbarService) {
	}

	showOfflineMessage() {
		this.snackbarService.warning('Je bent momenteel offline, een deel van de functionaliteit is niet beschikbaar. Zet internet aan om de volledige functionaliteit en synchronisatie te herstellen.', 10000);
	}
}
