import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../../_services/forms.service';
import {ClientsService} from '../../../../_services/clients.service';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute, Params} from '@angular/router';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {PageTitleService} from '../../../../_services/page-title.service';
import {environment} from '../../../../../environments/environment';
import {Client} from '../../../../_interfaces/clients/clients';

@Component({
	selector: 'app-clients-contact-persons-create',
	templateUrl: './clients-contacts-persons-create.component.html',
	styleUrls: ['./clients-contacts-persons-create.component.scss'],
	animations: [routeAnimation]
})

export class ClientsContactPersonsCreateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public client_id: number = null;

	public clientContactPersonsCreateForm = this.formBuilder.group({
		firstname: ['', [Validators.required, Validators.maxLength(50)]],
		lastname: ['', [Validators.required, Validators.maxLength(50)]],
		telephone: ['', Validators.maxLength(20)],
		email: ['', [Validators.required, Validators.email]],
		mobile: ['']
	});

	private _activatedRouteSubscription: Subscription = null;
	private _errorsSubscription: Subscription = null;

	constructor(public formsService: FormsService,
				private formBuilder: FormBuilder,
				private activatedRoute: ActivatedRoute,
				private previousUrlService: PreviousUrlService,
				public clientsService: ClientsService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.formsService.form = this.clientContactPersonsCreateForm;

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.client_id = parseInt(params['client_id'], 10);

			this.clientsService.getClient(this.client_id).then((client: Client) => {
				if (typeof client !== 'undefined') {
					this.pageTitleService.setTitle(client.company_name + ' | Contactperson toevoegen | ' + environment.webappName);
					this.pageTitleService.setPageTitle(client.company_name);
				}
			});

			this.previousUrlService.setPreviousUrlIfNull('/clients/' + this.client_id + '/view');
		});

		this._errorsSubscription = this.clientsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}
}
