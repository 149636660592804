import { OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../../_interceptors/api.service';
import { APP_DATE_FORMATS } from '../../../_pipes/angular2-material-datepicker-custom-date';
import * as moment from 'moment';
import { PlanningService } from '../../../_services/planning.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormsService } from '../../../_services/forms.service';
const ɵ0 = APP_DATE_FORMATS;
export class PlanningListComponent {
    constructor(planningService, formsService, formBuilder, apiService) {
        this.planningService = planningService;
        this.formsService = formsService;
        this.formBuilder = formBuilder;
        this.apiService = apiService;
        this.routerTransition = true;
        this.totalItems = 0;
        this.currentPage = 0;
        this.searchString = '';
        this.isLoading = false;
        this.keepLoading = false;
        this.user = null;
        this.planning = [];
        this.start = 0;
        this.end = 0;
        this.activeDayButton = 0;
        this.planningForm = this.formBuilder.group({
            planning_date: ['', Validators.required],
            planning_date_input: ['', Validators.required]
        });
        this._authenticationService = null;
        this._errorsSubscription = null;
        this.weekendsDatesFilter = (d) => {
            const day = d.getDay();
            /* Prevent Sunday for select. */
            return day !== 0;
        };
    }
    ngOnInit() {
        this.formsService.form = this.planningForm;
        this._errorsSubscription = this.planningService.errors.subscribe(errors => {
            this.formsService.processErrors(errors);
        });
        this.apiService.connectionChanged.subscribe(online => {
            console.log(online, this.planning);
            if (online && !this.planning.length) {
                this.currentPage = 0;
                this.getPlanning(false);
            }
        });
        this.selectDate();
    }
    ngOnDestroy() {
        if (this._authenticationService !== null) {
            this._authenticationService.unsubscribe();
        }
    }
    getPlanning(append_data = true) {
        if (!this.isLoading || this.keepLoading) {
            this.isLoading = true;
            this.keepLoading = false;
            this.currentPage++;
            this.planningService.getPlannings({
                startDate: this.start,
                endDate: this.end
            }).then((data) => {
                if (typeof data !== 'undefined') {
                    if (typeof data.recordsTotal !== 'undefined') {
                        this.totalItems = data.recordsTotal;
                    }
                    if (append_data !== true) {
                        this.planning = [];
                    }
                    if (typeof data.data !== 'undefined') {
                        data.data.forEach(planning => {
                            this.planning.push(planning);
                        });
                    }
                }
                this.isLoading = false;
            });
        }
    }
    search(event) {
        this.searchString = event;
        this.currentPage = 0;
        this.getPlanning(false);
    }
    planningDateChange($event) {
        this.selectDate(moment($event.value).valueOf());
    }
    selectDate(date = null, day = null) {
        if (!date && !day) {
            day = moment().isoWeekday();
        }
        if (day) {
            if (day === 7) {
                this.start = moment().isoWeekday(6).valueOf();
                this.activeDayButton = 6;
            }
            else {
                this.start = moment().isoWeekday(day).valueOf();
                this.activeDayButton = day;
            }
        }
        else if (date) {
            let weekStart = moment().isoWeekday(1).valueOf(), weekEnd = moment().isoWeekday(7).valueOf(), current = moment(date).valueOf();
            if (current >= weekStart && current <= weekEnd) {
                this.activeDayButton = moment(date).isoWeekday();
            }
            else {
                this.activeDayButton = null;
            }
            this.start = date;
        }
        this.planningForm.get('planning_date_input').setValue(new Date(this.start));
        this.getPlanning(false);
    }
    selectToday() {
        this.selectDate(moment().valueOf());
    }
    previousDay() {
        let day = 1, date = moment(this.start);
        if (date.isoWeekday() === 1) {
            day = 2;
        }
        this.selectDate(moment(this.start).subtract(day, 'day').valueOf());
    }
    nextDay() {
        let day = 1, date = moment(this.start);
        if (date.isoWeekday() === 6) {
            day = 2;
        }
        this.selectDate(moment(this.start).add(day, 'day').valueOf());
    }
}
export { ɵ0 };
