import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {debounceTime, tap} from 'rxjs/operators';
import {ServerResponse} from '../../../_interfaces/server.response';
import {Client} from '../../../_interfaces/clients/clients';
import {ActivatedRoute, Params} from '@angular/router';
import {User} from '../../../_interfaces/user';
import {ReportsService} from '../../../_services/reports.service';
import {ClientsService} from '../../../_services/clients.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {ApiService} from '../../../_interceptors/api.service';

@Component({
	selector: 'app-reports-create',
	templateUrl: './reports-create.component.html',
	styleUrls: ['./reports-create.component.scss'],
	animations: [routeAnimation]
})

export class ReportsCreateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_type: string = null;
	public isLoadingClient: boolean = false;
	public isLoadingInspector: boolean = false;
	public missingData: Array<string> = [];

	public clientsFiltered: Client[] = null;
	public inspectors: User[] = null;
	public inspectorsFiltered: User[] = null;

	public reportsCreateForm = this.formBuilder.group({
		client_id: ['', Validators.required],
		client_filterString: [''],
		company_name: [''],
		account_user_id: ['', Validators.required],
		account_user_id_filterString: [''],
		tent_star_rating: null,
		inspector: [''],
		inspection_duration_hour: [''],
		inspection_duration_minute: [''],
		recurring: ['1']
	});

	private _errorsSubscription: Subscription = null;
	private _activatedRouteSubscription: Subscription = null;
	private _authenticationService: Subscription = null;

	constructor(private activatedRoute: ActivatedRoute,
				private apiService: ApiService,
				private clientsService: ClientsService,
				public reportsService: ReportsService,
				public formsService: FormsService,
				private previousUrlService: PreviousUrlService,
				private formBuilder: FormBuilder,
				private authenticationService: AuthenticationService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length) {
				this.missingData = [];
				this.loadData();
			}
		});

		this.formsService.form = this.reportsCreateForm;

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this.reportsCreateForm
			.get('client_filterString')
			.valueChanges
			.pipe(
				debounceTime(300),
				tap(() => this.isLoadingClient = true)
			)
			.subscribe(value => {
				if (typeof value === 'string') {
					if (value !== '' && value !== null) {
						if (value.length >= 3) {
							this.clientsService.getClients({
								start: 0,
								length: -1,
								'columns[0][search][value]': value
							}).then((data: ServerResponse) => {
								this.clientsFiltered = <Client[]>data.data;
								this.isLoadingClient = false;
							});
						} else {
							this.isLoadingClient = false;
						}
					} else {
						this.isLoadingClient = false;
					}
				} else {
					this.isLoadingClient = false;
				}
			});

		this.reportsCreateForm
			.get('account_user_id_filterString')
			.valueChanges
			.pipe(
				debounceTime(300),
				tap(() => {
					this.isLoadingInspector = true;
				}))
			.subscribe(value => {
				if (typeof value === 'string') {
					if (this.inspectors !== null) {
						if (value !== '' && value !== null) {
							this.inspectorsFiltered = this.inspectors.filter(data => {
								return data.display_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
							});
							this.isLoadingInspector = false;
						} else if (value !== null) {
							this.inspectorsFiltered = this.inspectors.slice();
							this.isLoadingInspector = false;
						}
					}
				} else {
					this.isLoadingInspector = false;
				}
			});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];

			if (typeof params['client_id'] !== 'undefined') {
				let client_id = parseInt(params['client_id'], 10);
				this.clientsService.getClient(client_id).then((client: Client) => {
					if (typeof client !== 'undefined') {
						this.clientsFiltered = <Client[]>[client];
						this.reportsCreateForm.get('client_filterString').setValue(client.company_name);
						this.reportsCreateForm.get('client_id').setValue(client.id);
					}
				});
			}

			this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_type + '/open');
		});

		this.formsService.form = this.reportsCreateForm;

		this.loadData();
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
		if (this._authenticationService !== null) {
			this._authenticationService.unsubscribe();
		}

	}

	loadData() {
		this.reportsService.getInspectors().then((data: ServerResponse) => {
			if (typeof data !== 'undefined') {
				if (typeof data.data !== 'undefined') {
					if (data.data.length) {
						this.inspectorsFiltered = <User[]>data.data;
						this.inspectors = <User[]>data.data;

						this._authenticationService = this.authenticationService.user.first().subscribe(user => {
							if (typeof user !== 'undefined') {
								if (typeof user.id !== 'undefined') {
									if (user.id !== null) {
										this.reportsCreateForm.get('account_user_id').setValue(parseInt(user.id + '', 10));
									}
								}
							}
						});
					}
				} else {
					this.missingData.push('Inspecteur');
				}
			} else {
				this.missingData.push('Inspecteur');
			}
		});
	}

	setCompanyName(company_name: string) {
		this.reportsCreateForm.get('company_name').setValue(company_name);
	}

	setInspectorName(inspector: string) {
		this.reportsCreateForm.get('inspector').setValue(inspector);
	}
}
