import {Component, HostBinding, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {routeAnimation} from '../../../../../route.animation';
import {GeneralService} from '../../../../../_services/general.service';
import {PreviousUrlService} from '../../../../../_services/previous-url.service';
import {ReportsBuildingsReports} from '../../../../../_interfaces/reports/reports-buildings-reports';
import {ReportsService} from '../../../../../_services/reports.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {ServerResponse} from '../../../../../_interfaces/server.response';
import {ApiService} from '../../../../../_interceptors/api.service';
import {AuthenticationService} from '../../../../../_services/authentication.service';
import {User} from '../../../../../_interfaces/user';
import {ReportsBuildings} from '../../../../../_interfaces/reports/reports-buildings';
import {PageTitleService} from '../../../../../_services/page-title.service';
import {environment} from '../../../../../../environments/environment';
import {MediaReplayService} from '../../../../../core/sidenav/mediareplay/media-replay.service';
import {MediaChange} from '@angular/flex-layout';
import {MatTable} from '@angular/material/table';
import * as moment from 'moment';
import {ClientsService} from '../../../../../_services/clients.service';
import {Reports} from '../../../../../_interfaces/reports/reports';
import {ClientsBuildingMaps} from '../../../../../_interfaces/clients/clients-building-maps';
import {ReportsBuildingsMapsMarker} from '../../../../../_interfaces/reports/reports-buildings-maps-marker';

@Component({
	selector: 'app-reports-buildings-reports-list',
	templateUrl: './reports-buildings-reports-list.component.html',
	styleUrls: ['./reports-buildings-reports-list.component.scss'],
	animations: [routeAnimation]
})

export class ReportsBuildingsReportsListComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	private isLoading: boolean = false;
	private keepLoading: boolean = false;

	public report_type: string = null;
	public report_id: number = null;
	public building_id: number = null;
	public report_status: string = '/open';
	public buildingReports: ReportsBuildingsReports[] = [];
	public reportEditable: boolean = false;
	public user: User = null;
	public show_checkboxes: boolean = true;

	public hasBuildingMap: boolean = false;
	public buildingMapUrl: string = null;
	public mapMarkers: ReportsBuildingsMapsMarker[] = [];

	private _activatedRouteSubscription: Subscription = null;
	private _authenticationSubscription: Subscription = null;
	private _mediaSubscription: Subscription = null;

	// table on tablet
	public isTablet: boolean = false;
	public displayedColumns: string[] = ['number', 'location_nr', 'measure_depth', 'measure_height', 'color_code', 'category_name', 'subcategory_name', 'shortcoming_types', 'shortcoming_actions', 'commands'];
	@ViewChild(MatTable, {static: false}) table: MatTable<any>;

	@Input() smallWidget: boolean = false;

	constructor(private reportsService: ReportsService,
				private mediaReplayService: MediaReplayService,
				public apiService: ApiService,
				private activatedRoute: ActivatedRoute,
				private authenticationService: AuthenticationService,
				private previousUrlService: PreviousUrlService,
				private pageTitleService: PageTitleService,
				private clientsService: ClientsService,
				private generalService: GeneralService) {
	}

	ngOnInit(): void {
		this._mediaSubscription = this.mediaReplayService.media$.subscribe((change: MediaChange) => {
			this.isTablet = (change.mqAlias === 'md' || change.mqAlias === 'lg');
		});

		this.apiService.connectionChanged.subscribe(online => {
			if (online && !this.buildingReports.length) {
				this.currentPage = 0;
				this.getReportsBuildingReports(false);
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];
			this.report_id = parseInt(params['report_id'], 10);
			this.building_id = parseInt(params['building_id'], 10);

			this.reportsService.getBuilding(this.report_type, this.report_id, this.building_id).then((data: ReportsBuildings) => {
				if (typeof data !== 'undefined') {

					if (typeof data.building_map_id !== 'undefined') {
						if (data.building_map_id !== null) {
							this.reportsService.getReport(this.report_type, this.report_id).then((report: Reports) => {
								if (typeof report !== 'undefined') {
									this.clientsService.getBuildingMap(report.client_id, data.building_map_id).then((buildingMap: ClientsBuildingMaps) => {
										if (typeof buildingMap !== 'undefined') {
											this.hasBuildingMap = true;
											this.buildingMapUrl = buildingMap.map_url;
										}
									});
									this.reportsService.getBuildingMapMarkers(this.report_id, this.building_id).then((markers: ReportsBuildingsMapsMarker[]) => {
										this.mapMarkers = markers;
									});
								}
							});
						}
					}

					if (this.smallWidget === false) {
						if (this.report_type === 'warehouse') {
							this.pageTitleService.setTitle(data.name + ' | Gebouwen | ' + environment.webappName);
						} else {
							this.pageTitleService.setTitle(data.name + ' | Gebouwen - Trappen | ' + environment.webappName);
						}
						this.pageTitleService.setPageTitle(data.name);
					}

					if (typeof data.edit_lock !== 'undefined' && typeof data.edit_lock_user_id !== 'undefined') {
						if (data.edit_lock === true && (<any>data.edit_lock_user_id === '' ||
							data.edit_lock_user_id === null ||
							data.edit_lock_user_id === 0 ||
							data.edit_lock_user_id === this.user.id ||
							this.user.type === 'kvvm-admin')) {
							this.reportEditable = true;
						}
					}
				}
			});

			this._authenticationSubscription = this.authenticationService.user.first().subscribe(user => {
				this.user = user;
				this.currentPage = 0;
				this.getReportsBuildingReports(false);
			});
		});
	}

	ngOnDestroy(): void {
		if (this._mediaSubscription !== null) {
			this._mediaSubscription.unsubscribe();
		}
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._authenticationSubscription !== null) {
			this._authenticationSubscription.unsubscribe();
		}
	}

	getReportsBuildingReports(append_data: boolean = true) {
		if (!this.isLoading || this.keepLoading) {
			this.isLoading = true;
			this.keepLoading = false;
			this.currentPage++;

			this.reportsService.getBuildingReports(this.report_type, this.report_id, this.building_id, {
				start: (this.currentPage - 1) * this.generalService.itemsPerPage,
				length: this.generalService.itemsPerPage,
				'columns[0][search][value]': this.searchString
			}).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.recordsTotal !== 'undefined') {
						this.totalItems = data.recordsTotal;
					}

					if (append_data === true) {
						this.buildingReports.push(...this.processReportData(<ReportsBuildingsReports[]>data.data));

						if (this.isTablet && this.report_type === 'warehouse') {
							this.table.renderRows();
						}
					} else {
						this.buildingReports = this.processReportData(<ReportsBuildingsReports[]>data.data);

						if (this.isTablet && this.report_type === 'warehouse') {
							this.table.renderRows();
						}
					}
				}
				this.isLoading = false;
			});
		}
	}

	processReportData(reportData: ReportsBuildingsReports[]) {
		reportData.forEach(report => {
			report.shortcoming_types = [];
			report.shortcoming_actions = [];

			this.reportsService.getShortcomingTypes(this.report_type, report.lib_warehouse_element_id, report.lib_warehouse_subelement_id).then((data3: ServerResponse) => {
				if (typeof data3 !== 'undefined') {
					if (typeof data3.data !== 'undefined') {
						data3.data.forEach(data4 => {
							if (data4.active === true) {
								if (report.warehouse_type_ids.indexOf(data4.id) !== -1) {
									let type = {
										id: data4.id,
										category_id: data4.category_id,
										subcategory_id: data4.subcategories,
										type_name: data4.type_name,
										amounts: data4.amounts,
										active: true,
										result: null
									};
									if (data4.amounts === true) {
										if (typeof report.warehouse_type_field_value !== 'undefined') {
											if (typeof report.warehouse_type_field_value[data4.id] !== 'undefined') {
												type.result = report.warehouse_type_field_value[data4.id];
											}
										}
									}
									report.shortcoming_types.push(type);
								}
							}
						});

						this.reportsService.getShortcomingActions(this.report_type, report.lib_warehouse_element_id, report.lib_warehouse_subelement_id).then((data4: ServerResponse) => {
							if (typeof data4 !== 'undefined') {
								if (typeof data4.data !== 'undefined') {
									data4.data.forEach(data5 => {
										if (typeof report.warehouse_action_ids !== 'undefined') {
											if (report.warehouse_action_ids !== null) {
												if (report.warehouse_action_ids.indexOf(data5.id) !== -1) {
													report.shortcoming_actions.push({
														id: data5.id,
														category_id: data5.category_id,
														subcategory_id: data5.subcategory_id,
														action_name: data5.action_name,
														active: true
													});
												}
											}
										}
									});
								}
							}
						});
					}
				}
			});
		});
		return reportData;
	}

	deleteBuildingReport(building_report_id: number) {
		this.reportsService.deleteBuildingReport(this.report_type, this.report_id, this.building_id, building_report_id).then(status => {
			if (status === true) {
				let index = this.buildingReports.findIndex(data => data.id === building_report_id);

				if (index !== -1) {
					this.buildingReports.splice(index, 1);
				}
			}
		});
	}

	search($event) {
		this.searchString = $event;
		this.currentPage = 0;
		this.getReportsBuildingReports(false);
	}

	toggleBuildingReportInspectorStatus(status, report_type: string, report_id: number, building_id: number, building_report_id: number): void {
		this.reportsService.verifyInspectorStatus(report_type, report_id, building_id, building_report_id, status).then(result => {
			if (typeof result !== 'undefined') {
				let index = this.buildingReports.findIndex(data => data.id === building_report_id);
				if (index !== -1) {
					this.buildingReports[index].verify_inspector_status = status;
					this.buildingReports[index].verify_inspector_date = moment().valueOf();
				}
			}
		});
	}

	showBuildingMap() {
		this.reportsService.showReportBuildingMap(this.buildingMapUrl, true, this.mapMarkers).then(() => {
		});
	}
}
