import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute, Params} from '@angular/router';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {APP_DATE_FORMATS, CustomDateAdapter} from '../../../_pipes/angular2-material-datepicker-custom-date';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {ReportsService} from '../../../_services/reports.service';
import {Client} from '../../../_interfaces/clients/clients';
import {ClientsService} from '../../../_services/clients.service';
import {ClientsContactsPersons} from '../../../_interfaces/clients/clients-contacts-persons';
import {ApiService} from '../../../_interceptors/api.service';
import {ServerResponse} from '../../../_interfaces/server.response';
import {AuthenticationService} from '../../../_services/authentication.service';
import {Reports} from '../../../_interfaces/reports/reports';
import {User} from '../../../_interfaces/user';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';
import {MultipleEmailsValidator} from '../../../_validators/multiple-emails';

@Component({
	selector: 'app-reports-send-to-client',
	templateUrl: './reports-send-to-client.component.html',
	styleUrls: ['./reports-send-to-client.component.scss'],
	animations: [routeAnimation],
	providers: [
		{
			provide: DateAdapter, useClass: CustomDateAdapter
		},
		{
			provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
		}
	]
})

export class ReportsSendToClientComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;
	public report_type: string = null;
	public report: Reports = null;
	public client: Client = null;
	public contactPersons: ClientsContactsPersons[] = [];
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];
	public reportEditable: boolean = false;

	public days: Array<number> = [];
	public hours: Array<number> = [];
	public minutes: Array<number> = [];

	public reportSendToClientForm = this.formBuilder.group({
		inspection_duration_day: ['0', Validators.required],
		inspection_duration_hour: ['0', Validators.required],
		inspection_duration_minute: ['0', Validators.required],
		contact_persons_ids: this.formBuilder.array([]),
		custom_contacts: ['', MultipleEmailsValidator()],
		include_certificate: ['1'],
		message: ['']
	});

	private _errorsSubscription: Subscription = null;
	private _activatedRouteSubscription: Subscription = null;
	private _authenticationServiceSubscription: Subscription = null;

	constructor(public reportsService: ReportsService,
				private authenticationService: AuthenticationService,
				private apiService: ApiService,
				private clientsService: ClientsService,
				public formsService: FormsService,
				private activatedRoute: ActivatedRoute,
				private formBuilder: FormBuilder,
				private previousUrlService: PreviousUrlService,
				private pageTitleService: PageTitleService) {
		for (let i = 0; i <= 31; i++) {
			this.days.push(i);
		}
		for (let i = 0; i <= 24; i++) {
			this.hours.push(i);
		}
		for (let i = 0; i <= 60; i = i + 10) {
			this.minutes.push(i);
		}
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length) {
				this.missingData = [];
				this.loadData();
			}
		});

		this.formsService.form = this.reportSendToClientForm;

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];
			this.report_id = parseInt(params['report_id'], 10);

			this.reportsService.getReport(this.report_type, this.report_id).then((report: Reports) => {
				this._authenticationServiceSubscription = this.authenticationService.user.first().subscribe((user: User) => {
					if (typeof report !== 'undefined' && typeof user !== 'undefined') {
						if (typeof report.edit_lock !== 'undefined' && typeof report.edit_lock_user_id !== 'undefined') {
							if (report.edit_lock === true && (<any>report.edit_lock_user_id === '' ||
								report.edit_lock_user_id === null ||
								report.edit_lock_user_id === 0 ||
								report.edit_lock_user_id === user.id ||
								user.type === 'kvvm-admin')) {
								this.reportEditable = true;
							}
						}
						if (typeof report.parent_report_id === 'undefined' || typeof report.parent_report_id !== 'undefined' && report.parent_report_id !== 0) {
							this.reportSendToClientForm.get('include_certificate').setValue('0');
						}

						this.pageTitleService.setTitle(report.report_nr + ' | Verstuur naar klant | ' + environment.webappName);
						this.pageTitleService.setPageTitle(report.report_nr);
					}
				});
			});

			this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_type + '/' + this.report_id + '/view');

			this.loadData();
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}

		if (this._authenticationServiceSubscription !== null) {
			this._authenticationServiceSubscription.unsubscribe();
		}
	}

	fixAngularScrewUpAndInsertActualValue(event, index) {
		if (event.checked) {
			this.reportSendToClientForm.controls.contact_persons_ids['controls'][index].setValue(event.source.value);
		}
	}

	loadData() {
		this.reportsService.getReport(this.report_type, this.report_id).then((details: Reports) => {
			if (typeof details !== 'undefined') {
				this.report = details;

				if (typeof this.report.client_id !== 'undefined') {
					this.clientsService.getClient(this.report.client_id).then((client: Client) => {
						if (typeof client !== 'undefined') {
							this.client = client;
						} else {
							this.missingData.push('Klant');
						}
					});

					this.clientsService.getContactPersons(this.report.client_id).then((contactPersons: ServerResponse) => {
						if (typeof contactPersons !== 'undefined') {
							if (typeof contactPersons.data !== 'undefined') {
								this.contactPersons = <ClientsContactsPersons[]>contactPersons.data;
								contactPersons.data.forEach(contact_person => {
									let control = <FormArray>this.reportSendToClientForm.controls.contact_persons_ids;
									control.push(new FormControl({value: contact_person.id, disabled: !contact_person.email}));
								});
							} else {
								this.missingData.push('Contactpersoon');
							}
						} else {
							this.missingData.push('Contactpersoon');
						}
					});
				}

				this.dataLoaded = true;
			} else {
				this.missingData.push('Rapportage');
			}
		});
	}
}
