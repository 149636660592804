import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';

import {AuthenticationService} from '../../../_services/authentication.service';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {LocalStorageService} from '../../../_services/local-storage.service';

@Component({
	selector: 'app-login-with-recovery-code',
	templateUrl: './login-with-recovery-code.component.html',
	styleUrls: ['./login-with-recovery-code.component.scss'],
	animations: [routeAnimation]
})

export class LoginWithRecoveryCodeComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	public sessionId: string = null;
	public otpForm = this.formBuilder.group({
		sessionID: ['', Validators.required],
		code: ['', [Validators.required, Validators.minLength(21), Validators.maxLength(21)]]
	});

	private _errorSubscription: Subscription = null;

	constructor(public authenticationService: AuthenticationService,
				private formBuilder: FormBuilder,
				private localStorageService: LocalStorageService,
				public formService: FormsService) {
	}

	ngOnInit(): void {
		this.formService.form = this.otpForm;

		this.otpForm.get('sessionID').setValue(this.localStorageService.get('sessionID'));

		this._errorSubscription = this.authenticationService.errors.subscribe(errors => {
			this.formService.processErrors(errors);
		});
	}

	ngOnDestroy() {
		if (this._errorSubscription !== null) {
			this._errorSubscription.unsubscribe();
		}
	}
}
