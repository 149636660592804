import {
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	HostBinding,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import {routeAnimation} from '../../../../../route.animation';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormsService} from '../../../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../../../_services/previous-url.service';
import {ReportsService} from '../../../../../_services/reports.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ReportsShortcomingsCategories} from '../../../../../_interfaces/reports/reports-shortcomings-categories';
import {ReportsShortcomingsSubcategories} from '../../../../../_interfaces/reports/reports-shortcomings-subcategories';
import {ReportsShortcomingsMaterials} from '../../../../../_interfaces/reports/reports-shortcomings-materials';
import {ReportsShortcomingsTypes} from '../../../../../_interfaces/reports/reports-shortcomings-types';
import {ReportsShortcomingsActions} from '../../../../../_interfaces/reports/reports-shortcomings-actions';
import {ReportsBuildingsShortcomingsQuestionnaire} from '../../../../../_interfaces/reports/reports-buildings-shortcomings-questionnaire';
import {PageTitleService} from '../../../../../_services/page-title.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BarcodeScannerComponent} from '../../../../../core/barcode-scanner/barcode-scanner.component';
import {SpeechToTextComponent} from '../../../../../core/speech-to-text/speech-to-text.component';
import {ApiService} from '../../../../../_interceptors/api.service';
import {ServerResponse} from '../../../../../_interfaces/server.response';
import {Reports} from '../../../../../_interfaces/reports/reports';
import {User} from '../../../../../_interfaces/user';
import {AuthenticationService} from '../../../../../_services/authentication.service';
import {debounceTime, tap} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {ReportsBuildings} from '../../../../../_interfaces/reports/reports-buildings';
import {RemovablePhotoComponent} from '../../../../../core/removable-photo/removable-photo.component';
import {FileInputComponent} from 'ngx-material-file-input';
import {ClientsBuildingMaps} from '../../../../../_interfaces/clients/clients-building-maps';
import {ClientsService} from '../../../../../_services/clients.service';
import {ReportsBuildingsMapsMarker} from '../../../../../_interfaces/reports/reports-buildings-maps-marker';

@Component({
	selector: 'app-reports-buildings-reports-create',
	templateUrl: './reports-buildings-reports-create.component.html',
	styleUrls: ['./reports-buildings-reports-create.component.scss'],
	animations: [routeAnimation]
})

export class ReportsBuildingsReportsCreateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_type: string = null;
	public report_id: number = null;
	public building_id: number = null;
	public missingData: Array<string> = [];
	public reportEditable: boolean = false;

	public barcodeScannerActive: boolean = false;
	public speechToTextActive: boolean = true;

	public isVerifyingLocation: boolean = false;
	public locationNotUnique: boolean = false;
	public locationErrorMessage: string = null;
	public locationLink: string = null;

	public isVerifyingId: boolean = false;
	public idNotUnique: boolean = false;
	public idErrorMessage: string = null;
	public idLink: string = null;
	public companyInspection: boolean = true;

	public category_id: number = null;
	public subcategory_id: number = null;
	public categories: ReportsShortcomingsCategories[] = [];
	public subcategories: ReportsShortcomingsSubcategories[] = [];
	public actions: ReportsShortcomingsActions[] = [];
	public materials: ReportsShortcomingsMaterials[] = [];
	public types: ReportsShortcomingsTypes[] = [];
	public questionnaire: ReportsBuildingsShortcomingsQuestionnaire[] = [];

	public hasBuildingMap: boolean = false;
	public buildingMapUrl: string = null;

	@ViewChild('photos', {read: ViewContainerRef, static: false}) photos: ViewContainerRef = null;
	@ViewChild('photosContainer', {static: false}) photosContainer: FileInputComponent;
	public photosRef: Array<ComponentRef<RemovablePhotoComponent>> = [];

	public reportsBuildingsReportCreateForm = this.formBuilder.group({
		'photosContainer[]': [''],
		photos: this.formBuilder.array([]),
		marker: this.formBuilder.array([]),
	});

	public photosCount: number = 0;

	private _errorsSubscription: Subscription = null;
	private _activatedRouteSubscription: Subscription = null;
	private _authenticationServiceSubscription: Subscription = null;

	constructor(public apiService: ApiService,
				private authenticationService: AuthenticationService,
				private resolver: ComponentFactoryResolver,
				public reportsService: ReportsService,
				public formsService: FormsService,
				private activatedRoute: ActivatedRoute,
				private clientsService: ClientsService,
				private pageTitleService: PageTitleService,
				private previousUrlService: PreviousUrlService,
				private dialogService: MatDialog,
				private formBuilder: FormBuilder) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length) {
				this.missingData = [];
				this.loadData();
			}
		});

		this.formsService.form = this.reportsBuildingsReportCreateForm;

		this.reportsService.checkBarcodeScanner().then(response => {
			if (response) {
				this.barcodeScannerActive = true;
			}
		});

		this.reportsService.checkSpeechToText().then(response => {
			if (response) {
				this.speechToTextActive = true;
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];
			this.report_id = parseInt(params['report_id'], 10);
			this.building_id = parseInt(params['building_id'], 10);

			if (this.report_type === 'warehouse') {
				this.reportsBuildingsReportCreateForm.addControl('location_nr', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('lib_warehouse_element_id', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('lib_warehouse_subelement_id', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('color_code', new FormControl('0', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('measure_depth', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('measure_height', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('warehouse_type_ids', new FormArray([]));
				this.reportsBuildingsReportCreateForm.addControl('warehouse_type_field_value', new FormGroup({}));
				this.reportsBuildingsReportCreateForm.addControl('warehouse_action_ids', new FormArray([]));
				this.reportsBuildingsReportCreateForm.addControl('category_name', new FormControl(''));
				this.reportsBuildingsReportCreateForm.addControl('subcategory_name', new FormControl(''));

				this.reportsBuildingsReportCreateForm.get('measure_depth').setValue(1);
				this.reportsBuildingsReportCreateForm.get('measure_height').setValue(1);
			} else if (this.report_type === 'ladders') {
				this.reportsBuildingsReportCreateForm.addControl('location_nr', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('ladder_id', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('lib_ladder_element_id', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('lib_ladder_subelement_id', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('material_id', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('brand', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('sticker_posted', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('description', new FormControl(''));
				this.reportsBuildingsReportCreateForm.addControl('ladder_questions', new FormGroup({}));
				this.reportsBuildingsReportCreateForm.addControl('category_name', new FormControl(''));
				this.reportsBuildingsReportCreateForm.addControl('subcategory_name', new FormControl(''));

				this.reportsBuildingsReportCreateForm.get('location_nr').valueChanges
					.pipe(
						debounceTime(300),
						tap(() => this.isVerifyingLocation = true)
					)
					.subscribe(value => {
						this.locationErrorMessage = null;
						this.locationLink = null;
						this.locationNotUnique = false;
						if (typeof value === 'string') {
							if (value !== '' && value !== null) {
								this.reportsService.isLadderLocationNrUnique(this.report_id, this.building_id, value).then((data: ServerResponse) => {
									if (typeof data !== 'undefined') {
										if (typeof data.data !== 'undefined') {
											if (typeof data.data.message !== 'undefined' && typeof data.data.link !== 'undefined') {
												this.locationErrorMessage = data.data.message.replace('#before#', '<a>').replace('#after#', '</a>');
												this.locationLink = data.data.link;
												this.locationNotUnique = true;
											}
										}
									}
									this.isVerifyingLocation = false;
								});
							} else {
								this.isVerifyingLocation = false;
							}
						} else {
							this.isVerifyingLocation = false;
						}
					});
				this.reportsBuildingsReportCreateForm.get('ladder_id').valueChanges
					.pipe(
						debounceTime(300),
						tap(() => this.isVerifyingId = true)
					)
					.subscribe(value => {
						this.idErrorMessage = null;
						this.idLink = null;
						this.idNotUnique = false;
						if (typeof value === 'string') {
							if (value !== '' && value !== null) {
								this.reportsService.isLadderIDUnique(this.report_id, this.building_id, value).then((data: ServerResponse) => {
									if (typeof data !== 'undefined') {
										if (typeof data.data !== 'undefined') {
											if (typeof data.data.message !== 'undefined' && data.data.link !== 'undefined') {
												this.idErrorMessage = data.data.message.replace('#before#', '<a>').replace('#after#', '</a>');
												this.idLink = data.data.link;
												this.idNotUnique = true;
											}
										}
									}
									this.isVerifyingId = false;
								});
							} else {
								this.isVerifyingId = false;
							}
						} else {
							this.isVerifyingId = false;
						}
					});

				this.reportsService.getNextLadderLocationNr(this.report_id, this.building_id).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.data !== 'undefined') {
							if (typeof data.data.location_nr !== 'undefined') {
								this.reportsBuildingsReportCreateForm.get('location_nr').setValue(data.data.location_nr);
							}
						}
					}
				});
			} else {
				this.reportsBuildingsReportCreateForm.addControl('tent_id', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('lib_tent_element_id', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('lib_tent_subelement_id', new FormControl('', Validators.required));
				this.reportsBuildingsReportCreateForm.addControl('description', new FormControl(''));
				this.reportsBuildingsReportCreateForm.addControl('tent_questions', new FormGroup({}));
				this.reportsBuildingsReportCreateForm.addControl('category_name', new FormControl(''));
				this.reportsBuildingsReportCreateForm.addControl('subcategory_name', new FormControl(''));

				this.reportsBuildingsReportCreateForm.get('tent_id').valueChanges
					.pipe(
						debounceTime(300),
						tap(() => this.isVerifyingId = true)
					)
					.subscribe(value => {
						this.idErrorMessage = null;
						this.idLink = null;
						this.idNotUnique = false;
						if (typeof value === 'string') {
							if (value !== '' && value !== null) {
								this.reportsService.isTentIDUnique(this.report_id, this.building_id, value).then((data: ServerResponse) => {
									if (typeof data !== 'undefined') {
										if (typeof data.data !== 'undefined') {
											if (typeof data.data.message !== 'undefined' && data.data.link !== 'undefined') {
												this.idErrorMessage = data.data.message.replace('#before#', '<a>').replace('#after#', '</a>');
												this.idLink = data.data.link;
												this.idNotUnique = true;
											}
										}
									}
									this.isVerifyingId = false;
								});
							} else {
								this.isVerifyingId = false;
							}
						} else {
							this.isVerifyingId = false;
						}
					});
			}

			this.reportsService.getReport(this.report_type, this.report_id).then((report: Reports) => {
				this._authenticationServiceSubscription = this.authenticationService.user.first().subscribe((user: User) => {
					if (typeof report !== 'undefined' && typeof user !== 'undefined') {

						this.reportsService.getBuilding(this.report_type, this.report_id, this.building_id).then((building: ReportsBuildings) => {
							console.log(building);
							if (typeof building.company_inspection !== 'undefined') {
								this.companyInspection = building.company_inspection;
								if (this.report_type === 'tent' && !building.company_inspection) {
									this.addTentLocationNr();
								}
							}
							this.clientsService.getBuildingMap(report.client_id, building.building_map_id).then((buildingMap: ClientsBuildingMaps) => {
								if (typeof buildingMap !== 'undefined') {
									this.hasBuildingMap = true;
									this.buildingMapUrl = buildingMap.map_url;
								}
							});

							if (this.report_type === 'warehouse') {
								this.pageTitleService.setTitle(building.name + ' | Nieuwe schade toevoegen | ' + environment.webappName);
							} else if (this.report_type === 'ladders') {
								this.pageTitleService.setTitle(building.name + ' | Nieuwe inspectie | ' + environment.webappName);
							} else {
								this.pageTitleService.setTitle(building.name + ' | Nieuwe tent inspectie | ' + environment.webappName);
							}
							this.pageTitleService.setPageTitle(building.name);
						});

						if (typeof report.edit_lock !== 'undefined' && typeof report.edit_lock_user_id !== 'undefined') {
							if (report.edit_lock === true && (<any>report.edit_lock_user_id === '' ||
								report.edit_lock_user_id === null ||
								report.edit_lock_user_id === 0 ||
								report.edit_lock_user_id === user.id ||
								user.type === 'kvvm-admin')) {
								this.reportEditable = true;
							}
						}
					}

					// @ts-ignore
					this.loadData((report.type === 'tent' ? report.tent_star_rating : null));
				});
			});

		});

		this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_type + '/' + this.report_id + '/buildings/' + this.building_id + '/reports');

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}

		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._authenticationServiceSubscription !== null) {
			this._authenticationServiceSubscription.unsubscribe();
		}

		this.clearAddedPhotos();
	}

	redirectLocationNr() {
		if (this.locationLink !== null) {
			this.previousUrlService.goTo(this.locationLink);
		}
	}

	redirectId() {
		if (this.idLink !== null) {
			this.previousUrlService.goTo(this.idLink);
		}
	}

	getSubcategories(category_id: number) {
		this.category_id = category_id;
		this.actions = [];
		this.types = [];
		this.questionnaire = [];
		this.reportsService.getShortcomingSubcategories(this.report_type, category_id).then((data: ServerResponse) => {
			if (typeof data !== 'undefined') {
				if (typeof data.data !== 'undefined') {
					this.subcategories = <ReportsShortcomingsSubcategories[]>data.data;
				}
			}
		});

		this.reportsService.getReport(this.report_type, this.report_id).then((report: Reports) => {
			this.authenticationService.user.first().subscribe((user: User) => {
				if (typeof report !== 'undefined' && typeof user !== 'undefined') {
					if (typeof report.edit_lock !== 'undefined' && typeof report.edit_lock_user_id !== 'undefined') {
						if (report.edit_lock === true && (<any>report.edit_lock_user_id === '' ||
							report.edit_lock_user_id === null ||
							report.edit_lock_user_id === 0 ||
							report.edit_lock_user_id === user.id ||
							user.type === 'kvvm-admin')) {
							this.reportEditable = true;
						}
					}
				}
			});
		});
	}

	getDetails(subcategory_id: number) {
		this.subcategory_id = subcategory_id;

		if (this.report_type === 'warehouse') {
			this.reportsService.getShortcomingTypes(this.report_type, this.category_id, this.subcategory_id).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.data !== 'undefined') {
						this.types = <ReportsShortcomingsTypes[]>data.data;

						const form = this.reportsBuildingsReportCreateForm;
						form.removeControl('warehouse_type_ids');
						form.removeControl('warehouse_type_field_value');
						form.addControl('warehouse_type_ids', new FormArray([]));
						form.addControl('warehouse_type_field_value', new FormGroup({}));

						const control = <FormArray>this.reportsBuildingsReportCreateForm.get('warehouse_type_ids'),
							control2 = <FormGroup>this.reportsBuildingsReportCreateForm.get('warehouse_type_field_value');

						data.data.forEach((data2, index) => {
							control.push(new FormControl(data2.id));

							if (data2.amounts === true) {
								control2.addControl(data2.id, new FormControl(''));
							}

							control.get(index + '').setValue(false);
						});
					} else {
						this.missingData.push('Type tekortkomingen');
					}
				} else {
					this.missingData.push('Type tekortkomingen');
				}

			});

			this.reportsService.getShortcomingActions(this.report_type, this.category_id, this.subcategory_id).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.data !== 'undefined') {
						this.actions = <ReportsShortcomingsActions[]>data.data;
						const form = this.reportsBuildingsReportCreateForm;
						form.removeControl('warehouse_action_ids');
						form.addControl('warehouse_action_ids', new FormArray([]));

						const control = <FormArray>this.reportsBuildingsReportCreateForm.get('warehouse_action_ids');
						data.data.forEach((data2, index) => {
							control.push(new FormControl(data2.id));

							control.get(index + '').setValue(false);
						});
					} else {
						this.missingData.push('Acties');
					}
				} else {
					this.missingData.push('Acties');
				}
			});
		} else {
			if (this.report_type === 'ladders') {
				this.reportsService.getShortcomingMaterials(this.report_type).then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.data !== 'undefined') {
							this.materials = <ReportsShortcomingsMaterials[]>data.data;
						} else {
							this.missingData.push('Materiaal');
						}
					} else {
						this.missingData.push('Materiaal');
					}
				});
			}

			this.reportsService.getShortcomingQuestionnaires(this.report_type, this.category_id, this.subcategory_id).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.data !== 'undefined') {
						this.questionnaire = <ReportsBuildingsShortcomingsQuestionnaire[]>data.data;

						const form = this.reportsBuildingsReportCreateForm,
							formControlName = this.report_type === 'ladders' ? 'ladder_questions' : 'tent_questions';
						form.removeControl(formControlName);
						form.addControl(formControlName, new FormGroup({}));

						const control = <FormGroup>this.reportsBuildingsReportCreateForm.get(formControlName);
						data.data.forEach(data2 => {
							if (typeof data2.questions !== 'undefined') {
								data2.questions.forEach(data3 => {
									control.addControl('[' + data2.id + '][' + data3.id + ']', new FormControl(data3.id));

									control.get('[' + data2.id + '][' + data3.id + ']').setValue('yes');
								});
							}
						});
					} else {
						this.missingData.push('Schema');
					}
				} else {
					this.missingData.push('Schema');
				}
			});
		}
	}

	fixAngularScrewUpAndInsertActualValue(control_name, event, index) {
		if (event.checked) {
			this.reportsBuildingsReportCreateForm.controls[control_name]['controls'][index].setValue(parseInt(event.source.value, 10));
		}
	}

	scanBarcode() {
		let dialogRef: MatDialogRef<BarcodeScannerComponent>;
		dialogRef = this.dialogService.open(BarcodeScannerComponent);
		dialogRef.afterClosed().subscribe(response => {
			this.reportsBuildingsReportCreateForm.get('location_nr').setValue(response);
		});
	}

	recognizeSpeechToText() {
		let dialogRef: MatDialogRef<SpeechToTextComponent>;
		dialogRef = this.dialogService.open(SpeechToTextComponent);
		dialogRef.afterClosed().subscribe(response => {
			this.reportsBuildingsReportCreateForm.get('location_nr').setValue(response);
		});
	}

	decreaseValue(element, toggleElement = null) {
		const el = this.reportsBuildingsReportCreateForm.get(element),
			val = parseInt(el.value, 10);

		el.setValue(val > 1 ? val - 1 : '');
		if (val === 1 && toggleElement !== null) {
			let te = toggleElement.split('.');
			this.reportsBuildingsReportCreateForm.get(te[0]).get(te[1]).setValue(false);
		}
	}

	increaseValue(element, toggleElement = null, id = null) {
		const el = this.reportsBuildingsReportCreateForm.get(element),
			val = parseInt(el.value, 10);

		el.setValue(isNaN(val) ? 1 : val + 1);
		if (toggleElement !== null) {
			let te = toggleElement.split('.');
			this.reportsBuildingsReportCreateForm.get(te[0]).get(te[1]).setValue(id);
		}
	}

	loadData(tent_star_rating: number = null): void {
		this.reportsService.getShortcomingCategories(this.report_type, tent_star_rating).then((data: ServerResponse) => {
			if (typeof data !== 'undefined') {
				if (typeof data.data !== 'undefined') {
					this.categories = <ReportsShortcomingsCategories[]>data.data;
					if (this.report_type === 'warehouse') {
						data.data.forEach(category => {
							if (category.category_name === 'Palletstelling' || category.category_name === 'Pallet racking') {
								this.reportsBuildingsReportCreateForm.get('lib_warehouse_element_id').setValue(category.id);
								this.getSubcategories(category.id);
								this.setCategoryName(category.category_name);
							}
						});
					} else if (this.report_type === 'ladders') {
						data.data.forEach(category => {
							if (category.category_name === 'Bordes') {
								this.reportsBuildingsReportCreateForm.get('lib_ladder_element_id').setValue(category.id);
								this.getSubcategories(category.id);
								this.setCategoryName(category.category_name);
							}
						});
					}
				} else {
					this.missingData.push('Hoofd onderdeel');
				}
			} else {
				this.missingData.push('Hoofd onderdeel');
			}
		});
	}

	setCategoryName(category_name: string) {
		this.reportsBuildingsReportCreateForm.get('category_name').setValue(category_name);
	}

	setSubcategoryName(subcategory_name: string) {
		this.reportsBuildingsReportCreateForm.get('subcategory_name').setValue(subcategory_name);
	}

	clearAddedPhotos(): void {
		if (this.photosRef.length) {
			this.photosRef.forEach(ref => ref.destroy());
		}
	}

	detectUploadFiles(event: any): void {
		if (typeof event !== 'undefined') {
			if (typeof event.target !== 'undefined') {
				if (typeof event.target.files !== 'undefined') {
					let formArray = this.reportsBuildingsReportCreateForm.get('photos') as FormArray;

					Object.keys(event.target.files).map(file => {
						this.photosCount = formArray.controls.length + 1;

						if (this.photosCount < 11) {
							const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(RemovablePhotoComponent);
							let component: any = null;
							component = this.photos.createComponent(factory);
							component.instance.photo = event.target.files[file];
							component.instance.filename = event.target.files[file].name;
							component.instance.size = event.target.files[file].size;

							this.photosRef.push(component);

							let control = new FormControl(event.target.files[file]);
							formArray.push(control);

							component.instance.destroy.subscribe(() => {
								let index = this.photos.indexOf(component.hostView);
								formArray.removeAt(index);
								component.destroy();
								this.photosCount = formArray.controls.length;
							});
						}
					});
				}
			}
		}
	}

	showBuildingMap(): void {
		this.reportsService.showReportBuildingMap(this.buildingMapUrl, false).then((returnedMarkers: ReportsBuildingsMapsMarker[]) => {
			let formArray = this.reportsBuildingsReportCreateForm.get('marker') as FormArray;
			formArray.clear();

			returnedMarkers.forEach(marker => {
				let control = new FormControl(marker.x + ',' + marker.y);

				formArray.push(control);
			});
		});
	}

	addTentLocationNr(): void {
		this.reportsBuildingsReportCreateForm.addControl('location_nr', new FormControl('', Validators.required));

		this.reportsBuildingsReportCreateForm.get('location_nr').valueChanges
			.pipe(
				debounceTime(300),
				tap(() => this.isVerifyingLocation = true)
			)
			.subscribe(value => {
				this.locationErrorMessage = null;
				this.locationLink = null;
				this.locationNotUnique = false;
				if (typeof value === 'string') {
					if (value !== '' && value !== null) {
						this.reportsService.isTentLocationNrUnique(this.report_id, this.building_id, value).then((data: ServerResponse) => {
							if (typeof data !== 'undefined') {
								if (typeof data.data !== 'undefined') {
									if (typeof data.data.message !== 'undefined' && typeof data.data.link !== 'undefined') {
										this.locationErrorMessage = data.data.message.replace('#before#', '<a>').replace('#after#', '</a>');
										this.locationLink = data.data.link;
										this.locationNotUnique = true;
									}
								}
							}
							this.isVerifyingLocation = false;
						});
					} else {
						this.isVerifyingLocation = false;
					}
				} else {
					this.isVerifyingLocation = false;
				}
			});

		this.reportsService.getNextTentLocationNr(this.report_id, this.building_id).then((data: ServerResponse) => {
			if (typeof data !== 'undefined') {
				if (typeof data.data !== 'undefined') {
					if (typeof data.data.location_nr !== 'undefined') {
						this.reportsBuildingsReportCreateForm.get('location_nr').setValue(data.data.location_nr);
					}
				}
			}
		});
	}
}
