import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {InspectorsService} from '../../../../_services/inspectors.service';
import {PreviousUrlService} from '../../../../_services/previous-url.service';

@Component({
	selector: 'app-inspectors-inventories-create',
	templateUrl: './inventories-create.component.html',
	styleUrls: ['./inventories-create.component.scss'],
	animations: [routeAnimation]
})

export class InspectorsInventoriesCreateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public inspectorInventoryCreateForm = this.formBuilder.group({
		filename: ['', Validators.required]
	});

	private _errorsSubscription: Subscription = null;

	constructor(public formsService: FormsService,
				public inspectorsService: InspectorsService,
				private previousUrlService: PreviousUrlService,
				private formBuilder: FormBuilder) {
	}

	ngOnInit(): void {
		this.formsService.form = this.inspectorInventoryCreateForm;

		this.previousUrlService.setPreviousUrlIfNull('/inspectors/inventories');

		this._errorsSubscription = this.inspectorsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}
}
