<button mat-button color="primary-outline" [routerLink]="['/clients/create']">Klant toevoegen</button>
<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
<mat-nav-list class="mat-nav-list-with-border" infiniteScroll infiniteScrollDistance="1" (scrolled)="getClients()">
	<mat-list-item *ngFor="let client of clients">
		<div class="mat-list-content" [routerLink]="['/clients/' + client.id + '/view']">
			<h4 matLine>{{ client.company_name }}</h4>
			<a matLine>{{ client.address }}, {{ client.city }} {{ client.postalcode }}</a>
		</div>
		<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item [routerLink]="['/clients/' + client.id + '/edit']">Aanpassen</a>
			<a mat-menu-item *ngIf="authenticationService.canUserAccessProperty(['kvvm-admin'])" (click)="deleteClient(client.id)">Verwijderen</a>
			<mat-divider></mat-divider>
			<a mat-menu-item [routerLink]="['/reports/warehouse/create/' + client.id]">Nieuwe magazijn rapportage</a>
			<a mat-menu-item [routerLink]="['/reports/ladders/create/' + client.id]">Nieuwe trappen rapportage</a>
			<a mat-menu-item [routerLink]="['/reports/tent/create/' + client.id]">Nieuwe tent rapportage</a>
			<mat-divider></mat-divider>
			<a mat-menu-item [routerLink]="['/clients/' + client.id + '/attachments/create']">Bijlage toevoegen</a>
			<a mat-menu-item [routerLink]="['/clients/' + client.id + '/contacts-persons/create']">Contactpersoon toevoegen</a>
			<mat-divider></mat-divider>
			<a mat-menu-item *ngIf="apiService.isOnline" [routerLink]="['/clients/' + client.id + '/next-inspection-notification']">Verstuur volgende inspectie notificatie</a>
			<a mat-menu-item *ngIf="apiService.isOnline" [routerLink]="['/clients/' + client.id + '/change-next-inspection-date']">Aanpassen aankomende inspectie datum</a>
			<a mat-menu-item *ngIf="!apiService.isOnline" (click)="apiService.notAvailableOffline()" disabled>Verstuur volgende inspectie notificatie</a>
			<a mat-menu-item *ngIf="!apiService.isOnline" (click)="apiService.notAvailableOffline()" disabled>Aanpassen aankomende inspectie datum</a>
		</mat-menu>
	</mat-list-item>
</mat-nav-list>
