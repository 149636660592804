import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {ReportsService} from '../../../../_services/reports.service';
import {ActivatedRoute, Params} from '@angular/router';
import {AuthenticationService} from '../../../../_services/authentication.service';
import {Reports} from '../../../../_interfaces/reports/reports';
import {User} from '../../../../_interfaces/user';
import {PageTitleService} from '../../../../_services/page-title.service';
import {environment} from '../../../../../environments/environment';

@Component({
	selector: 'app-reports-comments-create',
	templateUrl: './reports-comments-create.component.html',
	styleUrls: ['./reports-comments-create.component.scss'],
	animations: [routeAnimation]
})

export class ReportsCommentsCreateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_type: string = null;
	public report_id: number = null;
	public reportEditable: boolean = false;

	public reportCommentCreateForm = this.formBuilder.group({
		comment: ['', Validators.required]
	});

	private _activatedRouteSubscription: Subscription = null;
	private _errorsSubscription: Subscription = null;
	private _authenticationServiceSubscription: Subscription = null;

	constructor(public reportsService: ReportsService,
				private authenticationService: AuthenticationService,
				public formsService: FormsService,
				private activatedRoute: ActivatedRoute,
				private previousUrlService: PreviousUrlService,
				private formBuilder: FormBuilder,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.formsService.form = this.reportCommentCreateForm;

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];
			this.report_id = parseInt(params['report_id'], 10);

			this.reportsService.getReport(this.report_type, this.report_id).then((report: Reports) => {
				this._authenticationServiceSubscription = this.authenticationService.user.first().subscribe((user: User) => {
					if (typeof report !== 'undefined' && typeof user !== 'undefined') {

						this.pageTitleService.setTitle(report.report_nr + ' | Nieuwe opmerking toevoegen | ' + environment.webappName);
						this.pageTitleService.setPageTitle(report.report_nr);

						if (typeof report.edit_lock !== 'undefined' && typeof report.edit_lock_user_id !== 'undefined') {
							if (report.edit_lock === true && (<any>report.edit_lock_user_id === '' ||
								report.edit_lock_user_id === null ||
								report.edit_lock_user_id === 0 ||
								report.edit_lock_user_id === user.id ||
								user.type === 'kvvm-admin')) {
								this.reportEditable = true;
							}
						}
					}
				});
			});

			this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_type + '/' + this.report_id + '/view');
		});

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}

		if (this._authenticationServiceSubscription !== null) {
			this._authenticationServiceSubscription.unsubscribe();
		}
	}
}
