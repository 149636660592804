<form class="with-action-buttons" *ngIf="!missingData.length" [formGroup]="clientsCreateForm" (ngSubmit)="clientsService.createClient(clientsCreateForm.value)">
	<div class="divider"><span>Klantgegevens</span></div>
	<mat-form-field>
		<input matInput placeholder="Bedrijfsnaam" type="text" required formControlName="company_name">
		<mat-error *ngIf="formsService.getFieldError('company_name')">{{formsService.getFieldError('company_name')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<mat-select placeholder="Taal" required formControlName="language_id">
			<mat-option *ngFor="let language of languages" [value]="language.id" (onSelectionChange)="setLanguageName(language.language_name)">{{ language.language_name }}</mat-option>
		</mat-select>
		<mat-error *ngIf="formsService.getFieldError('language_id')">{{formsService.getFieldError('language_id')}}</mat-error>
		<input type="hidden" formControlName="language_name">
	</mat-form-field>
	<div class="divider"><span>Locatiegegevens</span></div>
	<mat-form-field>
		<input matInput placeholder="Adres" type="text" required formControlName="address">
		<mat-error *ngIf="formsService.getFieldError('address')">{{formsService.getFieldError('address')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="Postcode" type="text" required formControlName="postalcode">
		<mat-error *ngIf="formsService.getFieldError('postalcode')">{{formsService.getFieldError('postalcode')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="Woonplaats" type="text" required formControlName="city">
		<mat-error *ngIf="formsService.getFieldError('city')">{{formsService.getFieldError('city')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<mat-select placeholder="Land" required formControlName="country_id">
			<mat-option>
				<ngx-mat-select-search formControlName="country_id_filterString" [searching]="isLoadingCountries" placeholderLabel="Zoeken"></ngx-mat-select-search>
			</mat-option>
			<mat-option *ngFor="let country of countriesFiltered" [value]="country.id" (onSelectionChange)="setCountryName(country.country_name)">{{ country.country_name }}</mat-option>
		</mat-select>
		<mat-error *ngIf="formsService.getFieldError('country_id')">{{formsService.getFieldError('country_id')}}</mat-error>
		<input type="hidden" formControlName="country_name">
	</mat-form-field>
	<div class="divider"><span>Contactgegevens</span></div>
	<mat-form-field>
		<input matInput placeholder="Telefoon" type="tel" formControlName="telephone">
		<mat-error *ngIf="formsService.getFieldError('telephone')">{{formsService.getFieldError('telephone')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="E-Mail" type="email" required formControlName="email">
		<mat-error *ngIf="formsService.getFieldError('email')">{{formsService.getFieldError('email')}}</mat-error>
	</mat-form-field>
	<div class="action-buttons">
		<button color="primary" mat-button [disabled]="!clientsCreateForm.valid">OPSLAAN</button>
	</div>
</form>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Some of the required data is not available offline. Please go online in order to proceed.</h2>
</div>
