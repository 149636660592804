import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {GeneralService} from '../../../_services/general.service';
import {Languages} from '../../../_interfaces/languages';
import {Countries} from '../../../_interfaces/countries';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {ClientsService} from '../../../_services/clients.service';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {debounceTime, tap} from 'rxjs/operators';
import {IndexedDBService} from '../../../_services/indexeddb.service';
import {ApiService} from '../../../_interceptors/api.service';

@Component({
	selector: 'app-clients-create',
	templateUrl: './clients-create.component.html',
	styleUrls: ['./clients-create.component.scss'],
	animations: [routeAnimation]
})

export class ClientsCreateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public languages: Languages[] = [];
	// noinspection JSMismatchedCollectionQueryUpdate
	public countries: Countries[] = [];
	// noinspection JSMismatchedCollectionQueryUpdate
	public countriesFiltered: Countries[] = [];

	public isLoadingCountries: boolean = false;
	public missingData: Array<string> = [];

	public clientsCreateForm = this.formBuilder.group({
		company_name: ['', [Validators.required, Validators.maxLength(50)]],
		language_id: ['', Validators.required],
		language_name: [''],
		address: ['', [Validators.required, Validators.maxLength(100)]],
		postalcode: ['', [Validators.required, Validators.maxLength(10)]],
		city: ['', [Validators.required, Validators.maxLength(50)]],
		country_id: ['', Validators.required],
		country_id_filterString: [''],
		country_name: [''],
		telephone: ['', Validators.maxLength(20)],
		email: ['', [Validators.required, Validators.email]]
	});

	private _errorsSubscription: Subscription = null;

	constructor(private generalService: GeneralService,
				public clientsService: ClientsService,
				public formsService: FormsService,
				private previousUrlService: PreviousUrlService,
				private indexedDBService: IndexedDBService,
				private apiService: ApiService,
				private formBuilder: FormBuilder) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length > 0) {
				this.missingData = [];
				this.loadData();
			}
		});
		this.formsService.form = this.clientsCreateForm;

		this.previousUrlService.setPreviousUrlIfNull('/clients');

		this.loadData();

		this._errorsSubscription = this.clientsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this.clientsCreateForm
			.get('country_id_filterString')
			.valueChanges
			.pipe(
				debounceTime(300),
				tap(() => this.isLoadingCountries = true)
			)
			.subscribe(value => {
				if (typeof value === 'string') {
					if (value !== '' && value !== null) {
						const val = value.toLowerCase();
						this.countriesFiltered = this.countries.filter(data => data.country_name.toLowerCase().indexOf(val) !== -1);
						this.isLoadingCountries = false;
					} else if (value !== null) {
						this.countriesFiltered = this.countries.slice();
						this.isLoadingCountries = false;
					}
				} else {
					this.isLoadingCountries = false;
				}
			});
	}

	loadData() {
		this.generalService.getLanguages().then((languages: Languages[]) => {
			if (typeof languages !== 'undefined') {
				this.clientsCreateForm.get('language_id').enable();
				this.languages = languages;

				for (let language of languages) {
					if (language.language_name === 'Dutch') {
						this.clientsCreateForm.get('language_id').setValue(language.id);
						break;
					}
				}
			} else {
				this.missingData.push('Taal');
				this.clientsCreateForm.get('language_id').disable();
			}
		}).catch(error => console.log(error));

		this.generalService.getCountries().then((countries: Countries[]) => {
			if (typeof countries !== 'undefined') {
				this.clientsCreateForm.get('country_id').enable();
				this.countries = countries;
				this.countriesFiltered = countries;

				for (let country of countries) {
					if (country.country_name === 'Netherlands') {
						this.clientsCreateForm.get('country_id').setValue(country.id);
						break;
					}
				}
			} else {
				this.missingData.push('Land');
				this.clientsCreateForm.get('country_id').disable();
			}
		});
	}

	ngOnDestroy(): void {
		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}

	setLanguageName(language_name: string) {
		this.clientsCreateForm.get('language_name').setValue(language_name);
	}

	setCountryName(country_name: string) {
		this.clientsCreateForm.get('country_name').setValue(country_name);
	}
}
