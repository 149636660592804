<mat-toolbar color="primary">
	<button (click)="sidenav.toggle()" mat-icon-button class="sidenavToggleButton">
		<mat-icon>menu</mat-icon>
	</button>
	<span fxFlex></span>
	<span class="title">{{ pageTitleService.title }}</span>
	<span fxFlex></span>
	<button *ngIf="apiService.isOnline" mat-icon-button [matMenuTriggerFor]="menu" class="sidenavToggleButton right">
		<mat-icon class="avatar">person</mat-icon>
	</button>
	<button *ngIf="!apiService.isOnline" mat-icon-button (click)="showOfflineMessage()" class="sidenavToggleButton right">
		<mat-icon class="avatar">warning</mat-icon>
	</button>
</mat-toolbar>
<mat-menu #menu="matMenu">
	<a mat-menu-item [routerLink]="['/my-account']">
		<mat-icon>account_circle</mat-icon>
		<span>Profiel aanpassen</span>
	</a>
	<a mat-menu-item [routerLink]="['/my-account/two-factor-authentication']">
		<mat-icon>lock</mat-icon>
		<span>Twee-factor-authenticatie</span>
	</a>
	<a mat-menu-item [routerLink]="['/change-password']">
		<mat-icon>settings</mat-icon>
		<span>Wachtwoord aanpassen</span>
	</a>
	<hr>
	<a mat-menu-item (click)="authenticationService.logout()">
		<mat-icon>exit_to_app</mat-icon>
		<span>Uitloggen</span>
	</a>
</mat-menu>
