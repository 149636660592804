<ng-container *ngIf="dataLoaded && !missingData.length">
	<div class="divider"><span>Klantgegevens</span></div>
	<dl>
		<dt>Bedrijfsnaam</dt>
		<dd>{{ client.company_name }}</dd>
		<dt>Locatie</dt>
		<dd>{{ client.address }}<br>{{ client.postalcode }} {{ client.city }}<br>{{ client.country_name }}</dd>
		<dt>Contacten</dt>
		<dd>
			<mat-icon>phone</mat-icon>
			<a *ngIf="client.telephone" href="tel:{{ client.telephone }}">{{ client.telephone }}</a>
			<span *ngIf="!client.telephone">-</span> <br>
			<mat-icon>email</mat-icon>
			<a *ngIf="client.email" href="mailto:{{ client.email }}">{{ client.email }}</a>
			<span *ngIf="!client.email">-</span>
		</dd>
		<dt>Taal</dt>
		<dd>{{ client.language_name }}</dd>
	</dl>
	<agm-map *ngIf="showMap" [latitude]="latitude" [longitude]="longitude" [zoom]="13">
		<agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
	</agm-map>
	<div *ngIf="!showMap" class="alert alert-danger" fxFlex fxLayout="row">
		<mat-icon>warning</mat-icon>
		<h2>Huidige adres kan niet worden weergegeven op de kaart</h2>
	</div>
	<ng-container *ngIf="user.type !== 'kvvm-inspector'">
		<button mat-button color="primary-outline" [routerLink]="['/clients/' + client.id + '/edit']">Aanpassen</button>
		<button mat-button color="accent-outline" (click)="deleteClient(client.id)">Verwijder</button>
		<div class="divider"><span>Inspectie</span></div>
		<dl>
			<dt>Laatst uitgevoerde inspectie</dt>
			<dd *ngIf="client.last_inspection_date">{{ client.last_inspection_date | datex:'DD-MM-YYYY' }}</dd>
			<dd *ngIf="!client.last_inspection_date">-</dd>
			<dt>Aankomende inspectie</dt>
			<dd *ngIf="client.next_inspection_date">{{ client.next_inspection_date | datex:'DD-MM-YYYY' }}</dd>
			<dd *ngIf="!client.next_inspection_date">-</dd>
			<dt>Laatst verstuurde notificatie</dt>
			<dd *ngIf="client.next_inspection_email_sent">{{ client.next_inspection_email_sent | datex:'DD-MM-YYYY' }}</dd>
			<dd *ngIf="!client.next_inspection_email_sent">-</dd>
		</dl>
	</ng-container>
	<button *ngIf="apiService.isOnline" mat-button color="primary-outline" [routerLink]="['/clients/' + client.id + '/next-inspection-notification']">Verstuur volgende inspectie notificatie</button>
	<button *ngIf="apiService.isOnline" mat-button color="primary-outline" [routerLink]="['/clients/' + client.id + '/change-next-inspection-date']">Aanpassen aankomende inspectie datum</button>
	<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">Verstuur volgende inspectie notificatie</button>
	<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">Aanpassen aankomende inspectie datum</button>
	<div class="divider"><span>Contactpersonen</span></div>
	<app-clients-contact-persons-list [smallWidget]="true" style="margin: 0;"></app-clients-contact-persons-list>
	<div class="divider"><span>Bijlagen</span></div>
	<app-clients-attachments-list [smallWidget]="true" style="margin: 0;"></app-clients-attachments-list>
	<div class="divider"><span>Plattegrond</span></div>
	<app-clients-building-maps-list [smallWidget]="true" style="margin: 0;"></app-clients-building-maps-list>
	<div class="divider"><span>Rapportages</span></div>
	<app-clients-reports-list [smallWidget]="true" style="margin: 0;"></app-clients-reports-list>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
