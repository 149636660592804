import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {ApiService} from '../../../_interceptors/api.service';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';
import {PlanningBasic} from '../../../_interfaces/planning/basic';
import {PlanningService} from '../../../_services/planning.service';
import {Reports} from '../../../_interfaces/reports/reports';
import {Client} from '../../../_interfaces/clients/clients';
import {ClientsService} from '../../../_services/clients.service';
import {ReportsService} from '../../../_services/reports.service';
import {PlanningAdvanced} from '../../../_interfaces/planning/advanced';

@Component({
	selector: 'app-planning-view',
	templateUrl: './planning-view.component.html',
	styleUrls: ['./planning-view.component.scss'],
	animations: [routeAnimation]
})

export class PlanningViewComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	public planning_id: number = null;
	public planning: PlanningBasic = null;
	public report: Reports = null;
	public client: Client = null;

	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];

	private _activatedRouteSubscription: Subscription = null;

	constructor(private planningService: PlanningService,
				private clientsService: ClientsService,
				private reportsService: ReportsService,
				public apiService: ApiService,
				private activatedRoute: ActivatedRoute,
				private previousUrlService: PreviousUrlService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length) {
				this.missingData = [];
				this.loadData();
			}
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.planning_id = parseInt(params['planning_id'], 10);

			this.planningService.getPlanning(this.planning_id).then((planning: PlanningBasic) => {
				if (typeof planning !== 'undefined') {

					this.pageTitleService.setTitle(planning.report_nr + ' | Bekijk planning | ' + environment.webappName);
					this.pageTitleService.setPageTitle(planning.report_nr);

				}
			});

			this.previousUrlService.setPreviousUrlIfNull('/planning/');

			this.loadData();
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}
	}

	loadData() {
		this.planningService.getPlanning(this.planning_id).then((data: PlanningAdvanced) => {
			if (typeof data !== 'undefined') {
				this.planning = data;

				// client data
				if (typeof data.client !== 'undefined') {
					this.client = data.client;
				} else if (typeof data.client_id !== 'undefined') {
					this.clientsService.getClient(data.client_id).then((client: Client) => {
						if (typeof client !== 'undefined') {
							this.client = client;
						} else {
							this.missingData.push('Klant');
						}
					});
				}

				// report data
				if (typeof data.report !== 'undefined') {
					this.report = data.report;
				} else if (typeof data.report_id !== 'undefined' && typeof data.type !== 'undefined') {
					this.reportsService.getReport(data.type, data.report_id).then((report: Reports) => {
						if (typeof report !== 'undefined') {
							this.report = report;
						} else {
							this.missingData.push('Rapportage');
						}
					});
				}

				this.dataLoaded = true;
			} else {
				this.missingData.push('Planning');
			}
		});
	}
}
