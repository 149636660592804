<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + report_status]">Bekijk alle rapportages</button>
<ng-container *ngIf="dataLoaded && !missingData.length">
	<div class="language-note" *ngIf="client && client.language_name">
		Let op: de standaard taal van deze klant is {{ client.language_name }}
	</div>
	<app-reports-view-small-widget [report]="report"></app-reports-view-small-widget>
	<app-clients-view-small-widget [client]="client" [showMap]="true"></app-clients-view-small-widget>
	<button *ngIf="reportEditable" mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report.id + (report_status === '/closed' ? '/closed' : '') + '/edit']">Aanpassen</button>
	<div class="divider">
		<span *ngIf="report_type != 'tent'">Gebouwen<span *ngIf="report_type === 'ladders'"> - Trappen</span></span>
		<span *ngIf="report_type == 'tent'">Tenten</span>
	</div>
	<app-reports-buildings-list *ngIf="report_status !== '/closed'" [smallWidget]="true" [reportEditable]="reportEditable" style="margin:-10px 0 0 0;"></app-reports-buildings-list>
	<app-reports-closed-buildings-list *ngIf="report_status === '/closed'" [smallWidget]="true" [reportEditable]="reportEditable" style="margin:-10px 0 0 0;"></app-reports-closed-buildings-list>
	<div class="divider"><span>Bijlagen</span></div>
	<app-reports-attachments-list *ngIf="report_status !== '/closed'" [smallWidget]="true" [reportEditable]="reportEditable" style="margin:-10px 0 0 0;"></app-reports-attachments-list>
	<app-reports-closed-attachments-list *ngIf="report_status === '/closed'" [smallWidget]="true" [reportEditable]="reportEditable" style="margin:-10px 0 0 0;"></app-reports-closed-attachments-list>
	<div class="divider"><span>Download</span></div>
	<button *ngIf="apiService.isOnline" mat-button color="primary-outline" (click)="downloadReport(report_type, report.id, 'pdf')">PDF</button>
	<button *ngIf="apiService.isOnline" mat-button color="primary-outline" (click)="downloadReport(report_type, report.id, 'xlsx')">XLS</button>
	<button *ngIf="apiService.isOnline" mat-button color="primary-outline" (click)="downloadReport(report_type, report.id, 'map')">Plattegrond</button>
	<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">PDF</button>
	<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">XLS</button>
	<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">Plattegrond</button>
	<div class="divider"><span>Opmerking</span></div>
	<app-reports-comments-list *ngIf="report_status !== '/closed'" [smallWidget]="true" [reportEditable]="reportEditable" style="margin:-10px 0 0 0;"></app-reports-comments-list>
	<app-reports-closed-comments-list *ngIf="report_status === '/closed'" [smallWidget]="true" [reportEditable]="reportEditable" style="margin:-10px 0 0 0;"></app-reports-closed-comments-list>
	<div class="divider"><span>Management</span></div>
	<button *ngIf="report_type === 'warehouse'" mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report.id + (report_status === '/closed' ? '/closed' : '') + '/questionnaire']">Inspectieschema's</button>
	<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report.id + '/send-to-client']">Verstuur naar klant</button>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
