<button mat-button color="primary-outline" [routerLink]="['/clients/' + client_id + '/attachments/create']">Bijlage toevoegen</button>
<ng-container *ngIf="smallWidget === false">
	<button mat-button color="primary-outline" [routerLink]="['/clients/' + client_id + '/view']">Bekijk klant</button>
	<button mat-button color="primary-outline" [routerLink]="['/clients']">Bekijk alle klanten</button>
	<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
</ng-container>
<mat-nav-list *ngIf="clientAttachments.length" class="mat-nav-list-with-border">
	<div *ngIf="!smallWidget" infiniteScroll infiniteScrollDistance="1" (scrolled)="getClientAttachments()"></div>
	<mat-list-item *ngFor="let attachment of clientAttachments">
		<div class="mat-list-content" (click)="clientsService.downloadFile(attachment.download_url, attachment.original_filename)">
			<h4 matLine>{{ attachment.title }}</h4>
			<a matLine>
				<mat-icon class="small">get_app</mat-icon>
				<span>{{ attachment.created | datex:'DD-MM-YYYY HH:mm' }}</span>
			</a>
		</div>
		<button mat-icon-button [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item (click)="clientsService.downloadFile(attachment.download_url, attachment.original_filename)">
				<mat-icon>get_app</mat-icon>
				<span>Download</span>
			</a>
			<a mat-menu-item *ngIf="attachment.client_id === client_id" [routerLink]="['/clients/' + attachment.client_id + '/attachments/' + attachment.id + '/edit']">
				<mat-icon>edit</mat-icon>
				<span>Aanpassen</span>
			</a>
			<a mat-menu-item *ngIf="attachment.client_id === client_id" (click)="deleteClientAttachment(attachment.client_id, attachment.id)">
				<mat-icon class="icon-danger">delete</mat-icon>
				<span>Verwijderen</span>
			</a>
		</mat-menu>
	</mat-list-item>
	<a mat-list-item *ngIf="smallWidget === true && totalItems > 5" [routerLink]="['/clients/' + client_id + '/attachments']" class="mat-list-item-more">
		<span matLine>Bekijk meer ...</span>
		<button mat-icon-button>
			<mat-icon>chevron_right</mat-icon>
		</button>
	</a>
</mat-nav-list>
<div *ngIf="!clientAttachments.length">
	<br> Geen bijlagen gevonden.
</div>
