import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../../_services/forms.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ClientsService} from '../../../../_services/clients.service';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {Subscription} from 'rxjs/Subscription';
import {PageTitleService} from '../../../../_services/page-title.service';
import {environment} from '../../../../../environments/environment';
import {Client} from '../../../../_interfaces/clients/clients';

@Component({
	selector: 'app-clients-attachments-create',
	templateUrl: './clients-attachments-create.component.html',
	styleUrls: ['./clients-attachments-create.component.scss'],
	animations: [routeAnimation]
})

export class ClientsAttachmentsCreateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public client_id: number = null;

	public clientsAttachmentsCreateForm = this.formBuilder.group({
		filename: ['', Validators.required],
		title: ['', [Validators.required, Validators.maxLength(100)]],
		description: [''],
		append_warehouse: [true],
		append_ladder: [true],
		append_tent: [true],
	});

	private _activatedRouteSubscription: Subscription = null;
	private _errorsSubscription: Subscription = null;

	constructor(public clientsService: ClientsService,
				public formsService: FormsService,
				private activatedRoute: ActivatedRoute,
				private formBuilder: FormBuilder,
				private previousUrlService: PreviousUrlService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.formsService.form = this.clientsAttachmentsCreateForm;

		this._errorsSubscription = this.clientsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.client_id = parseInt(params['client_id'], 10);

			this.clientsService.getClient(this.client_id).then((client: Client) => {
				if (typeof client !== 'undefined') {
					this.pageTitleService.setTitle(client.company_name + ' | Bijlage toevoegen | ' + environment.webappName);
					this.pageTitleService.setPageTitle(client.company_name);
				}
			});

			this.previousUrlService.setPreviousUrlIfNull('clients/' + this.client_id + '/view');
		});

	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}
}
