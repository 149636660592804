import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {GeneralService} from '../../../../_services/general.service';
import {InspectorsService} from '../../../../_services/inspectors.service';
import {InspectorsInspections} from '../../../../_interfaces/inspectors/inspectors-inspections';
import {ApiService} from '../../../../_interceptors/api.service';
import {ServerResponse} from '../../../../_interfaces/server.response';

@Component({
	selector: 'app-inspectors-inspections-list',
	templateUrl: './inspections-list.component.html',
	styleUrls: ['./inspections-list.component.scss'],
	animations: [routeAnimation]
})

export class InspectorsInspectionsListComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	private totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	private isLoading: boolean = false;

	// noinspection JSMismatchedCollectionQueryUpdate
	public inspectorsInspections: InspectorsInspections[] = [];

	constructor(private inspectorsService: InspectorsService,
				public apiService: ApiService,
				private generalService: GeneralService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.inspectorsInspections.length === 0) {
				this.currentPage = 0;
				this.getInspectorsInspections();
			}
		});

		this.getInspectorsInspections();
	}

	ngOnDestroy(): void {
	}

	getInspectorsInspections(append_data: boolean = true) {
		if (!this.isLoading) {
			this.isLoading = true;
			this.currentPage++;

			this.inspectorsService.getInspectorsInspections({
				start: (this.currentPage - 1) * this.generalService.itemsPerPage,
				length: this.generalService.itemsPerPage,
				'columns[0][search][value]': this.searchString
			}).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.recordsTotal !== 'undefined') {
						this.totalItems = data.recordsTotal;
					}

					if (append_data === true) {
						this.inspectorsInspections.push(...<InspectorsInspections[]>data.data);
					} else {
						this.inspectorsInspections = <InspectorsInspections[]>data.data;
					}
				}
				this.isLoading = false;
			});
		}
	}

	search($event) {
		this.searchString = $event;
		this.currentPage = 0;
		this.getInspectorsInspections(false);
	}
}
