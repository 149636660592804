<form [formGroup]="myProfileForm" (ngSubmit)="authenticationService.updateMyProfile(myProfileForm.value)">
	<mat-form-field>
		<input matInput placeholder="Voornaam" type="text" required formControlName="firstname">
		<mat-error *ngIf="formsService.getFieldError('firstname')">{{formsService.getFieldError('firstname')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="Achternaam" type="text" required formControlName="lastname">
		<mat-error *ngIf="formsService.getFieldError('lastname')">{{formsService.getFieldError('lastname')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<mat-select placeholder="Taal" required formControlName="language_id">
			<mat-option *ngFor="let language of languages" [value]="language.id">{{ language.language_name }}</mat-option>
		</mat-select>
		<mat-error *ngIf="formsService.getFieldError('language_id')">{{formsService.getFieldError('language_id')}}</mat-error>
	</mat-form-field>
	<div class="divider"><span>Gebruikersgegevens</span></div>
	<mat-form-field>
		<input matInput placeholder="E-Mail" type="email" required formControlName="email" readonly>
		<mat-error *ngIf="formsService.getFieldError('email')">{{formsService.getFieldError('email')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="Functie omschrijving" type="text" formControlName="job_function">
		<mat-error *ngIf="formsService.getFieldError('job_function')">{{formsService.getFieldError('job_function')}}</mat-error>
	</mat-form-field>
	<div class="divider"><span>Handtekening</span></div>
	<mat-form-field>
		<ngx-mat-file-input formControlName="signature_image" placeholder="Handtekening" #filenameInput>
			<button mat-icon-button *ngIf="!filenameInput.empty" (click)="filenameInput.clear($event)">
				<mat-icon>clear</mat-icon>
			</button>
		</ngx-mat-file-input>
		<mat-error *ngIf="formsService.getFieldError('signature_image')">{{formsService.getFieldError('signature_image')}}</mat-error>
	</mat-form-field>
	<button color="primary" mat-button [disabled]="!myProfileForm.valid">MIJN PROFIEL AANPASSEN</button>
</form>
