<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
<mat-nav-list class="mat-nav-list-with-border" infiniteScroll infiniteScrollDistance="1" (scrolled)="getReports()">
	<mat-list-item *ngFor="let report of reports">
		<div class="mat-list-content" [routerLink]="['/reports/' + report.type + '/' + report.id + '/' + (report.status === 'closed' ? '/closed' : '') + '/view']">
			<h4 matLine>{{ report.company_name }}</h4>
			<a matLine>{{ report.created | datex:'DD-MM-YYYY' }} - {{ report.report_nr }}</a>
		</div>
		<ng-container *ngIf="report.color">
			<div class="tag" [style.background]="report.color"></div>
		</ng-container>
		<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item (click)="copyReport(report.type, report.id)">Copy</a>
		</mat-menu>
	</mat-list-item>
</mat-nav-list>
