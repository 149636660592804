import {NativeDateAdapter} from '@angular/material/core';
import * as moment from 'moment';

export class CustomDateAdapter extends NativeDateAdapter {
	parse(value: any): Date | null {
		if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
			const str = value.split('/');
			const year = parseInt('20' + Number(str[2]), 10);
			const month = Number(str[1]) - 1;
			const date = Number(str[0]);
			return new Date(year, month, date);
		}
		const timestamp = typeof value === 'number' ? value : Date.parse(value);
		return isNaN(timestamp) ? null : new Date(timestamp);
	}

	format(date: Date, displayFormat: Object): string {
		if (displayFormat === 'input') {
			const day = date.getDate(),
				month = date.getMonth() + 1,
				year = date.getFullYear();
			return this._to2digit(day) + '-' + this._to2digit(month) + '-' + year;
		} else if (displayFormat === 'month-input') {
			const day = date.getDate(),
				year = date.getFullYear();
			return this._to2digit(day) + '. ' + moment(date).format('MMM') + ' ' + this._shortYear(year);
		} else {
			return date.toDateString();
		}
	}

	// noinspection JSMethodCanBeStatic
	private _to2digit(n: number) {
		return ('00' + n).slice(-2);
	}

	// noinspection JSMethodCanBeStatic
	private _shortYear(n: number) {
		return ('00' + n).slice(-2);
	}
}

export const APP_DATE_FORMATS = {
	parse: {
		dateInput: {
			month: 'numeric',
			year: 'numeric',
			day: 'numeric'
		}
	},
	display: {
		dateInput: 'input',
		monthYearLabel: 'month-input',
		dateA11yLabel: {year: 'numeric', month: 'numeric', day: 'numeric'},
		monthYearA11yLabel: {year: 'numeric', month: 'numeric'}
	}
};
