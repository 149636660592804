import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {FormsService} from '../../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {ReportBuildingParticulars} from '../../../../_interfaces/reports/report-building-particulars';
import {ReportsService} from '../../../../_services/reports.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ServerResponse} from '../../../../_interfaces/server.response';
import {debounceTime} from 'rxjs/operators';
import {ApiService} from '../../../../_interceptors/api.service';
import {Reports} from '../../../../_interfaces/reports/reports';
import {User} from '../../../../_interfaces/user';
import {AuthenticationService} from '../../../../_services/authentication.service';
import {PageTitleService} from '../../../../_services/page-title.service';
import {environment} from '../../../../../environments/environment';
import {ClientsService} from '../../../../_services/clients.service';
import {ClientsBuildingMaps} from '../../../../_interfaces/clients/clients-building-maps';

@Component({
	selector: 'app-reports-buildings-create',
	templateUrl: './reports-buildings-create.component.html',
	styleUrls: ['./reports-buildings-create.component.scss'],
	animations: [routeAnimation]
})

export class ReportsBuildingsCreateComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public report_type: string = null;
	public report_id: number = null;
	public particulars: ReportBuildingParticulars[] = [];
	public isFiltered: boolean = false;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];
	public reportEditable: boolean = false;
	public showParticularTextarea: Array<number> = [];
	public clientBuildingMaps: ClientsBuildingMaps[] = [];

	public reportsBuildingCreateForm = this.formBuilder.group({
		name: ['', [Validators.required, Validators.maxLength(50)]],
		description: [''],
		squares: [''],
		company_inspection: null,
		building_map_id: [''],
		query: ['']
	});

	private _activatedRouteSubscription: Subscription = null;
	private _errorsSubscription: Subscription = null;
	private _authenticationServiceSubscription: Subscription = null;

	constructor(public reportsService: ReportsService,
				private authenticationService: AuthenticationService,
				private apiService: ApiService,
				public formsService: FormsService,
				private previousUrlService: PreviousUrlService,
				private activatedRoute: ActivatedRoute,
				private formBuilder: FormBuilder,
				private clientsService: ClientsService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.particulars.length === 0) {
				if (this.report_type === 'warehouse') {
					this.missingData = [];
					this.loadData();
				}
			}
		});

		this.formsService.form = this.reportsBuildingCreateForm;

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];
			this.report_id = parseInt(params['report_id'], 10);

			this.reportsService.getReport(this.report_type, this.report_id).then((report: Reports) => {
				this._authenticationServiceSubscription = this.authenticationService.user.first().subscribe((user: User) => {
					if (typeof report !== 'undefined' && typeof user !== 'undefined') {

						this.clientsService.getBuildingMaps(report.client_id).then(buildingMaps => {
							if (typeof buildingMaps !== 'undefined') {
								if (typeof buildingMaps.data !== 'undefined') {
									this.clientBuildingMaps = buildingMaps.data;
								}
							}
						});

						this.pageTitleService.setTitle(report.report_nr + ' | Rapportage gebouw toevoegen | ' + environment.webappName);
						this.pageTitleService.setPageTitle(report.report_nr);

						if (typeof report.edit_lock !== 'undefined' && typeof report.edit_lock_user_id !== 'undefined') {
							if (report.edit_lock === true && (<any>report.edit_lock_user_id === '' ||
								report.edit_lock_user_id === null ||
								report.edit_lock_user_id === 0 ||
								report.edit_lock_user_id === user.id ||
								user.type === 'kvvm-admin')) {
								this.reportEditable = true;
							}
						}
					}
				});
			});

			this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_type + '/' + this.report_id + '/buildings');

			if (this.report_type === 'warehouse') {
				this.loadData();
			} else {
				this.dataLoaded = true;
			}
		});

		this.reportsBuildingCreateForm
			.get('query')
			.valueChanges
			.pipe(
				debounceTime(300))
			.subscribe(value => {
				if (value !== null && value !== '') {
					this.particulars.forEach(data => {
						data.visible = data.building_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
					});
					this.isFiltered = true;
				} else {
					this.clearSearchInput();
				}
			});

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}

		if (this._authenticationServiceSubscription !== null) {
			this._authenticationServiceSubscription.unsubscribe();
		}
	}

	clearSearchInput() {
		this.reportsBuildingCreateForm.get('query').setValue('');
		this.isFiltered = false;
		this.particulars.forEach(data => {
			data.visible = true;
		});
	}

	fixAngularScrewUpAndInsertActualValue(event, index) {
		if (event.checked) {
			this.reportsBuildingCreateForm.controls['lib_particular_id[' + index + ']'].setValue(parseInt(event.source.value, 10));
		} else {
			this.reportsBuildingCreateForm.controls['lib_particular_id[' + index + ']'].setValue('');
		}
	}

	loadData() {
		this.reportsService.getBuildingParticulars().then((data: ServerResponse) => {
			if (typeof data !== 'undefined') {
				if (typeof data.data !== 'undefined') {
					this.particulars = <ReportBuildingParticulars[]>data.data;

					if (data.data.length) {
						data.data.forEach(particular => {
							this.reportsBuildingCreateForm.addControl('lib_particular_id[' + particular.id + ']', new FormControl(particular.id));
							this.reportsBuildingCreateForm.addControl('particular_description[' + particular.id + ']', new FormControl(''));
						});

						const controls = this.reportsBuildingCreateForm.controls;
						Object.keys(controls).map(key => {
							if (key.indexOf('lib_particular_id') !== -1) {
								controls[key].setValue('');
								controls[key].valueChanges.subscribe(value => {
									if (typeof value !== 'number') {
										let cleanKey = parseInt(key.replace('lib_particular_id[', '').replace(']', ''), 10);
										if (value !== null && value !== '' && value !== false) {
											this.showParticularTextarea.push(cleanKey);
										} else {
											let index = this.showParticularTextarea.findIndex(id => id === cleanKey);
											if (index !== -1) {
												this.showParticularTextarea.splice(index, 1);
											}
										}
									}
								});
							}
						});
					}
					this.dataLoaded = true;
				} else {
					this.missingData.push('Particulars');
				}
			} else {
				this.missingData.push('Particulars');
			}
		});
	}
}
