<ng-container *ngIf="dataLoaded">
	<div class="divider"><span>Plattengrond gegevens</span></div>
	<form class="with-action-buttons" [formGroup]="clientsBuildingMapsEditForm" (ngSubmit)="clientsService.updateBuildingMap(client_id, building_map_id, clientsBuildingMapsEditForm.value)">
		<mat-form-field>
			<input title="" matInput placeholder="Naam gebouw" type="text" formControlName="building_name" required>
			<mat-error *ngIf="formsService.getFieldError('building_name')">{{formsService.getFieldError('building_name')}}</mat-error>
		</mat-form-field>
		<mat-form-field>
			<ngx-mat-file-input formControlName="filename" placeholder="Plattengrond" #filenameInput>
			</ngx-mat-file-input>
			<button mat-icon-button matSuffix *ngIf="!filenameInput.empty" (click)="filenameInput.clear($event)">
				<mat-icon>clear</mat-icon>
			</button>
			<mat-error *ngIf="formsService.getFieldError('filename')">{{formsService.getFieldError('filename')}}</mat-error>
		</mat-form-field>
		<ng-container *ngIf="buildingMap.map_url">
			<div class="divider"><span>Actieve plattegrond</span></div>
			<img src="{{ buildingMap.map_url }}" alt="">
		</ng-container>
		<div class="action-buttons">
			<button color="primary" mat-button [disabled]="!clientsBuildingMapsEditForm.valid">OPSLAAN</button>
		</div>
	</form>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
