<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/' + report_id + '/view']">Bekijk rapportage</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_type + '/open']">Bekijk alle rapportages</button>
<ng-container *ngIf="dataLoaded && !missingData.length">
	<div *ngIf="!reportEditable" class="alert alert-danger" fxFlex fxLayoutAlign="center" fxLayout="row">
		<mat-icon>warning</mat-icon>
		<h2>Rapport is vergrendeld door een andere gebruiker. Om het te bewerken, moet u eerst de rapportage ontgrendelen.</h2>
	</div>
	<mat-expansion-panel>
		<mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
			<mat-panel-title>
				<div class="divider"><span>Rapportage gegevens</span></div>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<dl>
			<dt>Rapportage nummer</dt>
			<dd>{{ report.report_nr }}</dd>
			<dt>Inspecteur</dt>
			<dd>{{ report.inspector }}</dd>
			<dt>Status</dt>
			<dd>{{ report.status_text }}</dd>
			<dt>Datum aangemaakt</dt>
			<dd *ngIf="report.created !== null">{{ report.created | datex:'DD-MM-YYYY' }}</dd>
			<dd *ngIf="report.created === null">-</dd>
			<dt>Datum aangepast</dt>
			<dd *ngIf="report.updated !== null">{{ report.updated | datex:'DD-MM-YYYY' }}</dd>
			<dd *ngIf="report.updated === null">-</dd>
		</dl>
	</mat-expansion-panel>
	<mat-expansion-panel>
		<mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
			<mat-panel-title>
				<div class="divider"><span>Klantgegevens</span></div>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<dl *ngIf="client">
			<dt>Bedrijfsnaam</dt>
			<dd>{{ client.company_name }}</dd>
			<dt>Locatie</dt>
			<dd>{{ client.address }}<br>{{ client.postalcode }} {{ client.city }}<br>{{ client.country_name }}</dd>
			<dt>Contacten</dt>
			<dd>
				<mat-icon>phone</mat-icon>
				<a *ngIf="client.telephone" href="tel:{{ client.telephone }}">{{ client.telephone }}</a>
				<span *ngIf="!client.telephone">-</span> <br>
				<mat-icon>email</mat-icon>
				<a *ngIf="client.email" href="mailto:{{ client.email }}">{{ client.email }}</a>
				<span *ngIf="!client.email">-</span>
			</dd>
		</dl>
	</mat-expansion-panel>
	<div class="divider"><span>Inspectieschema gegevens</span></div>
	<form class="with-action-buttons" *ngIf="questionnaire" [formGroup]="reportQuestionnaireForm" (ngSubmit)="reportsService.saveReportQuestionnaire(report_type, report_id, reportQuestionnaireForm.value)">
		<div *ngFor="let questionnaire of questionnaire" class="questionnaire-container" formGroupName="questions">
			<div class="questionnaire-header">
				<h4>{{ questionnaire.name }}</h4>
				<p *ngIf="questionnaire.description">{{ questionnaire.description }}</p>
				<div>
					<mat-radio-group formControlName="[{{ questionnaire.id }}][status]" [disabled]="!reportEditable">
						<mat-radio-button color="primary" [value]="true" [checked]="questionnaire.result === true">Actief</mat-radio-button>
						<mat-radio-button color="primary" [value]="false" [checked]="questionnaire.result === false">Uitgeschakelt</mat-radio-button>
					</mat-radio-group>
				</div>
			</div>
			<ng-container *ngIf="reportQuestionnaireForm.get('questions').get('[' + questionnaire.id + '][status]').value === true">
				<div class="questionnaire-body">
					<div *ngFor="let question of questionnaire.questions" class="questionnaire-question">
						<p>{{ question.question }}</p>
						<p *ngIf="question.norm">{{ question.norm }}</p>
						<div>
							<mat-radio-group formControlName="[{{ questionnaire.id }}][{{ question.id }}]" [disabled]="!reportEditable">
								<mat-radio-button color="primary" [value]="368" [checked]="question.result === 368 || question.result === 0">Goed</mat-radio-button>
								<mat-radio-button color="primary" [value]="369" [checked]="question.result === 369">Fout</mat-radio-button>
								<mat-radio-button color="primary" [value]="370" [checked]="question.result === 370">nvt</mat-radio-button>
							</mat-radio-group>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
		<div *ngIf="reportEditable" class="action-buttons">
			<button color="primary" mat-button [disabled]="!reportQuestionnaireForm.valid">OPSLAAN</button>
		</div>
	</form>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Niet alle data is beschikbaar online, ga online om verder te gaan.</h2>
</div>
