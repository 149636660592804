import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import Quagga from 'quagga';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-barcode-scanner',
	templateUrl: './barcode-scanner.component.html',
	styleUrls: ['./barcode-scanner.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class BarcodeScannerComponent implements OnInit {
	public barcode: string = '';

	private configQuagga = {
		inputStream: {
			name: 'Live',
			type: 'LiveStream',
			target: '#interactive',
			constraints: {
				width: {min: 640},
				height: {min: 480},
				aspectRatio: {min: 1, max: 100},
				facingMode: 'environment', // or user
			},
			singleChannel: false // true: only the red color-channel is read
		},
		locator: {
			patchSize: 'medium',
			halfSample: true
		},
		locate: true,
		numOfWorkers: 4,
		decoder: {
			readers: ['ean_reader']
		}
	};

	constructor(private ref: ChangeDetectorRef,
				public dialogRef: MatDialogRef<BarcodeScannerComponent>) {
	}

	ngOnInit() {
		this.startScanner();
	}

	startScanner() {
		this.barcode = '';
		this.ref.detectChanges();
		Quagga.onProcessed((result) => this.onProcessed(result));
		Quagga.onDetected((result) => this.logCode(result));

		Quagga.init(this.configQuagga, (err) => {
			if (err) {
				throw new Error(err);
			}
			Quagga.start();
		});
	}

	private onProcessed(result: any) {
		const drawingCtx = Quagga.canvas.ctx.overlay;
		const drawingCanvas = Quagga.canvas.dom.overlay;

		if (result) {
			if (result.boxes) {
				drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width'), 10), parseInt(drawingCanvas.getAttribute('height'), 10));
				result.boxes.filter(function (box) {
					return box !== result.box;
				}).forEach(function (box) {
					Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: 'green', lineWidth: 2});
				});
			}

			if (result.box) {
				Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: '#00f', lineWidth: 2});
			}

			if (result.codeResult && result.codeResult.code) {
				Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
			}
		}
	}

	private logCode(result) {
		const code = result.codeResult.code;

		if (this.barcode !== code) {
			this.barcode = code;
			this.ref.detectChanges();
			Quagga.stop();
			this.dialogRef.close(code);
		}
	}
}
