import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../route.animation';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FormsService} from '../../../_services/forms.service';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute, Params} from '@angular/router';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {APP_DATE_FORMATS, CustomDateAdapter} from '../../../_pipes/angular2-material-datepicker-custom-date';
import {PreviousUrlService} from '../../../_services/previous-url.service';
import {ReportsService} from '../../../_services/reports.service';
import {Reports} from '../../../_interfaces/reports/reports';
import {Client} from '../../../_interfaces/clients/clients';
import {ReportsQuestionsAnswers} from '../../../_interfaces/reports/reports-questions-answers';
import {ReportsQuestions} from '../../../_interfaces/reports/reports-questions';
import {ClientsService} from '../../../_services/clients.service';
import {ApiService} from '../../../_interceptors/api.service';
import {ServerResponse} from '../../../_interfaces/server.response';
import {AuthenticationService} from '../../../_services/authentication.service';
import {User} from '../../../_interfaces/user';
import {PageTitleService} from '../../../_services/page-title.service';
import {environment} from '../../../../environments/environment';

@Component({
	selector: 'app-reports-questionnaire',
	templateUrl: './reports-questionnaire.component.html',
	styleUrls: ['./reports-questionnaire.component.scss'],
	animations: [routeAnimation],
	providers: [
		{
			provide: DateAdapter, useClass: CustomDateAdapter
		},
		{
			provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
		}
	]
})

export class ReportsQuestionnaireComponent implements OnInit, OnDestroy {
	@HostBinding('@routeAnimation') routerTransition = true;

	public report_id: number = null;
	public report_type: string = null;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];
	public reportEditable: boolean = false;

	public report: Reports = null;
	public client: Client = null;
	public questionnaire: ReportsQuestions[] = [];

	public reportQuestionnaireForm = this.formBuilder.group({
		questions: this.formBuilder.group([])
	});

	private _errorsSubscription: Subscription = null;
	private _activatedRouteSubscription: Subscription = null;
	private _authenticationServiceSubscription: Subscription = null;

	constructor(public reportsService: ReportsService,
				private authenticationService: AuthenticationService,
				private apiService: ApiService,
				private clientsService: ClientsService,
				public formsService: FormsService,
				private activatedRoute: ActivatedRoute,
				private formBuilder: FormBuilder,
				private previousUrlService: PreviousUrlService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length) {
				this.missingData = [];
				this.loadData();
			}
		});

		this.formsService.form = this.reportQuestionnaireForm;

		this._errorsSubscription = this.reportsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];
			this.report_id = parseInt(params['report_id'], 10);

			this.reportsService.getReport(this.report_type, this.report_id).then((report: Reports) => {
				this._authenticationServiceSubscription = this.authenticationService.user.first().subscribe((user: User) => {
					if (typeof report !== 'undefined' && typeof user !== 'undefined') {

						this.pageTitleService.setTitle(report.report_nr + ' | Rapportage inspectieschema | ' + environment.webappName);
						this.pageTitleService.setPageTitle(report.report_nr);

						if (report.status !== 'closed') {
							if (typeof report.edit_lock !== 'undefined' && typeof report.edit_lock_user_id !== 'undefined') {
								if (report.edit_lock === true && (<any>report.edit_lock_user_id === '' ||
									report.edit_lock_user_id === null ||
									report.edit_lock_user_id === 0 ||
									report.edit_lock_user_id === user.id ||
									user.type === 'kvvm-admin')) {
									this.reportEditable = true;
								}
							}
						}
					}
				});
			});

			this.previousUrlService.setPreviousUrlIfNull('/reports/' + this.report_type + '/' + this.report_id + '/view');

			this.loadData();
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}

		if (this._authenticationServiceSubscription !== null) {
			this._authenticationServiceSubscription.unsubscribe();
		}
	}

	loadData() {
		this.reportsService.getReport(this.report_type, this.report_id).then((report: Reports) => {
			if (typeof report !== 'undefined') {
				this.report = report;

				this.clientsService.getClient(report.client_id).then((client: Client) => {
					this.client = client;
				});

				this.reportsService.getWarehouseQuestionnaires(this.report_type).then((questionnaire: ServerResponse) => {
					if (typeof questionnaire !== 'undefined') {
						if (typeof questionnaire.data !== 'undefined') {
							questionnaire.data.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
							this.reportsService.getWarehouseQuestionnaireAnswer(this.report_type, this.report_id).then((answers: ReportsQuestionsAnswers) => {
								questionnaire.data.forEach(question_group => {
									let control = <FormGroup>this.reportQuestionnaireForm.get('questions'),
										answer = (typeof answers !== 'undefined' ? (typeof answers.questions !== 'undefined' ? (typeof answers.questions['[' + question_group.id + '][status]'] !== 'undefined' ? answers.questions['[' + question_group.id + '][status]'] : null) : null) : null);
									control.addControl('[' + question_group.id + '][status]', new FormControl(answer));
									question_group['status'] = answer;

									if (typeof question_group.questions !== 'undefined') {
										question_group.questions.sort((a, b) => a.sequence > b.sequence ? 1 : -1);
										if (typeof question_group.questions !== 'undefined') {
											question_group.questions.forEach(question => {
												let answer2 = (typeof answers !== 'undefined' ? (typeof answers.questions !== 'undefined' ? (typeof answers.questions['[' + question_group.id + '][' + question.id + ']'] !== 'undefined' ? answers.questions['[' + question_group.id + '][' + question.id + ']'] : null) : null) : 368);
												control.addControl('[' + question_group.id + '][' + question.id + ']', new FormControl(answer2));
												question['result'] = answer2;
											});
										}
									}
									this.questionnaire.push(<ReportsQuestions>question_group);
								});
							});
						} else {
							this.missingData.push('Inspectieschema');
						}
					} else {
						this.missingData.push('Inspectieschema');
					}
				});

				this.dataLoaded = true;
			} else {
				this.missingData.push('Rapportage');
			}
		});
	}
}
