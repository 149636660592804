import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {routeAnimation} from '../../../../route.animation';
import {FormBuilder, Validators} from '@angular/forms';
import {FormsService} from '../../../../_services/forms.service';
import {ActivatedRoute, Params} from '@angular/router';
import {ClientsService} from '../../../../_services/clients.service';
import {PreviousUrlService} from '../../../../_services/previous-url.service';
import {Subscription} from 'rxjs/Subscription';
import {ApiService} from '../../../../_interceptors/api.service';
import {PageTitleService} from '../../../../_services/page-title.service';
import {environment} from '../../../../../environments/environment';
import {Client} from '../../../../_interfaces/clients/clients';
import {ClientsBuildingMaps} from '../../../../_interfaces/clients/clients-building-maps';

@Component({
	selector: 'app-clients-building-maps-edit',
	templateUrl: './clients-building-maps-edit.component.html',
	styleUrls: ['./clients-building-maps-edit.component.scss'],
	animations: [routeAnimation]
})

export class ClientsBuildingMapsEditComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	public client_id: number = null;
	public building_map_id: number = null;
	public dataLoaded: boolean = false;
	public missingData: Array<string> = [];

	public buildingMap: ClientsBuildingMaps = null;

	public clientsBuildingMapsEditForm = this.formBuilder.group({
		building_name: ['', [Validators.required, Validators.maxLength(100)]],
		filename: [''],
	});

	private _activatedRouteSubscription: Subscription = null;
	private _errorsSubscription: Subscription = null;

	constructor(public clientsService: ClientsService,
				public formsService: FormsService,
				private activatedRoute: ActivatedRoute,
				private formBuilder: FormBuilder,
				private apiService: ApiService,
				private previousUrlService: PreviousUrlService,
				private pageTitleService: PageTitleService) {
	}

	ngOnInit(): void {
		this.apiService.connectionChanged.subscribe(online => {
			if (online && this.missingData.length === 0) {
				this.loadData();
			}
		});

		this.formsService.form = this.clientsBuildingMapsEditForm;

		this._errorsSubscription = this.clientsService.errors.subscribe(errors => {
			this.formsService.processErrors(errors);
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.client_id = parseInt(params['client_id'], 10);
			this.building_map_id = parseInt(params['building_map_id'], 10);

			this.clientsService.getClient(this.client_id).then((client: Client) => {
				if (typeof client !== 'undefined') {
					this.pageTitleService.setTitle(client.company_name + ' | Plattegrond aanpassen | ' + environment.webappName);
					this.pageTitleService.setPageTitle(client.company_name);
				}
			});

			this.loadData();

			this.previousUrlService.setPreviousUrlIfNull('clients/' + this.client_id + '/view');
		});
	}

	ngOnDestroy(): void {
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._errorsSubscription !== null) {
			this._errorsSubscription.unsubscribe();
		}
	}

	loadData() {
		this.clientsService.getBuildingMap(this.client_id, this.building_map_id).then(building_map => {
			if (typeof building_map !== 'undefined') {
				this.formsService.populateForm(<ClientsBuildingMaps>building_map);
				this.buildingMap = building_map;

				this.dataLoaded = true;
			} else {
				this.missingData.push('Plattegrond');
			}
		});
	}
}
