import {Component, HostBinding, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {routeAnimation} from '../../../../../route.animation';
import {GeneralService} from '../../../../../_services/general.service';
import {PreviousUrlService} from '../../../../../_services/previous-url.service';
import {ReportsBuildingsReports} from '../../../../../_interfaces/reports/reports-buildings-reports';
import {ReportsService} from '../../../../../_services/reports.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {ServerResponse} from '../../../../../_interfaces/server.response';
import {ApiService} from '../../../../../_interceptors/api.service';
import {AuthenticationService} from '../../../../../_services/authentication.service';
import {User} from '../../../../../_interfaces/user';
import {PageTitleService} from '../../../../../_services/page-title.service';
import {environment} from '../../../../../../environments/environment';
import {MatTable} from '@angular/material/table';
import {MediaChange} from '@angular/flex-layout';
import {MediaReplayService} from '../../../../../core/sidenav/mediareplay/media-replay.service';
import {ReportsBuildingsMapsMarker} from '../../../../../_interfaces/reports/reports-buildings-maps-marker';
import {ClientsService} from '../../../../../_services/clients.service';
import {Reports} from '../../../../../_interfaces/reports/reports';
import {ClientsBuildingMaps} from '../../../../../_interfaces/clients/clients-building-maps';

@Component({
	selector: 'app-reports-closed-buildings-reports-list',
	templateUrl: './reports-buildings-reports-list.component.html',
	styleUrls: ['./reports-buildings-reports-list.component.scss'],
	animations: [routeAnimation]
})

export class ReportsClosedBuildingsReportsListComponent implements OnInit, OnDestroy {

	@HostBinding('@routeAnimation') routerTransition = true;

	private totalItems: number = 0;
	private currentPage: number = 0;
	private searchString: string = '';
	private isLoading: boolean = false;
	private keepLoading: boolean = false;

	public report_type: string = null;
	public report_id: number = null;
	public report_status: string = '/closed';
	public building_id: number = null;
	public buildingReports: ReportsBuildingsReports[] = [];
	public reportEditable: boolean = false;
	public user: User = null;

	public hasBuildingMap: boolean = false;
	public buildingMapUrl: string = null;
	public mapMarkers: ReportsBuildingsMapsMarker[] = [];

	private _activatedRouteSubscription: Subscription = null;
	private _authenticationSubscription: Subscription = null;
	private _mediaSubscription: Subscription = null;

	// table on tablet
	public isTablet: boolean = false;
	public displayedColumns: string[] = ['number', 'location_nr', 'measure_depth', 'measure_height', 'color_code', 'category_name', 'subcategory_name', 'shortcoming_types', 'shortcoming_actions', 'commands'];
	@ViewChild(MatTable, {static: false}) table: MatTable<any>;

	@Input() smallWidget: boolean = false;

	constructor(private reportsService: ReportsService,
				private mediaReplayService: MediaReplayService,
				public apiService: ApiService,
				private activatedRoute: ActivatedRoute,
				private authenticationService: AuthenticationService,
				private previousUrlService: PreviousUrlService,
				private pageTitleService: PageTitleService,
				private clientsService: ClientsService,
				private generalService: GeneralService) {
	}

	ngOnInit(): void {
		this._mediaSubscription = this.mediaReplayService.media$.subscribe((change: MediaChange) => {
			this.isTablet = (change.mqAlias === 'md' || change.mqAlias === 'lg');
		});

		this._activatedRouteSubscription = this.activatedRoute.params.subscribe((params: Params) => {
			this.report_type = params['report_type'];
			this.report_id = parseInt(params['report_id'], 10);
			this.building_id = parseInt(params['building_id'], 10);

			this.reportsService.getClosedReportBuildings(this.report_type, this.report_id, this.building_id).then((data: ServerResponse) => {
				if (typeof data.data !== 'undefined') {
					if (typeof data.data[0] !== 'undefined') {
						if (typeof data.data[0].building_map_id !== 'undefined') {
							if (data.data[0].building_map_id !== null) {
								this.reportsService.getClosedReports(this.report_type, this.report_id).then((report: Reports) => {
									if (typeof report !== 'undefined') {
										this.clientsService.getBuildingMap(report.client_id, data.data[0].building_map_id).then((buildingMap: ClientsBuildingMaps) => {
											if (typeof buildingMap !== 'undefined') {
												this.hasBuildingMap = true;
												this.buildingMapUrl = buildingMap.map_url;
											}
										});
										this.reportsService.getBuildingMapMarkers(this.report_id, this.building_id).then((markers: ReportsBuildingsMapsMarker[]) => {
											this.mapMarkers = markers;
										});
									}
								});
							}
						}

						if (this.smallWidget === false) {
							if (this.report_type === 'warehouse') {
								this.pageTitleService.setTitle(data.data[0].name + ' | Gebouwen | ' + environment.webappName);
							} else {
								this.pageTitleService.setTitle(data.data[0].name + ' | Gebouwen - Trappen | ' + environment.webappName);
							}
							this.pageTitleService.setPageTitle(data.data[0].name);
						}
					}
				}
			});

			this._authenticationSubscription = this.authenticationService.user.first().subscribe(user => {
				this.user = user;
				this.currentPage = 0;
				this.getReportsBuildingReports(false);
			});
		});
	}

	ngOnDestroy(): void {
		if (this._mediaSubscription !== null) {
			this._mediaSubscription.unsubscribe();
		}
		if (this._activatedRouteSubscription !== null) {
			this._activatedRouteSubscription.unsubscribe();
		}

		if (this._authenticationSubscription !== null) {
			this._authenticationSubscription.unsubscribe();
		}
	}

	getReportsBuildingReports(append_data: boolean = true) {
		if (!this.isLoading || this.keepLoading) {
			this.isLoading = true;
			this.keepLoading = false;
			this.currentPage++;

			this.reportsService.getClosedReportBuildingReports(this.report_type, this.report_id, this.building_id, null, {
				start: (this.currentPage - 1) * this.generalService.itemsPerPage,
				length: this.generalService.itemsPerPage,
				'columns[0][search][value]': this.searchString
			}).then((data: ServerResponse) => {
				if (typeof data !== 'undefined') {
					if (typeof data.recordsTotal !== 'undefined') {
						this.totalItems = data.recordsTotal;
					}

					if (append_data === true) {
						if (this.isTablet && this.report_type === 'warehouse') {
							this.buildingReports.push(...this.processReportData(<ReportsBuildingsReports[]>data.data));
							this.table.renderRows();
						} else {
							this.buildingReports.push(...<ReportsBuildingsReports[]>data.data);
						}
					} else {
						if (this.isTablet && this.report_type === 'warehouse') {
							this.buildingReports = this.processReportData(<ReportsBuildingsReports[]>data.data);
							this.table.renderRows();
						} else {
							this.buildingReports = <ReportsBuildingsReports[]>data.data;
						}
					}
				}
				this.isLoading = false;
			});
		}
	}

	processReportData(reportData: ReportsBuildingsReports[]) {
		reportData.forEach(report => {
			this.reportsService.getShortcomingTypes(this.report_type, report.lib_warehouse_element_id, report.lib_warehouse_subelement_id).then((data3: ServerResponse) => {
				if (typeof data3 !== 'undefined') {
					if (typeof data3.data !== 'undefined') {
						data3.data.forEach(data4 => {
							if (data4.active === true) {
								if (report.warehouse_type_ids.indexOf(data4.id) !== -1) {
									let type = {
										id: data4.id,
										category_id: data4.category_id,
										subcategory_id: data4.subcategories,
										type_name: data4.type_name,
										amounts: data4.amounts,
										active: true,
										result: null
									};
									if (data4.amounts === true) {
										if (typeof report.warehouse_type_field_value !== 'undefined') {
											if (typeof report.warehouse_type_field_value[data4.id] !== 'undefined') {
												type.result = report.warehouse_type_field_value[data4.id];
											}
										}
									}
									if (typeof report.shortcoming_types === 'undefined') {
										report.shortcoming_types = [];
									}
									report.shortcoming_types.push(type);
								}
							}
						});

						this.reportsService.getShortcomingActions(this.report_type, report.lib_warehouse_element_id, report.lib_warehouse_subelement_id).then((data4: ServerResponse) => {
							if (typeof data4 !== 'undefined') {
								if (typeof data4.data !== 'undefined') {
									data4.data.forEach(data5 => {
										if (typeof report.warehouse_action_ids !== 'undefined') {
											if (report.warehouse_action_ids !== null) {
												if (report.warehouse_action_ids.indexOf(data5.id) !== -1) {
													if (typeof report.shortcoming_actions === 'undefined') {
														report.shortcoming_actions = [];
													}
													report.shortcoming_actions.push({
														id: data5.id,
														category_id: data5.category_id,
														subcategory_id: data5.subcategory_id,
														action_name: data5.action_name,
														active: true
													});
												}
											}
										}
									});
								}
							}
						});
					}
				}
			});
		});
		return reportData;
	}

	search($event) {
		this.searchString = $event;
		this.currentPage = 0;
		this.getReportsBuildingReports(false);
	}

	showBuildingMap() {
		this.reportsService.showReportBuildingMap(this.buildingMapUrl, true, this.mapMarkers).then(() => {
		});
	}}
