import {Injectable} from '@angular/core';
import {ApiService} from '../_interceptors/api.service';
import {ServerResponse} from '../_interfaces/server.response';

import {Languages} from '../_interfaces/languages';
import {Countries} from '../_interfaces/countries';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {GoogleMapsApiResponse, GoogleMapsCoordinates} from '../_interfaces/google-maps-api-response';
import {IndexedDBService} from './indexeddb.service';
import {SnackbarService} from './snackbar.service';
import {Settings} from '../_interfaces/settings';

@Injectable()

export class GeneralService {

	public itemsPerPage: number = 20;
	public itemsPerPageShort: number = 5;

	private debug: boolean = !environment.production;

	constructor(private apiService: ApiService,
				private snackbarService: SnackbarService,
				private indexedDBService: IndexedDBService,
				private http: HttpClient) {
	}

	getLanguages(): Promise<Languages[]> {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableLanguages).then(
						languagesDB => {
							if (typeof languagesDB !== 'undefined') {
								languagesDB.sort((a, b) => a.language_name.toLowerCase() > b.language_name.toLowerCase() ? 1 : -1);
								resolve(languagesDB);
							} else {
								this.syncLanguages()
									.then((languages: Languages[]) => {
										if (typeof languages !== 'undefined') {
											resolve(languages);
										} else {
											resolve(undefined);
										}
									})
									.catch(error => {
										reject();
										throw new Error(error);
									});
							}
						},
						() => {
							this.syncLanguages()
								.then((languages: Languages[]) => {
									if (typeof languages !== 'undefined') {
										resolve(languages);
									} else {
										resolve(undefined);
									}
								})
								.catch(error => {
									reject();
									throw new Error(error);
								});
						}
					);
				}
			});
		});
	}

	getCountries(): Promise<Countries[]> {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableCountries).then(
						countriesDB => {
							if (typeof countriesDB !== 'undefined') {
								countriesDB.sort((a, b) => a.country_name.toLowerCase() > b.country_name.toLowerCase() ? 1 : -1);
								resolve(countriesDB);
							} else {
								this.syncCountries()
									.then((countries: Countries[]) => {
										if (typeof countries !== 'undefined') {
											resolve(countries);
										} else {
											resolve(undefined);
										}
									})
									.catch(error => {
										reject();
										throw new Error(error);
									});
							}
						},
						() => {
							this.syncCountries()
								.then((countries: Countries[]) => {
									if (typeof countries !== 'undefined') {
										resolve(countries);
									} else {
										resolve(undefined);
									}
								})
								.catch(error => {
									reject();
									throw new Error(error);
								});
						}
					);
				}
			});
		});
	}

	getSettings(): Promise<Settings[]> {
		return new Promise((resolve, reject) => {
			this.indexedDBService.databaseReady.subscribe(event => {
				if (event) {
					this.indexedDBService.database.getAllFast(this.indexedDBService.tableSettings).then(
						settingsDB => {
							if (typeof settingsDB !== 'undefined') {
								if (settingsDB.length) {
									resolve(settingsDB);
								}
							}
						});

					this.syncSettings()
						.then((settings: Settings[]) => {
							if (typeof settings !== 'undefined') {
								resolve(settings);
							} else {
								resolve(undefined);
							}
						})
						.catch(error => {
							reject();
							throw new Error(error);
						});
				}
			});
		});
	}

	syncLanguages(): Promise<Languages[]> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('general/languages/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('general/languages', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.update(this.indexedDBService.tableLanguages, <Languages[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: 'languages',
															updated: server_date.data.updated
														})
															.then(() => resolve(<Languages[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncCountries(): Promise<Countries[]> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('general/countries/last-update-date').then((server_date: ServerResponse) => {
					if (server_date.success === true) {
						if (typeof server_date.data !== 'undefined') {
							if (typeof server_date.data.updated !== 'undefined') {
								this.apiService.get('general/countries', {
									start: 0,
									length: -1
								}).then((data: ServerResponse) => {
									if (typeof data.success !== 'undefined') {
										if (data.success === true) {
											if (typeof data.data !== 'undefined') {
												return this.indexedDBService.database.update(this.indexedDBService.tableCountries, <Countries[]>data.data)
													.then(() => {
														return this.indexedDBService.database.update(this.indexedDBService.tableDataSync, {
															name: this.indexedDBService.tableCountries,
															updated: server_date.data.updated
														})
															.then(() => resolve(<Countries[]>data.data))
															.catch(error => {
																if (this.debug) {
																	console.log(error);
																}
																throw new Error(error);
															});
													})
													.catch(error => {
														if (this.debug) {
															console.log(error);
														}
														throw new Error(error);
													});
											} else {
												resolve(undefined);
											}
										} else {
											resolve(undefined);
										}
									} else {
										resolve(undefined);
									}
								}).catch(error => {
									if (this.debug) {
										console.log(error);
									}
									throw new Error(error);
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					} else {
						resolve(undefined);
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve(undefined);
			}
		});
	}

	syncSettings(): Promise<Settings[]> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				this.apiService.get('settings').then((data: ServerResponse) => {
					if (typeof data !== 'undefined') {
						if (typeof data.success !== 'undefined') {
							if (data.success === true) {
								if (typeof data.data !== 'undefined') {
									let settings = [];
									Object.keys(data.data).map(key => {
										settings.push({
											name: key,
											value: data.data[key]
										});
									});
									return this.indexedDBService.database.updateBulk(this.indexedDBService.tableSettings, settings)
										.then(() => {
											resolve(<Settings[]>settings);
										})
										.catch(error => {
											if (this.debug) {
												console.log(error);
											}
											throw new Error(error);
										});
								} else {
									resolve();
								}
							} else {
								resolve();
							}
						} else {
							resolve();
						}
					} else {
						resolve();
					}
				}).catch(error => {
					if (this.debug) {
						console.log(error);
					}
					throw new Error(error);
				});
			} else {
				resolve();
			}
		});
	}

	getLatLngFromAddress(address): Promise<GoogleMapsCoordinates> {
		return new Promise(resolve => {
			if (this.apiService.isOnline) {
				return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?key=' + environment.google_api_key + '&address=' + address.join(','), {responseType: 'json'})
					.map((response: GoogleMapsApiResponse) => {
						if (typeof response !== 'undefined') {
							if (response.status === 'OK') {
								resolve({
									lat: response.results[0].geometry.location.lat,
									lng: response.results[0].geometry.location.lng
								});
							} else {
								resolve(undefined);
							}
						} else {
							resolve(undefined);
						}
					})
					.toPromise()
					.catch(error => {
						console.log(error);
						resolve(undefined);
					});
			} else {
				resolve(undefined);
			}
		});
	}
}
