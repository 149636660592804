<button mat-button color="primary-outline" [routerLink]="['/reports/warehouse/create/' + client_id]">Nieuwe magazijn rapportage toevoegen</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/ladders/create/' + client_id]">Nieuwe trappen rapportage toevoegen</button>
<button mat-button color="primary-outline" [routerLink]="['/reports/tent/create/' + client_id]">Nieuwe tent rapportage toevoegen</button>
<ng-container *ngIf="smallWidget === false">
	<button mat-button color="primary-outline" [routerLink]="['/clients/' + client_id + '/view']">Bekijk klant</button>
	<button mat-button color="primary-outline" [routerLink]="['/clients']">Bekijk alle klanten</button>
	<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
</ng-container>
<mat-nav-list *ngIf="reports.length" class="mat-nav-list-with-border">
	<div *ngIf="!smallWidget" infiniteScroll infiniteScrollDistance="1" (scrolled)="getReports()"></div>
	<mat-list-item *ngFor="let report of reports">
		<div class="mat-list-content" [routerLink]="['/reports/' + report.type + '/' + report.id + '/view']">
			<h4 matLine>{{ report.report_nr }}</h4>
			<a matLine>{{ report.type_text }} - {{ report.created | datex:'DD-MM-YYYY' }}</a>
		</div>
		<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item [routerLink]="['/reports/' + report.type + '/' + report.id + '/edit']">Aanpassen</a>
			<a mat-menu-item (click)="deleteReport(report.type, report.id)">Verwijderen</a>
			<mat-divider></mat-divider>
			<a mat-menu-item [routerLink]="['/reports/' + report.type + '/' + report.id + '/buildings/create']">Nieuw gebouw toevoegen</a>
			<a mat-menu-item [routerLink]="['/reports/' + report.type + '/' + report.id + '/attachments/create']">Nieuwe bijlage toevoegen</a>
			<a mat-menu-item [routerLink]="['/reports/' + report.type + '/' + report.id + '/comments/create']">Nieuwe opmerkingen toevoegen</a>
			<a *ngIf="report.type === 'warehouse'" mat-menu-item [routerLink]="['/reports/' + report.type + '/' + report.id + '/questionnaire']">Inspectieschema's</a>
			<a mat-menu-item [routerLink]="['/reports/' + report.type + '/' + report.id + '/send-to-client']">Verstuur naar klant</a>
		</mat-menu>
	</mat-list-item>
	<a mat-list-item *ngIf="smallWidget === true && totalItems > 5" [routerLink]="['/clients/' + client_id + '/reports']" class="mat-list-item-more">
		<span matLine>Bekijk meer ...</span>
		<button mat-icon-button>
			<mat-icon>chevron_right</mat-icon>
		</button>
	</a>
</mat-nav-list>
<div *ngIf="!reports.length">
	<br> Geen rapportages gevonden.
</div>
